const timezones = [
  ["LDN", "Europe/London"],
  ["EIR", "Europe/Dublin"],
  ["BLF", "Europe/Belfast"],
  ["EST", "America/New York"],
  ["CST", "America/Chicago"],
  ["MST", "America/Denver"],
  ["PST", "America/Los Angeles"],
  ["APN", "America/Phoenix"],
  ["TOR", "America/Toronto"],
  ["WIN", "America/Winnipeg"],
  ["EDM", "America/Edmonton"],
  ["VNC", "America/Vancouver"],
  ["AWST", "Australia/Perth"],
  ["ACWST", "Australia/Eucla"],
  ["ACST", "Australia/Darwin"],
  ["AEST", "Australia/Brisbane"],
  ["LHST", "Australia/Lord Howe"],
  ["NZST", "Pacific/Auckland"],
  ["CHAST", "Pacific/Chatham"],
  ["IST", "Asia/Kolkata"],
]

export default timezones