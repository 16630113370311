import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import { submitEmailToRecoverPassword } from "../../slices/auth";
import generateTranslatedText from "../../utils/boilerplate";
import { LifebuoyIcon } from "@heroicons/react/24/solid";
import { validateEmail } from "../../utils/validate";

export default function ForgotMyPasswordForm() {
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user } = useAppSelector((state) => state.auth);

  const [state, setState] = useState({
    email: "",
  });

  const onChange = (e: any) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

  async function submitEmailForPasswordRecovery(e: any) {
    e.preventDefault();

    const { email } = state;
    if (email === "") {
      dispatch(addAlert(generateTranslatedText("email_required", language), "danger"));
      return;
    }

    // Validate email
    const check = validateEmail(email);
    if (!check) {
      dispatch(addAlert(generateTranslatedText("invalid_email", language), "danger"));
      return;
    }

    // Submit email for recovery
    const res: any = await dispatch(submitEmailToRecoverPassword({ email }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("password_recovery_email_sent", language), "success"));
    } else {
      dispatch(addAlert(generateTranslatedText("password_recovery_email_error", language), "danger"));
    }
  }

  // Constant variables
  const language = user?.language || "EN";

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="bg-white dark:bg-flint rounded-xl p-8 w-full max-w-md space-y-8 drop-shadow">
        <div>
          <a href="/">
            <img
              className="mx-auto h-12 w-auto rounded-lg"
              src="/img/logo/logo_coloured_bg.svg"
              alt="Igloo"
            />
          </a>
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-flint dark:text-white">
            {generateTranslatedText("forgotten_your_password", language)}
          </h2>
          <p className="text-sm text-center mt-2 dark:text-light text-gray italic">{generateTranslatedText("forgotten_your_password_subheader", language)}</p>
        </div>
        <form
          className="mt-8 space-y-6"
          onSubmit={(e) => submitEmailForPasswordRecovery(e)}
        >
          <div className="-space-y-px rounded-md">
            <div>
              <label htmlFor="email-address" className="sr-only">
                {generateTranslatedText("email", language)}
              </label>
              <input
                id="email"
                name="email"
                autoComplete="email"
                required
                className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-flint placeholder-gray-500 focus:z-10 focus:border-info focus:outline-none focus:ring-info sm:text-sm dark:text-white bg-gray-50 dark:bg-zinc-800 dark:placeholder-gray-400"
                placeholder={generateTranslatedText("email_placeholder", language)}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-full border border-transparent bg-info py-2 px-4 text-sm font-medium text-white hover:bg-info-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LifebuoyIcon
                  className="h-5 w-5 fill-info-light group-hover:fill-white"
                  aria-hidden="true"
                />
              </span>
              {generateTranslatedText("submit", language)}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
