import { MicrophoneIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import Spinner from "../layout/Spinner";

export default function Dictaphone({ state, stateName, setState }: { state: any, stateName: string, setState: (e: any) => void }) {
  const dispatch = useAppDispatch();

  // Initalise speech recognition hook
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  // Update writing text on update of final transcript
  useEffect(() => {
    if (finalTranscript === "") return;
    console.log(transcript)
    const transcriptArray = finalTranscript.split(" ").map((word: string, index: number) => ({ index, value: word, highlighted: false, source: "voice" }));
    setState({ ...state, [stateName]: transcriptArray });
  }, [finalTranscript]);

  const [toggleRecorder, setToggleRecorder] = useState(false);

  function startRecording(e: any) {
    e.preventDefault();

    // Update toggle state
    setToggleRecorder(!toggleRecorder);

    // Start recording stream
    SpeechRecognition.startListening({ continuous: true });
  }

  function stopRecording(e: any) {
    e.preventDefault();

    // Update toggle state
    setToggleRecorder(!toggleRecorder);

    // Stop recording stream
    SpeechRecognition.stopListening();
    resetTranscript();
  }

  function toggleRecording(e: any) {
    if (toggleRecorder) {
      stopRecording(e);
      return;
    }
    startRecording(e);
    resetTranscript();
  }

  if (!browserSupportsSpeechRecognition) {
    // return <span>Browser doesn't support speech recognition.</span>;
    return null;
  }

  return (
    <>
      <div className="z-50 fixed bottom-0 right-24 inline-flex items-end flex flex-col">
        <button
          type="button"
          className={`group mr-3 my-3 inline-flex items-center rounded-full border border-transparent p-${!listening ? "6" : "8"} text-white shadow-sm focus:outline-none focus:ring-2 ${!listening ? "focus:ring-igloo-dark" : "focus:ring-info"} focus:ring-offset-2 ${!listening ? "bg-gradient-to-r from-igloo-light via-igloo to-igloo-dark" : "bg-gradient-to-r from-igloo-dark to-info"} background-animate drop-shadow`}
          onClick={(e) => toggleRecording(e)}
        >
          <MicrophoneIcon className={`h-${!listening ? "6" : "12"} w-${!listening ? "6" : "12"} text-white group-hover:text-zinc-800 dark:text-zinc-800 dark:group-hover:text-white" aria-hidden="true`} />
          {interimTranscript !== "" && <Spinner colour="white" size={6} />}
        </button>
      </div>
    </>
  );
}
