import Banner from "../layout/Banner";
import { useAppSelector } from "../../hooks";
import Keypad from "../emotion/Keypad";
import MobileSlideOver from "../layout/MobileSlideOver";
import DesktopSidebar from "../layout/DesktopSidebar";
import { useState } from "react";
import MobileTopNav from "../layout/MobileTopNav";
import { navigation } from "../../utils/navigation";

interface TemplateProps {
  children: React.ReactNode;
}

export default function Template({ children }: TemplateProps) {

  // Get app level state from redux store
  const { user } = useAppSelector((state) => state.auth);
  const authGroups = user === null ? [] : user.auth.groups.map((group) => group[1]);

  // Get component level state
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <div className="flex bg-ice dark:bg-zinc-800">

        {/* Add emotion keypad for individual users */}
        {authGroups.includes("Individual User") && <Keypad />}

        {/* Mobile slide over */}
        <MobileSlideOver
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          navigation={navigation({ profileId: user?.profile.id, authGroups, numNotifications: user?.notifications.length })}
        />

        {/* Static sidebar for desktop */}
        <DesktopSidebar navigation={navigation({ profileId: user?.profile.id, authGroups, numNotifications: user?.notifications.length })} />

        <div className="flex min-w-0 flex-1 flex-col">
          {/* Mobile top navigation */}
          <MobileTopNav setMobileMenuOpen={setMobileMenuOpen} />

          {/* Main body */}
          <main className="flex bg-ice dark:bg-zinc-800 justify-center">
            <div className="flex flex-col h-screen container">
              {children}
            </div>
          </main>
        </div>

        {/* Alert banner */}
        <Banner />
      </div>
    </>
  );
}