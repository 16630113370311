import { CalendarDaysIcon, FlagIcon, LinkIcon, MapPinIcon, RectangleGroupIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import Pill from "./Pill";
import { getColour } from "../../utils/colours";
import { getSolidIcon } from "../../utils/icons";

interface MetadataProps {
  createdDate?: string;
  updatedDate?: string | null;
  startDate?: string;
  endDate?: string;
  badge?: string[] | null;
}

export default function Metadata({ createdDate, updatedDate, startDate, endDate, badge }: MetadataProps) {

  return (
    <>
      <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">

        {/* Created date */}
        {createdDate !== null && (
          <div className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
            <CalendarDaysIcon className="mr-1.5 h-5 w-5 flex-shrink-0 dark:text-gray-300" aria-hidden="true" />
            {`Created on ${createdDate}`}
          </div>
        )}

        {/* Updated date */}
        {updatedDate !== null && (
          <div className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
            <CalendarDaysIcon className="mr-1.5 h-5 w-5 flex-shrink-0 dark:text-gray-300" aria-hidden="true" />
            {`Last updated on ${updatedDate}`}
          </div>
        )}

        {/* Start date */}
        {startDate !== null && (
          <div className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
            <CalendarDaysIcon className="mr-1.5 h-5 w-5 flex-shrink-0 dark:text-gray-300" aria-hidden="true" />
            {`Starting on ${startDate}`}
          </div>
        )}

        {/* End date */}
        {endDate !== null && (
          <div className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
            <FlagIcon className="mr-1.5 h-5 w-5 flex-shrink-0 dark:text-gray-300" aria-hidden="true" />
            {`Ending on ${endDate}`}
          </div>
        )}
      </div>

      {/* Badge */}
      {badge !== null && badge !== undefined && (
        <div className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
          <Pill
            text={badge[1]}
            bgColour={getColour(badge[0])}
            textColour={getColour(badge[0])}
            icon={getSolidIcon(badge[0], getColour(badge[0]), 3, "mr-2")}
          />
        </div>
      )}
    </>
  );
}

Metadata.defaultProps = {
  startDate: null,
  endDate: null,
  createdDate: null,
  updatedDate: null,
  badge: null,
}