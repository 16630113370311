import { useEffect, useState } from "react";
import Header from "../layout/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteAccountPermanently, getAuth, sendReferralEmail, updateProfile } from "../../slices/auth";
import { HandRaisedIcon, RocketLaunchIcon, SparklesIcon, TrashIcon } from "@heroicons/react/24/solid";
import generateTranslatedText from "../../utils/boilerplate";
import { addAlert } from "../../slices/alert";
import Template from "../app/Template";
import SpaceFiller from "../layout/SpaceFiller";
import Spinner from "../layout/Spinner";
import Container from "../layout/Container";
import Card from "../layout/Card";
import TextInput from "../layout/TextInput";
import Modal from "../layout/Modal";
import { Dialog } from "@headlessui/react";
import { cancelPremiumMembership, createPaymentSessionPortal } from "../../slices/payment";
import Button from "../layout/Button";
import { ClipboardDocumentCheckIcon, ClipboardDocumentIcon, CreditCardIcon } from "@heroicons/react/24/outline";
import Dropdown from "../layout/Dropdown";
import timezones from "../../utils/timezones";
import Checkboxes from "../layout/Checkboxes";
import frequency from "../../utils/email";
import { buildSubscriptionPurchaseUrl } from "../../utils/proxy";

interface UpdateProfileData {
  current_password: string;
  update_password: string;
  confirm_password: string;
  timezone: string;
  wants_emails: boolean | null;
  email_frequency: string | null;
}

export default function Profile() {
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user: userData, loading: userLoading, email: emailObject } = useAppSelector(
    (state) => state.auth
  );
  const { cancellation: paymentCancellation, portal: paymentPortal, waiting: paymentWaiting } = useAppSelector((state) => state.payment);

  useEffect(() => {
    dispatch(getAuth());
  }, [dispatch]);

  // Get component level state
  const [updateFormData, setUpdateFormData] = useState<UpdateProfileData>({
    current_password: "",
    update_password: "",
    confirm_password: "",
    timezone: "",
    wants_emails: null,
    email_frequency: null,
  });
  const [showCancelPremiumMembership, setShowCancelPremiumMembership] = useState<boolean>(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState<boolean>(false);
  const [referralEmail, setReferralEmail] = useState<string>("");
  const [itemCopied, setItemCopied] = useState<boolean>(false);

  // Functions
  function openCancelPremiumMembershipModal(e: any) {
    e.preventDefault();
    setShowCancelPremiumMembership(true);
  }

  function openDeleteAccountModal(e: any) {
    e.preventDefault();
    setShowDeleteAccount(true);
  }

  async function submitForm(e: any) {
    e.preventDefault();
    if (userData === null) return;
    if (updateFormData.current_password.length > 0) {
      if (updateFormData.update_password.length === 0) {
        dispatch(addAlert(generateTranslatedText("missing_new_password", language), "warning"));
        return;
      }
      if (updateFormData.confirm_password.length === 0) {
        dispatch(addAlert(generateTranslatedText("missing_confirm_password", language), "warning"));
        return;
      }
    }
    console.log(updateFormData.timezone);
    const obj = {
      language: userData ? userData?.language : "EN",
      username: userData?.username,
      currentPassword: updateFormData.current_password,
      password: updateFormData.update_password,
      confirmPassword: updateFormData.confirm_password,
      timezone: updateFormData.timezone === "" ? userData?.timezone[0] : updateFormData.timezone,
      wantsEmails: updateFormData.wants_emails === null ? userData?.email.wants_emails : updateFormData.wants_emails,
      emailFrequency: updateFormData.email_frequency === null ? userData?.email.frequency[0] : updateFormData.email_frequency,
    };
    const res: any = await dispatch(updateProfile(obj));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("updated_profile", language), "success", 2000));
      setUpdateFormData({
        current_password: "",
        update_password: "",
        confirm_password: "",
        timezone: userData?.timezone || "",
        wants_emails: null,
        email_frequency: null,
      });
    } else {
      dispatch(
        addAlert(generateTranslatedText("updated_profile_error", language), "danger", 2000)
      );
    }
  }

  async function confirmCancelPremiumMembership(e: any) {
    e.preventDefault();
    setShowCancelPremiumMembership(false);
    const res: any = await dispatch(cancelPremiumMembership());
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("premium_membership_cancelled", language), "success"));
      dispatch(getAuth());
    } else {
      dispatch(addAlert(generateTranslatedText("premium_membership_cancelled_error", language), "danger"));
    }
  }

  async function confirmDeleteAccount(e: any) {
    e.preventDefault();
    setShowDeleteAccount(false);
    const res: any = await dispatch(deleteAccountPermanently());
    if (res.payload.status === 205) {
      dispatch(addAlert(generateTranslatedText("delete_account_permanently", language), "success"));
    } else {
      dispatch(addAlert(generateTranslatedText("delete_account_permanently_error", language), "danger"));
    }
  }

  async function redirectToPaymentSessionPortal(e: any) {
    const res: any = await dispatch(createPaymentSessionPortal());
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("payment_session_portal_redirect", language), "success"));
      window.location.href = res.payload.data.data;
    } else {
      dispatch(addAlert(generateTranslatedText("payment_session_portal_redirect_error", language), "danger"));
    }
  }

  async function triggerSendReferralEmail(e: any) {
    e.preventDefault();
    if (referralEmail === "") {
      dispatch(addAlert(generateTranslatedText("missing_email", language), "warning"));
      return;
    }
    const res: any = await dispatch(sendReferralEmail({ email: referralEmail }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("referral_email_sent", language), "success"));
      setReferralEmail("");
    } else if (res.payload.status === 403) {
      dispatch(addAlert(generateTranslatedText("referral_email_sent_duplicate", language), "danger", 4000));
    } else {
      dispatch(addAlert(generateTranslatedText("referral_email_sent_error", language), "danger", 4000));
    }
  }

  function copyToClipboard(e: any, toCopy: string) {
    e.preventDefault();
    navigator.clipboard.writeText(toCopy);
    setItemCopied(true);
  }

  // Constant variables
  const language = userData?.language || "EN";

  return (
    <Template>

      {/* Cancel premium membership modal */}
      <Modal
        show={showCancelPremiumMembership}
        setShow={setShowCancelPremiumMembership}
        confirmText={generateTranslatedText("cancel_premium_confirm_text", language)}
        cancelText={generateTranslatedText("cancel_premium_cancel_text", language)}
        confirmHandler={confirmCancelPremiumMembership}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
              {generateTranslatedText("cancel_premium", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">
              <p className="text-sm text-gray-400 dark:text-gray-200">{generateTranslatedText("cancel_premium_confirmation", language)}</p>
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete account modal */}
      <Modal
        show={showDeleteAccount}
        setShow={setShowDeleteAccount}
        confirmText={generateTranslatedText("delete_account_confirm_text", language)}
        cancelText={generateTranslatedText("delete_account_cancel_text", language)}
        confirmHandler={confirmDeleteAccount}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
              {generateTranslatedText("delete_account", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">
              <p className="text-sm text-gray-400 dark:text-gray-200">{
                userData?.subscription.type === "P" ? generateTranslatedText("delete_account_premium_confirmation", language) : generateTranslatedText("delete_account_free_confirmation", language)
              }</p>
            </div>
          </div>
        </div>
      </Modal>

      {/* Profile information */}
      <Container className="pt-0 overflow-y-auto h-screen scrollbar-invisible">
        {userLoading || paymentWaiting === 2 ? (
          <SpaceFiller>
            <Spinner colour="info" />
          </SpaceFiller>
        ) : (
          <>

            {/* Header */}
            <Header text="Profile" />

            {/* Profile form */}
            <Card className="mx-1 max-w-100">
              <form
                className="space-y-8 divide-y divide-gray-200"
                onSubmit={(e) => submitForm(e)}
              >
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="grid grid-cols-1 gap-y-8">

                    {/* Date joined */}
                    <div>
                      <div className="mt-1 flex">
                        <p className="text-gray-400 text-xs italic">
                          {userData?.username}{generateTranslatedText("joined_igloo_on", language)}{" "}
                          {userData?.profile.datetime_created}
                        </p>
                      </div>
                    </div>

                    {/* Refer a friend */}
                    {userData?.subscription.type === "P" && (
                      <>
                        <div className="border-t" />
                        <div>
                          <span className="block text-sm font-medium text-gray-700 dark:text-white text-left">{generateTranslatedText("refer_a_friend", language)}</span>
                          <p className="text-gray-400 dark:text-white text-xs">{generateTranslatedText("refer_a_friend_subtext", language)}</p>

                          <div className="flex items-center py-3">
                            <input
                              type="text"
                              className="block w-full appearance-none rounded-l-md bg-transparent border border-gray-200 px-3 py-2 text-gray-900 placeholder-gray-400 focus:ring-0 focus:border-gray-200 sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white"
                              placeholder={generateTranslatedText("friend_email_placeholder", language)}
                              value={referralEmail}
                              onChange={(e) => setReferralEmail(e.target.value)}
                            />
                            <button
                              className="flex inline-flex flex-shrink-0 bg-success hover:bg-success-dark rounded-r-md text-sm font-medium text-white dark:text-flint border border-success hover:border-success-dark px-3 py-2"
                              type="button"
                              onClick={triggerSendReferralEmail}
                              disabled={emailObject.referral.loading}
                            >
                              {emailObject.referral.loading && (
                                <Spinner colour="white" size={5} className="mr-2" />
                              )}
                              {generateTranslatedText("send_referral_link", language)}
                            </button>
                          </div>
                          <p className="text-gray-400 dark:text-white text-xs">
                            {generateTranslatedText("or_copy_this_link", language)}
                            <br />
                            <button onClick={(e) => copyToClipboard(e, `https://entertheigloo.xyz/register?referral_id=${userData.profile.id}`)}>
                              {!itemCopied ? (
                                <ClipboardDocumentIcon className="h-4 w-4 text-gray-400 dark:text-white hover:text-gray-600 dark:hover:text-slate-200 inline-block mr-1" aria-hidden="true" />
                              ) : (
                                <ClipboardDocumentCheckIcon className="h-4 w-4 text-success hover:text-success-dark inline-block mr-1" aria-hidden="true" />
                              )}
                            </button>
                            <span className="underline">{`https://entertheigloo.xyz/register?referral_id=${userData.profile.id}`}</span>
                          </p>
                        </div>
                      </>
                    )}

                    {/* Subscription */}
                    {userData?.profile.has_payment_portal_access && (
                      <>
                        <div className="border-t" />
                        <div>
                          <label className="mb-3 block text-sm font-medium text-gray-700 dark:text-white text-left">
                            {generateTranslatedText("subscription", language)}
                          </label>
                          <span className="flex inline-flex">
                            <p className="text-gray-400 dark:text-white text-md mr-2">
                              {userData?.subscription.type === "F" ? generateTranslatedText("free", language) : generateTranslatedText("premium", language)}
                            </p>
                            {userData?.subscription.type === "F" ? (
                              <HandRaisedIcon className="h-5 w-5 text-igloo" aria-hidden="true" />
                            ) : (
                              <RocketLaunchIcon className="h-5 w-5 text-warning" aria-hidden="true" />
                            )}
                          </span>
                          {userData?.subscription.type === "F" ? (
                            <p><a href={buildSubscriptionPurchaseUrl()} target="_blank" className="text-warning hover:underline hover:text-warning-dark text-sm font-bold mr-2" rel="noreferrer">{generateTranslatedText("click_to_upgrade_premium", language)}</a></p>
                          ) : (
                            <>
                              {/* Cancel membership */}
                              <p className="text-danger hover:underline hover:text-danger-dark text-xs font-bold cursor-pointer" onClick={openCancelPremiumMembershipModal}>{generateTranslatedText("cancel_premium_membership", language)}</p>

                              {/* Update payment method */}
                              <div>
                                <Button
                                  type="button"
                                  className="mt-4 inline-flex justify-center rounded-full border border-transparent bg-igloo hover:bg-igloo-dark drop-shadow py-1 px-2 text-sm font-medium text-white dark:text-flint focus:outline-none focus:ring-2 focus:ring-igloo focus:ring-offset-2"
                                  onClick={redirectToPaymentSessionPortal}
                                >
                                  {paymentWaiting === 0 ? (
                                    <CreditCardIcon
                                      className="mr-2 h-5 w-5 text-white dark:text-flint"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <Spinner colour="white" size={5} className="mr-2" />
                                  )}
                                  {generateTranslatedText("update_payment_method", language)}
                                </Button>
                              </div>
                            </>

                          )}
                        </div>
                      </>
                    )}

                    {/* Timezone */}
                    <div className="border-t" />
                    <Dropdown
                      id="timezone"
                      className="col-span-full"
                      label={generateTranslatedText("timezone", language)}
                      showLabel={true}
                      required={false}
                      state={updateFormData}
                      stateName="timezone"
                      onChange={setUpdateFormData}
                      defaultValue={userData === null ? updateFormData.timezone : userData.timezone[0]}
                    >
                      <option value="">{generateTranslatedText("timezone_default", language)}</option>
                      {timezones.map((opt) => (
                        <option key={opt[0]} value={opt[0]}>{opt[1]}</option>
                      ))}
                    </Dropdown>

                    {/* Current password */}
                    <div className="border-t" />
                    <TextInput
                      id="current_password"
                      type="password"
                      label={generateTranslatedText("current_password", language)}
                      className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                      required={false}
                      showLabel={true}
                      state={updateFormData}
                      stateName="current_password"
                      onChange={setUpdateFormData}
                    />

                    {/* Update password */}
                    <TextInput
                      id="update_password"
                      type="password"
                      label={generateTranslatedText("new_password", language)}
                      className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                      required={false}
                      showLabel={true}
                      state={updateFormData}
                      stateName="update_password"
                      placeholder={generateTranslatedText("new_password_placeholder", language)}
                      onChange={setUpdateFormData}
                    />

                    {/* Update password */}
                    <TextInput
                      id="confirm_password"
                      type="password"
                      label={generateTranslatedText("confirm_new_password", language)}
                      className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                      required={false}
                      showLabel={true}
                      state={updateFormData}
                      stateName="confirm_password"
                      placeholder={generateTranslatedText("confirm_new_password_placeholder", language)}
                      onChange={setUpdateFormData}
                    />

                    {/* Email preferences */}
                    <div className="border-t" />
                    <Checkboxes
                      id="wants_emails"
                      label={generateTranslatedText("notifications", language)}
                      showLabel={true}
                      options={[
                        [true, generateTranslatedText("like_to_receive_emails", language), generateTranslatedText("like_to_receive_emails_subtext", language), null, null]
                      ]}
                      state={updateFormData}
                      stateName="wants_emails"
                      onChange={setUpdateFormData}
                      baseValue={userData?.email.wants_emails}
                    />

                    {updateFormData.wants_emails || (updateFormData.wants_emails === null && userData?.email.wants_emails) ? (
                      <Dropdown
                        id="email_frequency"
                        className="col-span-full"
                        label={generateTranslatedText("email_frequency", language)}
                        showLabel={true}
                        required={false}
                        state={updateFormData}
                        stateName="email_frequency"
                        onChange={setUpdateFormData}
                        defaultValue={userData === null ? updateFormData.email_frequency : userData.email.frequency[0]}
                      >
                        <option value="">{generateTranslatedText("email_frequency_default", language)}</option>
                        {frequency.map((opt) => (
                          <option key={opt[0]} value={opt[0]}>{opt[1]}</option>
                        ))}
                      </Dropdown>
                    ) : null}

                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end border-none border-0">
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center rounded-full border border-transparent bg-info drop-shadow py-2 px-4 text-sm font-medium text-white hover:underline focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
                    >
                      <SparklesIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      {generateTranslatedText("save", language)}
                    </button>
                  </div>
                </div>
              </form>
            </Card>

            {/* Danger zone */}
            <Card className="mx-1 max-w-100 my-10">

              <div>
                <label className="block text-xs font-bold text-gray-400 dark:text-white underline">
                  {generateTranslatedText("danger_zone", language)}
                </label>
                <div>
                  <Button
                    type="button"
                    className="group mt-4 inline-flex justify-center rounded-full border border-transparent hover:bg-danger/10 py-1 px-2 text-xs font-medium text-gray-400 dark:text-white hover:text-danger focus:outline-none focus:ring-2 focus:ring-danger focus:ring-offset-2"
                    onClick={openDeleteAccountModal}
                  >
                    <TrashIcon
                      className="mr-2 h-4 w-4 text-gray-400 dark:text-white group-hover:text-danger"
                      aria-hidden="true"
                    />
                    {generateTranslatedText("delete_account", language)}
                  </Button>
                </div>
              </div>

            </Card>
          </>

        )}
      </Container>

    </Template>
  );
}
