interface SearchBarProps {
  className?: string;
  placeholder?: string;
  state: { [key: string]: any };
  stateName: string;
  setState: (e: any) => void;
}

export default function SearchBar({
  className,
  placeholder,
  state,
  stateName,
  setState,
}: SearchBarProps) {
  return (
    <div className={`${className}`}>
      <div className="relative flex items-center">
        {/* <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div> */}
        <input
          type="text"
          name="search"
          id="search"
          placeholder={placeholder}
          // className="block w-full rounded-md border-0 py-1.5 pl-10 pr-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          className="block w-full rounded-full border-0 py-1.5 px-4 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          onChange={(e) => setState({ ...state, [stateName]: e.target.value })}
        />
      </div>
    </div>
  );
}

SearchBar.defaultProps = {
  className: "",
  placeholder: "Enter a search term here...",
};
