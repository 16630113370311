import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";
import { buildUrl } from "../utils/proxy";

interface EntryState {
  id: string;
  datetime_sort: number;
  datetime_created: string;
  datetime_updated: string;
  title: string;
  subject: [string, string];
  text: string;
}

export interface HistoryState {
  id: string;
  datetime_sort: number;
  datetime_created: string;
  datetime_updated: string;
  type: string;
  content: {
    title: string | null;
    subject: [string, string] | null;
    text: string;
    icon: string | null;
  }
}

interface HistoryEntryState {
  data: Array<HistoryState>;
  date_range: {
    start_date: string | null;
    end_date: string | null;
  }
  loading: boolean;
  pagination: {
    has_previous: boolean;
    has_next: boolean;
    current_page: number;
    total_pages: number;
    total_objects: number;
  }
  options: Array<[string, string]>;
}

// Define a type for the slice state
interface DiaryEntryState {
  data: Array<EntryState>;
  history: HistoryEntryState;
  loading: boolean;
  options: Array<[string, string]>;
}

// Define the initial state using that type
const initialState: DiaryEntryState = {
  data: [],
  history: {
    data: [],
    date_range: {
      start_date: null,
      end_date: null,
    },
    pagination: {
      has_previous: false,
      has_next: false,
      current_page: 1,
      total_pages: 1,
      total_objects: 0
    },
    loading: true,
    options: []
  },
  loading: true,
  options: []
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get diary entries
export const getDiaryEntries = createAsyncThunk(
  "diary/get",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/diary`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Create diary entry
export const createDiaryEntry = createAsyncThunk(
  "diary/post",
  async (
    obj: { title: string; subject: string; text: string },
    { getState, rejectWithValue }
  ) => {
    try {
      const { auth } = getState() as { auth: AuthState };
      const { title, subject, text } = obj;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };
      const body = JSON.stringify({
        profile: auth.user?.profile.id,
        title,
        subject,
        text,
      });
      const res = await axios.post(buildUrl(`/api/diary`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Update diary entry
export const updateDiaryEntry = createAsyncThunk(
  "diary/patch",
  async (
    obj: {
      entryId: string | undefined;
      type: string;
      title: string | null;
      subject: string | null;
      text: string;
      icon: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };

      // Build body
      const body = JSON.stringify({
        profile: auth.user?.profile.id,
        id: obj.entryId,
        title: obj.title,
        subject: obj.subject,
        text: obj.text,
      });
      const res = await axios.patch(buildUrl(`/api/diary`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Update emotion entry
export const updateEmotionEntry = createAsyncThunk(
  "emotion/patch",
  async (
    obj: {
      entryId: string | undefined;
      type: string;
      icon: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };

      // Build body
      const body = JSON.stringify({
        profile: auth.user?.profile.id,
        id: obj.entryId,
        emoji: obj.icon,
      });
      const res = await axios.patch(buildUrl(`/api/emotion`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Delete diary entry
export const deleteDiaryEntry = createAsyncThunk(
  "diary/delete",
  async (
    obj: {
      entryId: string | undefined;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const res = await axios.delete(buildUrl(`/api/diary`), { data: { id: obj.entryId }, headers: { Authorization: `Bearer ${auth.access}` } });
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get diary entry subjects
export const getDiaryEntrySubjects = createAsyncThunk(
  "diary/subjects/get",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/diary/subjects`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get history
export const getHistory = createAsyncThunk(
  "history/get",
  async (obj: { pageNum: number, dateRange: string | null }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      const params: { [key: string]: string } = {};
      if (obj.pageNum !== null) params.page = obj.pageNum.toString();
      if (obj.dateRange !== null) params.dateRange = obj.dateRange;
      const searchParams = new URLSearchParams(params);

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/diary/history?${searchParams.toString()}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Delete emotion entry
export const deleteEmotionEntry = createAsyncThunk(
  "history/emotion/delete",
  async (obj: { emotionId: string }, { getState, rejectWithValue }) => {
    try {
      const { auth } = getState() as { auth: AuthState };
      const res = await axios.delete(buildUrl(`/api/emotion`), { data: { id: obj.emotionId }, headers: { Authorization: `Bearer ${auth.access}` } });
      return { data: res.data, status: res.status };
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const diarySlice = createSlice({
  name: "diary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Create diary entry
      .addCase(createDiaryEntry.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDiaryEntry.fulfilled, (state, action) => {
        state.data = state.data.length === 0 ? [action.payload.data] : [action.payload.data, ...state.data];
        state.loading = false;
      })
      .addCase(createDiaryEntry.rejected, (state) => {
        state.loading = false;
      })

      // Update diary entry
      .addCase(updateDiaryEntry.pending, (state) => {
        state.history.loading = true;
      })
      .addCase(updateDiaryEntry.fulfilled, (state, action) => {
        const updatedData: any[] = [];
        const previousData = state.history.data;
        for (let i = 0; i < previousData.length; i++) {
          const obj = previousData[i];
          if (obj.id === action.payload.data.id) {
            updatedData.push({
              ...obj,
              datetime_updated: action.payload.data.datetime_updated,
              content: {
                title: action.payload.data.title,
                subject: action.payload.data.subject,
                text: action.payload.data.text,
                icon: null,
              }
            });
          } else {
            updatedData.push(obj);
          }
        }
        state.history.data = updatedData;
        state.history.loading = false;
      })
      .addCase(updateDiaryEntry.rejected, (state) => {
        state.history.loading = false;
      })

      // Update emotion entry
      .addCase(updateEmotionEntry.pending, (state) => {
        state.history.loading = true;
      })
      .addCase(updateEmotionEntry.fulfilled, (state, action) => {
        const updatedData: any[] = [];
        const previousData = state.history.data;
        for (let i = 0; i < previousData.length; i++) {
          const obj = previousData[i];
          if (obj.id === action.payload.data.id) {
            updatedData.push({
              ...obj,
              datetime_updated: action.payload.data.datetime_updated,
              content: {
                title: null,
                subject: null,
                text: action.payload.data.text,
                icon: action.payload.data.icon,
              }
            });
          } else {
            updatedData.push(obj);
          }
        }
        state.history.data = updatedData;
        state.history.loading = false;
      })
      .addCase(updateEmotionEntry.rejected, (state) => {
        state.history.loading = false;
      })

      // Delete diary entry
      .addCase(deleteDiaryEntry.pending, (state) => {
        state.history.loading = true;
      })
      .addCase(deleteDiaryEntry.fulfilled, (state, action) => {
        const updatedData = state.history.data.filter((x) => x.id !== action.payload.data.id);
        state.history.data = updatedData;
        state.history.loading = false;
      })
      .addCase(deleteDiaryEntry.rejected, (state) => {
        state.history.loading = false;
      })

      // Get diary entries
      .addCase(getDiaryEntries.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDiaryEntries.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getDiaryEntries.rejected, (state) => {
        state.loading = false;
      })

      // Get diary entry subjects
      .addCase(getDiaryEntrySubjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDiaryEntrySubjects.fulfilled, (state, action) => {
        state.options = action.payload.data;
        state.loading = false;
      })
      .addCase(getDiaryEntrySubjects.rejected, (state) => {
        state.loading = false;
      })

      // Get history
      .addCase(getHistory.pending, (state) => {
        state.history.loading = true;
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        state.history.data = action.payload.data.data;
        state.history.pagination = action.payload.data.pagination;
        state.history.date_range = action.payload.data.date_range;
        state.history.loading = false;
      })
      .addCase(getHistory.rejected, (state) => {
        state.history.loading = false;
      })

      // Delete emotion entry
      .addCase(deleteEmotionEntry.pending, (state) => {
        state.history.loading = true;
      })
      .addCase(deleteEmotionEntry.fulfilled, (state, action) => {
        const updatedData = state.history.data.filter((entry) => entry.id !== action.payload.data.id);
        state.history.data = updatedData
        state.history.loading = false;
      })
      .addCase(deleteEmotionEntry.rejected, (state) => {
        state.history.loading = false;
      })
  },
});

export default diarySlice.reducer;
