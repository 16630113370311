export default function DividerWithLabel({ text, className = "" }: { text: string, className?: string }) {
  return (
    <div className={className}>
      <div className="relative">
        <div aria-hidden="true" className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-ice dark:bg-zinc-800 px-2 text-sm text-gray-400">{text}</span>
        </div>
      </div>
    </div>
  )
}
