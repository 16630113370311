export function capitalise(text: string | null) {
  if (text === null) return "";
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function lowercase(text: string) {
  return text.toLowerCase()
}

export function pluralise(text: string) {
  const lastChar = text.charAt(text.length - 1)
  if (lastChar === "s") {
    return `${text}'`
  }
  return `${text}'s`
}

export function joinStrings(arr: any[], separator: string = ", ", finalJoiner: string = "and") {
  if (arr.length < 2) return arr.join(separator)
  const front = arr.slice(0, arr.length - 1)
  const back = arr.slice(arr.length - 1)
  return `${front.join(separator)} ${finalJoiner} ${back.join(separator)}`
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
