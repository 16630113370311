
export function getDatesBetween(startDate: Date, endDate: Date) {
  const currentDate = new Date(startDate.getTime());
  const dates = [];
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

export function formatDateStandard(date: Date) {
  const year = `${date.getFullYear()}`;
  let month: number | string = date.getMonth() + 1;
  let day: number | string = date.getDate();

  if (month < 10 && month.toString().length === 1) {
    month = `0${month.toString()}`;
  }

  if (day < 10 && day.toString().length === 1) {
    day = `0${day.toString()}`;
  }
  const dateString = `${year}-${month}-${day}`;
  return dateString;
}

export function createDateFromString(date: string) {
  return new Date(Date.parse(date));
}
