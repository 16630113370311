import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Container from "../layout/Container";
import SpaceFiller from "../layout/SpaceFiller";
import Spinner from "../layout/Spinner";
import { ArrowRightCircleIcon, SparklesIcon, TrophyIcon } from "@heroicons/react/24/solid";
import getButtonStyle from "../../utils/styles";
import { useSearchParams } from "react-router-dom";
import Link from "../layout/Link";
import { confirmSubscriptionPaymentSession } from "../../slices/payment";
import { XCircleIcon } from "@heroicons/react/24/outline";

export default function PaymentConfirmation() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const checkoutSessionId = searchParams.get("checkout_session_id");

  useEffect(() => {
    dispatch(confirmSubscriptionPaymentSession({checkoutSessionId}));
  }, [checkoutSessionId, dispatch]);

  const { loading, success, response } = useAppSelector(
    (state) => state.payment
  );

  return (
    <div className="w-full h-screen bg-igloo">

      <Container className="pt-0 overflow-y-auto h-screen scrollbar-invisible">
        {loading ? (
          <SpaceFiller>
            <Spinner/>
          </SpaceFiller>
        ) : success ? (
          <SpaceFiller>
            <Container className="justify-center bg-white dark:bg-zinc-800 p-4 rounded-lg grid grid-cols-1 gap-y-8 drop-shadow">

              <h1 className="text-4xl font-bold tracking-tight text-flint dark:text-white sm:text-5xl mx-auto text-center">
                Upgrade successful!
              </h1>
              <p className="text-flint dark:text-white text-center">
                Congratulations, you're now a Premium Igloo member!
              </p>
              <TrophyIcon className="mx-auto h-20 w-20 animate-bounce text-warning" aria-hidden="true" />

              <Link
                href="/profile"
                className={`${getButtonStyle("solid", "info")} rounded-full drop-shadow mt-4 text-center justify-center`}
              >
                <span className="mx-auto flex inline-flex">
                  <SparklesIcon className="h-5 w-5 mr-2 text-white" aria-hidden="true" />
                  {`Visit your profile`}
                </span>
              </Link>

            </Container>
          </SpaceFiller>
        ) : (
          <SpaceFiller>
            <Container className="justify-center bg-white dark:bg-zinc-800 p-4 rounded-lg grid grid-cols-1 gap-y-8 drop-shadow">

              <h1 className="text-4xl font-bold tracking-tight text-flint dark:text-white sm:text-5xl mx-auto text-center">
                Whoops, something went wrong!
              </h1>
              <p className="text-flint dark:text-white text-center">
                Don't worry... any payment taken will be refunded. Visit your profile and try again.
              </p>
              <XCircleIcon className="mx-auto h-20 w-20 animate-bounce text-danger" aria-hidden="true" />

              <Link
                href="/profile"
                className={`${getButtonStyle("solid", "info")} rounded-full drop-shadow mt-4 text-center justify-center`}
              >
                <span className="mx-auto flex inline-flex">
                  <ArrowRightCircleIcon className="mx-auto h-5 w-5 mr-2 text-white" aria-hidden="true" />
                  {`Go back to my profile`}
                </span>
              </Link>

              </Container>

          </SpaceFiller>

        )}
      </Container>

    </div>
  );
}
