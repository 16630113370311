import { BellIcon } from "@heroicons/react/20/solid";
import {
  BellAlertIcon,
  BookOpenIcon,
  CalendarDaysIcon,
  EyeIcon,
  FireIcon,
  MegaphoneIcon,
  PencilIcon,
  TrophyIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/24/solid";

export function navigation({ numNotifications, profileId, authGroups }: { numNotifications?: number, profileId: string | undefined, authGroups: string[] }) {

  const allPages = [
    { name: "Write", id: "write-nav", href: "/write", icon: PencilIcon, alert: false, allowedGroups: ["Individual User"] },
    { name: "History", id: "history-nav", href: "/history", icon: BookOpenIcon, alert: false, allowedGroups: ["Individual User"] },
    { name: "Trends", id: "trends-nav", href: "/trends", icon: FireIcon, alert: false, allowedGroups: ["Individual User"] },
    { name: "Community", id: "community-nav", href: "/community", icon: UserGroupIcon, alert: false, allowedGroups: ["Individual User"] },
    { name: "Goals", id: "goals-nav", href: "/goals", icon: TrophyIcon, alert: false, allowedGroups: ["Individual User"] },
    { name: "Calendar", id: "calendar-nav", href: "/calendar", icon: CalendarDaysIcon, alert: false, allowedGroups: ["Individual User"] },
    { name: "Notifications", id: "notifications-nav", href: "/notifications", icon: numNotifications === 0 ? BellIcon : BellAlertIcon, alert: numNotifications !== undefined && numNotifications > 0, allowedGroups: ["Individual User"] },
    { name: "Profile", id: "profile-nav", href: "/profile", icon: UserIcon, alert: false, allowedGroups: ["Individual User"] },
    { name: "Overview", id: "overview-nav", href: "/entity/overview", icon: EyeIcon, alert: false, allowedGroups: ["Entity Owner", "Entity Administrator"] },
    { name: "Announcements", id: "announcements-nav", href: "/entity/announcements", icon: MegaphoneIcon, alert: false, allowedGroups: ["Entity Owner", "Entity Administrator"] },
  ]

  // Filter pages based on auth groups
  const filteredPages = allPages.filter(page => page.allowedGroups.filter(x => authGroups.includes(x)).length > 0)

  return filteredPages
}

navigation.defaultProps = {
  numNotifications: 0,
  memberType: "User"
}