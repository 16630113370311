import { ChatBubbleLeftEllipsisIcon, EyeIcon } from "@heroicons/react/24/outline";
import Footer from "../landing/Footer";
import LandingNav from "../landing/LandingNav";
import Banner from "../layout/Banner";
import StickyContent from "../layout/StickyContent";
import { CheckBadgeIcon, HeartIcon, KeyIcon, LockClosedIcon, PencilSquareIcon, RocketLaunchIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/solid";
import generateTranslatedText from "../../utils/boilerplate";
import { useAppSelector } from "../../hooks";
import { capitalise } from "../../utils/text";


export default function About() {
  const { user } = useAppSelector((state) => state.auth);
  const language = user?.language || "EN";
  const features = [
    {
      name: generateTranslatedText("safe_space", language),
      description: generateTranslatedText("safe_space_description", language),
      href: '#safe-space',
      icon: LockClosedIcon,
    },
    {
      name: generateTranslatedText("expression", language),
      description: generateTranslatedText("expression_description", language),
      href: '#expression',
      icon: ChatBubbleLeftEllipsisIcon,
    },
    {
      name: generateTranslatedText("awareness", language),
      description: generateTranslatedText("awareness_description", language),
      href: '#awareness',
      icon: EyeIcon,
    },
  ]

  return (
    <div className="bg-white dark:bg-zinc-800">
      <div className="w-full h-screen bg-white dark:bg-zinc-800">
        {/* Navbar */}
        <LandingNav />

        <div className="bg-white dark:bg-zinc-800">
          <div className="container mx-auto mt-10 bg-white dark:bg-zinc-800">
            <div className="pb-8 sm:pb-12 lg:pb-12">

              {/* Stats */}
              <div className="mx-auto max-w-7xl px-6 lg:px-8 sm:pt-10 sm:pb-20 pt-10 pb-20">
                <div className="mx-auto max-w-2xl lg:mx-0">
                  <h2 className="text-3xl font-bold tracking-tight text-flint dark:text-white sm:text-4xl">
                    {generateTranslatedText("about_stats_header", language)}

                  </h2>
                  <p className="mt-6 text-base leading-7 text-slate-600 dark:text-slate-300">
                    {generateTranslatedText("about_stats_subheader", language)}
                  </p>
                </div>
                <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
                  <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-slate-100 dark:bg-zinc-700 hover:bg-danger p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
                    <p className="flex-none text-3xl font-bold tracking-tight text-flint dark:text-white group-hover:text-zinc-800">60%</p>
                    <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                      <p className="text-lg font-semibold tracking-tight text-flint dark:text-white group-hover:text-zinc-800">{generateTranslatedText("of_employees", language)}</p>
                      <p className="mt-2 text-base leading-7 text-flint dark:text-slate-100 group-hover:text-zinc-700">
                        {generateTranslatedText("are_experiencing_anxiety", language)}
                      </p>
                      <span className="text-xs italic text-flint dark:text-white group-hover:text-zinc-600">*Champion Health</span>
                    </div>
                  </div>
                  <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-slate-400 dark:bg-zinc-900 hover:bg-warning p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
                    <p className="flex-none text-3xl font-bold tracking-tight text-white group-hover:text-zinc-800">74%</p>
                    <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                      <p className="text-lg font-semibold tracking-tight text-white group-hover:text-zinc-800">
                        {generateTranslatedText("of_people_feel_so_stressed", language)}
                      </p>
                      <p className="mt-2 text-base leading-7 text-slate-100 group-hover:text-zinc-700">
                        {generateTranslatedText("unable_to_cope", language)}
                      </p>
                      <span className="text-xs italic text-white group-hover:text-zinc-600">*Mental Health Foundation and YouGov</span>
                    </div>
                  </div>
                  <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-slate-200 dark:bg-zinc-600 hover:bg-info p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
                    <p className="flex-none text-3xl font-bold tracking-tight text-flint dark:text-white group-hover:text-white">{`${capitalise(generateTranslatedText("over", language))} 700,000`}</p>
                    <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                      <p className="text-lg font-semibold tracking-tight text-flint dark:text-white group-hover:text-white">{generateTranslatedText("people_take_their_own_life", language)}</p>
                      <p className="mt-2 text-base leading-7 text-slate-600 dark:text-slate-100 group-hover:text-slate-200">
                        {generateTranslatedText("every_40_seconds", language)}
                      </p>
                      <span className="text-xs italic text-flint dark:text-white group-hover:text-white">*World Health Organization</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* CTA */}
              <div className="flex relative isolate px-6 pt-14 lg:px-8 min-h-screen bg-igloo rounded-lg drop-shadow md:mx-8 mx-6">
                <div className="m-auto max-w-2xl">
                  <div className="text-center">
                    <h1 className="text-4xl font-bold tracking-tight text-zinc-800 sm:text-6xl">
                      {generateTranslatedText("about_cta_header", language)}
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-zinc-700">
                      {generateTranslatedText("about_cta_text", language)}
                      <br />
                      {generateTranslatedText("this_is_where", language)}<span className="font-bold underline text-zinc-800">Igloo</span>{generateTranslatedText("comes_in", language)}
                    </p>

                  </div>
                </div>
              </div>

              {/* Mission */}
              <div className="mx-auto max-w-7xl px-6 lg:px-8 sm:py-20 pt-10 pb-20 mt-10">
                <div className="mx-auto max-w-2xl lg:mx-0">
                  <h2 className="text-3xl font-bold tracking-tight text-flint dark:text-white sm:text-4xl">
                    {generateTranslatedText("about_mission_header", language)}
                  </h2>
                  <p className="mt-6 text-lg leading-8 text-slate-600 dark:text-slate-300">
                    {generateTranslatedText("about_mission_subheader", language)}
                  </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                  <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                    {features.map((feature) => (
                      <div key={feature.name} className="flex flex-col">
                        <dt className="text-base font-semibold leading-7 text-slate-600 dark:text-white">
                          <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-info">
                            <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                          </div>
                          {feature.name}
                        </dt>
                        <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-slate-600 dark:text-slate-300">
                          <p className="flex-auto">{feature.description}</p>
                          <p className="mt-6">
                            <a href={feature.href} className="text-sm font-semibold leading-6 text-info">
                              {`${generateTranslatedText("learn_more", language)} `}<span aria-hidden="true">→</span>
                            </a>
                          </p>
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>

              {/* Features */}
              <div id="safe-space" />
              <StickyContent
                header={generateTranslatedText("safe_space", language)}
                subheader={generateTranslatedText("safe_space_feature_subheader", language)}
                image="/img/graphics/write.svg"
                firstParagraph=""
                secondParagraph={generateTranslatedText("safe_space_feature_2p", language)}
                bullets={
                  <ul className="mt-8 space-y-8 text-slate-600 dark:text-slate-300">
                    <li className="flex gap-x-3">
                      <HeartIcon className="mt-1 h-5 w-5 flex-none text-info" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-flint dark:text-white">{generateTranslatedText("safe_space_feature_b1a", language)}</strong>{generateTranslatedText("safe_space_feature_b1b", language)}
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <PencilSquareIcon className="mt-1 h-5 w-5 flex-none text-info" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-flint dark:text-white">{generateTranslatedText("safe_space_feature_b2a", language)}.</strong>{generateTranslatedText("safe_space_feature_b2b", language)}
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-info" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-flint dark:text-white">{generateTranslatedText("safe_space_feature_b3a", language)}.</strong>{generateTranslatedText("safe_space_feature_b3b", language)}
                      </span>
                    </li>
                  </ul>
                }
                thirdParagraph=""
                finalHeader={generateTranslatedText("safe_space_feature_final_header", language)}
                finalParagraph={generateTranslatedText("safe_space_feature_final_p", language)}
              />

              <div id="expression" />
              <StickyContent
                header={generateTranslatedText("expression", language)}
                subheader={generateTranslatedText("expression_feature_subheader", language)}
                image="/img/graphics/trends.svg"
                firstParagraph=""
                secondParagraph={generateTranslatedText("expression_feature_2p", language)}
                bullets={
                  <ul className="mt-8 space-y-8 text-slate-600 dark:text-slate-300">
                    <li className="flex gap-x-3">
                      <HeartIcon className="mt-1 h-5 w-5 flex-none text-info" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-flint dark:text-white">{generateTranslatedText("expression_feature_b1a", language)}</strong>{generateTranslatedText("expression_feature_b1b", language)}
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <CheckBadgeIcon className="mt-1 h-5 w-5 flex-none text-info" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-flint dark:text-white">{generateTranslatedText("expression_feature_b2a", language)}</strong>{generateTranslatedText("expression_feature_b2b", language)}
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <WrenchScrewdriverIcon className="mt-1 h-5 w-5 flex-none text-info" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-flint dark:text-white">{generateTranslatedText("expression_feature_b3a", language)}</strong>{generateTranslatedText("expression_feature_b3b", language)}
                      </span>
                    </li>
                  </ul>
                }
                thirdParagraph=""
                finalHeader="Giving clarity to the unclear"
                finalParagraph="Thoughts & feelings can be so intangible and complicated. By starting small and building up a picture over time, Igloo helps you to understand your emotions and take action."
              />

              <div id="awareness" />
              <StickyContent
                header={generateTranslatedText("awareness", language)}
                subheader={generateTranslatedText("awareness_feature_subheader", language)}
                image="/img/graphics/goal.svg"
                firstParagraph=""
                secondParagraph={generateTranslatedText("awareness_feature_2p", language)}
                bullets={
                  <ul className="mt-8 space-y-8 text-slate-600 dark:text-slate-300">
                    <li className="flex gap-x-3">
                      <KeyIcon className="mt-1 h-5 w-5 flex-none text-info" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-flint dark:text-white">{generateTranslatedText("awareness_feature_b1a", language)}</strong>{generateTranslatedText("awareness_feature_b1b", language)}
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <RocketLaunchIcon className="mt-1 h-5 w-5 flex-none text-info" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-flint dark:text-white">{generateTranslatedText("awareness_feature_b2a", language)}</strong>{generateTranslatedText("awareness_feature_b2b", language)}
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <HeartIcon className="mt-1 h-5 w-5 flex-none text-info" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-flint dark:text-white">{generateTranslatedText("awareness_feature_b3a", language)}</strong>{generateTranslatedText("awareness_feature_b3b", language)}
                      </span>
                    </li>
                  </ul>
                }
                thirdParagraph=""
                finalHeader={generateTranslatedText("awareness_feature_final_header", language)}
                finalParagraph={generateTranslatedText("awareness_feature_final_p", language)}
              />

            </div>
          </div>
        </div>

        <Footer />

        {/* Alert banner */}
        <Banner />
      </div>
    </div>
  );
}
