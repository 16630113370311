import { useEffect, useState } from "react";
import Header from "../layout/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Container from "../layout/Container";
import SpaceFiller from "../layout/SpaceFiller";
import { createGoalPlan, getGoalPlans } from "../../slices/goals";
import Card from "../layout/Card";
import Spinner from "../layout/Spinner";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import Pill from "../layout/Pill";
import { getColour } from "../../utils/colours";
import { getSolidIcon } from "../../utils/icons";
import { CalendarDaysIcon, FlagIcon, PlusCircleIcon, TrophyIcon } from "@heroicons/react/24/outline";
import { KeyIcon } from "@heroicons/react/24/solid";
import NoContent from "../layout/NoContent";
import Button from "../layout/Button";
import getButtonStyle from "../../utils/styles";
import ActionButtons from "../layout/ActionButtons";
import Modal from "../layout/Modal";
import { Dialog } from "@headlessui/react";
import TextInput from "../layout/TextInput";
import Dropdown from "../layout/Dropdown";
import TextArea from "../layout/TextArea";
import Datepicker from "react-tailwindcss-datepicker";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";
import Link from "../layout/Link";

export default function Goals() {
  const dispatch = useAppDispatch();

  // Get app level state from redux
  const { plans: plansData, options: goalsOptions } = useAppSelector(
    (state) => state.goals
  );
  const goalsLoading = plansData.loading;
  const { user: userData } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getGoalPlans());
  }, [dispatch]);

  // Get component level state
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [createGoalData, setCreateGoalData] = useState({
    name: "",
    description: "",
    goal_type: "",
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });

  // Functions
  const handleDateChange = (newValue: any) => {
    setDateRange(newValue);
  }

  function openCreateGroupModal(e: any) {
    e.preventDefault();
    setShowCreateModal(true);
  }

  async function confirmGoalCreate(e: any) {
    e.preventDefault();
    if (userData === null) {
      dispatch(addAlert(generateTranslatedText("auth_error", language), "danger"));
      return;
    };
    const { name, description, goal_type: goalType } = createGoalData;
    const { startDate, endDate } = dateRange;
    if (name === "" || description === "" || goalType === "" || startDate === null || endDate === null) {
      dispatch(addAlert(generateTranslatedText("missing_fields", language), "danger"));
      return;
    }
    const res: any = await dispatch(createGoalPlan({
      name,
      description,
      profile: userData.profile.id,
      goal_type: goalType,
      start_date: startDate,
      end_date: endDate,
      feedback: null,
      rating: null,
      status: "A",
      complete: false
    }));
    if (res.payload.status === 201) {
      dispatch(addAlert(generateTranslatedText("create_goal_plan", language), "success"));
    } else {
      dispatch(addAlert(generateTranslatedText("create_goal_plan_error", language), "danger"));
    }
    setShowCreateModal(false);
  }

  // Constant variables
  const language = userData?.language || "EN";

  return (
    <Template>

      {/* Create modal */}
      <Modal
        show={showCreateModal}
        setShow={setShowCreateModal}
        confirmText={generateTranslatedText("create_goal", language)}
        cancelText={generateTranslatedText("cancel", language)}
        confirmHandler={confirmGoalCreate}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
              {generateTranslatedText("create_new_goal_plan", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">

              {/* Goal type */}
              {goalsLoading ? (
                <SpaceFiller>
                  <Spinner />
                </SpaceFiller>
              ) : (
                <>
                  <Dropdown
                    id="goal_type"
                    className="col-span-full"
                    label={generateTranslatedText("type", language)}
                    showLabel={true}
                    required={true}
                    state={createGoalData}
                    stateName="goal_type"
                    onChange={setCreateGoalData}
                    defaultValue={createGoalData.goal_type}
                  >
                    <option value="">Choose a plan type</option>
                    {goalsOptions.map((topic, idx) => (
                      <option key={idx} value={topic[2]}>{topic[1]}</option>
                    ))}
                  </Dropdown>
                </>
              )}

              {/* Name */}
              <TextInput
                id="name"
                type="text"
                label={generateTranslatedText("name", language)}
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                required={true}
                showLabel={true}
                state={createGoalData}
                stateName="name"
                onChange={setCreateGoalData}
              />

              {/* Description */}
              <TextArea
                id="description"
                rows={3}
                label={generateTranslatedText("description", language)}
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                required={true}
                showLabel={true}
                state={createGoalData}
                stateName="description"
                onChange={setCreateGoalData}
              />

              {/* Start and end date */}
              <div className="date-picker-wrapper w-full">
                <label className="mb-3 block text-sm font-medium text-gray-700 dark:text-white text-left">
                  {generateTranslatedText("start_end_date", language)}
                </label>
                <Datepicker
                  primaryColor={"indigo"}
                  value={dateRange}
                  onChange={handleDateChange}
                  showShortcuts={false}
                />
              </div>

            </div>
          </div>
        </div>
      </Modal>

      <Container className="pt-0 overflow-y-auto h-screen scrollbar-invisible">

        {/* Header */}
        <Header
          text={generateTranslatedText("goals", language)}
          actions={
            <ActionButtons
              primaryButton={userData?.subscription.type === "F" ? null :
                <Button
                  type="button"
                  className={`${getButtonStyle("solid", "info")} rounded-full drop-shadow`}
                  onClick={openCreateGroupModal}
                >
                  <PlusCircleIcon className="h-5 w-5 text-white mr-2" aria-hidden="true" />
                  {generateTranslatedText("create_goal", language)}
                </Button>
              }
            />
          }
        />

        {/* Saved goals */}
        {goalsLoading ? (
          <SpaceFiller>
            <Spinner colour="info" />
          </SpaceFiller>
        ) : userData?.subscription.type === "F" ? (
          <SpaceFiller>
            <NoContent
              icon={<TrophyIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />}
              text={generateTranslatedText("goals_upgrade_header", language)}
              subtext={generateTranslatedText("goals_upgrade_subheader", language)}
              actionButton={
                <Link
                  href="/profile"
                  className={`${getButtonStyle("solid", "info")} rounded-full drop-shadow mt-4`}
                >
                  <KeyIcon className="mx-auto h-5 w-5 mr-2 text-white" aria-hidden="true" />
                  {generateTranslatedText("upgrade", language)}
                </Link>
              }
            />
          </SpaceFiller>
        ) : plansData.data.length > 0 ? (
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-6 mx-1 place-content-center">
            {plansData.data.map((plan) => (
              <a href={`/goals/${plan.id}`} key={plan.id}>
                <Card colour="bg-white dark:bg-zinc-900">
                  <h1 className="text-lg font-medium text-flint dark:text-white">
                    {plan.name}
                    {plan.complete && <CheckCircleIcon className="h-5 w-5 inline-block ml-2 text-green-500" />}
                  </h1>
                  <span className="text-slate-400 dark:text-slate-300 text-sm mb-4">{`Created @ ${plan.datetime_created}`}</span>

                  <Pill
                    text={plan.goal_type[1]}
                    bgColour={getColour(plan.goal_type[0])}
                    textColour={getColour(plan.goal_type[0])}
                    icon={getSolidIcon(plan.goal_type[0], getColour(plan.goal_type[0]), 3, "mr-2")}
                  />

                  <div>
                    <span className="text-slate-400 dark:text-slate-300 text-sm mb-4">
                      <CalendarDaysIcon className="h-4 w-4 inline-block mr-2" />
                      {`${generateTranslatedText("start", language)}: ${plan.start_date.readable}`}
                    </span>
                  </div>
                  <div>
                    <span className="text-slate-400 dark:text-slate-300 text-sm mb-4">
                      <FlagIcon className="h-4 w-4 inline-block mr-2" />
                      {`${generateTranslatedText("end", language)}: ${plan.end_date.readable}`}
                    </span>
                  </div>

                  {/* {plan.rating && (
                    <ProgressBar progress={plan.rating} limit={10} />
                  )} */}

                </Card>
              </a>
            ))}
          </div>
        ) : (
          <SpaceFiller>
            <NoContent text={generateTranslatedText("no_goals", language)} subtext={generateTranslatedText("no_goals_subheader", language)} />
          </SpaceFiller>
        )}

      </Container>

    </Template>
  );
}
