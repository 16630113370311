import { useEffect, useState } from "react";
import Header from "../layout/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Container from "../layout/Container";
import SpaceFiller from "../layout/SpaceFiller";
import Spinner from "../layout/Spinner";
import { ArrowTrendingUpIcon, CalendarDaysIcon, CursorArrowRaysIcon, StarIcon, TrophyIcon, UserGroupIcon } from "@heroicons/react/24/solid";
import Filters from "../layout/Filters";
import { useSearchParams } from "react-router-dom";
import { getEntityCommunityOverview } from "../../slices/overview";
import { CommunityState } from "../../slices/auth";
import NumericChart from "../charts/number/Numeric";
import DonutChart from "../charts/radial/Donut";
import { getColours } from "../../utils/colours";
import LineChart from "../charts/line/Line";
import { getWidthOfLineChart } from "../../utils/charts";
import CirclePacking from "../charts/hierarchy/CirclePacking";
import Swarm from "../charts/comparison/Swarm";
import WaffleChart from "../charts/block/Waffle";
import Radar from "../charts/radial/Radar";

export default function Overview() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  // Get search params
  const communityId = searchParams.get("community");
  const dateRangeTerm = searchParams.get("dateRange");
  const userIdTerm = searchParams.get("user");

  useEffect(() => {
    dispatch(getEntityCommunityOverview({ dateRange: dateRangeTerm, user: userIdTerm }));
  }, [dispatch, dateRangeTerm]);

  const { data: overviewData, loading: overviewLoading } = useAppSelector(
    (state) => state.overview
  );
  const { user } = useAppSelector((state) => state.auth);
  // const subscription = user?.subscription.type;

  // Create component level state
  const [community, setCommunity] = useState({ value: "" });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [userId, setUserId] = useState({ value: "" });

  function createContent() {
    if (overviewLoading) {
      return (
        <SpaceFiller>
          <Spinner colour="info" />
        </SpaceFiller>
      );
    }

    let keywordSentiment: any[] = [];
    if (Object.keys(overviewData.keywords.sentiment).length > 0) {
      keywordSentiment = overviewData.keywords.sentiment.positive.map((x: any) => ({ id: x.keyword, group: "Positive", sentiment: x.score, compound: x.compound }));
      keywordSentiment.push(...overviewData.keywords.sentiment.negative.map((x: any) => ({ id: x.keyword, group: "Negative", sentiment: x.score, compound: x.compound })));
    }

    let keywordClustering: any = { name: "Clustering", children: [] };
    if (Object.keys(overviewData.keywords.hierarchy).length > 0) keywordClustering = overviewData.keywords.hierarchy;

    return (
      <>
        {/* Users */}
        <div className="grid gap-6 grid-cols-1 grid-rows-3 md:grid-cols-3 md:grid-rows-1 mb-6 mx-1 place-content-center">
          {/* Number of users within entity */}
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow">
            <div className="flex inline-flex">
              <UserGroupIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Users</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              How many users are in this community?
            </p>
            <NumericChart
              value={overviewData.users.length.toString()}
              textSize="8xl"
              colour="flint"
              className="md:h-32 h-24"
            />
          </div>

          {/* Percentage active */}
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow">
            <div className="flex inline-flex">
              <UserGroupIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Users</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              What percentage of these users have been active?
            </p>
            <NumericChart
              value={overviewData.activity.by_user.pct_active.toString()}
              textSize="8xl"
              colour={overviewData.activity.by_user.pct_active > 66 ? "success" : overviewData.activity.by_user.pct_active > 33 ? "warning" : "danger"}
              className="md:h-32 h-24"
              smallSuffixText="%"
            />
          </div>

          {/* Number of inactive users */}
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow">
            <div className="flex inline-flex">
              <UserGroupIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Users</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              How many users have been inactive?
            </p>
            <NumericChart
              value={overviewData.activity.by_user.num_inactive.toString()}
              textSize="8xl"
              colour={
                overviewData.activity.by_user.num_inactive / overviewData.users.length > 0.66 ? "danger" : overviewData.activity.by_user.num_inactive / overviewData.users.length > 0.33 ? "warning" : "success"
              }
              className="md:h-32 h-24"
            />
          </div>
        </div>

        {/* Activity */}
        <div className="grid lg:gap-6 gap-y-6 grid-cols-1 grid-rows-2 lg:grid-cols-3 lg:grid-rows-1 mb-6 mx-1 place-content-center">

          {/* Activity by type */}
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow">
            <div className="flex inline-flex">
              <CursorArrowRaysIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Activity</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              How many times have users made an entry?
            </p>
            <DonutChart
              data={overviewData.activity.by_type}
              className="lg:h-64 md:h-48 h-32"
            />
          </div>

          {/* Activity time series */}
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow col-span-2">
            <div className="flex inline-flex">
              <CursorArrowRaysIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Activity</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              How many entries were made per day?
            </p>
            <LineChart
              data={overviewData.activity.by_day}
              xScaleInputFormat="%Y-%m-%d"
              xScaleInputPrecision="day"
              xScaleOutputFormat="%b %d"
              xScaleTickIncrement="every 1 days"
              padding={[40, 100, 40, 40]}
              hSmall="h-32"
              hLarge="h-64"
              widthClass={getWidthOfLineChart(overviewData.activity.by_day[0].data.length)}
              colourPalette="bar-chart-stacked"
            />
          </div>
        </div>

        {/* Sentiment */}
        <div className="grid lg:gap-6 gap-y-6 grid-cols-1 grid-rows-2 lg:grid-cols-3 lg:grid-rows-1 mb-6 mx-1 place-content-center">

          {/* Sentiment time series */}
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow col-span-2">
            <div className="flex inline-flex">
              <ArrowTrendingUpIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Sentiment</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              How did the sentiment change each day?
            </p>
            <LineChart
              data={overviewData.sentiment.time_series}
              xScaleInputFormat="%Y-%m-%d"
              xScaleInputPrecision="day"
              xScaleOutputFormat="%b %d"
              xScaleTickIncrement="every 1 days"
              padding={[40, 100, 40, 40]}
              hSmall="h-32"
              hLarge="h-64"
              // widthClass={overviewData.sentiment.time_series[0].data.length < 10 ? "w-[32rem]" : overviewData.sentiment.time_series[0].data.length < 20 ? "w-[50rem]" : overviewData.sentiment.time_series[0].data.length < 30 ? "w-[75rem]" : "w-[100rem]"}
              widthClass={overviewData.sentiment.time_series.length === 0 ? "w-full" : getWidthOfLineChart(overviewData.sentiment.time_series[0].data.length)}
              colourPalette="sentiment-chart"
            />
          </div>

          {/* Sentiment by category */}
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow">
            <div className="flex inline-flex">
              <ArrowTrendingUpIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Sentiment</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              How % of writing was Positive, Negative & Neutral?
            </p>
            <DonutChart
              data={overviewData.sentiment.distribution}
              className="lg:h-64 md:h-48 h-32"
              colours={getColours("radar-sentiment-chart")}
            />
          </div>

        </div>

        {/* Keyword clustering */}
        <div className="grid gap-6 grid-cols-1 grid-rows-1 mt-6 mx-1 place-content-center">
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow">
            <div className="flex inline-flex">
              <StarIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Keywords</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              What are the key topics being spoken about?
            </p>
            <CirclePacking data={keywordClustering} />
          </div>
        </div>

        {/* Keyword sentiment */}
        <div className="grid gap-6 grid-cols-1 grid-rows-1 mt-6 mx-1 place-content-center mb-6">
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow">
            <div className="flex inline-flex">
              <StarIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Keywords</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              Which keywords were spoken about most positively and negatively?
            </p>
            <Swarm
              data={keywordSentiment}
              hSmall="h-32"
              hLarge="h-64"
            />
          </div>
        </div>

        {/* Goals */}
        <div className="grid lg:gap-6 gap-y-6 grid-cols-1 grid-rows-2 lg:grid-cols-3 lg:grid-rows-1 mb-6 mx-1 place-content-center">

          {/* Goal completion */}
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow">
            <div className="flex inline-flex">
              <TrophyIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Goals</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              What percentage of goals have been completed?
            </p>
            <WaffleChart
              className="mx-auto text-sm"
              data={[
                {
                  id: "Not completed",
                  label: "Not completed",
                  value: 100 - overviewData.goals.completion
                },
                {
                  id: "Completed",
                  label: "Completed",
                  value: overviewData.goals.completion
                },
              ]}
              hSmall="h-32"
              hLarge="h-64"
              colour={[overviewData.goals.completion < 33 ? "#FF7878" : overviewData.goals.completion < 66 ? "#FFB76C" : "#DADADA", "#61DF94"]}
            />
          </div>

          {/* Goal check in frequency */}
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow col-span-2">
            <div className="flex inline-flex">
              <TrophyIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Goals</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              How many check-ins were made per day?
            </p>
            <LineChart
              data={overviewData.goals.check_ins.by_day}
              xScaleInputFormat="%Y-%m-%d"
              xScaleInputPrecision="day"
              xScaleOutputFormat="%b %d"
              xScaleTickIncrement="every 1 days"
              padding={[40, 100, 40, 40]}
              hSmall="h-32"
              hLarge="h-64"
              widthClass={overviewData.goals.check_ins.by_day.length === 0 ? "w-full" : getWidthOfLineChart(overviewData.goals.check_ins.by_day[0].data.length)}
              colourPalette="bar-chart"
            />
          </div>

        </div>

        {/* Events */}
        <div className="grid lg:gap-6 gap-y-6 grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 mb-6 mx-1 place-content-center">

          {/* Event type frequency */}
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow">
            <div className="flex inline-flex">
              <CalendarDaysIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Calendar</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              What event types have been registered?
            </p>
            <DonutChart
              data={overviewData.events.type_frequency}
              className="lg:h-64 md:h-48 h-32"
            />
          </div>

          {/* Event type sentiment */}
          <div className="rounded-lg bg-white dark:bg-zinc-800 p-4 drop-shadow">
            <div className="flex inline-flex">
              <CalendarDaysIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">Calendar</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              What sentiment was associated with each event type?
            </p>
            <Radar
              data={overviewData.events.sentiment}
              indexBy="event_type"
              scoreBy={["Positive", "Negative", "Neutral"]}
              hSmall="h-64"
              hLarge="h-80"
            />
          </div>
        </div>
      </>
    );
  }

  // Constants
  const language = user === null ? "EN" : user.language;
  const filterChoicesCommunity = user === null ? [] : user.profile.communities.map((opt: CommunityState) => ({
    value: opt.id,
    label: opt.name,
  }));
  const filterChoicesUser = overviewData.users.map((opt: any) => ({
    value: opt.id.toString(),
    label: opt.username,
  }));

  return (
    <Template>
      {/* Charts */}
      <Container className="pt-0 overflow-y-auto h-screen scrollbar-invisible">

        {/* Header */}
        <Header text="Overview" />

        {/* Filters */}
        <Filters
          language={language}
          className="pb-10 mx-1"
          filters={[
            {
              id: "community",
              name: "Community",
              options: filterChoicesCommunity,
            },
            {
              id: "user",
              name: "User",
              options: filterChoicesUser,
            },
          ]}
          queryParams={[
            ["community", communityId],
            ["dateRange", dateRangeTerm],
            ["user", userIdTerm],
          ]}
          handlers={{
            community: {
              state: community,
              stateName: "value",
              onChange: setCommunity
            },
            dateRange: {
              state: dateRange,
              stateName: "N/A",
              onChange: setDateRange,
            },
            user: {
              state: userId,
              stateName: "value",
              onChange: setUserId,
            }
          }}
          loading={overviewLoading}
          baseUrl="/entity/overview"
          showDateRange={true}
          showFilters={true}
          showSearchBar={false}
          placeholderText={communityId === null && dateRangeTerm === null ? `Filters = Date range: ${overviewData.date_range.start_date} - ${overviewData.date_range.end_date}` : null}
        />

        {/* Dashboard */}
        {createContent()}
      </Container>
    </Template>
  );
}
