import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Header from "../layout/Header";
import Spinner from "../layout/Spinner";
import SpaceFiller from "../layout/SpaceFiller";
import Container from "../layout/Container";
import { CheckBadgeIcon, TrashIcon } from "@heroicons/react/24/outline";
import Button from "../layout/Button";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../layout/Breadcrumbs";
import Modal from "../layout/Modal";
import { Dialog } from "@headlessui/react";
import TextInput from "../layout/TextInput";
import Dropdown from "../layout/Dropdown";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";
import { deleteGoalPlan, getGoalPlanById, updateGoalPlan } from "../../slices/goals";
import TextArea from "../layout/TextArea";
import Datepicker from "react-tailwindcss-datepicker";
import Card from "../layout/Card";

export default function GoalPlanSettings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Get string params
  const { plan_id: goalPlanId } = useParams();

  // Get app level state from redux store
  const { plans: plansData, options: goalsOptions } = useAppSelector(
    (state) => state.goals
  );
  const { user: userData } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getGoalPlanById({ planId: goalPlanId }));
  }, [dispatch, goalPlanId]);

  const planLoading = plansData.loading

  // Find plan from ID or null
  const plan = plansData.data.find((x) => x.id === goalPlanId) ?? null;
  if (plan !== null && plan.status !== "A") {
    navigate("/goals")
  }

  // Get component level state
  const [updatePlanData, setUpdatePlanData] = useState<{ name: string, description: string, goal_type: string }>({
    name: "",
    description: "",
    goal_type: "",
  });
  const [dateRange, setDateRange] = useState<{ startDate: string | null, endDate: string | null }>({
    startDate: null,
    endDate: null
  });
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    if (plan !== null) {
      setUpdatePlanData({
        name: plan.name,
        description: plan.description,
        goal_type: plan.goal_type[0]
      })
      setDateRange({
        startDate: plan.start_date.standard,
        endDate: plan.end_date.standard
      })
    }
  }, [dispatch, plan]);

  // Functions
  const handleDateChange = (newValue: any) => {
    setDateRange(newValue);
  }

  function checkDeletePlan(e: any) {
    e.preventDefault();
    setShowDeleteModal(true);
  }

  async function confirmGoalPlanDelete(e: any) {
    e.preventDefault();
    const res: any = await dispatch(deleteGoalPlan({ planId: goalPlanId }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("delete_goal_plan", language), "success"));
      navigate("/goals");
    } else {
      dispatch(addAlert(res.payload.data.response, "danger"));
    }
  }

  async function confirmUpdatePlanData(e: any) {
    e.preventDefault();
    if (userData === null) {
      dispatch(addAlert(generateTranslatedText("auth_error", language), "danger"));
      return;
    };
    if (plan === null) {
      dispatch(addAlert(generateTranslatedText("missing_object", language), "danger"));
      return;
    }

    // Extract text
    const { name, description, goal_type: goalType } = updatePlanData;
    const updatedName = name.length === 0 ? plan.name : name;
    const updatedDescription = description.length === 0 ? plan.description : description;
    const updatedGoalType = goalType.length === 0 ? plan.goal_type[0] : goalType;

    // Extract dates
    const { startDate, endDate } = dateRange;
    if (startDate === null || endDate === null) {
      dispatch(addAlert(generateTranslatedText("missing_fields", language), "danger"));
      return;
    }

    const res: any = await dispatch(updateGoalPlan({
      planId: goalPlanId,
      name: updatedName,
      description: updatedDescription,
      profile: userData.profile.id,
      goal_type: updatedGoalType,
      start_date: startDate,
      end_date: endDate,
      feedback: null,
      rating: null,
      status: plan.status,
      complete: plan.complete
    }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("update_goal_plan", language), "success"));
    } else {
      dispatch(addAlert(res.payload.data.response, "danger"));
    }
  }

  // Constant variables
  const language = userData?.language || "EN";

  return (
    <Template>

      <Container className="pt-0 overflow-y-auto h-screen scrollbar-invisible">
        {planLoading ? (
          <SpaceFiller>
            <Spinner />
          </SpaceFiller>
        ) : (
          <>

            {/* Delete goal plan */}
            <Modal
              show={showDeleteModal}
              setShow={setShowDeleteModal}
              confirmText={generateTranslatedText("delete_goal_plan_modal", language)}
              cancelText={generateTranslatedText("cancel", language)}
              confirmHandler={confirmGoalPlanDelete}
            >
              <div>
                <div className="text-center">
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                    {`${generateTranslatedText("delete_goal_plan_modal", language)} "${plan === null ? updatePlanData.name : plan.name}"`}
                  </Dialog.Title>
                  <div className="my-10 grid grid-cols-1 gap-y-8">
                    <p className="text-sm text-gray-400 dark:text-gray-200">{generateTranslatedText("delete_goal_plan_confirmation", language)}</p>
                  </div>
                </div>
              </div>
            </Modal>

            {/* Header */}
            <Header
              text={plan !== null ? `${generateTranslatedText("edit", language)} "${plan.name}" ${generateTranslatedText("settings", language)}` : generateTranslatedText("not_found", language)}
              breadcrumbs={<Breadcrumbs breadcrumbs={[{ href: "/goals", text: generateTranslatedText("goals", language) }, { href: `/goals/${goalPlanId}`, text: plan === null ? updatePlanData.name : plan.name }]} />}
            />

            {/* Goal plan settings form */}
            <Card className="mx-1 max-w-100">
              <form
                className="space-y-8 divide-y divide-gray-200"
                onSubmit={(e) => confirmUpdatePlanData(e)}
              >
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="grid grid-cols-1 gap-y-8">

                    {/* Name */}
                    <TextInput
                      id="name"
                      type="text"
                      label={generateTranslatedText("name", language)}
                      className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                      required={true}
                      showLabel={true}
                      state={updatePlanData}
                      placeholder={plan === null ? updatePlanData.name : plan.name}
                      stateName="name"
                      onChange={setUpdatePlanData}
                    />

                    {/* Description */}
                    <TextArea
                      id="description"
                      label={generateTranslatedText("description", language)}
                      className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                      required={true}
                      showLabel={true}
                      state={updatePlanData}
                      placeholder={plan === null ? updatePlanData.description : plan.description}
                      stateName="description"
                      onChange={setUpdatePlanData}
                    />

                    {/* Goal type */}
                    {planLoading ? (
                      <SpaceFiller>
                        <Spinner />
                      </SpaceFiller>
                    ) : (
                      <>
                        <Dropdown
                          id="goal_type"
                          className="col-span-full"
                          label={generateTranslatedText("type", language)}
                          showLabel={true}
                          required={true}
                          state={updatePlanData}
                          stateName="goal_type"
                          onChange={setUpdatePlanData}
                          defaultValue={plan === null ? updatePlanData.goal_type : plan.goal_type[2]}
                        >
                          <option key="" value="">Choose a plan type</option>
                          {goalsOptions.map((opt: string[]) => (
                            <option key={opt[0]} value={opt[2]}>{opt[1]}</option>
                          ))}
                        </Dropdown>
                      </>
                    )}

                    {/* Start and end date */}
                    <div className="date-picker-wrapper w-full">
                      <label className="mb-3 block text-sm font-medium text-gray-700 dark:text-white text-left">
                        {generateTranslatedText("start_end_date", language)}
                      </label>
                      <Datepicker
                        primaryColor={"indigo"}
                        value={dateRange}
                        onChange={handleDateChange}
                        showShortcuts={false}
                      />
                    </div>

                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end border-none border-0">
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center rounded-full border border-transparent bg-info drop-shadow py-2 px-4 text-sm font-medium text-white hover:underline focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
                    >
                      <CheckBadgeIcon className="w-5 h-5 text-white dark:text-white mr-2" aria-hidden="true" />
                      {generateTranslatedText("save", language)}
                    </button>
                  </div>
                </div>

              </form>
            </Card>

            {/* Danger zone */}
            <Card className="mx-1 max-w-100 my-10">
              <div>
                <label className="block text-xs font-bold text-gray-400 dark:text-white underline">
                  {generateTranslatedText("danger_zone", language)}
                </label>
                <div>
                  <Button
                    type="button"
                    className="group mt-4 inline-flex justify-center rounded-full border border-transparent hover:bg-danger/10 py-1 px-2 text-xs font-medium text-gray-400 dark:text-white hover:text-danger focus:outline-none focus:ring-2 focus:ring-danger focus:ring-offset-2"
                    onClick={checkDeletePlan}
                  >
                    <TrashIcon
                      className="mr-2 h-4 w-4 text-gray-400 dark:text-white group-hover:text-danger"
                      aria-hidden="true"
                    />
                    {generateTranslatedText("delete_goal_plan_modal", language)}
                  </Button>
                </div>
              </div>
            </Card>

          </>
        )}

      </Container>

    </Template>
  );
}