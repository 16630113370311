import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import LandingNav from "../landing/LandingNav";
import Banner from "../layout/Banner";
import Spinner from "../layout/Spinner";
import { useNavigate } from "react-router-dom";
import Card from "../layout/Card";
import TextInput from "../layout/TextInput";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import Container from "../layout/Container";
import { sendVerificationEmail } from "../../slices/auth";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";

export default function Verification() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { loading, user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (user?.verified) navigate("/write");
  }, [navigate, user?.verified]);

  // Get component level state
  const [updateFormData, setUpdateFormData] = useState<any>({
    resend_email: ""
  });

  // Functions
  async function resendConfirmationEmail(e: any) {
    e.preventDefault();
    const { resend_email: resendEmail } = updateFormData;
    if (!resendEmail || resendEmail.length === 0) {
      dispatch(addAlert(generateTranslatedText("missing_email", language), "warning", 4000));
      return;
    };
    const res: any = await dispatch(sendVerificationEmail({ email: resendEmail }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("verification_email_sent", language), "success"));
    } else {
      dispatch(addAlert(generateTranslatedText("verification_email_sent_error", language), "danger", 5000));
    }
  }

  // Constant variables
  const language = user ? user.language[0] : "EN";

  return (
    <>
      <div className="w-full h-screen overflow-scroll bg-white dark:bg-flint">
        {loading ? <Spinner /> : (
          <>
            {/* Navbar */}
            <LandingNav />

            {/* Content */}
            <Container>
              <div className="bg-transparent">
                <div className="overflow-hidden pt-8 sm:pt-12 lg:relative lg:py-24">
                  <h1 className="text-4xl font-bold tracking-tight text-flint dark:text-white sm:text-5xl mx-auto text-center">
                    We need to verify your account
                  </h1>
                  <p className="mt-5 text-flint dark:text-white text-center">
                    You should have received an email with a verification link. Please click the link to verify your account.
                  </p>

                  <Card className="mx-auto px-10 sm:max-w-2xl max-w-lg my-10" colour="bg-slate-100 dark:bg-zinc-800">
                    <form
                      className="space-y-8 divide-y divide-gray-200"
                      onSubmit={(e) => resendConfirmationEmail(e)}
                    >
                      <div className="space-y-8 divide-y divide-gray-200">
                        <div className="grid grid-cols-1 gap-y-8">

                          <p className="text-gray-500 dark:text-white text-center">
                            <span className="font-medium text-info hover:text-info-dark focus:outline-igloo dark:text-white dark:hover:text-ice">Can't find the email?</span> Enter your email below and click send, we'll send you another.
                          </p>

                          {/* Current password */}
                          <TextInput
                            id="resend_email"
                            type="text"
                            label="Resend email"
                            className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                            required={true}
                            showLabel={false}
                            state={updateFormData}
                            stateName="resend_email"
                            onChange={setUpdateFormData}
                          />

                        </div>
                      </div>

                      <div className="pt-5">
                        <div className="flex justify-end border-none border-0">
                          <button
                            type="submit"
                            className="ml-3 inline-flex justify-center rounded-full border border-transparent bg-info drop-shadow py-2 px-4 text-sm font-medium text-white hover:underline focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
                          >
                            <PaperAirplaneIcon
                              className="-ml-0.5 mr-2 h-4 w-4"
                              aria-hidden="true"
                            />
                            Send
                          </button>
                        </div>
                      </div>
                    </form>
                  </Card>

                </div>
              </div>
            </Container>
          </>
        )}

        {/* Alert banner */}
        <Banner />
      </div>
    </>
  );
}
