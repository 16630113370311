import { useEffect } from "react";
import Header from "../layout/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Container from "../layout/Container";
import SpaceFiller from "../layout/SpaceFiller";
import Spinner from "../layout/Spinner";
import { useSearchParams } from "react-router-dom";
import Pagination from "../layout/Pagination";
import NotificationFeed from "./NotificationFeed";
import { getNotifications } from "../../slices/notifications";
import NoContent from "../layout/NoContent";
import { BellAlertIcon, KeyIcon } from "@heroicons/react/24/solid";
import Link from "../layout/Link";
import getButtonStyle from "../../utils/styles";
import generateTranslatedText from "../../utils/boilerplate";

export default function Notifications() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  // Get search params
  const pageNum = searchParams.get("pageNum");


  useEffect(() => {
    dispatch(getNotifications({ pageNum }));
  }, [dispatch, pageNum]);

  const { data, pagination, loading } = useAppSelector((state) => state.notifications);
  const { user } = useAppSelector((state) => state.auth);

  // Create component level state

  // Constants
  const language = user?.language || "EN";

  return (
    <Template>
      {/* Charts */}
      <Container className="pt-0 overflow-y-auto h-screen scrollbar-invisible">

        {/* Header */}
        <Header text={generateTranslatedText("notifications", language)} />

        {loading ? (
          <SpaceFiller>
            <Spinner colour="info" />
          </SpaceFiller>
        ) : user?.subscription.type === "F" ? (
          <SpaceFiller>
            <NoContent
              icon={<BellAlertIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />}
              text={generateTranslatedText("notifications_upgrade_header", language)}
              subtext={generateTranslatedText("notifications_upgrade_subheader", language)}
              actionButton={
                <Link
                  href="/profile"
                  className={`${getButtonStyle("solid", "info")} rounded-full drop-shadow mt-4`}
                >
                  <KeyIcon className="mx-auto h-5 w-5 mr-2 text-white" aria-hidden="true" />
                  {generateTranslatedText("upgrade", language)}
                </Link>
              }
            />
          </SpaceFiller>
        ) : (
          <>
            <NotificationFeed data={data} type="full" />
            <Pagination
              className="mt-10 mb-36 md:mb-0"
              showPrevious={pagination.has_previous}
              showNext={pagination.has_next}
              currentPage={pagination.current_page}
              totalPages={pagination.total_pages}
              totalObjects={pagination.total_objects}
              queryParams={["page"]}
            />
          </>
        )}
      </Container>
    </Template>
  );
}
