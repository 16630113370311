import { HeartIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { emotionEntry, getEmojis } from "../../slices/emotion";
import generateTranslatedText from "../../utils/boilerplate";
import { addAlert } from "../../slices/alert";
import Spinner from "../layout/Spinner";

export default function Keypad() {
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user } = useAppSelector((state) => state.auth);
  const { emojis: emojiData, loading: emojiLoading } = useAppSelector(
    (state) => state.emotion
  );

  useEffect(() => {
    dispatch(getEmojis());
  }, [dispatch]);

  // Get component level state
  const [toggleKeypad, setToggleKeypad] = useState(false);

  // Functions
  async function touchEmoji(e: any, emojiId: string) {
    e.preventDefault();

    // Refocus cursor on textarea
    const entry = document.getElementById("entry-input");
    entry?.focus();

    // Send action to make entry for emotion
    const res: any = await dispatch(emotionEntry({ emojiId }));
    if (res.payload.status === 201) {
      dispatch(addAlert(generateTranslatedText("emotion_entry", language), "success", 2000));
    } else {
      dispatch(
        addAlert(generateTranslatedText("emotion_entry_error", language), "danger", 2000)
      );
    }
  }

  // Constant variables
  const language = user?.language || "EN";

  return (
    <div className="z-40 fixed bottom-0 right-0 inline-flex items-end flex flex-col">
      {/* Keypad for emojis to log emotions */}
      {toggleKeypad && (
        <div className="container w-auto mr-3 mt-3 bg-white dark:bg-zinc-700 drop-shadow sm:p-4 px-6 py-6 flex flex-wrap flex-row row justify-center rounded-lg above-all">
          {emojiLoading ? <Spinner colour="info" /> : emojiData.map((x: any) => (
            <button
              key={x.id}
              className="rounded-full px-2 py-1 hover:bg-slate-100"
              onClick={(e) => touchEmoji(e, x.id)}
            >
              <span className="md:text-3xl text-2xl">{x.emoji}</span>
            </button>
          ))}
        </div>
      )}

      <button
        type="button"
        className={`group mr-3 my-3 inline-flex items-center rounded-full border border-transparent p-6 text-white shadow-sm focus:outline-none focus:ring-2 ${!toggleKeypad ? "focus:ring-igloo-dark" : "focus:ring-info"} focus:ring-offset-2 ${!toggleKeypad ? "bg-gradient-to-r from-igloo-light via-igloo to-igloo-dark" : "bg-gradient-to-r from-igloo-dark to-info"} background-animate drop-shadow`}
        onClick={(e) => setToggleKeypad(!toggleKeypad)}
      >
        <HeartIcon className="h-6 w-6 text-white group-hover:text-zinc-800 dark:text-zinc-800 dark:group-hover:text-white" aria-hidden="true" />
      </button>
    </div>
  );
}
