interface SpaceFillerProps {
  id?: string;
  className?: string;
  children: React.ReactNode;
  verticalPosition?: string;
  flex?: boolean,
  horizontalPosition?: string;
}

export default function SpaceFiller({ id, className, children, verticalPosition, flex, horizontalPosition }: SpaceFillerProps) {
  return (
    <div id={id} className={`${flex === true ? "flex" : ""} flex-1 h-full justify-${horizontalPosition} items-${verticalPosition} ${className}`}>
      {children}
    </div>
  );
}

SpaceFiller.defaultProps = {
  id: "",
  className: "",
  verticalPosition: "center",
  flex: true,
  horizontalPosition: "center",
}