import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { logout } from "../../slices/auth";
import { useAppDispatch, useAppSelector } from "../../hooks";
import generateTranslatedText from "../../utils/boilerplate";
import { addAlert } from "../../slices/alert";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";

interface Navigation {
  name: string;
  href: string;
  icon: any;
  alert: boolean;
}

interface MobileSlideOverProps {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: any;
  navigation: Array<Navigation>;
  // user: User;
}

export default function MobileSlideOver({
  mobileMenuOpen,
  setMobileMenuOpen,
  navigation,
}: MobileSlideOverProps) {
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user } = useAppSelector((state) => state.auth);

  // Functions
  async function logUserOut(e: any) {
    e.preventDefault();
    const res: any = await dispatch(logout());
    if (res.payload.status === 205) {
      dispatch(addAlert(generateTranslatedText("logout", language), "success"));
    } else {
      dispatch(addAlert(generateTranslatedText("logout_error", language), "danger"));
    }
  }

  // Constant variables
  const language = user?.language || "EN";

  return (
    // Mobile side bar
    <Transition.Root show={mobileMenuOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 lg:hidden"
        onClose={setMobileMenuOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-800 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-igloo focus:outline-none">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-4">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="pt-5 pb-4">
                <div className="flex flex-shrink-0 items-center px-4">
                  <a href="/">
                    <img
                      className="h-10 sm:h-12 w-auto"
                      src="/img/logo/logo_transparent_bg.svg"
                      alt="Igloo"
                    />
                  </a>
                </div>
                <nav aria-label="Sidebar" className="mt-5">
                  <div className="space-y-1 px-2">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="group flex items-center rounded-md p-2 text-base font-medium text-white hover:text-flint dark:text-zinc-800 hover:bg-white/20 dark:hover:text-white"
                      >
                        <item.icon
                          className="mr-4 h-6 w-6 text-white group-hover:text-flint dark:text-zinc-800 dark:group-hover:text-white"
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                    <a
                      href="#!"
                      className="group flex items-center rounded-md p-2 text-base font-medium text-white hover:text-zinc-800 dark:text-zinc-800 hover:bg-white/20 dark:hover:text-white"
                      onClick={(e) => logUserOut(e)}
                    >
                      <ArrowLeftCircleIcon
                        className="mr-4 h-6 w-6 text-white group-hover:text-flint dark:text-zinc-800 dark:group-hover:text-white"
                        aria-hidden="true"
                      />
                      Logout
                    </a>
                  </div>
                </nav>
              </div>
              <div className="flex flex-shrink-0 border-t border-ice dark:border-flint p-4">
                {/* <a href="#!" className="group block flex-shrink-0">
                  <div className="flex items-center">
                    <div>
                      <img className="inline-block h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">{user.name}</p>
                      <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                        Account Settings
                      </p>
                    </div>
                  </div>
                </a> */}
              </div>
            </Dialog.Panel>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
