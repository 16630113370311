import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";
import { buildUrl } from "../utils/proxy";

// Define a type for the slice state
interface PaymentState {
  loading: boolean;
  success: boolean;
  response: any;
  cancellation: {
    loading: boolean;
    success: boolean;
    response: any;
  },
  portal: {
    loading: boolean;
    success: boolean;
    url: string | null;
  },
  waiting: number;
}

// Define the initial state using that type
const initialState: PaymentState = {
  loading: true,
  success: false,
  response: null,
  cancellation: {
    loading: false,
    success: false,
    response: null
  },
  portal: {
    loading: false,
    success: false,
    url: null
  },
  waiting: 0
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Confirm subscription payment session
export const confirmSubscriptionPaymentSession = createAsyncThunk(
  "payment/subscription/confirm/post",
  async (obj: {
    checkoutSessionId: string | null;
  }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      const res = await axios.get(buildUrl(`/api/payment/subscription/confirmation?checkout_session_id=${obj.checkoutSessionId}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Cancel premium membership
export const cancelPremiumMembership = createAsyncThunk(
  "payment/subscription/cancel/patch",
  async (args, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };

      const res = await axios.patch(buildUrl(`/api/payment/subscription/cancel`), {}, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Create payment session portal
export const createPaymentSessionPortal = createAsyncThunk(
  "payment/session/portal/get",
  async (args, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      const res = await axios.get(buildUrl(`/api/payment/session`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Confirm subscription payment session
      .addCase(confirmSubscriptionPaymentSession.pending, (state) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(confirmSubscriptionPaymentSession.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.response = action.payload;
      })
      .addCase(confirmSubscriptionPaymentSession.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.response = action.payload;
      })

      // Cancel premium membership
      .addCase(cancelPremiumMembership.pending, (state) => {
        state.cancellation.loading = true;
        state.cancellation.success = false;
        state.waiting = 2;
      })
      .addCase(cancelPremiumMembership.fulfilled, (state, action) => {
        state.cancellation.loading = false;
        state.cancellation.success = true;
        state.cancellation.response = action.payload;
        state.waiting = 0;
      })
      .addCase(cancelPremiumMembership.rejected, (state, action) => {
        state.cancellation.loading = false;
        state.cancellation.success = false;
        state.cancellation.response = action.payload;
        state.waiting = 0;
      })

      // Create payment session portal
      .addCase(createPaymentSessionPortal.pending, (state) => {
        state.portal.loading = true;
        state.portal.success = false;
        state.waiting = 1;
      })
      .addCase(createPaymentSessionPortal.fulfilled, (state, action) => {
        state.portal.loading = false;
        state.portal.success = true;
        state.portal.url = action.payload.data.data;
        state.waiting = 0;
      })
      .addCase(createPaymentSessionPortal.rejected, (state) => {
        state.portal.loading = false;
        state.portal.success = false;
        state.portal.url = null
        state.waiting = 0;
      });
  },
});

export default paymentSlice.reducer;
