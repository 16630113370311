import { ResponsiveSwarmPlot } from '@nivo/swarmplot'
import { getColour, getColours } from "../../../utils/colours";
import { useEffect, useState } from 'react';
import getWindowDimensions from '../../../utils/dimensions';
import { randomIntFromInterval } from '../../../utils/random';

interface swarmData {
  id: string | number;
  group: string;
  sentiment: number;
  compound: number;
}

const dummyData = Array.from(Array(80).keys()).map(x => (
  {
    id: x,
    group: x % 2 === 0 ? "Positive" : "Negative",
    sentiment: randomIntFromInterval(1, 100),
    compound: randomIntFromInterval(10, 20),
  }
))

export default function Swarm({ data, hSmall, hLarge }: { data: swarmData[], hSmall: string, hLarge: string }) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let heightClass = hLarge;
  if (windowDimensions.width < 992) heightClass = hSmall;

  let showPlaceholder = false;
  const showPositive = data.filter(x => x.group === "Positive").length > 0;
  const showNegative = data.filter(x => x.group === "Negative").length > 0;
  if (!showPositive && !showNegative) showPlaceholder = true;

  let groups = [];
  if (showPositive) groups.push("Positive");
  if (showNegative) groups.push("Negative");

  let colours = [];
  if (showPositive) colours.push(getColours("swarm-chart")[0]);
  if (showNegative) colours.push(getColours("swarm-chart")[1]);

  let widthClass = "h-full"
  if (windowDimensions.width < 992) {
    if (groups.length === 1) widthClass = "h-full w-[25rem]"
    if (groups.length === 2) widthClass = "h-full w-[40rem]"
  }

  return (
    <div className={`${heightClass} chart-container overflow-auto scrollbar-invisible`}>
      <div className={widthClass}>
        <ResponsiveSwarmPlot
          data={showPlaceholder ? dummyData : data}
          groups={showPlaceholder ? ["Positive", "Negative"] : groups}
          value="sentiment"
          valueFormat={x => `${x}%`}
          valueScale={{ type: 'linear', min: 0, max: 100, reverse: false }}
          spacing={2}
          layout="vertical"
          enableGridX={false}
          enableGridY={!showPlaceholder}
          size={{
            key: 'compound',
            values: [
              1,
              100
            ],
            sizes: [
              1,
              100
            ]
          }}
          colors={showPlaceholder ? getColour("placeholder") : colours}
          forceStrength={4}
          simulationIterations={100}
          borderColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                0.6
              ],
              [
                'opacity',
                0.5
              ]
            ]
          }}
          margin={showPlaceholder ? { top: 20, right: 40, bottom: 20, left: 40 } : { top: 20, right: 40, bottom: 40, left: 60 }}
          axisTop={null}
          axisRight={null}
          axisBottom={showPlaceholder ? null : {
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
          }}
          axisLeft={showPlaceholder ? null : {
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legend: 'Sentiment strength',
            legendPosition: 'middle',
            legendOffset: -50
          }}
          isInteractive={!showPlaceholder}
          animate={true}
          motionConfig="wobbly"
        />
      </div>
    </div>
  );
}
