import { ResponsiveCirclePacking } from '@nivo/circle-packing'
import { useState } from 'react';
import { randomIntFromInterval } from '../../../utils/random';
import { getColours } from '../../../utils/colours';

interface childNodes {
  name: string,
  children: Array<{
    name: string,
    score: number
  }>;
}

interface circlePackingData {
  name: string;
  children: childNodes[];
}
const dummyData = {
  name: "Dummy data",
  children: [
    {
      "name": "1",
      "children": [
        {
          "name": "1a",
          "score": randomIntFromInterval(1, 10)
        },
        {
          "name": "1b",
          "score": randomIntFromInterval(1, 10)
        }
      ]
    },
    {
      "name": "2",
      "children": [
        {
          "name": "2a",
          "score": randomIntFromInterval(1, 10)
        },
        {
          "name": "2b",
          "score": randomIntFromInterval(1, 10)
        }
      ]
    },
    {
      "name": "3",
      "children": [
        {
          "name": "3a",
          "score": randomIntFromInterval(1, 10)
        },
        {
          "name": "3c",
          "score": randomIntFromInterval(1, 10)
        }
      ]
    }
  ]
}

export default function CirclePacking({ data }: { data: circlePackingData }) {

  const [zoomedId, setZoomedId] = useState<string | null>(null)
  const showPlaceholder = data.children.length === 0

  return (
    <div className="h-96 chart-container circle-packing">
      <ResponsiveCirclePacking
        data={showPlaceholder ? dummyData : data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        id="name"
        value="score"
        valueFormat=">-.0f"
        colors={showPlaceholder ? ["rgb(255, 255, 255)", "rgb(227, 227, 227)", "rgb(212, 212, 212)"] : getColours("circle-packing")}
        childColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              0.5
            ]
          ]
        }}
        padding={4}
        enableLabels={!showPlaceholder}
        labelsFilter={label => label.node.depth === 1}
        leavesOnly={false}
        labelsSkipRadius={10}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              2
            ]
          ]
        }}
        borderWidth={0}
        isInteractive={!showPlaceholder}
        motionConfig="slow"
        zoomedId={zoomedId}
        onClick={node => {
          setZoomedId(zoomedId === node.id ? null : node.id)
        }}
      />
    </div>
  )
}
