
export default function ProgressBar({ progress, limit, marginY }: { progress: number, limit: number, marginY?: string }) {

  let colour = "success"
  const pct_30 = (progress / limit) * 100;
  if (pct_30 < 33) {
    colour = "danger"
  } else if (pct_30 < 66) {
    colour = "warning"
  }

  let pct = "0%";
  if (limit === undefined) {
    pct = `${progress}%`;
  } else {
    pct = `${(progress / limit) * 100}%`;
  }

  return (
    <div className={`w-full bg-slate-200 rounded-full dark:bg-slate-700 ${marginY !== undefined && "my-" + marginY}`}>
      {limit !== undefined ? (
        <div className={`bg-${colour} text-xs font-medium text-white dark:text-flint text-center p-0.5 leading-none rounded-full`} style={{ width: pct }}>{`${progress}/${limit}`}</div>
      ) : (
        <div className={`bg-${colour} text-xs font-medium text-white text-center p-0.5 leading-none rounded-full`} style={{ width: `${progress}%` }}>{`${progress}%`}</div>
      )}
    </div>
  )
}
