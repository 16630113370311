import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import { useSearchParams } from "react-router-dom";

interface PaginationProps {
  className?: string;
  showPrevious: boolean;
  showNext: boolean;
  currentPage: number;
  totalPages: number;
  totalObjects: number;
  queryParams: string[];
}

export default function Pagination({
  className,
  showPrevious,
  showNext,
  currentPage,
  totalPages,
  totalObjects,
  queryParams,
}: PaginationProps) {
  const [searchParams] = useSearchParams();

  const pageRange: number[] = [];
  for (let i = 1; i <= totalPages; i += 1) {
    pageRange.push(i);
  }

  function buildPage(page: number) {
    if (page === currentPage) {
      return (
        <a
          href={`?page=${page}`}
          key={`page-${page}`}
          className="inline-flex items-center border-t-2 border-info px-4 pt-4 text-sm font-medium text-info"
          aria-current="page"
        >
          {page}
        </a>
      );
    }
    return (
      <a
        href={`?page=${page}`}
        key={`page-${page}`}
        className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-400 dark:text-white dark:hover:text-igloo hover:border-gray-300 dark:hover:border-igloo hover:text-flint"
      >
        {page}
      </a>
    );
  }

  function createMovePageLinks(newPage: number) {
    let url = `?page=${newPage}`;
    queryParams.forEach((param) => {
      if (param !== "page") {
        const paramValue = searchParams.get(param);
        if (paramValue !== null) {
          url += `&${param}=${paramValue}`;
        }
      }
    });
    return url;
  }

  return (
    <nav
      className={`flex items-center justify-between border-t-2 px-4 sm:px-0 ${className}`}
    >
      <div className="-mt-px flex w-0 flex-1">
        <a
          href={createMovePageLinks(currentPage - 1)}
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-400 dark:text-white dark:hover:text-igloo hover:border-gray-300 dark:hover:border-igloo hover:text-flint"
        >
          {showPrevious && (
            <>
              <ArrowLongLeftIcon
                className="mr-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Previous
            </>
          )}
        </a>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pageRange.map((page) => buildPage(page))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <a
          href={createMovePageLinks(currentPage + 1)}
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-400 dark:text-white dark:hover:text-igloo hover:border-gray-300 dark:hover:border-igloo hover:text-flint"
        >
          {showNext && (
            <>
              Next
              <ArrowLongRightIcon
                className="ml-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </>
          )}
        </a>
      </div>
    </nav>
  );
}

Pagination.defaultProps = {
  className: "",
};