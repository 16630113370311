import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";
import { buildUrl } from "../utils/proxy";

export interface EventState {
  id: string;
  datetime_created: string;
  datetime_updated: string;
  name: string;
  description: string;
  event_type: [string, string];
  start_date: {
    readable: string,
    standard: string,
    time: {
      readable: string;
      hour: number;
      minute: number;
    },
  };
  end_date: {
    readable: string,
    standard: string,
    time: {
      readable: string;
      hour: number;
      minute: number;
    },
  };
  status: string;
}

// Define a type for the slice state
interface CalendarState {
  data: Array<EventState>,
  loading: boolean,
  options: Array<[string, string]>;
  date_range: {
    min: string | null,
    max: string | null,
    by: string | null,
    filter: { choices: { current: string; previous: string; next: string; }, start: string, end: string; } | null
  };
  waiting: boolean;
}

// Define the initial state using that type
const initialState: CalendarState = {
  data: [],
  loading: true,
  options: [],
  date_range: {
    min: null,
    max: null,
    by: null,
    filter: null
  },
  waiting: false,
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get calendar events
export const getCalendarEvents = createAsyncThunk(
  "calendar/event/get",
  async (obj: {
    filterBy: string | null;
    filterDate: string | null;
  }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      const { filterBy, filterDate } = obj;

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/calendar/event?by=${filterBy}&date=${filterDate}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Create calendar event
export const createCalendarEvent = createAsyncThunk(
  "calendar/event/post",
  async (obj: {
    name: string;
    description: string;
    profile: string;
    event_type: string;
    start_date: string;
    end_date: string;
    status: string;
    source: string;
    secondary_id: string;
  }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };

      // Build body
      const body = JSON.stringify(obj);

      const res = await axios.post(buildUrl(`/api/calendar/event`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Update calendar event
export const updateCalendarEvent = createAsyncThunk(
  "calendar/event/patch",
  async (obj: {
    eventId: string | undefined;
    name: string;
    description: string;
    profile: string;
    event_type: string;
    start_date: string;
    end_date: string;
    status: string;
  }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };

      // Build body
      const body = JSON.stringify({ id: obj.eventId, ...obj });

      const res = await axios.patch(buildUrl(`/api/calendar/event`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Delete calendar event
export const deleteCalendarEvent = createAsyncThunk(
  "calendar/event/delete",
  async (
    obj: {
      eventId: string | undefined;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const res = await axios.delete(buildUrl(`/api/calendar/event`), { data: { id: obj.eventId }, headers: { Authorization: `Bearer ${auth.access}` } });
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get calendar event by ID
export const getCalendarEventById = createAsyncThunk(
  "calendar/event/id/get",
  async (obj: {
    eventId: string | undefined;
  }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      const { eventId } = obj;

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/calendar/event/${eventId}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get google calendar oauth initiation
export const getGoogleCalendarOauthInit = createAsyncThunk(
  "calendar/event/google-oauth/init/get",
  async (args, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      const res = await axios.get(buildUrl(`/api/calendar/event/google-oauth/init`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get google calendar oauth show
export const getGoogleCalendarOauthLoad = createAsyncThunk(
  "calendar/event/google-oauth/load/get",
  async (args, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      const res = await axios.get(buildUrl(`/api/calendar/event/google-oauth/load`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Revoke google calendar oauth
export const revokeGoogleCalendarOauth = createAsyncThunk(
  "calendar/event/google-oauth/revoke/get",
  async (args, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      const res = await axios.get(buildUrl(`/api/calendar/event/google-oauth/revoke`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get calendar events
      .addCase(getCalendarEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCalendarEvents.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.options = action.payload.data.options;
        state.date_range = action.payload.data.date_range;
        state.loading = false;
      })
      .addCase(getCalendarEvents.rejected, (state) => {
        state.loading = false;
      })

      // Create calendar event
      .addCase(createCalendarEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCalendarEvent.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.options = action.payload.data.options;
        state.date_range = action.payload.data.date_range;
        state.loading = false;
      })
      .addCase(createCalendarEvent.rejected, (state) => {
        state.loading = false;
      })

      // Update calendar event
      .addCase(updateCalendarEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCalendarEvent.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.options = action.payload.data.options;
        state.loading = false;
      })
      .addCase(updateCalendarEvent.rejected, (state) => {
        state.loading = false;
      })

      // Delete calendar event
      .addCase(deleteCalendarEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCalendarEvent.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.loading = false;
      })
      .addCase(deleteCalendarEvent.rejected, (state) => {
        state.loading = false;
      })

      // Get calendar event by ID
      .addCase(getCalendarEventById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCalendarEventById.fulfilled, (state, action) => {
        state.data = [action.payload.data.data];
        state.options = action.payload.data.options;
        state.loading = false;
      })
      .addCase(getCalendarEventById.rejected, (state) => {
        state.loading = false;
      })

      // Get google calendar oauth initiation
      .addCase(getGoogleCalendarOauthInit.pending, (state) => {
        state.waiting = true;
      })
      .addCase(getGoogleCalendarOauthInit.fulfilled, (state, action) => {
        state.waiting = false;
      })
      .addCase(getGoogleCalendarOauthInit.rejected, (state) => {
        state.waiting = false;
      })

      // Get google calendar oauth show
      .addCase(getGoogleCalendarOauthLoad.pending, (state) => {
        state.waiting = true;
      })
      .addCase(getGoogleCalendarOauthLoad.fulfilled, (state, action) => {
        state.waiting = false;
      })
      .addCase(getGoogleCalendarOauthLoad.rejected, (state) => {
        state.waiting = false;
      })

      // Revoke google calendar oauth
      .addCase(revokeGoogleCalendarOauth.pending, (state) => {
        state.waiting = true;
      })
      .addCase(revokeGoogleCalendarOauth.fulfilled, (state) => {
        state.waiting = false;
      })
      .addCase(revokeGoogleCalendarOauth.rejected, (state) => {
        state.waiting = false;
      });
  },
});

export default calendarSlice.reducer;
