import { useState } from "react";
import { TrashIcon, XCircleIcon } from "@heroicons/react/24/solid";
import getButtonStyle from "../../utils/styles";
import Button from "../layout/Button";
import generateTranslatedText from "../../utils/boilerplate";

interface CheckboxProps {
  objectId: string;
  handler: (e: any, args: any[]) => void;
  language: string;
}

export default function DangerZone({
  objectId,
  handler,
  language,
}: CheckboxProps) {
  const [showConfirmationMessage, setShowConfirmationMessage] =
    useState<boolean>(false);

  function showFinalConfirmation(e: any) {
    e.preventDefault();
    setShowConfirmationMessage(true);
  }

  function confirmDeleteYes(e: any) {
    e.preventDefault();
    handler(e, [objectId]);
  }

  function confirmDeleteNo(e: any) {
    e.preventDefault();
    setShowConfirmationMessage(false);
  }

  return (
    <div className="border-t border-b py-8 my-4">
      <div className="mb-4">
        <label className="mb-1 block text-left text-sm font-semibold text-black dark:text-white">
          {generateTranslatedText("danger_zone", language)}
        </label>
        <p className="text-left text-xs italic text-gray-500 dark:text-gray-400">
          {generateTranslatedText("danger_zone_subheader", language)}
        </p>
      </div>

      <div className="text-left">
        <Button
          type="button"
          className={`${getButtonStyle("solid", "hoverDanger")} rounded-md`}
          onClick={showFinalConfirmation}
        >
          <TrashIcon className={`h-5 w-5 text-danger`} aria-hidden="true" />
        </Button>
        {showConfirmationMessage && (
          <div className="mt-4">
            <p className="text-left text-sm text-black dark:text-white">
              {generateTranslatedText("danger_zone_confirmation", language)}
            </p>
            <div className="grid grid-cols-2 mt-2">
              <Button
                type="button"
                className={`${getButtonStyle("solid", "hoverLight")} rounded-md`}
                onClick={confirmDeleteNo}
              >
                <XCircleIcon
                  className={`h-5 w-5 text-gray-500 dark:text-gray-400 mr-1`}
                  aria-hidden="true"
                />
                <span className="text-gray-500 dark:text-gray-400">
                  {generateTranslatedText("danger_zone_cancel", language)}
                </span>
              </Button>
              <Button
                type="button"
                className={`${getButtonStyle("solid", "hoverDanger")} rounded-md`}
                onClick={confirmDeleteYes}
              >
                <TrashIcon
                  className={`h-5 w-5 text-danger mr-1`}
                  aria-hidden="true"
                />
                <span className="text-danger">
                  {generateTranslatedText("danger_zone_delete", language)}
                </span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
