import { useState } from "react";
import Banner from "../layout/Banner";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";
import { sendContactUsEmail } from "../../slices/auth";
import { useAppDispatch, useAppSelector } from "../../hooks";
import TextInput from "../layout/TextInput";
import TextArea from "../layout/TextArea";


export default function Contact() {
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user } = useAppSelector((state) => state.auth);

  // Get component level state
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  });

  // Functions
  async function onSubmit(e: any) {
    e.preventDefault();

    const { email, subject, message } = formData;
    if (!email || !subject || !message) {
      dispatch(addAlert(generateTranslatedText("missing_fields", language), "danger"));
      return;
    }

    const res: any = await dispatch(sendContactUsEmail({ email, subject, message }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("contact_us_email_sent", language), "success"));
      setFormData({
        email: "",
        subject: "",
        message: "",
      });
    } else {
      dispatch(addAlert(generateTranslatedText("contact_us_email_error", language), "danger"));
    }
  }

  // Constant variables
  const language = user?.language || "EN";

  return (
    <div className="w-full h-screen bg-igloo">

      {/* Form */}
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="bg-white dark:bg-zinc-800 rounded-xl p-8 w-full max-w-md space-y-8 drop-shadow">
          <div>
            <a href="/">
              <img
                className="mx-auto h-12 w-auto rounded-lg"
                src="/img/logo/logo_coloured_bg.svg"
                alt="Igloo"
              />
            </a>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-flint dark:text-white">
              {generateTranslatedText("get_in_touch", language)}
            </h2>
          </div>
          <form
            className="mt-8 space-y-6"
            onSubmit={(e) => onSubmit(e)}
          >
            <div className="grid grid-cols-1 gap-y-8">

              {/* Email */}
              <TextInput
                id="email"
                type="text"
                label={generateTranslatedText("email", language)}
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                required={false}
                showLabel={true}
                state={formData}
                stateName="email"
                onChange={setFormData}
                placeholder={generateTranslatedText("email_placeholder", language)}
              />

              {/* Subject */}
              <TextInput
                id="subject"
                type="text"
                label={generateTranslatedText("subject", language)}
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                required={false}
                showLabel={true}
                state={formData}
                stateName="subject"
                onChange={setFormData}
                placeholder={generateTranslatedText("subject_placeholder", language)}
              />

              {/* Message */}
              <TextArea
                id="subject"
                label={generateTranslatedText("message", language)}
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                required={false}
                showLabel={true}
                state={formData}
                stateName="message"
                onChange={setFormData}
                placeholder={generateTranslatedText("message_placeholder", language)}
                rows={5}
              />

              {/* Button */}
              <div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-full border border-transparent bg-info py-2 px-4 text-sm font-medium text-white hover:bg-info-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <PaperAirplaneIcon
                      className="h-5 w-5 fill-info-light group-hover:fill-white"
                      aria-hidden="true"
                    />
                  </span>
                  {generateTranslatedText("send", language)}
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>

      {/* Alert banner */}
      <Banner />
    </div>
  );
}
