import { useAppSelector } from "../../hooks";
import Alert from "./Alert";

export default function Banner() {
  const { message } = useAppSelector((state) => state.alert);
  return (
    <>
      {message.map((alert) => (
        <span key={alert.id}>
          <Alert
            id={alert.id}
            status={alert.status}
            message={alert.message}
            timeout={alert.timeout}
          />
        </span>
      ))}
    </>
  );
}
