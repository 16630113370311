interface ActionButtonsProps {
  primaryButton: React.ReactNode;
  secondaryButton?: React.ReactNode;
  tertiaryButton?: React.ReactNode;
}

export default function ActionButtons({ primaryButton, secondaryButton, tertiaryButton }: ActionButtonsProps) {

  return (
    <div className="mt-5 flex lg:ml-4 lg:mt-0">
      {secondaryButton !== null && (
        <span className="">
          <>{secondaryButton}</>
        </span>
      )}

      {tertiaryButton !== null && (
        <span className="ml-3">
          <>{tertiaryButton}</>
        </span>
      )}

      <span className={`ml-${tertiaryButton !== null || secondaryButton !== null ? "3" : "0"}`}>
        <>{primaryButton}</>
      </span>

      {/* Dropdown on small viewport */}
      {/* <Menu as="div" className="relative ml-3 sm:hidden">
        <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
          More
          <ChevronDownIcon className="-mr-1 ml-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#!"
                  className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                >
                  Edit
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#!"
                  className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                >
                  View
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu> */}
    </div>
  );
}

ActionButtons.defaultProps = {
  secondaryButton: null,
  tertiaryButton: null,
}
