import { useAppSelector } from "../../hooks";
import generateTranslatedText from "../../utils/boilerplate";
import Footer from "../landing/Footer";
import LandingNav from "../landing/LandingNav";
import Banner from "../layout/Banner";
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'


export default function FAQs() {
  const { user } = useAppSelector((state) => state.auth);
  const language = user?.language || "EN";

  const faqs = [
    {
      question: generateTranslatedText("faq_1_q", language),
      answer: generateTranslatedText("faq_1_a", language),
    },
    {
      question: generateTranslatedText("faq_2_q", language),
      answer: generateTranslatedText("faq_2_a", language),
    },
    {
      question: generateTranslatedText("faq_3_q", language),
      answer: generateTranslatedText("faq_3_a", language),
    },
    {
      question: generateTranslatedText("faq_4_q", language),
      answer: generateTranslatedText("faq_4_a", language),
    },
    {
      question: generateTranslatedText("faq_5_q", language),
      answer: generateTranslatedText("faq_5_a", language),
    },
    {
      question: generateTranslatedText("faq_6_q", language),
      answer: generateTranslatedText("faq_6_a", language),
    },
    {
      question: generateTranslatedText("faq_7_q", language),
      answer: generateTranslatedText("faq_7_a", language),
    },
    {
      question: generateTranslatedText("faq_8_q", language),
      answer: generateTranslatedText("faq_8_a", language),
    },
  ]
  return (
    <div className="bg-white dark:bg-zinc-800">
      <div className="w-full bg-white dark:bg-zinc-800">
        {/* Navbar */}
        <LandingNav />

        <div className="bg-white dark:bg-zinc-800">
          <div className="container mx-auto mt-10 bg-white dark:bg-zinc-800">
            <div className="pb-8 sm:pb-12 lg:pb-12">

              {/* FAQs */}
              <div className="mx-auto max-w-7xl px-6 lg:px-8sm:pt-10 sm:pb-20 pt-10 pb-20">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10 dark:divide-slate-100/20">
                  <h2 className="text-2xl font-bold leading-10 tracking-tight text-flint dark:text-white">{generateTranslatedText("faqs", language)}</h2>
                  <dl className="mt-10 space-y-6 divide-y divide-slate-900/10 dark:divide-slate-100/20">
                    {faqs.map((faq) => (
                      <Disclosure as="div" key={faq.question} className="pt-6">
                        {({ open }) => (
                          <>
                            <dt>
                              <Disclosure.Button className="flex w-full items-start justify-between text-left text-flint dark:text-white">
                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                <span className="ml-6 flex h-7 items-center">
                                  {open ? (
                                    <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                  ) : (
                                    <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </dt>
                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                              <p className="text-base leading-7 text-slate-600 dark:text-slate-300">{faq.answer}</p>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

        {/* Alert banner */}
        <Banner />
      </div>
    </div>
  );
}
