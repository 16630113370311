import LandingNav from "../landing/LandingNav";
import Banner from "../layout/Banner";

export default function NotFound() {
  return (
    <>
      <div className="w-full h-screen overflow-scroll bg-white dark:bg-flint">
        {/* Navbar */}
        <LandingNav />

        {/* Content */}
        <div className="container mx-auto my-10">
          <div className="bg-transparent pb-8 sm:pb-12 lg:pb-12">
            <div className="overflow-hidden pt-8 sm:pt-12 lg:relative lg:py-48">
              <h1 className="text-4xl font-bold tracking-tight text-flint dark:text-white sm:text-5xl mx-auto text-center">
                Whoops! Page not found...
              </h1>
            </div>
          </div>
        </div>

        {/* Alert banner */}
        <Banner />
      </div>
    </>
  );
}
