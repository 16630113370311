import Banner from "../layout/Banner";
import Footer from "./Footer";
import LandingContent from "./LandingContent";
import LandingNav from "./LandingNav";

export default function Landing() {
  return (
    <div className="bg-white dark:bg-zinc-800">
      <div className="w-full h-screen bg-white dark:bg-zinc-800">
        {/* Navbar */}
        <LandingNav />

        {/* Content */}
        <LandingContent />

        {/* Footer */}
        <Footer />

        {/* Alert banner */}
        <Banner />
      </div>
    </div>
  );
}
