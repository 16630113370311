import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";
import { buildUrl } from "../utils/proxy";

export interface AnnouncementState {
  id: string;
  datetime_created: string;
  datetime_updated: string | null;
  priority: number;
  community: [string, string];
  title: string;
  message: string;
  status: [string, string];
}

// Define a type for the slice state
interface AnnouncementsState {
  data: AnnouncementState[];
  pagination: {
    has_previous: boolean;
    has_next: boolean;
    current_page: number;
    total_pages: number;
    total_objects: number;
  }
  date_range: {
    start_date: string | null;
    end_date: string | null;
  };
  loading: boolean;
}

// Define the initial state using that type
const initialState: AnnouncementsState = {
  data: [],
  pagination: {
    has_previous: false,
    has_next: false,
    current_page: 0,
    total_pages: 0,
    total_objects: 0,
  },
  date_range: {
    start_date: null,
    end_date: null
  },
  loading: true,
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get entity announcements
export const getEntityAnnouncements = createAsyncThunk(
  "announcements/entity/get",
  async (obj: { dateRange: string | null, pageNum: string | null; community: string | null }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      const params: { [key: string]: string } = {};
      if (obj.dateRange !== null) params.dateRange = obj.dateRange;
      if (obj.pageNum !== null) params.page = obj.pageNum;
      if (obj.community !== null) params.community = obj.community;
      const searchParams = new URLSearchParams(params);

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/entity/community/announcement?${searchParams.toString()}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Create entity announcement
export const createEntityAnnouncement = createAsyncThunk(
  "announcements/entity/post",
  async (
    obj: { priority: number; community: string; title: string; message: string },
    { getState, rejectWithValue }
  ) => {
    try {
      const { auth } = getState() as { auth: AuthState };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };
      const body = JSON.stringify({
        priority: obj.priority,
        community: obj.community,
        title: obj.title,
        message: obj.message,
        status: "A"
      });
      const res = await axios.post(buildUrl(`/api/entity/community/announcement`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Update entity announcement
export const updateEntityAnnouncement = createAsyncThunk(
  "announcements/entity/patch",
  async (
    obj: {
      announcementId: string;
      priority: number;
      community: string;
      title: string;
      message: string;
      status: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };

      // Build body
      const body = JSON.stringify({
        id: obj.announcementId,
        priority: obj.priority,
        community: obj.community,
        title: obj.title,
        message: obj.message,
        status: obj.status
      });
      const res = await axios.patch(buildUrl(`/api/entity/community/announcement`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Delete entity announcement
export const deleteEntityAnnouncement = createAsyncThunk(
  "announcements/entity/delete",
  async (
    obj: { announcementId: string; },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const res = await axios.delete(buildUrl(`/api/entity/community/announcement`), { data: { id: obj.announcementId }, headers: { Authorization: `Bearer ${auth.access}` } });
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get community announcement updates
export const getCommunityAnnouncements = createAsyncThunk(
  "announcements/community/updates/get",
  async (obj: { dateRange: string | null, community: string | null }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      const params: { [key: string]: string } = {};
      if (obj.dateRange !== null) params.dateRange = obj.dateRange;
      if (obj.community !== null) params.community = obj.community;
      const searchParams = new URLSearchParams(params);

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/entity/community/updates/announcement?${searchParams.toString()}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const announcementsSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get entity announcements
      .addCase(getEntityAnnouncements.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEntityAnnouncements.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.data = data.data;
        state.pagination = data.pagination;
        state.date_range = data.date_range;
        state.loading = false;
      })
      .addCase(getEntityAnnouncements.rejected, (state) => {
        state.loading = false;
      })

      // Create entity announcement
      .addCase(createEntityAnnouncement.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEntityAnnouncement.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [action.payload.data, ...state.data];
      })
      .addCase(createEntityAnnouncement.rejected, (state) => {
        state.loading = false;
      })

      // Update entity announcement
      .addCase(updateEntityAnnouncement.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateEntityAnnouncement.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.map((announcement) => {
          if (announcement.id === action.payload.data.id) {
            return action.payload.data;
          }
          return announcement;
        });
      })
      .addCase(updateEntityAnnouncement.rejected, (state) => {
        state.loading = false;
      })

      // Delete entity announcement
      .addCase(deleteEntityAnnouncement.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteEntityAnnouncement.fulfilled, (state, action) => {
        state.loading = false;
        const updatedData = state.data.filter((announcement) => announcement.id !== action.payload.data.id);
        state.data = updatedData;
      })
      .addCase(deleteEntityAnnouncement.rejected, (state) => {
        state.loading = false;
      })

      // Get community announcement updates
      .addCase(getCommunityAnnouncements.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCommunityAnnouncements.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.date_range = action.payload.data.date_range;
        state.loading = false;
      })
      .addCase(getCommunityAnnouncements.rejected, (state) => {
        state.loading = false;
      });

  },
});

export default announcementsSlice.reducer;
