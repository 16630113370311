
export default function Card({ children, padding, colour, shadow, className }: { children: React.ReactNode, padding?: string, colour?: string, shadow?: string, className?: string }) {
  return (
    <div className={`rounded-lg ${colour} ${padding} ${shadow} ${className}`}>
      {children}
    </div>
  );
}

Card.defaultProps = {
  padding: "p-4",
  colour: "bg-white dark:bg-zinc-900",
  shadow: "drop-shadow",
  className: ""
};
