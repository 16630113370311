import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Header from "../layout/Header";
import Spinner from "../layout/Spinner";
import SpaceFiller from "../layout/SpaceFiller";
import Container from "../layout/Container";
import Button from "../layout/Button";
import Breadcrumbs from "../layout/Breadcrumbs";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";
import Card from "../layout/Card";
import { getGoogleCalendarOauthLoad, revokeGoogleCalendarOauth } from "../../slices/calendar";
import getButtonStyle from "../../utils/styles";
import { ArrowDownOnSquareStackIcon, BoltSlashIcon, LinkIcon } from "@heroicons/react/24/solid";
import { buildGoogleCalendarOauthUrl } from "../../utils/proxy";

export default function CalendarSettings() {
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user, loading } = useAppSelector((state) => state.auth);
  const { waiting } = useAppSelector((state) => state.calendar);

  // Get component level state

  // Functions
  function handleGoogleAuthClick() {
    window.location.href = buildGoogleCalendarOauthUrl(`/api/calendar/event/google-oauth/init?id=${user?.profile.id}`);
  };

  async function handleGoogleAuthRevokeClick() {
    const res: any = await dispatch(revokeGoogleCalendarOauth());
    if (res.payload.status === 205) {
      dispatch(addAlert(generateTranslatedText("calendar_auth_revoked", language), "success"));
    } else {
      dispatch(addAlert(generateTranslatedText("calendar_auth_revoked_error", language), "danger"));
    }
  };

  async function LoadGoogleCalendarEvents() {
    const res: any = await dispatch(getGoogleCalendarOauthLoad());
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("calendar_auth_load", language), "success"));
    } else {
      dispatch(addAlert(generateTranslatedText("calendar_auth_load_error", language), "danger"));
    }
  }

  // Constant variables
  const language = user?.language || "EN";

  return (
    <Template>

      <Container className="pt-0 overflow-y-auto h-screen scrollbar-invisible">
        {loading ? (
          <SpaceFiller>
            <Spinner />
          </SpaceFiller>
        ) : (
          <>

            {/* Header */}
            <Header
              text={`${generateTranslatedText("calendar", language)} ${generateTranslatedText("settings", language)}`}
              breadcrumbs={<Breadcrumbs breadcrumbs={[{ href: "/calendar", text: generateTranslatedText("calendar", language) }, { href: `/calendar/settings`, text: generateTranslatedText("settings", language) }]} />}
            />

            {/* Calendar settings form */}
            <Card className="mx-1 max-w-100">
              <form
                className="space-y-8 divide-y divide-gray-200"
                onSubmit={(e) => null}
              >
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="grid grid-cols-1 gap-y-8">

                    {user !== null && (
                      <div>
                        <p className="mt-3 block text-sm font-medium text-black dark:text-white text-left">{generateTranslatedText("calendar_settings_integration", language)}</p>
                        <p className="text-sm text-gray-500 dark:text-gray-400 mb-3">{generateTranslatedText("calendar_settings_integration_description", language)}</p>
                        <div className={`grid grid-cols-1 sm:grid-cols-${user.google_calendar_auth === true ? "3" : "1"} gap-4`}>
                          {/* Authorise Google Calendar */}
                          <Button
                            type="button"
                            className={`${getButtonStyle("solid", "danger")} rounded-full drop-shadow`}
                            onClick={handleGoogleAuthClick}
                            disabled={waiting}
                          >
                            <LinkIcon className="h-5 w-5 text-white mr-2" aria-hidden="true" />
                            {generateTranslatedText("google_calendar_connect", language)}
                          </Button>

                          {/* Load Google Calendar events */}
                          {user.google_calendar_auth === true && (
                            <Button
                              type="button"
                              className={`${getButtonStyle("solid", "primary")} rounded-full drop-shadow`}
                              onClick={LoadGoogleCalendarEvents}
                              disabled={waiting}
                            >
                              {waiting ? <Spinner className="mr-2" colour="white" size={5} /> : <ArrowDownOnSquareStackIcon className="h-5 w-5 text-white mr-2" aria-hidden="true" />}
                              {generateTranslatedText("google_calendar_load", language)}
                            </Button>
                          )}

                          {/* Revoke Google Calendar */}
                          {user.google_calendar_auth === true && (
                            <Button
                              type="button"
                              className={`${getButtonStyle("solid", "warning")} rounded-full drop-shadow`}
                              onClick={handleGoogleAuthRevokeClick}
                              disabled={waiting}
                            >
                              <BoltSlashIcon className="h-5 w-5 text-black mr-2" aria-hidden="true" />
                              {generateTranslatedText("google_calendar_revoke", language)}
                            </Button>
                          )}

                        </div>
                      </div>
                    )}

                  </div>
                </div>

                {/* <div className="pt-5">
                  <div className="flex justify-end border-none border-0">
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center rounded-full border border-transparent bg-info drop-shadow py-2 px-4 text-sm font-medium text-white hover:underline focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
                    >
                      <CheckBadgeIcon className="w-5 h-5 text-white dark:text-white mr-2" aria-hidden="true" />
                      {generateTranslatedText("save", language)}
                    </button>
                  </div>
                </div> */}

              </form>
            </Card>
          </>
        )}

      </Container>
    </Template>
  );
}