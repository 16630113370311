import { useEffect, useState } from "react";
import Header from "../layout/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Container from "../layout/Container";
import Pulse from "../layout/Pulse";
import SpaceFiller from "../layout/SpaceFiller";
import { CheckInState, createCheckIn, getCheckInsByPlanId, getGoalPlanById, updateGoalPlan } from "../../slices/goals";
import Card from "../layout/Card";
import Spinner from "../layout/Spinner";
import { CheckCircleIcon, Cog8ToothIcon, PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import ProgressBar from "../layout/ProgressBar";
import NoContent from "../layout/NoContent";
import Button from "../layout/Button";
import getButtonStyle from "../../utils/styles";
import ActionButtons from "../layout/ActionButtons";
import Modal from "../layout/Modal";
import { Dialog } from "@headlessui/react";
import TextArea from "../layout/TextArea";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../layout/Breadcrumbs";
import Metadata from "../layout/Metadata";
import Link from "../layout/Link";
import RangeSlider from "../layout/RangeSlider";
import LineChart from "../charts/line/Line";
import { getWidthOfLineChart } from "../../utils/charts";

export default function GoalPlan() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Get string params
  const { plan_id: goalPlanId } = useParams()

  // Get app level state from redux store
  const { plans: plansData, check_ins: checkInsData } = useAppSelector(
    (state) => state.goals
  );
  const { user: userData } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getGoalPlanById({ planId: goalPlanId }));
    dispatch(getCheckInsByPlanId({ planId: goalPlanId }));
  }, [dispatch, goalPlanId]);

  const planData = plansData.data.filter(x => x.id === goalPlanId)[0];
  if (planData !== undefined && planData.status !== "A") {
    navigate("/goals")
  }
  const checkInData = checkInsData.data;
  const planLoading = plansData.loading
  const checkInsLoading = checkInsData.loading;

  // Get component level state
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showMarkAsCompleteModal, setShowMarkAsCompleteModal] = useState<boolean>(false);
  const [showUnMarkAsCompleteModal, setShowUnMarkAsCompleteModal] = useState<boolean>(false);
  const [createCheckInData, setCreateCheckInData] = useState({
    progress: 0,
    confidence: 0,
    enthusiasm: 0,
    feedback: "",
  });

  // Functions
  function openCreateCheckInModal(e: any) {
    e.preventDefault();
    setShowCreateModal(true);
  }

  async function confirmCheckInCreate(e: any) {
    e.preventDefault();
    if (userData === null) {
      dispatch(addAlert(generateTranslatedText("auth_error", language), "danger"));
      return;
    };
    const { progress, confidence, enthusiasm, feedback } = createCheckInData;
    const res: any = await dispatch(createCheckIn({
      profile: userData.profile.id,
      plan: goalPlanId,
      progress,
      confidence,
      enthusiasm,
      feedback,
      status: "A"
    }));
    if (res.payload.status === 201) {
      dispatch(addAlert(generateTranslatedText("create_check_in", language), "success"));
    } else {
      dispatch(addAlert(generateTranslatedText("create_check_in_error", language), "danger"));
    }
    setShowCreateModal(false);
  }

  function openMarkAsCompleteModal(e: any) {
    e.preventDefault();
    setShowMarkAsCompleteModal(true);
  }

  function openUnMarkAsCompleteModal(e: any) {
    e.preventDefault();
    setShowUnMarkAsCompleteModal(true);
  }

  async function confirmMarkAsComplete(e: any) {
    e.preventDefault();
    if (userData === null) {
      dispatch(addAlert(generateTranslatedText("auth_error", language), "danger"));
      return;
    };

    const res: any = await dispatch(updateGoalPlan({
      planId: goalPlanId,
      name: planData.name,
      description: planData.description,
      profile: userData.profile.id,
      goal_type: planData.goal_type[2],
      start_date: planData.start_date.standard,
      end_date: planData.end_date.standard,
      feedback: planData.feedback,
      rating: planData.rating,
      status: planData.status,
      complete: true
    }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("mark_goal_plan_complete", language), "success"));
    } else {
      dispatch(addAlert(res.payload.data.response, "danger"));
    }
    setShowMarkAsCompleteModal(false);
  }

  async function confirmUnMarkAsComplete(e: any) {
    e.preventDefault();
    if (userData === null) {
      dispatch(addAlert(generateTranslatedText("auth_error", language), "danger"));
      return;
    };

    const res: any = await dispatch(updateGoalPlan({
      planId: goalPlanId,
      name: planData.name,
      description: planData.description,
      profile: userData.profile.id,
      goal_type: planData.goal_type[0],
      start_date: planData.start_date.standard,
      end_date: planData.end_date.standard,
      feedback: planData.feedback,
      rating: planData.rating,
      status: planData.status,
      complete: false
    }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("mark_goal_plan_incomplete", language), "success"));
    } else {
      dispatch(addAlert(res.payload.data.response, "danger"));
    }
    setShowUnMarkAsCompleteModal(false);
  }

  // Constant variables
  const language = userData?.language || "EN";
  const progressLabel = generateTranslatedText("goal_progress_label", language);
  const confidenceLabel = generateTranslatedText("goal_confidence_label", language);
  const enthusiasmLabel = generateTranslatedText("goal_enthusiasm_label", language);
  const feedbackLabel = generateTranslatedText("goal_feedback_label", language);

  const timeSeriesData = [
    {
      id: "Progress",
      data: checkInData.map((ci) => ({
        x: ci.datetime.readable,
        y: checkInData.filter(x => x.datetime.readable === ci.datetime.readable).reduce((a, b) => a + b.progress, 0) / checkInData.filter(x => x.datetime.readable === ci.datetime.readable).length
      }))
    },
    {
      id: "Confidence",
      data: checkInData.map((ci) => ({
        x: ci.datetime.readable,
        y: checkInData.filter(x => x.datetime.readable === ci.datetime.readable).reduce((a, b) => a + b.confidence, 0) / checkInData.filter(x => x.datetime.readable === ci.datetime.readable).length
      }))
    },
    {
      id: "Enthusiasm",
      data: checkInData.map((ci) => ({
        x: ci.datetime.readable,
        y: checkInData.filter(x => x.datetime.readable === ci.datetime.readable).reduce((a, b) => a + b.enthusiasm, 0) / checkInData.filter(x => x.datetime.readable === ci.datetime.readable).length
      }))
    }
  ]


  return (
    <Template>

      {planLoading ? (
        <SpaceFiller>
          <Spinner />
        </SpaceFiller>
      ) : (
        <>
          {/* Create check in modal */}
          <Modal
            show={showCreateModal}
            setShow={setShowCreateModal}
            confirmText={generateTranslatedText("create_check_in_modal", language)}
            cancelText={generateTranslatedText("cancel", language)}
            confirmHandler={confirmCheckInCreate}
          >
            <div className="overflow-auto px-1">
              <div className="text-center">
                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                  {`${generateTranslatedText("create_check_in_for", language)} "${planData.name}"`}
                </Dialog.Title>
                <div className="my-10 grid grid-cols-1 gap-y-8">

                  {/* Progress */}
                  <RangeSlider
                    id="progress"
                    start={0}
                    end={10}
                    step={1}
                    state={createCheckInData}
                    stateName="progress"
                    onChange={setCreateCheckInData}
                    showLabel={true}
                    label={progressLabel}
                  />

                  {/* Confidence */}
                  <RangeSlider
                    id="confidence"
                    start={0}
                    end={10}
                    step={1}
                    state={createCheckInData}
                    stateName="confidence"
                    onChange={setCreateCheckInData}
                    showLabel={true}
                    label={confidenceLabel}
                  />

                  {/* Enthusiasm */}
                  <RangeSlider
                    id="enthusiasm"
                    start={0}
                    end={10}
                    step={1}
                    state={createCheckInData}
                    stateName="enthusiasm"
                    onChange={setCreateCheckInData}
                    showLabel={true}
                    label={enthusiasmLabel}
                  />

                  {/* Feedback */}
                  <TextArea
                    id="feedback"
                    rows={3}
                    label={feedbackLabel}
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                    required={true}
                    showLabel={true}
                    state={createCheckInData}
                    stateName="feedback"
                    onChange={setCreateCheckInData}
                  />

                </div>
              </div>
            </div>
          </Modal>

          {/* Mark as complete modal */}
          <Modal
            show={showMarkAsCompleteModal}
            setShow={setShowMarkAsCompleteModal}
            confirmText={generateTranslatedText("mark_as_complete", language)}
            cancelText={generateTranslatedText("cancel", language)}
            confirmHandler={confirmMarkAsComplete}
          >
            <div>
              <div className="text-center">
                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                  {`${generateTranslatedText("mark_goal_plan", language)} "${planData?.name}" ${generateTranslatedText("as_complete", language)}`}
                </Dialog.Title>
                <div className="my-10 grid grid-cols-1 gap-y-8">
                  <p className="text-sm text-gray-400 dark:text-gray-300">{generateTranslatedText("mark_goal_plan_complete_confirmation", language)}</p>
                </div>
              </div>
            </div>
          </Modal>

          {/* Mark as incomplete modal */}
          <Modal
            show={showUnMarkAsCompleteModal}
            setShow={setShowUnMarkAsCompleteModal}
            confirmText={generateTranslatedText("mark_as_incomplete", language)}
            cancelText={generateTranslatedText("cancel", language)}
            confirmHandler={confirmUnMarkAsComplete}
          >
            <div>
              <div className="text-center">
                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                  {`${generateTranslatedText("mark_goal_plan", language)} "${planData?.name}" ${generateTranslatedText("as_incomplete", language)}`}
                </Dialog.Title>
                <div className="my-10 grid grid-cols-1 gap-y-8">
                  <p className="text-sm text-gray-400 dark:text-gray-300">{generateTranslatedText("mark_goal_plan_incomplete_confirmation", language)}</p>
                </div>
              </div>
            </div>
          </Modal>

          <Container className="pt-0 overflow-y-auto h-screen scrollbar-invisible">

            {/* Header */}
            <Header
              text={planData.name}
              breadcrumbs={<Breadcrumbs breadcrumbs={[{ href: "/goals", text: "Goals" }]} />}
              metadata={<Metadata createdDate={planData.datetime_created} startDate={planData.start_date.readable} endDate={planData.end_date.readable} badge={planData.goal_type} />}
              actions={
                <ActionButtons
                  primaryButton={
                    <Link
                      href={`/goals/${goalPlanId}/settings`}
                      className={`${getButtonStyle("outline", "gray")} rounded-full`}
                    >
                      <Cog8ToothIcon className="h-5 w-5 text-gray-500 dark:text-gray-300" aria-hidden="true" />
                    </Link>
                  }
                  secondaryButton={planData.complete === false ?
                    <Button
                      type="button"
                      className={`${getButtonStyle("solid", "success")} rounded-full drop-shadow`}
                      onClick={openMarkAsCompleteModal}
                    >
                      <CheckCircleIcon className="h-5 w-5 text-white dark:text-flint" aria-hidden="true" />
                    </Button> : (
                      <Button
                        type="button"
                        className={`${getButtonStyle("solid", "danger")} rounded-full drop-shadow`}
                        onClick={openUnMarkAsCompleteModal}
                      >
                        <XCircleIcon className="h-5 w-5 text-white dark:text-flint" aria-hidden="true" />
                      </Button>
                    )
                  }
                  tertiaryButton={planData.complete === false ?
                    <Button
                      type="button"
                      className={`${getButtonStyle("solid", "info")} rounded-full drop-shadow`}
                      onClick={openCreateCheckInModal}
                    >
                      <PlusCircleIcon className="h-5 w-5 text-white mr-2" aria-hidden="true" />
                      {generateTranslatedText("create_check_in_button", language)}
                    </Button> : null
                  }
                />
              }
            />

            {/* Check ins */}
            {checkInsLoading ? (
              <>
                <h3 className="text-lg font-medium leading-7 text-flint sm:truncate sm:tracking-tight">
                  {generateTranslatedText("check_ins", language)}
                </h3>
                <ul className="mt-6 lg:mb-10 mb-32 mx-1">
                  {Array.from({ length: 3 }, (_, i) => (
                    <div className="bg-white dark:bg-zinc-800 rounded-lg mb-8 drop-shadow" key={i}>
                      <Pulse type="full" />
                    </div>
                  ))}
                </ul>
              </>
            ) : checkInData.length === 0 ? (
              <NoContent text={generateTranslatedText("no_check_ins", language)} subtext={generateTranslatedText("no_check_ins_subheader", language)} />
            ) : (
              <>
                {/* Time series */}
                <div className="grid gap-6 grid-cols-1 grid-rows-1 mt-6 lg:mb-10 mb-10 mx-1 place-content-center">
                  <div className="rounded-lg bg-white dark:bg-zinc-900 p-4 drop-shadow">
                    <h1 className="text-lg font-medium text-flint dark:text-white">
                      {generateTranslatedText("check_ins", language)}
                    </h1>
                    <p className="text-gray-400 dark:text-gray-300 text-sm mb-4">
                      {`${generateTranslatedText("see_how_check_ins_for", language)} "${planData.name}" ${generateTranslatedText("have_changed_over_time", language)}`}
                    </p>
                    <LineChart
                      data={timeSeriesData}
                      xScaleInputFormat="%b %d %H:%M"
                      xScaleInputPrecision="minute"
                      xScaleOutputFormat="%d %b %y %H:%M"
                      xScaleTickIncrement="every 10 minutes"
                      padding={[40, 100, 60, 40]}
                      colourPalette="check-in-chart"
                      hSmall="h-64"
                      hLarge="h-80"
                      xAxisLinear={true}
                      widthClass={getWidthOfLineChart(timeSeriesData[0].data.length)}
                    />
                  </div>
                </div>

                {/* Check ins array */}
                <div className="grid gap-6 grid-cols-1 mx-1 place-content-center">
                  {checkInData.map((checkIn: CheckInState) => (
                    <Card
                      key={checkIn.id}
                    >
                      <span className="text-slate-400 dark:text-slate-300 text-sm mb-4">{`Created @ ${checkIn.datetime.readable}`}</span>

                      {/* Progress */}
                      <span className="mt-3 block text-sm font-medium text-gray-700 dark:text-gray-300 text-left">
                        {progressLabel}
                      </span>
                      <ProgressBar
                        progress={checkIn.progress}
                        limit={10}
                        marginY="2"
                      />

                      {/* Confidence */}
                      <span className="mt-5 block text-sm font-medium text-gray-700 dark:text-gray-300 text-left">
                        {confidenceLabel}
                      </span>
                      <ProgressBar
                        progress={checkIn.confidence}
                        limit={10}
                        marginY="2"
                      />

                      {/* Enthusiasm */}
                      <span className="mt-5 block text-sm font-medium text-gray-700 dark:text-gray-300 text-left">
                        {enthusiasmLabel}
                      </span>
                      <ProgressBar
                        progress={checkIn.enthusiasm}
                        limit={10}
                        marginY="2"
                      />

                      {checkIn.feedback.length > 0 && (
                        <p className="mt-5 text-slate-500 text-sm italic">{`"${checkIn.feedback}"`}</p>
                      )}

                    </Card>
                  ))}

                </div>
              </>
            )}

          </Container>
        </>
      )}

    </Template>
  );
}
