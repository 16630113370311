import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";
import { buildUrl } from "../utils/proxy";

export interface NotificationState {
  id: string;
  datetime_created: string;
  datetime_updated: string | null;
  sent_by: [string, string];
  recipient: [string, string];
  associated_log: string;
  type: [string, string];
  action: [string, string];
  message: string;
  status: [string, string];
}

export interface CommunityFeedState {
  id: string;
  action_time: string;
  user: [string, string];
  content_type: {
    id: string;
    app_label: string;
    model: string;
  };
  object_id: string;
  object_repr: string;
  action_flag: number;
  change_message: { [key: string]: any };
  notifications: Array<NotificationState>;
}

// Define a type for the slice state
interface CommunityState {
  data: CommunityFeedState[];
  pagination: {
    has_previous: boolean;
    has_next: boolean;
    current_page: number;
    total_pages: number;
    total_objects: number;
  }
  date_range: {
    start_date: string | null;
    end_date: string | null;
  };
  loading: boolean;
}

// Define the initial state using that type
const initialState: CommunityState = {
  data: [],
  pagination: {
    has_previous: false,
    has_next: false,
    current_page: 0,
    total_pages: 0,
    total_objects: 0,
  },
  date_range: {
    start_date: null,
    end_date: null
  },
  loading: true,
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get community feed
export const getCommunityFeed = createAsyncThunk(
  "community/feed/get",
  async (obj: { dateRange: string | null, pageNum: string | null; community: string | null }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      const params: { [key: string]: string } = {};
      if (obj.dateRange !== null) params.dateRange = obj.dateRange;
      if (obj.pageNum !== null) params.page = obj.pageNum;
      if (obj.community !== null) params.community = obj.community;
      const searchParams = new URLSearchParams(params);

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/entity/community/feed?${searchParams.toString()}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// React to community feed item
export const createReactionToCommunityItem = createAsyncThunk(
  "diary/post",
  async (
    obj: {
      sentBy: string;
      recipient: string;
      associatedLog: string;
      type: string;
      action: string;
      message: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const { auth } = getState() as { auth: AuthState };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };
      const body = JSON.stringify({
        sent_by: obj.sentBy,
        recipient: obj.recipient,
        associated_log: obj.associatedLog,
        type: obj.type,
        action: obj.action,
        message: obj.message,
        status: "A"
      });
      const res = await axios.post(buildUrl(`/api/entity/community/react`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const communitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get community feed
      .addCase(getCommunityFeed.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCommunityFeed.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.pagination = action.payload.data.pagination;
        state.date_range = action.payload.data.date_range;
        state.loading = false;
      })
      .addCase(getCommunityFeed.rejected, (state) => {
        state.loading = false;
      })

      // React to community feed item
      .addCase(createReactionToCommunityItem.pending, (state) => { })
      .addCase(createReactionToCommunityItem.fulfilled, (state, action) => {
        const notificationData = action.payload.data;

        // Find associated log item in data
        const updatedData = state.data.map((x: CommunityFeedState) => {
          if (x.id === notificationData.associated_log) {
            let updatedItem = x
            updatedItem.notifications.push(notificationData);
            return updatedItem
          }
          return x;
        })
        state.data = updatedData
      })
      .addCase(createReactionToCommunityItem.rejected, (state) => { })
  },
});

export default communitySlice.reducer;
