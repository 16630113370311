const host = window.location.href
const stagingServer = "https://igloo-staging-gunicorn.mru5tc51jh2ia.eu-west-2.cs.amazonlightsail.com";
const prodServer = "https://igloo-gunicorn-prod.mru5tc51jh2ia.eu-west-2.cs.amazonlightsail.com";

export function buildUrl(path: string) {

  // If host is localhost or local.igloo.com, it can use the local proxy
  if (host.includes("local")) return path

  // If host is staging environment, it can use the staging proxy
  if (host.includes("staging")) return `${stagingServer}${path}`

  // Otherwise, it can use the production proxy within the same network
  return `${prodServer}${path}`
}

export function buildGoogleCalendarOauthUrl(path: string) {

  // If host is localhost or local.igloo.com, it can use the local proxy
  if (host.includes("localhost")) return `http://localhost:8000${path}`

  if (host.includes("local")) return path

  // If host is staging environment, it can use the staging proxy
  if (host.includes("staging")) return `${stagingServer}${path}`

  // Otherwise, it can use the production proxy within the same network
  return `${prodServer}${path}`
}

export function buildSubscriptionPurchaseUrl() {

  // If host is localhost
  if (host.includes("localhost")) return "https://buy.stripe.com/test_eVaeXGaTT0ScciQ5kl"

  // If host is development environment
  if (host.includes("local.igloo")) return "https://buy.stripe.com/test_9AQdTC2nnfN6eqYcMM"

  // If host is staging environment
  if (host.includes("staging")) return "https://buy.stripe.com/test_14kaHq8LL6cwciQfZ0"

  // Otherwise, it is production
  return "https://buy.stripe.com/fZefZi0533Ht7PqcMM"
}