import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

// Define a type for the slice state
interface Message {
  id: string;
  message: string;
  status: string;
  timeout: number;
}
interface AlertState {
  message: Array<Message>;
}

// Define the initial state using that type
const initialState: AlertState = {
  message: [],
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    addAlert: {
      prepare(message: string, status: string, timeout: number = 3000) {
        const id = uuidv4();
        return { payload: { id, message, status, timeout } };
      },
      reducer(state, action: PayloadAction<Message>) {
        state.message.push(action.payload);
      },
    },
    removeAlert(state, action) {
      const remainingAlerts = state.message.filter(
        (alert) => alert.id !== action.payload
      );
      state.message = remainingAlerts;
      return state;
    },
  },
});

export const { addAlert, removeAlert } = alertSlice.actions;
export default alertSlice.reducer;
