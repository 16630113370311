import { CheckIcon, MinusIcon } from "@heroicons/react/24/outline";
import Footer from "../landing/Footer";
import LandingNav from "../landing/LandingNav";
import Banner from "../layout/Banner";
import { Fragment, useState } from "react";
import { useAppSelector } from "../../hooks";
import { classNames } from "../../utils/text";
import generateTranslatedText from "../../utils/boilerplate";


export default function Pricing() {
  const { user } = useAppSelector((state) => state.auth);
  const [showUpgradeText, setShowUpgradeText] = useState(false)

  const language = user?.language || "EN";
  const tiers = [
    {
      name: generateTranslatedText("free", language),
      id: 'tier-basic',
      href: '/register',
      priceMonthly: '£0',
      description: generateTranslatedText("free_description", language),
      mostPopular: false,
    },
    {
      name: generateTranslatedText("premium", language),
      id: 'tier-premium',
      href: '#',
      priceMonthly: '£4.99',
      description: generateTranslatedText("premium_description", language),
      mostPopular: true,
    },
  ]
  const sections = [
    {
      name: generateTranslatedText("pricing_section_1", language),
      features: [
        { name: generateTranslatedText("pricing_section_1_f1", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_1_f2", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_1_f3", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_1_f4", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_1_f5", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_1_f6", language), tiers: { Free: true, Premium: true } },
      ],
    },
    {
      name: generateTranslatedText("pricing_section_7", language),
      features: [
        { name: generateTranslatedText("pricing_section_7_f1", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_7_f2", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_7_f3", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_7_f4", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_7_f5", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_7_f6", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_7_f7", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_7_f8", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_7_f9", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_7_f10", language), tiers: { Free: false, Premium: true } },
      ],
    },
    {
      name: generateTranslatedText("pricing_section_2", language),
      features: [
        { name: generateTranslatedText("pricing_section_2_f1", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_2_f2", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_2_f3", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_2_f4", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_2_f5", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_2_f6", language), tiers: { Free: false, Premium: true } },
      ],
    },
    {
      name: generateTranslatedText("pricing_section_3", language),
      features: [
        { name: generateTranslatedText("pricing_section_3_f1", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_3_f2", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_3_f3", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_3_f4", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_3_f5", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_3_f6", language), tiers: { Free: false, Premium: true } },
      ],
    },
    {
      name: generateTranslatedText("pricing_section_4", language),
      features: [
        { name: generateTranslatedText("pricing_section_4_f1", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_4_f2", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_4_f3", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_4_f4", language), tiers: { Free: true, Premium: true } },
      ],
    },
    {
      name: generateTranslatedText("pricing_section_5", language),
      features: [
        { name: generateTranslatedText("pricing_section_5_f1", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_5_f2", language), tiers: { Free: true, Premium: true } },
        { name: generateTranslatedText("pricing_section_5_f3", language), tiers: { Free: true, Premium: true } },
      ],
    },
    {
      name: generateTranslatedText("pricing_section_6", language),
      features: [
        { name: generateTranslatedText("pricing_section_6_f1", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_6_f2", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_6_f3", language), tiers: { Free: false, Premium: true } },
        { name: generateTranslatedText("pricing_section_6_f4", language), tiers: { Free: false, Premium: true } },
      ],
    },
  ]
  return (
    <>
      <div className="w-full bg-white dark:bg-zinc-800">
        {/* Navbar */}
        <LandingNav />

        <div className="container mx-auto my-10 bg-white dark:bg-zinc-800">
          <div className="bg-transparent pb-8 sm:pb-12 lg:pb-12">

            {/* Pricing table */}
            <div className="mx-auto max-w-7xl px-6 lg:px-8 sm:pt-20 pt-10">
              <div className="mx-auto max-w-4xl text-center">
                <h2 className="text-base font-semibold leading-7 text-info">{generateTranslatedText("pricing", language)}</h2>
                <p className="mt-2 text-4xl font-bold tracking-tight text-flint dark:text-slate-300 sm:text-5xl">
                  {generateTranslatedText("pricing_subheader", language)}
                </p>
              </div>
              <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-flint dark:text-slate-300">
                {generateTranslatedText("pricing_subtext", language)}
              </p>

              {/* xs to lg */}
              <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
                {tiers.map((tier) => (
                  <section
                    key={tier.id}
                    className={classNames(
                      tier.mostPopular ? 'rounded-xl bg-slate-400/5 ring-1 ring-inset ring-gray-200' : '',
                      'p-8'
                    )}
                  >
                    <h3 id={tier.id} className="text-sm font-semibold leading-6 text-flint dark:text-white">
                      {tier.name}
                    </h3>
                    <p className="mt-2 flex items-baseline gap-x-1 text-flint dark:text-white">
                      <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                      <span className="text-sm font-semibold">{generateTranslatedText("per_month", language)}</span>
                    </p>
                    {tier.name === "Free" ? (
                      <a
                        href={tier.name === "Free" ? tier.href : "!#"}
                        aria-describedby={tier.id}
                        className={classNames(
                          tier.mostPopular
                            ? 'bg-info text-white hover:bg-info-dark'
                            : 'text-info ring-1 ring-inset ring-info hover:ring-info-dark',
                          'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-info'
                        )}
                      >
                        {generateTranslatedText("get_started", language)}
                      </a>
                    ) : (
                      <>
                        <a
                          href="#!"
                          aria-describedby={tier.id}
                          onClick={(e) => setShowUpgradeText(!showUpgradeText)}
                          className={classNames(
                            tier.mostPopular
                              ? 'bg-info text-white hover:bg-info-dark'
                              : 'text-info ring-1 ring-inset ring-info hover:ring-info-dark',
                            'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-info'
                          )}
                        >
                          {generateTranslatedText("upgrade_for_free_trial", language)}
                        </a>
                        {showUpgradeText && (
                          <p className="pt-2 text-flint dark:text-white text-sm">{generateTranslatedText("sign_up_for_a", language)}<a target="_blank" href="/register" className="underline text-info">{generateTranslatedText("free_account", language)}</a>{generateTranslatedText("first_then_upgrade", language)}<a target="_blank" href="/profile" className="underline text-info">{generateTranslatedText("your_profile", language)}</a></p>
                        )}
                      </>
                    )}
                    <ul className="mt-10 space-y-4 text-sm leading-6 text-flint dark:text-white">
                      {sections.map((section) => (
                        <li key={section.name}>
                          <ul className="space-y-4">
                            {section.features.map((feature: any) =>
                              feature.tiers[tier.name] ? (
                                <li key={feature.name} className="flex gap-x-3">
                                  <CheckIcon className="h-6 w-5 flex-none text-info" aria-hidden="true" />
                                  <span>
                                    {feature.name}{' '}
                                    {typeof feature.tiers[tier.name] === 'string' ? (
                                      <span className="text-sm leading-6 text-slate-500 dark:text-slate-300">({feature.tiers[tier.name]})</span>
                                    ) : null}
                                  </span>
                                </li>
                              ) : null
                            )}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </section>
                ))}
              </div>

              {/* lg+ */}
              <div className="isolate mt-20 hidden lg:block">
                <div className="relative -mx-8">
                  {tiers.some((tier) => tier.mostPopular) ? (
                    <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                      <div
                        className="flex w-1/3 px-4"
                        aria-hidden="true"
                        style={{ marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 1) * 33}%` }}
                      >
                        <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-slate-400/5 dark:bg-zinc-700/70" />
                      </div>
                    </div>
                  ) : null}
                  <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                    <caption className="sr-only">{generateTranslatedText("pricing_plan_comparison", language)}</caption>
                    <colgroup>
                      <col className="w-1/3" />
                      <col className="w-1/3" />
                      <col className="w-1/3" />
                    </colgroup>
                    <thead>
                      <tr>
                        <td />
                        {tiers.map((tier) => (
                          <th key={tier.id} scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
                            <div className="text-sm font-semibold leading-7 text-flint dark:text-white">{tier.name}</div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <span className="sr-only">{generateTranslatedText("price", language)}</span>
                        </th>
                        {tiers.map((tier) => (
                          <td key={tier.id} className="px-6 pt-2 xl:px-8">
                            <div className="flex items-baseline gap-x-1 text-flint dark:text-white">
                              <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                              <span className="text-sm font-semibold leading-6">{generateTranslatedText("per_month", language)}</span>
                            </div>
                            {tier.name === "Free" ? (
                              <a
                                href={tier.href}
                                className={classNames(
                                  tier.mostPopular
                                    ? 'bg-info text-white hover:bg-info-dark'
                                    : 'text-info ring-1 ring-inset ring-info hover:ring-info-dark',
                                  'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-info'
                                )}
                              >
                                {generateTranslatedText("get_started", language)}
                              </a>
                            ) : (
                              <>
                                <a
                                  href="#!"
                                  onClick={(e) => setShowUpgradeText(!showUpgradeText)}
                                  className={classNames(
                                    tier.mostPopular
                                      ? 'bg-info text-white hover:bg-info-dark'
                                      : 'text-info ring-1 ring-inset ring-info hover:ring-info-dark',
                                    'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-info'
                                  )}
                                >
                                  {generateTranslatedText("upgrade_for_free_trial", language)}
                                </a>
                                {showUpgradeText && (
                                  <p className="pt-2 text-flint dark:text-white text-sm">{generateTranslatedText("sign_up_for_a", language)}<a target="_blank" href="/register" className="underline text-info">{generateTranslatedText("free_account", language)}</a>{generateTranslatedText("first_then_upgrade", language)}<a target="_blank" href="/profile" className="underline text-info">{generateTranslatedText("your_profile", language)}</a></p>
                                )}
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                      {sections.map((section, sectionIdx) => (
                        <Fragment key={section.name}>
                          <tr>
                            <th
                              scope="colgroup"
                              colSpan={3}
                              className={classNames(
                                sectionIdx === 0 ? 'pt-8' : 'pt-16',
                                'pb-4 text-sm font-semibold leading-6 text-flint dark:text-white'
                              )}
                            >
                              {section.name}
                              <div className="absolute inset-x-8 mt-4 h-px bg-slate-900/10 dark:bg-slate-100/30" />
                            </th>
                          </tr>
                          {section.features.map((feature: any) => (
                            <tr key={feature.name}>
                              <th scope="row" className="py-4 text-sm font-normal leading-6 text-flint dark:text-white">
                                {feature.name}
                                <div className="absolute inset-x-8 mt-4 h-px bg-slate-900/5 dark:bg-slate-200/10" />
                              </th>
                              {tiers.map((tier: any) => (
                                <td key={tier.id} className="px-6 py-4 xl:px-8">
                                  {typeof feature.tiers[tier.name] === 'string' ? (
                                    <div className="text-center text-sm leading-6 text-slate-500 dark:text-slate-300">
                                      {feature.tiers[tier.name]}
                                    </div>
                                  ) : (
                                    <>
                                      {feature.tiers[tier.name] === true ? (
                                        <CheckIcon className="mx-auto h-5 w-5 text-info" aria-hidden="true" />
                                      ) : (
                                        <MinusIcon className="mx-auto h-5 w-5 text-slate-400" aria-hidden="true" />
                                      )}

                                      <span className="sr-only">
                                        {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                                      </span>
                                    </>
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>

        <Footer />

        {/* Alert banner */}
        <Banner />
      </div>
    </>
  );
}
