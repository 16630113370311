 interface PillProps {
  text: string;
  marginTop?: string;
  marginBottom?: string;
  bgColour: string;
  textColour: string;
  icon: React.ReactNode;
  onClick?: (e: any) => void;
  onClickArg?: string;
  active?: boolean;
  className?: string;
}


export default function Pill({text, marginTop, marginBottom, bgColour, icon, onClick, onClickArg, active, className}: PillProps) {

  let ringColour = "";
  if (onClick && onClickArg && active) ringColour = `ring-${bgColour}`;
  
  return (
    onClick && onClickArg ? (
      <div className={`mt-${marginTop} mb-${marginBottom} flex flex-nowrap ${className}`} onClick={(e) => onClick(onClickArg)}>
        <span className={`flex items-center whitespace-nowrap rounded-full bg-${bgColour}/20 ${bgColour === "flint" && "dark:bg-black/20"} px-2 py-1 text-xs font-medium text-${bgColour} ${bgColour === "flint" && "dark:text-slate-300"} ${active === true && "ring-2 ring-offset-2"} ${active && ringColour} cursor-pointer`}>
          {icon}
          {text}
        </span>
      </div>
    ) : (
      <div className={`mt-${marginTop} mb-${marginBottom} flex flex-nowrap ${className}`}>
        <span className={`flex items-center whitespace-nowrap rounded-full bg-${bgColour}/20 ${bgColour === "flint" && "dark:bg-black/20"} px-2 py-1 text-xs font-medium text-${bgColour} ${bgColour === "flint" && "dark:text-slate-300"}`}>
          {icon}
          {text}
        </span>
      </div>
    )
  )
  
}

Pill.defaultProps = {
  marginTop: "1",
  marginBottom: "1",
  onClick: null,
  onClickArg: null,
  active: false
}
