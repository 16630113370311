import { useEffect, useState } from "react";
import Header from "../layout/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Container from "../layout/Container";
import SpaceFiller from "../layout/SpaceFiller";
import Spinner from "../layout/Spinner";
import { ArrowLeftCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { CheckBadgeIcon, TrashIcon } from "@heroicons/react/24/outline";
import NoContent from "../layout/NoContent";
import Button from "../layout/Button";
import getButtonStyle from "../../utils/styles";
import Modal from "../layout/Modal";
import { Dialog } from "@headlessui/react";
import TextInput from "../layout/TextInput";
import Dropdown from "../layout/Dropdown";
import TextArea from "../layout/TextArea";
import Datepicker from "react-tailwindcss-datepicker";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../layout/Breadcrumbs";
import Metadata from "../layout/Metadata";
import Link from "../layout/Link";
import { deleteCalendarEvent, getCalendarEventById, updateCalendarEvent } from "../../slices/calendar";
import Card from "../layout/Card";

export default function EventDetail() {
  const dispatch = useAppDispatch();
  const { event_id: eventId } = useParams()

  // Get app level state from redux store
  const { data: eventData, options: eventOptions, loading: eventLoading } = useAppSelector(
    (state) => state.calendar
  );
  const { user: userData } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCalendarEventById({ eventId }));
  }, [dispatch, eventId]);

  // Find event matching Id or return null
  const event = eventData.find((event) => event.id === eventId) ?? null;

  // Get component level state
  const [updateEventData, setUpdateEventData] = useState({
    name: "",
    description: "",
    event_type: "",
  });
  const [dateRange, setDateRange] = useState<{ startDate: string | null, endDate: string | null }>({
    startDate: null,
    endDate: null
  });
  const [timeRange, setTimeRange] = useState<{ startHour: string | null, startMinute: string | null, endHour: string | null, endMinute: string | null }>({
    startHour: null,
    startMinute: null,
    endHour: null,
    endMinute: null,
  });

  useEffect(() => {
    if (event === null) return;
    setUpdateEventData({
      name: event.name,
      description: event.description,
      event_type: event.event_type[0],
    })
    setDateRange({
      startDate: event.start_date.standard,
      endDate: event.end_date.standard
    })
    setTimeRange({
      startHour: event.start_date.time.hour.toString(),
      startMinute: event.start_date.time.minute.toString(),
      endHour: event.end_date.time.hour.toString(),
      endMinute: event.end_date.time.minute.toString(),
    })

  }, [dispatch, event]);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  // Functions
  const handleDateChange = (newValue: any) => {
    setDateRange(newValue);
  }

  async function confirmUpdateEventData(e: any) {
    e.preventDefault();
    if (userData === null) {
      dispatch(addAlert(generateTranslatedText("auth_error", language), "danger"));
      return;
    };
    if (event === null) {
      dispatch(addAlert(generateTranslatedText("missing_object", language), "danger"));
      return;
    }

    // Extract text
    const { name, description, event_type: eventType } = updateEventData;
    const updatedName = name.length === 0 ? event.name : name;
    const updatedDescription = description.length === 0 ? event.description : description;
    const updatedEventType = eventType.length === 0 ? event.event_type[0] : eventType;

    // Extract dates
    const { startDate, endDate } = dateRange;
    if (startDate === null || endDate === null) {
      dispatch(addAlert(generateTranslatedText("missing_fields", language), "danger"));
      return;
    }

    // Extract times
    const { startHour, startMinute, endHour, endMinute } = timeRange;

    // Build datetimes
    const startDatetime = `${startDate} ${startHour}:${startMinute}`;
    const endDatetime = `${endDate} ${endHour}:${endMinute}`;

    const res: any = await dispatch(updateCalendarEvent({
      eventId: eventId,
      name: updatedName,
      description: updatedDescription,
      profile: userData.profile.id,
      event_type: updatedEventType,
      start_date: startDatetime,
      end_date: endDatetime,
      status: event.status,
    }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("update_event", language), "success"));
    } else {
      dispatch(addAlert(res.payload.data.response, "danger"));
    }
  }

  function checkDeleteEvent(e: any) {
    e.preventDefault();
    setShowDeleteModal(true);
  }

  async function confirmEventDelete(e: any) {
    e.preventDefault();
    const res: any = await dispatch(deleteCalendarEvent({ eventId }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("delete_calendar_event", language), "success"));
    } else {
      dispatch(addAlert(res.payload.data.response, "danger"));
    }
  }

  // Constant variables
  const language = userData?.language || "EN";
  const hours = Array.from(Array(24).keys());
  const minutes = Array.from(Array(60).keys());

  return (
    <Template>
      <Container className="pt-0 overflow-y-auto h-screen scrollbar-invisible">
        {eventLoading || event === null ? (
          <SpaceFiller>
            <Spinner />
          </SpaceFiller>
        ) : event.status === "D" ? (
          <SpaceFiller>
            <NoContent
              icon={<ExclamationCircleIcon className="mx-auto h-12 w-12 text-danger animate-bounce" aria-hidden="true" />}
              text={generateTranslatedText("not_found", language)}
              subtext={generateTranslatedText("event_was_deleted", language)}
              actionButton={
                <Link
                  href="/calendar"
                  className={`${getButtonStyle("solid", "info")} rounded-full drop-shadow mt-4`}
                >
                  <ArrowLeftCircleIcon className="mx-auto h-5 w-5 mr-2 text-white" aria-hidden="true" />
                  {generateTranslatedText("back_to_calendar", language)}
                </Link>
              }
            />
          </SpaceFiller>
        ) : (
          <>

            {/* Delete event modal */}
            <Modal
              show={showDeleteModal}
              setShow={setShowDeleteModal}
              confirmText={generateTranslatedText("delete_event", language)}
              cancelText={generateTranslatedText("cancel", language)}
              confirmHandler={confirmEventDelete}
            >
              <div>
                <div className="text-center">
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                    {`${generateTranslatedText("delete_event", language)} "${event.name}"`}
                  </Dialog.Title>
                  <div className="my-10 grid grid-cols-1 gap-y-8">
                    <p className="text-sm text-gray-400 dark:text-gray-300">{generateTranslatedText("delete_event_confirmation", language)}</p>
                  </div>
                </div>
              </div>
            </Modal>

            {/* Header */}
            <Header
              text={event.name}
              breadcrumbs={<Breadcrumbs breadcrumbs={[{ href: "/calendar", text: "Calendar" }]} />}
              metadata={<Metadata createdDate={event.datetime_created} updatedDate={event.datetime_updated} />}
            />

            {/* Event details form */}
            <Card className="mx-1 max-w-100">

              <form
                className="space-y-8 divide-y divide-gray-200"
                onSubmit={(e) => confirmUpdateEventData(e)}
              >
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="grid grid-cols-1 gap-y-8">

                    {/* Name */}
                    <TextInput
                      id="name"
                      type="text"
                      label={generateTranslatedText("name", language)}
                      className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                      required={true}
                      showLabel={true}
                      state={updateEventData}
                      placeholder={event.name}
                      stateName="name"
                      onChange={setUpdateEventData}
                    />

                    {/* Description */}
                    <TextArea
                      id="description"
                      label={generateTranslatedText("description", language)}
                      className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                      required={true}
                      showLabel={true}
                      state={updateEventData}
                      placeholder={event.description}
                      stateName="description"
                      onChange={setUpdateEventData}
                    />

                    {/* Event type */}
                    <Dropdown
                      id="event_type"
                      className="col-span-full"
                      label={generateTranslatedText("type", language)}
                      showLabel={true}
                      required={true}
                      state={updateEventData}
                      stateName="event_type"
                      onChange={setUpdateEventData}
                      defaultValue={event === null ? updateEventData.event_type : event.event_type[0]}
                    >
                      <option value="">Choose an event type</option>
                      {eventOptions?.map((opt) => (
                        <option key={opt[0]} value={opt[0]}>{opt[1]}</option>
                      ))}
                    </Dropdown>

                    {/* Start and end date */}
                    <div className="date-picker-wrapper w-full">
                      <label className="mb-3 block text-sm font-medium text-gray-700 dark:text-white text-left">
                        {generateTranslatedText("start_end_date", language)}
                      </label>
                      <Datepicker
                        primaryColor={"indigo"}
                        value={dateRange}
                        onChange={handleDateChange}
                        showShortcuts={false}
                      />
                    </div>

                    {/* Start time */}
                    <div>
                      <span className="block text-sm font-medium text-gray-700 dark:text-white text-left">{generateTranslatedText("start_time", language)}</span>
                      <div className="grid grid-cols-2 gap-6">
                        <Dropdown
                          id="start_time_hour"
                          className="col-span-full"
                          label=""
                          showLabel={false}
                          required={true}
                          state={timeRange}
                          stateName="startHour"
                          onChange={setTimeRange}
                          defaultValue={event === null ? timeRange.startHour : event.start_date.time.hour}
                        >
                          <option value="">{generateTranslatedText("start_hour_default", language)}</option>
                          {hours.map((opt) => (
                            <option key={opt} value={opt}>{`${opt < 10 ? `0${opt}` : opt}`}</option>
                          ))}
                        </Dropdown>
                        <Dropdown
                          id="start_time_minute"
                          className="col-span-full"
                          label=""
                          showLabel={false}
                          required={true}
                          state={timeRange}
                          stateName="startMinute"
                          onChange={setTimeRange}
                          defaultValue={event === null ? timeRange.startMinute : event.start_date.time.minute}
                        >
                          <option value="">{generateTranslatedText("start_minute_default", language)}</option>
                          {minutes.map((opt) => (
                            <option key={opt} value={opt}>{`${opt < 10 ? `0${opt}` : opt}`}</option>
                          ))}
                        </Dropdown>
                      </div>
                    </div>

                    {/* End time */}
                    <div>
                      <span className="block text-sm font-medium text-gray-700 dark:text-white text-left">{generateTranslatedText("end_time", language)}</span>
                      <div className="grid grid-cols-2 gap-6">
                        <Dropdown
                          id="end_time_hour"
                          className="col-span-full"
                          label=""
                          showLabel={false}
                          required={true}
                          state={timeRange}
                          stateName="endHour"
                          onChange={setTimeRange}
                          defaultValue={event === null ? timeRange.endHour : event.end_date.time.hour}
                        >
                          <option value="">{generateTranslatedText("end_hour_default", language)}</option>
                          {hours.map((opt) => (
                            <option key={opt} value={opt}>{`${opt < 10 ? `0${opt}` : opt}`}</option>
                          ))}
                        </Dropdown>
                        <Dropdown
                          id="end_time_minute"
                          className="col-span-full"
                          label=""
                          showLabel={false}
                          required={true}
                          state={timeRange}
                          stateName="endMinute"
                          onChange={setTimeRange}
                          defaultValue={event === null ? timeRange.endMinute : event.end_date.time.minute}
                        >
                          <option value="">{generateTranslatedText("end_minute_default", language)}</option>
                          {minutes.map((opt) => (
                            <option key={opt} value={opt}>{`${opt < 10 ? `0${opt}` : opt}`}</option>
                          ))}
                        </Dropdown>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end border-none border-0">
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center rounded-full border border-transparent bg-info drop-shadow py-2 px-4 text-sm font-medium text-white hover:underline focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
                    >
                      <CheckBadgeIcon className="w-5 h-5 text-white dark:text-white mr-2" aria-hidden="true" />
                      {generateTranslatedText("update_event_button", language)}
                    </button>
                  </div>
                </div>
              </form>
            </Card>

            {/* Danger zone */}
            <Card className="mx-1 max-w-100 my-10">
              <div>
                <label className="block text-xs font-bold text-gray-400 dark:text-white underline">
                  {generateTranslatedText("danger_zone", language)}
                </label>
                <div>
                  <Button
                    type="button"
                    className="group mt-4 inline-flex justify-center rounded-full border border-transparent hover:bg-danger/10 py-1 px-2 text-xs font-medium text-gray-400 dark:text-white hover:text-danger focus:outline-none focus:ring-2 focus:ring-danger focus:ring-offset-2"
                    onClick={checkDeleteEvent}
                  >
                    <TrashIcon
                      className="mr-2 h-4 w-4 text-gray-400 dark:text-white group-hover:text-danger"
                      aria-hidden="true"
                    />
                    {generateTranslatedText("delete_event", language)}
                  </Button>
                </div>
              </div>
            </Card>
          </>
        )}
      </Container >
    </Template >
  );
}
