export function getColour(text: string | undefined | null) {
  if (text === "REF") return "primary"
  if (text === "DPT") return "info"
  if (text === "SGM") return "success"
  if (text === "LFE") return "canary"
  if (text === "WNR") return "danger"
  if (text === "TRX") return "flint"
  if (text === "ROM") return "love"
  if (text === "FAF") return "warning"
  if (text === "NAN") return "slate-300"
  if (text === "placeholder") return "#e8e8e8";
  if (text === "JM") return "primary";
  if (text === "TP") return "success";
  if (text === "SN") return "danger";
  if (text === "LN") return "warning";
  if (text === "EX") return "info";
  if (text === "BK") return "love";
  if (text === "diary") return "primary";
  if (text === "emotion") return "warning";
  if (text === "events") return "success";
  if (text === "goals") return "info";
  if (text === "Reaction") return "love";
  if (text === "Thank you") return "warning";
  if (text === "J") return "primary";
  if (text === "T") return "info";
  if (text === "P") return "success";
  if (text === "L") return "warning";
  if (text === "X") return "danger";
  if (text === "K") return "love";
  if (text === "O") return "#e5e7eb";
  return "#e5e7eb"
}

export function getTextColour(text: string | undefined) {
  if (text === "REF") return "white"
  if (text === "DPT") return "white"
  if (text === "SGM") return "white"
  if (text === "LFE") return "flint"
  if (text === "WNR") return "white"
  if (text === "TRX") return "white"
  if (text === "ROM") return "white"
  if (text === "FAF") return "flint"
  if (text === "NAN") return "flint"
  if (text === "TP") return "white"
  if (text === "placeholder") return "flint";
  return "flint"
}

export function getColours(text: string | undefined) {
  if (text === "donut-chart") return [
    "rgb(96 165 250)",
    "rgb(147 197 253)",
    "rgb(191 219 254)",
    "rgb(153 246 228)",
    "rgb(94 234 212)",
    "rgb(110 231 183)",
    "rgb(52 211 153)",
    "rgb(16 185 129)"
  ];
  if (text === "bar-chart") return [
    "#A4A2ED"
  ];
  if (text === "bar-chart-stacked") return [
    "#A4A2ED",
    "#e5e7eb"
  ];
  if (text === "radial-chart") return [
    "#F48F44",
    "#FFB76C",
    "#F8E165",
  ];
  if (text === "pie-chart") return [
    "#61DF94",
    "#FF7878",
    "#DADADA",
  ];
  if (text === "circle-packing") return [
    "rgba(0, 0, 0, 0.0)",
    "#87DFF6",
    "#F0F8FF",
  ];
  if (text === "sentiment-chart") return [
    "#FF7878",
    "#DADADA",
    "#61DF94",
  ];
  if (text === "radar-sentiment-chart") return [
    "#61DF94",
    "#FF7878",
    "#DADADA",
  ];
  if (text === "check-in-chart") return [
    "#7977D4",
    "#87DFF6",
    "#FA99CE",
  ];
  if (text === "swarm-chart") return [
    "#61DF94",
    "#FF7878",
  ];
  if (text === "waffle-chart") return [
    "#87DFF6",
    "#e5e7eb",
  ];
  return [
    "rgb(96 165 250)",
    "rgb(147 197 253)",
    "rgb(191 219 254)",
    "rgb(153 246 228)",
    "rgb(94 234 212)",
    "rgb(110 231 183)",
    "rgb(52 211 153)",
    "rgb(16 185 129)"
  ]
}
