import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import generateTranslatedText from "../utils/boilerplate";
import { addAlert } from "./alert";
import { buildUrl } from "../utils/proxy";
import { NotificationState } from "./community";

export interface CommunityState {
  id: string;
  datetime_created: string;
  datetime_updated: string | null;
  entity: {
    id: string;
    name: string;
  };
  name: string;
}

interface UserState {
  language: string;
  timezone: string;
  username: string;
  auth: {
    groups: [string, string];
    permissions: [string, string];
  };
  profile: {
    id: string;
    datetime_created: string;
    type: [string, string];
    has_been_onboarded: boolean;
    has_payment_portal_access: boolean;
    communities: CommunityState[];
  };
  verified: boolean;
  subscription: {
    type: string;
  },
  email: {
    wants_emails: boolean;
    frequency: [string, string];
  },
  notifications: NotificationState[];
  google_calendar_auth: boolean;
}

// Define a type for the slice state
export interface AuthState {
  access: string | null;
  refresh: string | null;
  isAuthenticated: boolean;
  loading: boolean;
  waiting: boolean;
  user: UserState | null;
  email: {
    verification: {
      loading: boolean;
      sent: boolean;
    },
    referral: {
      loading: boolean;
      sent: boolean;
    },
    contact: {
      loading: boolean;
      sent: boolean;
    },
    recovery: {
      loading: boolean;
      verified: boolean;
      complete: boolean;
    }
  },
}

// Define the initial state using that type
const initialState: AuthState = {
  access: localStorage.getItem("access"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: false,
  loading: true,
  waiting: false,
  user: null,
  email: {
    verification: {
      loading: false,
      sent: false
    },
    referral: {
      loading: false,
      sent: false
    },
    contact: {
      loading: false,
      sent: false
    },
    recovery: {
      loading: false,
      verified: false,
      complete: false
    }
  },
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get auth
export const getAuth = createAsyncThunk(
  "auth/get",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/auth`), config);
      if (res.status === 200) {
        return { data: res.data, status: res.status };
      }

      // If not successful, try to refresh credentials
      const refreshConfig = { headers: { "Content-Type": "application/json" } };
      const body = { refresh: localStorage.refresh };
      res = await axios.post(buildUrl(`/api/auth/login/refresh`), body, refreshConfig);

      // Store new access token
      localStorage.setItem("access", res.data.access);

      // Try to get auth again
      const newConfig = {
        headers: { Authorization: `Bearer ${res.data.access}` },
      };
      res = await axios.get(buildUrl(`/api/auth`), newConfig);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Login
export const login = createAsyncThunk(
  "auth/post/login",
  async (
    obj: { email: string; password: string },
    { getState, rejectWithValue }
  ) => {
    try {
      const { email, password } = obj;
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({ username: email, password });
      const res = await axios.post(buildUrl(`/api/auth/login`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Logout
export const logout = createAsyncThunk(
  "auth/post/logout",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };
      const res = await axios.post(buildUrl(`/api/auth/logout`), null, config);

      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Register
export const register = createAsyncThunk(
  "auth/post/register",
  async (
    obj: {
      password: string;
      confirmPassword: string;
      firstName: string;
      lastName: string;
      email: string;
      timezone: string;
      referralId: string | null;
      over18: boolean;
      agreeTerms: boolean;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        password,
        confirmPassword,
        firstName,
        lastName,
        email,
        timezone,
        referralId,
        over18,
        agreeTerms,
      } = obj;
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({
        username: email,
        current_password: password,
        update_password: "",
        confirm_password: confirmPassword,
        first_name: firstName,
        last_name: lastName,
        email,
        timezone,
        language: "EN",
        email_frequency: "A",
        wants_emails: true,
        over_18: over18,
        agree_terms: agreeTerms,
      });

      let endpoint = buildUrl(`/api/auth/register`)
      if (referralId !== null && referralId.length > 0) endpoint += `?referral_id=${referralId}`;
      const res = await axios.post(endpoint, body, config);

      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Update Profile
export const updateProfile = createAsyncThunk(
  "auth/post/update-profile",
  async (
    obj: {
      language: string;
      username: string;
      currentPassword: string;
      password: string;
      confirmPassword: string;
      timezone: string | undefined;
      wantsEmails: boolean | undefined;
      emailFrequency: string | undefined;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const { language, username, currentPassword, password, confirmPassword, timezone } =
        obj;

      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      let body: { [key: string]: any } = {
        language,
        username,
        email: username,
        timezone,
        wants_emails: obj.wantsEmails,
        email_frequency: obj.emailFrequency,
        current_password: currentPassword,
        update_password: password,
        confirm_password: confirmPassword
      };
      const jsonBody = JSON.stringify(body);

      const res = await axios.put(buildUrl(`/api/auth`), jsonBody, config);
      addAlert(generateTranslatedText("update_password", language), "success");
      return { data: res.data, status: res.status };
    } catch (err: any) {
      addAlert(generateTranslatedText("update_password_error", "EN"), "danger");
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Send verification email
export const sendVerificationEmail = createAsyncThunk(
  "auth/post/verification/email",
  async (
    obj: { email: string },
    { getState, rejectWithValue }
  ) => {
    try {
      const { email } = obj;
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({ email });
      const res = await axios.post(buildUrl(`/api/auth/verification`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Confirm account verification
export const confirmAccountVerification = createAsyncThunk(
  "auth/get/verification/confirm",
  async (obj: {
    profileId: string | undefined;
    referralId: string | null;
  }, { getState, rejectWithValue }) => {
    try {

      let endpoint = buildUrl(`/api/auth/verification/confirm/${obj.profileId}`);
      if (obj.referralId !== null && obj.referralId.length > 0) endpoint += `?referral_id=${obj.referralId}`;
      const res = await axios.get(endpoint);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Send referral email
export const sendReferralEmail = createAsyncThunk(
  "auth/post/referral/email",
  async (
    obj: { email: string },
    { getState, rejectWithValue }
  ) => {
    try {

      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const { email } = obj;
      const body = JSON.stringify({ email });

      const res = await axios.post(buildUrl(`/api/auth/referral`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Delete account
export const deleteAccountPermanently = createAsyncThunk(
  "auth/delete/account",
  async (args,
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const res = await axios.delete(buildUrl(`/api/auth`), { headers: { Authorization: `Bearer ${auth.access}` } });
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Send contact us email
export const sendContactUsEmail = createAsyncThunk(
  "auth/post/contact-us/email",
  async (
    obj: {
      email: string;
      subject: string;
      message: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {

      // Configure authorization header with user's token
      const config = { headers: { "Content-Type": "application/json" } };

      const { email, subject, message } = obj;
      const body = JSON.stringify({ email, subject, message });

      const res = await axios.post(buildUrl(`/api/auth/contact`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Forgot my password
export const submitEmailToRecoverPassword = createAsyncThunk(
  "auth/post/forgot-my-password",
  async (
    obj: { email: string; },
    { getState, rejectWithValue }
  ) => {
    try {
      const { email } = obj;
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({ email });

      let endpoint = buildUrl(`/api/auth/forgot-my-password`)
      const res = await axios.post(endpoint, body, config);

      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Verify password recovery session
export const verifyPasswordRecoverySession = createAsyncThunk(
  "auth/forgot-my-password/recovery/get",
  async (obj: { profileId: string | null, sessionId: string | null }, { getState, rejectWithValue }) => {
    try {

      // Verify profile ID and session ID
      const config = {};
      let res = await axios.get(buildUrl(`/api/auth/forgot-my-password/verify?profile=${obj.profileId}&session=${obj.sessionId}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Update password by recovery
export const updatePasswordByRecovery = createAsyncThunk(
  "auth/post/forgot-my-password/recovery",
  async (
    obj: { profileId: string | null, password: string; confirmPassword: string, sessionId: string | null },
    { getState, rejectWithValue }
  ) => {
    try {
      const { profileId, password, confirmPassword, sessionId } = obj;
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({ profile: profileId, new_password: password, confirm_password: confirmPassword, session: sessionId });

      let endpoint = buildUrl(`/api/auth/forgot-my-password/recover`)
      const res = await axios.post(endpoint, body, config);

      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);


/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get Auth
      .addCase(getAuth.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuth.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.loading = false;
        state.user = action.payload.data;
      })
      .addCase(getAuth.rejected, (state) => {
        state.loading = false;
      })

      // Login
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.access = action.payload.data.access;
        state.refresh = action.payload.data.refresh;
        state.isAuthenticated = true;

        // Set auth tokens in local storage
        localStorage.setItem("access", action.payload.data.access);
        localStorage.setItem("refresh", action.payload.data.refresh);
      })
      .addCase(login.rejected, (state) => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        state.user = null;
        state.access = null;
        state.refresh = null;
        state.isAuthenticated = false;
        state.loading = false;
      })

      // Logout
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        state.user = null;
        state.access = null;
        state.refresh = null;
        state.isAuthenticated = false;
        state.loading = false;
      })
      .addCase(logout.rejected, (state) => {
        return state;
      })

      // Register
      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(register.rejected, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
      })

      // Update Profile
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.loading = false;
      })
      .addCase(updateProfile.rejected, (state) => {
        state.loading = false;
      })

      // Send verification email
      .addCase(sendVerificationEmail.pending, (state) => {
        state.email.verification.loading = true;
        state.email.verification.sent = false;
      })
      .addCase(sendVerificationEmail.fulfilled, (state, action) => {
        state.email.verification.loading = false;
        state.email.verification.sent = true;
      })
      .addCase(sendVerificationEmail.rejected, (state) => {
        state.email.verification.loading = false;
        state.email.verification.sent = false;
      })

      // Confirm account verification
      .addCase(confirmAccountVerification.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmAccountVerification.fulfilled, (state, action) => {
        state.isAuthenticated = false;
        state.loading = false;
        state.user = action.payload.data;
      })
      .addCase(confirmAccountVerification.rejected, (state) => {
        state.loading = false;
      })

      // Send referral email
      .addCase(sendReferralEmail.pending, (state) => {
        state.email.referral.loading = true;
        state.email.referral.sent = false;
      })
      .addCase(sendReferralEmail.fulfilled, (state, action) => {
        state.email.referral.loading = false;
        state.email.referral.sent = true;
      })
      .addCase(sendReferralEmail.rejected, (state) => {
        state.email.referral.loading = false;
        state.email.referral.sent = false;
      })

      // Delete account permanently
      .addCase(deleteAccountPermanently.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAccountPermanently.fulfilled, (state, action) => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        state.user = null;
        state.access = null;
        state.refresh = null;
        state.isAuthenticated = false;
        state.loading = false;
      })
      .addCase(deleteAccountPermanently.rejected, (state) => {
        state.loading = false;
      })

      // Send contact us email
      .addCase(sendContactUsEmail.pending, (state) => {
        state.email.contact.loading = true;
        state.email.contact.sent = false;
      })
      .addCase(sendContactUsEmail.fulfilled, (state, action) => {
        state.email.contact.loading = false;
        state.email.contact.sent = true;
      })
      .addCase(sendContactUsEmail.rejected, (state) => {
        state.email.contact.loading = false;
        state.email.contact.sent = false;
      })

      // Forgot my password
      .addCase(submitEmailToRecoverPassword.pending, (state) => {
        state.email.recovery.loading = true;
      })
      .addCase(submitEmailToRecoverPassword.fulfilled, (state, action) => {
        state.email.recovery.loading = false;
      })
      .addCase(submitEmailToRecoverPassword.rejected, (state) => {
        state.email.recovery.loading = false;
      })

      // Verify password recovery session
      .addCase(verifyPasswordRecoverySession.pending, (state) => {
        state.email.recovery.loading = true;
        state.email.recovery.verified = false;
      })

      .addCase(verifyPasswordRecoverySession.fulfilled, (state, action) => {
        state.email.recovery.loading = false;
        state.email.recovery.verified = true;
      })

      .addCase(verifyPasswordRecoverySession.rejected, (state) => {
        state.email.recovery.loading = false;
        state.email.recovery.verified = false;
      })

      // Update password by recovery
      .addCase(updatePasswordByRecovery.pending, (state) => {
        state.email.recovery.loading = true;
        state.email.recovery.complete = false;
      })

      .addCase(updatePasswordByRecovery.fulfilled, (state, action) => {
        state.email.recovery.loading = false;
        state.email.recovery.complete = true;
      })

      .addCase(updatePasswordByRecovery.rejected, (state, action) => {
        state.email.recovery.loading = false;
        state.email.recovery.complete = false;
      });

  },
});

export default authSlice.reducer;
