import Footer from "../landing/Footer";
import LandingNav from "../landing/LandingNav";
import Banner from "../layout/Banner";

export default function PrivacyPolicy() {
  return (
    <div className="bg-white dark:bg-flint">
      <div className="w-full bg-white dark:bg-flint">
        {/* Navbar */}
        <LandingNav />

        <div className="bg-white dark:bg-flint">
          <div className="container mx-auto mt-10 bg-white dark:bg-flint">
            <div className="pb-8 sm:pb-12 lg:pb-12">

              {/* Content */}
              <div className="mx-auto max-w-7xl px-6 lg:px-8sm:pt-10 sm:pb-20 pt-10 pb-20">
                {/* <div className="mx-auto max-w-4xl divide-y divide-gray-900/10 dark:divide-slate-100/20"> */}
                <div className="grid grid-cols-1 space-y-4 mx-auto max-w-4xl">

                  {/* Header */}
                  <h2 className="text-2xl font-bold leading-10 tracking-tight text-flint dark:text-white pb-4">Privacy Policy</h2>

                  {/* Last updated */}
                  <p className="text-black dark:text-white italic text-sm">Last updated 23 April 2024</p>

                  {/* Text */}
                  <>
                    <p><u><span>1. Introduction</span></u></p>
                    <p><br /></p>
                    <p><span>Welcome to Igloo website and services</span></p>
                    <p><br /></p>
                    <p><span>THIS POLICY APPLIES TO OUR SERVICES AND ONLINE TOOLS, INCLUDING THE</span></p>
                    <p><span>WEBSITE AND SERVICES, AND OTHER (COLLECTIVELY &ldquo;THE WEBSITE AND</span></p>
                    <p><span>SERVICES&rdquo;), AS WELL AS OTHER INTERACTION (E.G. USER CONVERSATIONS,</span></p>
                    <p><span>USER SURVEYS AND INTERVIEWS ETC.) YOU MAY HAVE WITH US.</span></p>
                    <p><br /></p>
                    <p><span>This Privacy Policy and statement is created to explain to you how we (www.entertheigloo.xyz, or one of our other website and services) collect your data, how we process them, how we secure them, how we might share them as regards to providing our services and how we store them. These Privacy Statements also describe what right you have as regards to your personal data, how you give your consent and how you withdraw your consent. If you are a user who resides in the European Union Region, we also explain what rights you have as a data subject.</span></p>
                    <p><br /></p>
                    <ol className="list-decimal ml-8">

                      <li><p><span>Introduction</span></p></li>
                      <li><p><span>Acceptance and Use</span></p></li>
                      <li><p><span>Data Protection Principles</span></p></li>
                      <li><p><span>Personal Information We Collect About You (PIPEDA)</span></p></li>
                      <li><p><span>Types of Data We Collect From You</span></p></li>
                      <li><p><span>Non-Personal Information</span></p></li>
                      <li><p><span>UK and EU Cookie Law&nbsp;</span><span>&nbsp; &nbsp;&nbsp;</span></p></li>
                      <li><p><span>Information Collected Through Technology</span></p></li>
                      <li><p><span>Other Information You May Submit</span></p></li>
                      <li><p><span>Automatically-Collected Information</span></p></li>
                      <li><p><span>How We Use Your Data</span></p></li>
                      <li><p><span>Remarketing On the App and website and services</span></p></li>
                      <li><p><span>Google Adwords</span></p></li>
                      <li><p><span>Community Forums, User Content, And Posting Content To Third Party Sites</span></p></li>
                      <li><p><span>With Whom We Share Your Data</span></p></li>
                      <li><p><span>Do Not Track</span></p></li>
                      <li><p><span>Interest-Based Advertising Technologies</span></p></li>
                      <li><p><span>Data Retention</span></p></li>
                      <li><p><span>EEA Data Subjects</span></p></li>
                      <li><p><span>Your Privacy Choices</span></p></li>
                      <li><p><span>Our COPPA Policy towards Children</span></p></li>
                      <li><p><span>Cross Border Transfers</span></p></li>
                      <li><p><span>Privacy Shield</span></p></li>
                      <li><p><span>Legal Bases for Processing (For EEA Users):</span></p></li>
                      <li><p><span>Security Measures for Safeguarding Your Information</span></p></li>
                      <li><p><span>Third-Party Site and Links</span></p></li>
                      <li><p><span>Do Not Track Disclosures</span></p></li>
                      <li><p><span>Emails Communications</span></p></li>
                      <li><p><span>Data Security</span></p></li>
                      <li><p><span>GDPR Statement for (EEA Users)</span></p></li>
                      <li><p><span>GDPR Data Privacy User Rights</span></p></li>
                      <li><p><span>Your Right To Withdraw Consent</span></p></li>
                      <li><p><span>Opting Out Of Messages from Us</span></p></li>
                      <li><p><span>Opting Out Of Sharing With Third Parties for Marketing</span></p></li>
                      <li><p><span>Opting Out Of Interest Based Advertising</span></p></li>
                      <li><p><span>Security of Personal Information</span></p></li>
                      <li><p><span>Terms and Conditions</span></p></li>
                      <li><p><span>Jurisdiction</span></p></li>
                      <li><p><span>Changes to our Privacy Policy</span></p></li>
                      <li><p><span>Contact Us</span></p></li>

                    </ol>
                    <p><br /></p>
                    <p><u><span>2. Acceptance and Use</span></u></p>
                    <p><br /></p>
                    <p><span>This privacy policy covers Igloo and its subsidiaries including (collectively, &quot;</span><a href="http://www.halotechlab.com"><u><span>www.entertheigloo.xyz</span></u></a><span>,&quot; &quot;we&quot; &quot;us&quot; or &quot;our&quot;). By using Igloo website and services, you signify acceptance to the terms of this Privacy Policy. Your acceptance via clicking on [ACCEPT] when this policy is displayed to you on the site, is considered as your official consent to process your personal information, your consent to the collection, use, and disclosure of your personal information as described further below. We may request you to provide additional disclosures or information about the data processing practices of specific Services. These notices may supplement or clarify our privacy practices or may provide you with additional choices about how we process your data.</span></p>
                    <p><br /></p>
                    <p><span>If you do not agree with or you are not comfortable with any aspect of this Privacy Policy, you should immediately discontinue access or use of Igloo website and services and its related services.</span></p>
                    <p><br /></p>
                    <p><span>3. Data Protection Principles</span></p>
                    <p><br /></p>
                    <p><span>&ldquo;Personal data&rdquo; means any information that enables us to identify you or the beneficiary of your transaction with us, directly or indirectly, such as name, email, address, telephone number, any form of identification number or one or more factors specific to your or your beneficiary&rsquo;s identity.</span></p>
                    <p><br /></p>
                    <p><span>We are committed to complying with applicable data protection laws and will ensure that personal data is:</span></p>
                    <p><br /></p>
                    <ul className="list-disc ml-4">
                      <li>
                        <p><span>Used lawfully, fairly and in a transparent way;</span></p>
                      </li>
                      <li>
                        <p><span>Collected only for valid purposes that we have clearly explained to you and not used in any way that is incompatible with those purposes;</span></p>
                      </li>
                      <li>
                        <p><span>Relevant to the purposes we have told you about and limited only to those purposes;</span></p>
                      </li>
                      <li>
                        <p><span>Accurate and kept up to date;</span></p>
                      </li>
                      <li>
                        <p><span>Kept only as long as necessary for the purposes we have told you about;</span></p>
                      </li>
                      <li>
                        <p><span>Kept securely.</span></p>
                      </li>
                    </ul>
                    <p><br /></p>
                    <p><span>4. Personal Information We Collect About You (PIPEDA)</span></p>
                    <p><br /></p>
                    <p><span>According to the personal information protection and electronic documents act, 2000 (PIPEDA), &ldquo;personal information&rdquo; means information about an identifiable individual. The information you provide to Igloo is considered personal information if it is possible to relate it back to you through your name, address, e-mail address, telephone number and any other information that can identify you. If information cannot be related to an identifiable individual it is considered anonymous information (such as laboratory results identified by alpha-numeric identifier instead of individual&rsquo;s name). Whenever possible, Igloo works with anonymous information. This privacy statement does not apply to anonymous information.</span></p>
                    <p><br /></p>
                    <p><span>5. Types of Data We Collect From You</span></p>
                    <p><br /></p>
                    <ul className="list-disc ml-4">
                      <li>
                        <p><span>Personal Identifiable Information (PII). We acquire PII that may include, in certain contexts, your Full name, home address, nationality, gender, and/or email etc. Which certain jurisdictions consider to be PII because it could be used to identify an individual or Device if it were combined with other identifying information.</span></p>
                      </li>
                      <li>
                        <p><span>Sensitive PII: Information about the transactions you make on our Services, such as the names, mobile number, email address and other information about the directly connected accounts to your account [FAMILY] which are attracted to our service by you.</span></p>
                      </li>
                      <li>
                        <p><span>Correspondence: Survey responses, information provided to our support team or user research team.</span></p>
                      </li>
                    </ul>
                    <p><br /></p>
                    <p><span>6. Non-Personal Information</span></p>
                    <p><br /></p>
                    <p><span>Non-personal information refers to information that, by itself, does not identify you as a specific individual (e.g. demographic information or web site visitations). Igloo may collect non-personal information through any of the methods discussed above as well as automatically through use of industry standard technologies described further below.</span></p>
                    <p><br /></p>
                    <p><span>&nbsp;7. UK and EU Cookie Law</span></p>
                    <p><br /></p>
                    <p><span>The Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended by the Privacy and Electronic Communications (EC Directive) (Amendment) Regulations 2011; Cookies is a small text file placed on your computer by this Website and services when you visit certain parts of the Website and services and/or when you use certain features of the Website and services. Details of the cookies used by this Website and services are set out in the (Cookies policy page);</span></p>
                    <p><br /></p>
                    <p><span>8. Information Collected Through Technology</span></p>
                    <p><br /></p>
                    <p><span>While you are able to visit the Igloo site and remain anonymous, Igloo or its third party service providers may still collect non-personal information about your use of the Igloo materials (e.g. Your internet browser, operating system, ip address, connection speed, and the domain name of your internet service provider). Such information may be gathered by the following methods:</span></p>
                    <p><br /></p>
                    <ul className="list-disc ml-4">
                      <li>
                        <p><span>Cookies. Cookies, including local shared objects, are small pieces of information that are stored by your browser on your device&rsquo;s hard drive which work by assigning to your computer a unique number that has no meaning outside of the Igloo site. Cookies do not generally contain any personal information. Most web browsers automatically accept cookies, but you can usually configure your browser to prevent this. Not accepting cookies may make certain features of the Igloo materials unavailable to you.</span></p>
                      </li>
                      <li>
                        <p><span>IP address. You may visit many areas of the Igloo site anonymously without the need to become a registered user. Even in such cases, Igloo may collect IP addresses automatically. An IP address is a number that is automatically assigned to your computer whenever you begin services with an internet services provider. Each time you access the Igloo site and each time you request one of Igloo&rsquo; pages, the server logs your IP address.</span></p>
                      </li>
                      <li>
                        <p><span>Tracking content usage. If you use the Igloo services and you post audio visual materials including, without limitation, text, logos, artwork, graphics, pictures, advertisements, sound and other related intellectual property contained in such materials (collectively, &ldquo;content&rdquo;) to your app site or to a third party site, Igloo tracks and captures non-personal information associated with user accounts and the use of content.</span></p>
                      </li>
                    </ul>
                    <p><br /></p>
                    <p><span>9. Other Information You May Submit</span></p>
                    <p><br /></p>
                    <p><span>You may submit data to us for limited purposes such as requesting customer support; answering a questionnaire; participating in a study; entering contests or sweepstakes; or signing up to receive communications from us or another user.</span></p>
                    <p><br /></p>
                    <p><span>10. Automatically-Collected Information</span></p>
                    <p><br /></p>
                    <p><span>We automatically collect certain types of data when you use our services, regardless of whether you have an account. This data includes your IP address, technical information about your device (e.g., browser type, operating system, and basic device information), the web page you visited or search query you entered before reaching us, and your activities. We may track your activities using cookies and similar technologies. By using our services, you agree to our use of these methods as set forth in our cookie policy.</span></p>
                    <p><br /></p>
                    <p><span>11. How We Use Your Data</span></p>
                    <p><br /></p>
                    <p><span>For purposes of the General Data Protection Regulation (GDPR), Igloo is the &quot;data controller&quot;. We will retain any Data you submit for a minimum of 12 months or the length of our services. Unless we are obliged or permitted by law to do so, and subject to any third party disclosures specifically set out in this policy, your Data will not be disclosed to third parties. This does not include our affiliates and / or other companies within our group.&nbsp;</span></p>
                    <p><span>All personal Data is stored securely in accordance with the principles of the General Data Protection Regulation. For more details on security see the clause below (Data Security).</span></p>
                    <p><br /></p>
                    <p><span>Any or all of the above Data may be required by us from time to time in order to provide you with the best possible service and experience when using our Website and services. Specifically, Data may be used by us for the following reasons:</span></p>
                    <p><br /></p>
                    <ul className="list-disc ml-4">
                      <li>
                        <p><span>Internal record keeping;</span></p>
                      </li>
                      <li>
                        <p><span>Improvement of our products / services;</span></p>
                      </li>
                      {/* <li>
                        <p><span>Transmission by email of promotional materials that may be of interest to you;</span></p>
                      </li> */}
                      {/* <li>
                        <p><span>Contact for market research purposes which may be done using email, telephone, fax or mail. Such information may be used to customise or update the Website and services;</span></p>
                      </li> */}
                    </ul>
                    <p><br /></p>
                    <p><span>In each case, in accordance with this privacy policy. We may use your data for the following purposes:</span></p>
                    <p><br /></p>
                    <ul className="list-disc ml-4">
                      <li>
                        <p><span>Identification and authentication: we use your data to verify you when you access your account.</span></p>
                      </li>
                      <li>
                        <p><span>Communicating with you: we use your data when we communicate with you (e.g., when we respond to a customer support or other inquiry).</span></p>
                      </li>
                      <li>
                        <p><span>Improving our services: we use your data to understand how our services are being used and how we can improve them. In general, we analyse aggregated data, rather than specific user data. We may, however, need to analyse a specific case to address a specific problem (e.g., a bug that affects only a few accounts).</span></p>
                      </li>
                      <li>
                        <p><span>Customising your experience: we use your data to personalise the service to you. This may include remembering your preferences for language or volume or displaying app that you might enjoy, based upon your viewing choices. To ensure network and data security We process your personal information in order to enhance security, monitor and verify identity or service access, combat spam or other malware or security risks and to comply with applicable security laws and regulations. The threat landscape on the internet is constantly evolving, which makes it more important than ever that we have accurate and up-to-date information about your use of Igloo Services.</span></p>
                      </li>
                      {/* <li>
                        <p><span>Marketing and advertising: we use your data to display ads and send you offers. We may also use your data in delivering third-party advertisements to you. This may include &quot;targeted ads&quot; based upon your activities.</span></p>
                      </li> */}
                      <li>
                        <p><span>Exercising our rights: where reasonably necessary, we use your data to exercise our legal rights and prevent abuse of our service. For example, we may use your data to detect and prevent fraud, spam, or content that violates our terms of service.</span></p>
                      </li>
                      <li>
                        <p><span>Legal compliance: we use your data where we are legally required to do so. For example, we may need to gather your data to respond to a subpoena or court order.</span></p>
                      </li>
                      <li>
                        <p><span>Protecting your information: where appropriate, we may anonymize, backup, and delete certain data.</span></p>
                      </li>
                      {/* <li>
                        <p><span>For research and development: We process your personal information to better understand the way you use and interact with Igloo&rsquo;s Services. In addition, we use such information to customise, measure, and improve Igloo features and the content and layout of our App and applications, and to develop new services.</span></p>
                      </li> */}
                    </ul>
                    <p><br /></p>
                    <p><span>We may use algorithms and other automated means to implement any of the above.</span></p>
                    <p><br /></p>
                    <p><span>12. Remarketing On the website and services</span></p>
                    <p><br /></p>
                    <p><span>If you have provided your consent by accepting Targeting Cookies on the site, we may use Google AdWords (remarketing and Similar Audience features) and Facebook Ads. You can review the Igloo cookie policy which includes details on how to customise your cookie settings.</span></p>
                    <p><br /></p>
                    <p><span>13. Google Adwords</span></p>
                    <p><br /></p>
                    <p><span>Google AdWords enables Google, through the use of cookies, to identify the fact that you have visited the site, to identify aspects of your usage of the App and combine that with what it knows about your usage of other sites in the Google ad network. We use these services to advertise to visitors of Google ad network Apps who have previously visited our site or who Google deems to have shared interests with visitors of our site. Google&rsquo;s collection and use of your personal data is covered by the Google privacy policy. You can set preferences for how Google advertises to you using the Google Ad Preferences page.</span></p>
                    <p><br /></p>
                    <p><span>14. Community Forums, User Content, And Posting Content To Third Party Sites</span></p>
                    <p><br /></p>
                    <p><span>The Sites may provide you the opportunity to participate and post content publicly in forums, through interactive features and through other communication functionality (&quot;Community Forums&quot;). You may choose, through such features or otherwise, to submit or post photographs, videos, questions, comments, suggestions or other content, (collectively, &quot;User Content&quot;).</span></p>
                    <p><br /></p>
                    <p><span>Note that anything you post to a Community Forum is public -- others will have access to your User Content and may use it or share it with third parties. Information or other content you choose to post or make available in the Community Forums may be viewed, collected and used by others. If you choose to voluntarily disclose Personally Identifiable Information, that information will be considered public information and the protections of this Privacy Policy will not apply. Think carefully before you post and use caution before disclosing any Personally Identifiable Information. Also, functionality on the Sites may permit interactions between the Sites and a third party web site, such as enabling you to &ldquo;like&rdquo; or review a product on our Sites or &ldquo;share&rdquo; content to other web sites. If you choose to &ldquo;like,&rdquo; review, or &ldquo;share&rdquo; content through the Sites or to otherwise post information from or via the Sites to a third party website, feature or application, that information may be publicly displayed and the third party web site may have access to information about you and your use of our website and mobile application.</span></p>
                    <p><br /></p>
                    <p><span>15. With Whom We Share Your Data</span></p>
                    <p><br /></p>
                    <p><span>We share data with third parties as follows:</span></p>
                    <ul className="list-disc ml-4">
                      <li>
                        <p><span>With your consent: we may share your data with third parties where we have obtained your express consent to do so. You may revoke these consents.</span></p>
                      </li>
                      <li>
                        <p><span>Authorised vendors: we may share your data with third-party vendors that help us operate our services, process payments, and comply with your instructions and our contractual obligations. This includes payment processors, content delivery networks (CDNS), cloud-based hosting services, monitoring services, email service providers, quality assurance and testing vendors, fraud and abuse prevention vendors, customer relations management (CRM) vendors</span></p>
                      </li>
                      {/* <li>
                        <p><span>Advertising: we may share your data with advertising companies to display relevant ads to you. Unless you expressly agree, we will not share or sell your name, email address, or physical address with such persons.</span></p>
                      </li> */}
                      {/* <li>
                        <p><span>Analytics: we may share your data with persons who provide analytics showing how customers are using our services.</span></p>
                      </li> */}
                      {/* <li>
                        <p><span>Affiliates and advisors: we may share your data with our affiliates company, and our auditors and advisors for planning, financial reporting, accounting, auditing, tax filings, and legal compliance. Unless you expressly agree, we will not share your data with our parent or any affiliate for other purposes, such as direct marketing.</span></p>
                      </li> */}
                      <li>
                        <p><span>Certain legal situations: we may share your data where we believe disclosure is necessary to comply with a legal obligation or in connection with a corporate transaction.&nbsp;</span></p>
                      </li>
                      <li>
                        <p><span>Aggregated or anonymized information: we may publicly disclose non-personal aggregated or anonymized information such as our number of visitors and registered users.</span></p>
                      </li>
                    </ul>
                    <p><br /></p>
                    <p><br /></p>
                    <p><span>16. Do Not Track</span></p>
                    <p><br /></p>
                    <p><span>Do Not Track (&ldquo;DNT&rdquo;) is a privacy preference that users can set in certain web browsers and devices. DNT is a way for users to inform websites and services that they do not want certain information about their webpage visits collected over time and across websites and services or online services. Please note that we do not respond to or honour DNT signals or similar mechanisms transmitted by web browsers.</span></p>
                    <p><br /></p>
                    <p><span>17. Interest-Based Advertising Technologies</span></p>
                    <p><br /></p>
                    <p><span>As noted above, you may limit advertising tracking using advertising identifiers through your browser privacy settings. The online advertising industry also provides websites and services from which you may opt-out of receiving targeted ads from our data partners and our other advertising partners that participate in self-regulatory programs. You can access these, and also learn more about targeted advertising and consumer choice and privacy, at www.networkadvertising.org/managing/opt_out.asp, or www.youronlinechoices.eu and www.aboutads.info/choices.</span></p>
                    <p><br /></p>
                    <p><span>18. Data Retention</span></p>
                    <p><br /></p>
                    <p><span>We retain your data for as long as you have an account. When you close an account, we will delete your personal information. We may retain logs of automatically collected information (for internal analytics); your email address; your tax information; communications with you; and your transactional information (for auditing, tax, and financial purposes). When we no longer have a business reason for retaining data, we will delete or anonymize it. If we receive a legal process pertaining to your account, we will retain your data for as long as we in good faith believe is necessary to comply with the legal process. Similarly, if we believe that your account has been involved in wrongdoing, we may preserve your data to defend or assert our rights.</span></p>
                    <p><br /></p>
                    <p><span>19. EEA Data Subjects</span></p>
                    <p><br /></p>
                    <p><span>Legal Bases for Processing your Information:</span></p>
                    <p><br /></p>
                    <p><span>For individuals who are located in the European Economic Area, United Kingdom or Switzerland (collectively &ldquo;EEA Residents&rdquo;) at the time their personal data is collected, we rely on legal bases for processing your information under Article 6 of the EU General Data Protection Regulation (&ldquo;GDPR&rdquo;). We generally only process your data where we are legally required to, where the processing is necessary to perform any contracts we entered with you (or to take steps at your request prior to entering into a contract with you), for our legitimate interests to operate our business or to protect Igloo application and website and services or your, property, rights, or safety, or where we have obtained your consent to do so. Below is a list of the purposes described in our policy with the corresponding legal bases for processing.</span></p>
                    <p><br /></p>
                    <p><span>20. Your Privacy Choices</span></p>
                    <p><br /></p>
                    <p><span>We enable you to make numerous choices about your data:</span></p>
                    <p><br /></p>
                    <ul className="list-disc ml-4">
                      <li>
                        <p><span>You may choose not to provide us with certain information. For example, you may choose not to create an account or not to provide optional account information.</span></p>
                      </li>
                      <li>
                        <p><span>You may change your account and privacy settings. You may change or correct information voluntarily submitted to us. We encourage you to keep your data current by viewing your settings page.</span></p>
                      </li>
                      <li>
                        <p><span>You may opt out of receiving commercial emails from us.</span></p>
                      </li>
                      <li>
                        <p><span>You may limit the use of cookies. See our cookie policy for options.</span></p>
                      </li>
                      <li>
                        <p><span>You may close your account&nbsp;</span></p>
                      </li>
                    </ul>
                    <p><br /></p>
                    <p><span>Users from certain countries may have additional rights.</span></p>
                    <p><br /></p>
                    <p><span>21. Our COPPA Policy towards Children</span></p>
                    <p><br /></p>
                    <p><span>Igloo complies with both the Children&rsquo;s Online Privacy Protection Act of 1998 (COPPA) and, with regard to EU data subjects, with GDPR. We do not knowingly request to collect personal information required for Igloo services from any person under the age of 18. If a user submitting personal information is suspected of being younger than 18 years of age, Igloo will prevent users from registering for an account, also Igloo retains the right to close such an account and or not allow such users to continue using Igloo Service. We will also take steps to delete the information as soon as possible. Please notify us if you know of any individuals under the age of 18 using our Services so we can take action to prevent access to our Services.</span></p>
                    <p><br /></p>
                    <p><span>22. Cross Border Transfers</span></p>
                    <p><br /></p>
                    <p><span>To facilitate our global operations, Igloo may transfer, store, and process your information within our family of companies, partners, and service providers based throughout the world. We contractually obligate recipients of your personal information to agree to at least the same level of privacy safeguards as required under applicable data protection laws. By communicating electronically with Igloo, you acknowledge and agree to your personal information being processed in this way. If you have a complaint about our privacy practices and our collection, use or disclosure of personal information please submit your request via any of Igloo provided CONTACT US mediums.</span></p>
                    <p><br /></p>
                    <p><span>23. Privacy Shield</span></p>
                    <p><br /></p>
                    <p><span>Igloo adheres to the seven privacy shield principles of notice, choice, and accountability for onward transfer, security, data integrity, access, and recourse, enforcement and liability as they relate to personal data. Igloo verifies compliance to the principles through self-assessment. The privacy policy covering human resources data can be accessed on our intranet app site by all employees.</span></p>
                    <p><br /></p>
                    <p><span>24. Legal Bases for Processing (For EEA Users)</span></p>
                    <p><br /></p>
                    <p><span>If you are an individual in the European Economic Area (EEA), we collect and process information about you only where we have legal bases for doing so under applicable EU laws. &nbsp;The legal bases depend on the services you use and how you use them. This means we collect and use your information only where:</span></p>
                    <p><br /></p>
                    <ul className="list-disc ml-4">
                      <li>
                        <p><span>We need it to provide you the services, including to operate the services, provide customer support and personalised features and to protect the safety and security of the services;</span></p>
                      </li>
                      <li>
                        <p><span>It satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to market and promote the services and to protect our legal rights and interests;</span></p>
                      </li>
                      <li>
                        <p><span>You give us consent to do so for a specific purpose; or</span></p>
                      </li>
                      <li>
                        <p><span>We need to process your data to comply with a legal obligation.</span></p>
                      </li>
                    </ul>
                    <p><br /></p>
                    <p><span>If you have consented to our use of information about you for a specific purpose, you have the right to change your mind at any time, but this will not affect any processing that has already taken place. &nbsp;Where we are using your information because we or a third party (e.g. your employer) have a legitimate interest to do so, you have the right to object to that use though, in some cases, and this may mean no longer using the services</span></p>
                    <p><br /></p>
                    <p><span>25. Security Measures for Safeguarding Your Information</span></p>
                    <p><br /></p>
                    <p><span>Igloo takes appropriate security measures to protect against unauthorised access, alteration, disclosure or destruction of personal information. These include, but are not limited to, internal reviews of: (a) Igloo&rsquo; data collection; (b) storage and processing practices; (c) electronic security measures; and (d) physical security measures to guard against unauthorised access to systems where Igloo stores personal information. All Igloo employees, contractors and agents who access personal information are bound by confidentiality obligations and may be subject to discipline, including termination and criminal prosecution or unauthorised use or disclosure of personal information.</span></p>
                    <p><br /></p>
                    <p><span>26. Third-Party Site and Links</span></p>
                    <p><br /></p>
                    <p><span>The Igloo contents may contain links to third parties who may collect personal information and non-personal information directly from you. Additionally, Igloo may use third parties to provide components of the Igloo contents. In either case, such third parties may have separate privacy policies and data collection practices, independent of Igloo. Igloo: (a) has no responsibility or liability for these independent policies or actions; (b) is not responsible for the privacy practices or the content of such site; and (c) does not make any warranties or representations about the contents, products or services offered on such App or the security of any information you provide to them</span></p>
                    <p><br /></p>
                    <p><span>27. Do Not Track Disclosures&nbsp;</span></p>
                    <p><br /></p>
                    <p><span>Various third parties are developing or have developed signals or other mechanisms for the expression of consumer choice regarding the collection of information about an individual consumer&rsquo;s online activities over time and across third-party web sites or online services (e.g., browser do not track signals). &nbsp;Currently, Igloo does not monitor or take any action with respect to these signals or other mechanisms.</span></p>
                    <p><br /></p>
                    <p><span>28. Emails Communications</span></p>
                    <p><br /></p>
                    <p><span>By creating an account, you consent to receive commercial emails from us. This includes newsletters and offers. Users from certain countries may have the ability to opt out or opt in at the time of account creation. All users may decline to receive commercial messages in their account settings. Please note that any opt-out request may take several days to process and you will continue to receive transactional emails from us (e.g., emails confirming transactions and/or providing information about your account).</span></p>
                    <p><br /></p>
                    <p><span>29. Data Security</span></p>
                    <p><br /></p>
                    <p><span>We are committed to maintaining the security of your personal data and have measures in place to protect against the loss, misuse, and alteration of the data under our control. We employ modern and secure techniques to protect our systems from intrusion by unauthorised individuals, and we regularly upgrade our security as better methods become available. Our data centres and those of our partners utilise modern physical security measures to prevent unauthorised access to the facility. In addition, all personal data is stored in a secure location behind firewalls and other sophisticated security systems with limited (need-to-know) administrative access. All our employees who have access to, or are associated with, the processing of personal data are contractually obligated to respect the confidentiality of your data and abide by the privacy standards we have established. Please be aware that no security measures are perfect or impenetrable. Therefore, although we use industry standard practices to protect your privacy, we cannot (and do not) guarantee the absolute security of personal data.</span></p>
                    <p><br /></p>
                    <p><span>30. GDPR Statement for (EEA Users)</span></p>
                    <p><br /></p>
                    <p><span>This applies only to natural persons residing in the European economic area (for the purpose of this section only, &quot;you&quot; or &quot;your&quot; shall be limited accordingly). It is Igloo&rsquo; policy to comply with the EU&apos;S general data protection regulation (GDPR). In accordance with the GDPR, we may transfer your personal information from your home country to the United Kingdom (or other countries) based upon the following legal frameworks:</span></p>
                    <p><br /></p>
                    <ul className="list-disc ml-4">
                      <li>
                        <p><span>Legitimate business interests: we could not provide our services or comply with our obligations to you without transferring your personal information to the United Kingdom</span></p>
                      </li>
                      <li>
                        <p><span>Consent: we may transfer your personal information when we receive your express, revocable consent. Our use of standard contractual clauses (also known as &quot;model clauses&quot;) where appropriate.</span></p>
                      </li>
                      <li>
                        <p><span>You have the right to: opt out of non-essential cookies (see our cookie policy); access, correct, delete, restrict, or object to our use of your personal information; be forgotten; port your data; and withdraw consents.&nbsp;</span></p>
                      </li>
                    </ul>
                    <p><br /></p>
                    <p><span>We enable exercise of these rights primarily through our services (which we reserve the right to modify). We also fulfil our obligations in response to direct requests. We will endeavor to process requests within one month. Please note that we may not be able to comply with requests to the extent that they would cause us to violate any law or infringe any other person&apos;s rights. We reserve the right to request appropriate identification. We will process requests free of charge unless they would impose an unreasonable cost on us. If you have a request, complaint or inquiry, please contact our data protection officer at the address listed below. We are committed to working with you to obtain a fair resolution of any issue. You also have the right to file a complaint with the supervisory data protection authority of your jurisdiction.</span></p>
                    <p><br /></p>
                    <p><span>31. GDPR Data Privacy User Rights</span></p>
                    <p><br /></p>
                    <p><span>If you are an EU resident and Igloo is processing, and/or transmitting your personal data, then you - as an &ldquo;EU data subject&rdquo; &ndash; benefit from the following rights and privileges under the General Data Protection Regulation (GDPR):</span></p>
                    <p><br /></p>
                    <ul className="list-disc ml-4">
                      <li>
                        <p><span>Right of Access: you have the right to obtain from us, as controllers, confirmation as to whether or not personal data concerning you are being processed, and, where that is the case, access to the following personal data and information:</span></p>
                        <ul className="list-disc ml-4">
                          <li>
                            <p><span>the purposes of the processing;</span></p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p><span>the categories of personal data concerned; i.e name, email, phone number etc the recipients or categories of recipient to whom the personal data have been or will be disclosed, in particular recipients in third countries or international organisations&rsquo;;&nbsp;</span></p>
                      </li>
                      <li>
                        <p><span>where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period;</span></p>
                      </li>
                      <li>
                        <p><span>the existence of the right to request from us rectification or erasure of personal data or restriction of processing of personal data concerning you or to object to such processing; (f) the right to lodge a complaint with a supervisory authority (for a list of supervisory authorities, see&nbsp;</span><a href="https://edpb.europa.eu/about-edpb/board/members_en"><u><span>https://edpb.europa.eu/about-edpb/board/members_en</span></u></a><span>);&nbsp;</span></p>
                      </li>
                      <li>
                        <p><span>where the personal data are not collected from you, any available information as to their source;</span></p>
                      </li>
                      <li>
                        <p><span>The existence of automated decision-making, including profiling, along the lines indicated by Article 22(1) and (4) GDPR, and meaningful information about the logic involved, as well as the significance and the envisaged consequences of such processing for you.</span></p>
                      </li>
                      <li>
                        <p><span>Right to Rectification:</span></p>
                        <ul className="list-disc ml-4">
                          <li>
                            <p><span>you have the right to obtain from us without undue delay the rectification of inaccurate personal data concerned, provided during registration by him or her. Taking into account the purposes of the processing, the user has the right to request their incomplete personal data be completed, including by means of providing a supplementary statement.</span></p>
                          </li>
                        </ul >
                      </li >
                      <li>
                        <p><span>Right to Erasure (&ldquo;Right to be Forgotten):&nbsp;</span></p>
                        <ul className="list-disc ml-4">
                          <li>
                            <p><span>you have the right to obtain from us the erasure of your personal data without undue delay, and we have the obligation to erase personal data without undue delay when: a) your data are no longer necessary for the purposes for which they were collected; b) you had consented to the processing; c) you have objected to the processing, as per below; d) your persona data had been unlawfully collected; e) your personal data need to be erased as a matter of compliance with a legal obligation.</span></p>
                          </li>
                        </ul >
                      </li >
                      <li>
                        <p><span>Right to Restriction of Processing:&nbsp;</span></p>
                        <ul className="list-disc ml-4">
                          <li>
                            <p><span>you have the right to obtain from us the restriction of processing if you: a) contest the accuracy of the personal data, until this is verified; b) the processing is unlawful but you don&rsquo;t want erasure; c) we no longer need the personal data, but you require them to establish, exercise to defend a legal claim; d) you have objected to processing but there is a need to verify whether our legitimate grounds override your rights to object.</span></p>
                          </li>
                        </ul >
                      </li >
                      <li>
                        <p><span>Right to Data Portability:</span></p>
                        <ul className="list-disc ml-4">
                          <li>
                            <p><span>where your personal data have been provided on the basis of your consent or for the performance of a contract, and their processing occurs in an automated way, you have the right to receive the personal data you have provided to us in a structured, commonly used and machine-readable format and have the right to transmit those data&ndash; or have directly transmitted - to another controller.</span></p>
                          </li>
                        </ul >
                      </li >
                      <li>
                        <p><span>Right to Object:</span></p>
                        <ul className="list-disc ml-4">
                          <li>
                            <p><span>you have the right to object, on grounds relating to your particular situation, at any time to processing of your personal data based on a legitimate ground point (e) or (f) of Article 6(1), including profiling based on those provisions. In this case, we can no longer process your personal data unless we show that there is a compelling legitimate ground for the processing which overrides your interests, rights and freedoms or for our establishment, exercise or defence of legal claims.</span></p>
                          </li>
                        </ul >
                      </li >
                    </ul >
                    <p><br /></p>
                    <p><span>32. Your Right To Withdraw Consent</span></p>
                    <p><br /></p>
                    <p><span>Where the processing of your personal information by us is based on consent, you have the right to withdraw that consent without detriment at any time by contacting us&nbsp;</span><a href="mailto:support@halotechlab.com"><u><span>support@halotechlab.com</span></u></a><span>. You can also change your marketing preferences. You can also exercise the rights listed above at any time by contacting us at <a href="mailto:support@halotechlab.com"><u><span>support@halotechlab.com</span></u></a>.</span></p >
                    <p><br /></p>
                    <p><span>33. Opting Out Of Messages from Us</span></p>
                    <p><br /></p>
                    <p><span>To opt-out of any future promotional messages from us, you should unsubscribe in the body of the promotional message that was sent to you (found at the bottom of the email) or send an unsubscribe request to us at&nbsp;</span><a href="mailto:support@halotechlab.com"><u><span>support@halotechlab.com</span></u></a><span>. We will process your request within a reasonable time after receipt.</span></p >
                    <p><br /></p>
                    <p><span>34. Opting Out Of Sharing With Third Parties for Marketing</span></p>
                    <p><br /></p>
                    <p><span>If you do not want your Personal Information or Personal Data shared with any third party who may use such information for direct marketing purposes, then you may opt-out of such disclosures by sending an email&nbsp;</span><a href="mailto:support@halotechlab.com"><u><span>support@halotechlab.com</span></u></a><span>. Please note that if you opt out in this manner, certain aspects of the site and platform may no longer be available to you. We will process your request within a reasonable time after receipt.</span></p >
                    <p><br /></p>
                    <p><span>35. Opting Out Of Interest Based Advertising</span></p>
                    <p><br /></p>
                    <p><span>The online advertising industry provides a service through which you may opt-out of receiving targeted ads from certain data partners and other advertising partners that participate in self-regulatory programs. We comply with the Self-Regulatory Principles for Online Behavioral Advertising set forth by the Digital Advertising Alliance (DAA) and the European Interactive Digital Advertising Alliance (&quot;EDAA&quot;). You can opt-out of targeted advertising from certain providers at www.aboutads.info/consumers. Please note that by opting out, you will continue to see generic advertising that is not tailored to your specific interests and activities. Please note that cookie-based opt-outs must be performed on each device and browser that you wish to have opted-out. For example, if you have opted out on your device browser, that opt-out will not be effective on your mobile device. You must separately opt out on each device. EU residents who have provided their consent to our use of cookies and similar technologies can use the EDAA&rsquo;s opt out tool</span></p>
                    <p><br /></p>
                    <p><span>36. Security of Personal Information</span></p>
                    <p><br /></p>
                    <p><span>The Site uses its best endeavours to store all Personal Information on servers with restricted access, and all electronic storage and transmission of Personal Information are secured with appropriate security technologies. Not to withstand the foregoing, the app cannot guarantee that such precautions would render the Site and its servers immune to security breaches.</span></p>
                    <p><br /></p>
                    <p><span>37. Changes to our Privacy Policy</span></p>
                    <p><br /></p>
                    <p><span>Igloo continually strives to implement new technologies and processes to better protect you, your privacy and your use of the Igloo service. As a result, changes to this privacy policy may be made by Igloo from time to time. In no event will these changes result in the degradation of any of the security measures designed to protect you from unauthorised disclosure, as such measures are described in this privacy policy.</span></p>
                    <p><br /></p>
                    <p><span>38. Terms and Conditions</span></p>
                    <p><br /></p>
                    <p><span>To learn more about our terms and conditions, please visit our Terms and Conditions page establishing the use, disclaimers, and limitations of liability governing the use of our website and services</span></p>
                    <p><br /></p>
                    <p><span>39. Jurisdiction</span></p>
                    <p><br /></p>
                    <p><span>This privacy policy, and terms of service, cookies, and other policies of Igloo Platform and services is governed by the English Courts</span></p>
                    <p><br /></p>
                    <p><span>40. Contact us</span></p>
                    <p><br /></p>
                    <p><span>You may update your subscription preferences with respect to receiving communications from us and/or our partners by signing into your account and visiting &ldquo;Settings&rdquo; under your profile page or alternatively emailing us at <a href="mailto:support@halotechlab.com"><u><span>support@halotechlab.com</span></u></a>.</span></p>
                    <p><br /></p>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

        {/* Alert banner */}
        <Banner />
      </div >
    </div >
  );
}
