import { useEffect, useState } from "react";
import Header from "../layout/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Container from "../layout/Container";
import SpaceFiller from "../layout/SpaceFiller";
import Spinner from "../layout/Spinner";
import Filters from "../layout/Filters";
import { useSearchParams } from "react-router-dom";
import { CommunityState } from "../../slices/auth";
import { getCommunityFeed } from "../../slices/community";
import Pagination from "../layout/Pagination";
import CommunityFeed from "./CommunityFeed";
import NoContent from "../layout/NoContent";
import { KeyIcon, RectangleGroupIcon, UserGroupIcon } from "@heroicons/react/24/solid";
import Link from "../layout/Link";
import getButtonStyle from "../../utils/styles";
import { AnnouncementState, getCommunityAnnouncements } from "../../slices/announcements";
import Card from "../layout/Card";
import DividerWithLabel from "../layout/DividerWithLabel";
import generateTranslatedText from "../../utils/boilerplate";

export default function Feed() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  // Get search params
  const communityId = searchParams.get("community");
  const dateRangeTerm = searchParams.get("dateRange");
  const pageNum = searchParams.get("page");

  useEffect(() => {
    dispatch(getCommunityFeed({ dateRange: dateRangeTerm, community: communityId, pageNum }));
    dispatch(getCommunityAnnouncements({ dateRange: dateRangeTerm, community: communityId }));
  }, [dispatch, dateRangeTerm, communityId, pageNum]);

  const { data, date_range: dateRangeSelected, pagination, loading } = useAppSelector((state) => state.community);
  const { data: announcementsData } = useAppSelector((state) => state.announcements);
  const { user } = useAppSelector((state) => state.auth);

  // Create component level state
  const [community, setCommunity] = useState({ value: "" });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  // Constants
  const language = user?.language || "EN";
  const filterChoicesCommunity = user === null ? [] : user.profile.communities.map((opt: CommunityState) => ({
    value: opt.id,
    label: opt.name,
  }));
  const hasActiveCommunity = user === null ? false : user.profile.communities.length > 0;

  return (
    <Template>
      {/* Charts */}
      <Container className="pt-0 overflow-y-auto h-screen scrollbar-invisible">

        {/* Header */}
        <Header text={generateTranslatedText("community", language)} />

        {loading ? (
          <SpaceFiller>
            <Spinner colour="info" />
          </SpaceFiller>
        ) : user?.subscription.type === "F" ? (
          <SpaceFiller>
            <NoContent
              icon={<RectangleGroupIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />}
              text={generateTranslatedText("community_upgrade_header", language)}
              subtext={generateTranslatedText("community_upgrade_subheader", language)}
              actionButton={
                <Link
                  href="/profile"
                  className={`${getButtonStyle("solid", "info")} rounded-full drop-shadow mt-4`}
                >
                  <KeyIcon className="mx-auto h-5 w-5 mr-2 text-white" aria-hidden="true" />
                  {generateTranslatedText("upgrade", language)}
                </Link>
              }
            />
          </SpaceFiller>
        ) : !hasActiveCommunity ? (
          <SpaceFiller>
            <NoContent
              icon={<UserGroupIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />}
              text={generateTranslatedText("no_community_header", language)}
              subtext={generateTranslatedText("no_community_subheader", language)}
            />
          </SpaceFiller>
        ) : (
          <>
            {/* Filters */}
            <Filters
              language={language}
              className="pb-10 mx-1"
              filters={[
                {
                  id: "community",
                  name: "Community",
                  options: filterChoicesCommunity,
                },
              ]}
              queryParams={[
                ["community", communityId],
                ["dateRange", dateRangeTerm],
              ]}
              handlers={{
                community: {
                  state: community,
                  stateName: "value",
                  onChange: setCommunity
                },
                dateRange: {
                  state: dateRange,
                  stateName: "N/A",
                  onChange: setDateRange,
                },
              }}
              loading={loading}
              baseUrl="/community"
              showDateRange={true}
              showFilters={true}
              showSearchBar={false}
              placeholderText={communityId === null && dateRangeTerm === null ? `Filters = Date range: ${dateRangeSelected.start_date} - ${dateRangeSelected.end_date}` : null}
            />

            {/* Announcements */}
            {announcementsData.length > 0 && (
              <div>
                <DividerWithLabel text={generateTranslatedText("announcements", language)} className="" />
                <div className="flex flex-nowrap overflow-y-auto scrollbar-invisible gap-x-4 px-1 pb-8 pt-4">
                  {announcementsData.map((announcement: AnnouncementState, idx: number) => (
                    <Card key={announcement.id} colour={`bg-${announcement.priority < 4 ? "info/30" : announcement.priority < 7 ? "info/40" : "info/50"}`} className="lg:w-80 w-64 flex-none">
                      <h1 className="text-lg font-medium text-white dark:text-white text-wrap">
                        {announcement.title}
                      </h1>
                      <div className="pt-4">
                        <span className="text-white italic dark:text-slate-300 text-sm text-wrap">
                          {`"${announcement.message}"`}
                        </span>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            )}

            {/* Community feed */}
            <DividerWithLabel text={generateTranslatedText("community_updates", language)} className="pb-4" />
            <CommunityFeed data={data} type="full" />

            {/* Pagination */}
            <Pagination
              className="mt-10 mb-36 md:mb-0"
              showPrevious={pagination.has_previous}
              showNext={pagination.has_next}
              currentPage={pagination.current_page}
              totalPages={pagination.total_pages}
              totalObjects={pagination.total_objects}
              queryParams={["page", "dateRange"]}
            />
          </>
        )}

      </Container>
    </Template>
  );
}
