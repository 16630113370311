import { AcademicCapIcon, ArrowsPointingOutIcon, CakeIcon, CalendarDaysIcon, ChatBubbleBottomCenterIcon, ChatBubbleLeftEllipsisIcon, CheckCircleIcon, EllipsisHorizontalCircleIcon, ExclamationCircleIcon, FaceSmileIcon, HeartIcon, MegaphoneIcon, PencilIcon, PresentationChartLineIcon, QuestionMarkCircleIcon, TrophyIcon, UserCircleIcon, UserGroupIcon } from "@heroicons/react/24/solid"

export function getSolidIcon(text: string | undefined | null, colour: string, size: number, className?: string) {
  if (text === "REF") return <UserCircleIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "DPT") return <ChatBubbleBottomCenterIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "SGM") return <TrophyIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "LFE") return <CakeIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "WNR") return <MegaphoneIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "TRX") return <ExclamationCircleIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "ROM") return <HeartIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "FAF") return <UserGroupIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  // if (text === "NAN") return <QuestionMarkCircleIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "NAN") return <PencilIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "JM") return <PencilIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "TP") return <CheckCircleIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "SN") return <FaceSmileIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "LN") return <AcademicCapIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "EX") return <ArrowsPointingOutIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "BK") return <HeartIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "diary") return <PencilIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "emotion") return <HeartIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "events") return <CalendarDaysIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "goals") return <TrophyIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "Reaction") return <FaceSmileIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "Thank you") return <TrophyIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "J") return <PencilIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "T") return <ChatBubbleLeftEllipsisIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "P") return <FaceSmileIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "L") return <PresentationChartLineIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "X") return <ArrowsPointingOutIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "K") return <HeartIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "O") return <EllipsisHorizontalCircleIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  return <EllipsisHorizontalCircleIcon className={`h-4 w-4 text-${colour} inline-block ${className !== undefined && className}`} />
}

