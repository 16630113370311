interface LargeTextInputProps {
  name: string;
  rows?: number;
  placeholder?: string;
  value?: string;
  onChange: any;
}

export default function LargeTextInput({
  name,
  rows = 10,
  placeholder,
  value,
  onChange,
}: LargeTextInputProps) {
  return (
    <div className="-space-y-px rounded-md mt-3">
      <div>
        <label htmlFor={`${name}-input`} className="sr-only">
          Entry
        </label>
        <textarea
          id={`${name}-input`}
          name={name}
          required
          className="relative block w-full appearance-none border-none text-flint placeholder-gray-500 border-none focus:z-10 focus:border-none focus:outline-none focus:ring-transparent sm:text-sm p-0 bg-transparent dark:text-white"
          // className="relative block w-full appearance-none rounded-lg border-none px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-amber-500 focus:outline-none focus:ring-amber-500 sm:text-sm"
          placeholder={placeholder}
          rows={rows}
          autoFocus
          style={{ resize: "none" }}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
}
