import { Bars3Icon } from "@heroicons/react/24/outline";

interface MobileTopNavProps {
  setMobileMenuOpen: any;
}

export default function MobileTopNav({ setMobileMenuOpen }: MobileTopNavProps) {
  return (
    <div className="lg:hidden px-3 pb-3">
      <div className="flex items-center justify-between bg-igloo py-2 px-4 sm:px-6 lg:px-8 rounded-b-3xl drop-shadow">
        <div>
          <a href="/">
            <img
              className="h-10 sm:h-12 w-auto"
              src="/img/logo/logo_transparent_bg.svg"
              alt="Igloo"
            />
          </a>
        </div>
        <div>
          <button
            type="button"
            className="group -mr-3 inline-flex h-12 w-12 items-center justify-center rounded-full bg-transparent text-white hover:bg-white/20 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6 text-white group-hover:text-flint dark:text-flint group-hover:dark:text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
