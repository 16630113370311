import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { getColour } from "../../utils/colours";
import { getSolidIcon } from "../../utils/icons";
import { HistoryState, updateEmotionEntry } from "../../slices/diary";
import Button from "../layout/Button";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Modal from "../layout/Modal";
import { Dialog } from "@headlessui/react";
import TextInput from "../layout/TextInput";
import DangerZone from "../forms/DangerZone";
import Dropdown from "../layout/Dropdown";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteDiaryEntry, getDiaryEntrySubjects, updateDiaryEntry } from "../../slices/diary";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";
import TextArea from "../layout/TextArea";
import { deleteEmotionEntry } from "../../slices/diary";
import { getEmojis } from "../../slices/emotion";
import Spinner from "../layout/Spinner";

export default function ContentFeed({
  data,
  type = "small",
}: {
  data: Array<HistoryState>;
  type?: string;
}) {

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDiaryEntrySubjects());
    dispatch(getEmojis());
  }, [dispatch]);

  // Get redux store state
  const { user } = useAppSelector((state) => state.auth);
  const { options, loading } = useAppSelector((state) => state.diary);
  const { emojis: emojiData, loading: emojiLoading } = useAppSelector(
    (state) => state.emotion
  );

  // Component level state
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedObject, setSelectedObject] = useState<{
    id: string,
    type: string,
    title: string | null,
    subject: string | null,
    text: string,
    icon: string | null,
  } | null>(null)

  // Functions
  function selectEntry(e: any, entry: HistoryState) {
    e.preventDefault();
    setSelectedObject({
      id: entry.id,
      type: entry.type,
      title: entry.content.title,
      subject: entry.content.subject === null ? null : entry.content.subject[0],
      text: entry.content.text,
      icon: entry.content.icon
    });
    setShowModal(true);
  }

  async function updateEntry(e: any) {
    e.preventDefault();
    if (selectedObject === null) {
      dispatch(
        addAlert(generateTranslatedText("missing_object", language), "danger", 3000),
      );
      return;
    }

    // Validate text
    if (selectedObject.text === null || selectedObject.text === "") {
      dispatch(
        addAlert(generateTranslatedText("missing_text", language), "danger", 3000),
      );
      return;
    }

    setShowModal(false);
    let res: any;
    if (selectedObject.type === "diary") {
      res = await dispatch(updateDiaryEntry({
        entryId: selectedObject.id,
        type: selectedObject.type,
        title: selectedObject.title,
        subject: selectedObject.subject,
        text: selectedObject.text,
        icon: selectedObject.icon
      }));
    } else {
      const iconId = emojiData.find((x: any) => x.emoji === selectedObject.icon)?.id;
      if (iconId === undefined || iconId === null) {
        dispatch(
          addAlert(generateTranslatedText("missing_icon", language), "danger", 3000),
        );
        return;
      }
      res = await dispatch(updateEmotionEntry({
        entryId: selectedObject.id,
        type: selectedObject.type,
        icon: iconId
      }));
    }
    if (res.payload.status === 200) {
      dispatch(
        addAlert(
          generateTranslatedText("update_success", language),
          "success",
          3000,
        ),
      );
    } else {
      dispatch(
        addAlert(generateTranslatedText("update_error", language), "danger", 3000),
      );
      setShowModal(true);
    }
  }

  async function confirmDelete(e: any, args: any[]) {
    e.preventDefault();
    if (selectedObject === null) {
      dispatch(
        addAlert(generateTranslatedText("missing_object", language), "danger", 3000),
      );
      return;
    }
    const [objectId] = args;
    const selectedObjectType = selectedObject.type;
    if (user === null) {
      dispatch(
        addAlert(generateTranslatedText("missing_user", language), "danger", 3000),
      );
      return;
    }
    if (objectId === null || objectId === undefined) {
      dispatch(
        addAlert(generateTranslatedText("missing_object", language), "danger", 3000),
      );
      return;
    }

    setShowModal(false);
    let res: any;
    if (selectedObjectType === "diary") {
      res = await dispatch(deleteDiaryEntry({ entryId: objectId }));
    } else {
      res = await dispatch(deleteEmotionEntry({ emotionId: objectId }));
    }
    if (res.payload.status === 200) {
      dispatch(
        addAlert(
          generateTranslatedText("delete_success", language),
          "success",
          3000,
        ),
      );
    } else {
      dispatch(
        addAlert(generateTranslatedText("delete_error", language), "danger", 3000),
      );
      setShowModal(true);
    }
  }

  // Constant variables
  const language = user?.language || "EN";
  const missingDataString = generateTranslatedText("missing_data_history", language);

  return data.length === 0 ? (
    <div className="flow-root">
      <ul className="-mb-8">
        {type === "full" && (
          <li>
            <div className="relative bg-white drop-shadow dark:bg-zinc-900 p-8 mb-8 rounded-lg mx-1">
              <div className="relative flex items-start space-x-3">
                <div className="relative">
                  <div className="flex m-1 h-8 w-8 items-center justify-center rounded-full bg-white ring-8 ring-white text-black animate-bounce">
                    <ExclamationCircleIcon
                      className="h-8 w-8"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="min-w-0 flex-1">
                  <div className="mt-2 text-sm text-slate-600 dark:text-slate-300">
                    <p>{missingDataString}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
  ) : (

    <>
      {/* Update / delete modal */}
      <Modal
        show={showModal}
        setShow={setShowModal}
        confirmText="Update entry"
        cancelText="Cancel"
        confirmHandler={updateEntry}
        showConfirmButton={true}
      >
        <div>
          <div className="text-center">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
            >
              {generateTranslatedText("update_entry", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">
              {selectedObject === null ? null : selectedObject.type === "diary" ? (
                <>
                  {/* Subject */}
                  {!loading && options.length > 0 && (
                    <Dropdown
                      id="subject"
                      className="col-span-full"
                      label={generateTranslatedText("subject", language)}
                      showLabel={true}
                      required={true}
                      state={selectedObject}
                      stateName="subject"
                      onChange={setSelectedObject}
                      defaultValue={selectedObject.subject}
                    >
                      {options.map((opt) => (
                        <option key={opt[0]} value={opt[0]}>
                          {opt[1]}
                        </option>
                      ))}
                    </Dropdown>
                  )}

                  {/* Title */}
                  <TextInput
                    id="title"
                    type="text"
                    label={generateTranslatedText("title", language)}
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 dark:bg-zinc-700 placeholder-gray-400 px-3 py-2 dark:text-white dark:focus:bg-zinc-600 px-3 py-2 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm"
                    required={true}
                    showLabel={true}
                    state={selectedObject}
                    stateName="title"
                    onChange={setSelectedObject}
                  />

                  {/* Text */}
                  <TextArea
                    id="text"
                    label={generateTranslatedText("title", language)}
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 dark:bg-zinc-700 placeholder-gray-400 px-3 py-2 dark:text-white dark:focus:bg-zinc-600 px-3 py-2 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm"
                    required={true}
                    showLabel={true}
                    state={selectedObject}
                    stateName="text"
                    onChange={setSelectedObject}
                    rows={10}
                  />
                </>
              ) : (
                <>
                  {/* Icon */}
                  <TextInput
                    id="icon"
                    type="text"
                    label={generateTranslatedText("icon", language)}
                    className="block w-full appearance-none rounded-md border-none bg-transparent placeholder-gray-400 px-3 py-2 px-3 py-2 focus:outline-none text-4xl"
                    required={true}
                    showLabel={true}
                    state={selectedObject}
                    stateName="icon"
                    onChange={setSelectedObject}
                    disabled={true}
                  />

                  {/* Icon grid to update entry */}
                  <div className="container w-auto mr-3 mt-3 bg-white dark:bg-zinc-900 drop-shadow sm:p-4 px-6 py-6 flex flex-wrap flex-row row justify-center rounded-lg above-all">
                    {emojiLoading ? <Spinner colour="info" /> : emojiData.map((x: any) => (
                      <button
                        key={x.id}
                        className="rounded-full px-2 py-1 hover:bg-slate-100"
                        onClick={(e) => setSelectedObject({ ...selectedObject, icon: x.emoji })}
                      >
                        <span className="md:text-2xl text-xl">{x.emoji}</span>
                      </button>
                    ))}
                  </div>
                </>
              )}
              {selectedObject !== null && (
                <>
                  {/* Danger zone */}
                  <DangerZone
                    objectId={selectedObject.id}
                    handler={confirmDelete}
                    language={language}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* Feed */}
      <div className="flow-root">
        <ul className="-mb-8">
          {type === "full" &&
            data.map((item, itemIdx) => (
              <li key={item.id}>
                <div className="relative bg-white dark:bg-zinc-900 p-8 mb-8 rounded-lg drop-shadow mx-1 group">
                  {/* Update / delete button */}
                  <div className="group-hover:block hidden text-right">
                    <Button
                      type="button"
                      className="inline-flex justify-center rounded-full border border-transparent hover:bg-gray-400/10 p-1 text-xs font-medium text-gray-400 dark:text-white hover:text-danger focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
                      onClick={(e) => selectEntry(e, item)}
                    >
                      <PencilSquareIcon
                        className="h-4 w-4 text-gray-400 dark:text-white"
                        aria-hidden="true"
                      />
                    </Button>
                  </div>
                  <div className="relative flex items-start space-x-3">
                    {item.type === "diary" ? (
                      <>
                        {/* Icon */}
                        <div className="relative">
                          <div
                            className={`flex m-1 h-8 w-8 items-center justify-center rounded-full bg-${getColour(
                              item.content.subject !== null ? item.content.subject[0] : "NAN"
                            )} ring-8 ring-transparent text-transparent`}
                          >
                            {getSolidIcon(item.content.subject !== null ? item.content.subject[0] : "NAN", item.content.subject === null ? "white" : ["DPT", "TRX"].includes(item.content.subject[0]) ? "white" : "flint", 5)}
                          </div>
                        </div>

                        {/* Content */}
                        <div className="min-w-0 flex-1">
                          <div>
                            <p className="mt-0.5 text-sm text-slate-400 dark:text-slate-300">
                              {item.content.title !== null && (
                                <span className="text-black dark:text-white font-medium">{item.content.title}{" "}</span>
                              )}
                              {item.datetime_created}
                            </p>
                          </div>
                          <div className="mt-2 text-sm text-flint dark:text-white">
                            <p>{item.content.text}</p>
                          </div>
                        </div>
                      </>
                    ) : item.type === "emotion" ? (
                      <>
                        {/* Icon */}
                        <div>
                          <div className="relative px-1">
                            <span className="bg-transparent p-4 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-transparent">
                              <span className="md:text-3xl text-3xl rounded-full px-2 py-1">
                                {item.content.icon}
                              </span>
                            </span>
                          </div>
                        </div>

                        {/* Content */}
                        <div className="min-w-0 flex-1">
                          <div>
                            <p className="mt-0.5 text-sm text-slate-400 dark:text-slate-300">
                              {item.datetime_created}
                            </p>
                          </div>
                          <div className="mt-2 text-sm text-flint dark:text-white">
                            <p>{item.content.text}</p>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
