import { ResponsiveWaffle } from "@nivo/waffle";
import { randomIntFromInterval } from '../../../utils/random';
import { getColour } from '../../../utils/colours';
import { useEffect, useState } from "react";
import getWindowDimensions from "../../../utils/dimensions";

export interface WaffleChartData {
  id: string | number;
  label: string;
  value: number;
}

interface WaffleChartProps {
  data: WaffleChartData[];
  className?: string;
  reverse?: boolean;
  hSmall: string;
  hLarge: string;
  colour?: string[];
}

const pct1 = randomIntFromInterval(0, 50)
const pct2 = randomIntFromInterval(pct1, 45)
const pct3 = 100 - pct1 - pct2

const dummyData = [
  {
    "id": "1",
    "label": "No data yet!",
    "value": pct1
  },
  {
    "id": "2",
    "label": "No data yet!",
    "value": pct2
  },
  {
    "id": "3",
    "label": "No data yet!",
    "value": pct3
  }
]


export default function WaffleChart({ data, className, reverse, hSmall, hLarge, colour }: WaffleChartProps) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // let colour = "#9ae6b4"
  // if (!reverse) {
  //   if (data.length > 0) {
  //     if (data[0].value >= 35 && data[0].value < 60) {
  //       colour = "#ecc94b"
  //     } else if (data[0].value >= 60) {
  //       colour = "#f56565"
  //     }
  //   }
  // } else {
  //   if (data.length > 0) {
  //     if (data[0].value >= 35 && data[0].value < 60) {
  //       colour = "#ecc94b"
  //     } else if (data[0].value < 34) {
  //       colour = "#f56565"
  //     }
  //   }
  // }

  const showPlaceholder = data.length === 0;
  let heightClass = hLarge;
  if (windowDimensions.width < 992) heightClass = hSmall;

  return (
    <div className={`${className} ${heightClass}`}>
      <ResponsiveWaffle
        data={showPlaceholder ? dummyData : data}
        total={100}
        rows={10}
        columns={10}
        padding={3}
        valueFormat={(d: number) => `${d}%`}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        colors={showPlaceholder ? getColour("placeholder") : colour}
        borderRadius={3}
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              0.3
            ]
          ]
        }}
        motionStagger={2}
        legends={[]}
        emptyColor="#e8e8e8"
      />
    </div>
  );
}

WaffleChart.defaultProps = {
  reverse: false,
  colour: ["#9ae6b4"],
}
