import { Fragment, useState } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import { EventState } from '../../slices/calendar'
import { createDateFromString, formatDateStandard, getDatesBetween } from '../../utils/datetime'
import Link from '../layout/Link'
import generateTranslatedText from '../../utils/boilerplate'

function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(' ')
}

interface EventCalendarProps {
  events: EventState[],
  dateRange: {
    min: string | null,
    max: string | null,
    by: string | null,
    filter: { choices: { current: string; previous: string; next: string; }, start: string, end: string; } | null
  },
  language: string;
}

export default function EventCalendar({ events, dateRange, language }: EventCalendarProps) {

  // Get current date
  const now = new Date()
  const [selectedDate, setSelectedDate] = useState<string | null>(null)

  // Build array of dates for calendar
  const start = new Date(dateRange.min || '')
  const end = new Date(dateRange.max || '')
  const dateRangeArray = getDatesBetween(start, end)
  const formattedEvents = dateRangeArray.map(date => (
    {
      date: formatDateStandard(date),
      isCurrentMonth: date.getMonth() === now.getMonth(),
      isToday: date.toDateString() === now.toDateString(),
      isSelected: formatDateStandard(date) === selectedDate,
      events: events.filter(
        event => createDateFromString(event.start_date.standard) <= date && createDateFromString(event.end_date.standard) >= date
      ).map(event => ({
        id: event.id,
        name: event.name,
        range: {
          start: event.start_date,
          end: event.end_date,
        },
        datetime: event.start_date.standard,
        href: `/calendar/event/${event.id}`
      })),
    }
  ))

  let selectedEvents: Array<{
    id: string;
    name: string;
    range: {
      start: { readable: string; standard: string; time: { hour: number; minute: number; readable: string; } },
      end: { readable: string; standard: string; time: { hour: number; minute: number; readable: string; } }
    },
    datetime: string; href: string
  }> = [];
  if (selectedDate !== null) {
    const filteredEvents = formattedEvents.filter(event => event.date === selectedDate);
    if (filteredEvents.length > 0) {
      selectedEvents = filteredEvents[0].events;
    }
  }

  function navigateToToday() {
    const formattedNow = formatDateStandard(now)
    if (dateRange.by === "month") return `/calendar/?by=${dateRange.by}&date=${formattedNow.slice(0, -3)}`
    return `/calendar/?by=${dateRange.by}&date=${formattedNow}`
  }

  return (
    <div className="lg:flex lg:h-full lg:flex-col">
      <header className="flex items-center justify-between py-4 lg:flex-none">
        {dateRange.filter !== null && (
          <h1 className="font-semibold leading-6 text-flint dark:text-white">
            <time>{`${dateRange.filter.start} - ${dateRange.filter.end}`}</time>
          </h1>
        )}
        <div className="flex items-center">

          {/* Toggle daterange buttons */}
          <div className="relative flex items-center rounded-full bg-white dark:bg-zinc-900 md:items-stretch drop-shadow">

            {/* Previous */}
            <div
              className="pointer-events-none absolute inset-0 rounded-full"
              aria-hidden="true"
            />
            <Link
              href={`/calendar/?by=${dateRange.by}&date=${dateRange.filter?.choices.previous}`}
              className="flex items-center justify-center rounded-l-full py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50 dark:text-white dark:md:hover:bg-zinc-900"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </Link>

            {/* Today */}
            <Link
              href={navigateToToday()}
              className="hidden px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block dark:text-white dark:md:hover:bg-zinc-900"
            >
              {generateTranslatedText("today", language)}
            </Link>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />

            {/* Next */}
            <Link
              href={`/calendar/?by=${dateRange.by}&date=${dateRange.filter?.choices.next}`}
              className="flex items-center justify-center rounded-r-full py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50 dark:text-white dark:md:hover:bg-zinc-900"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
          </div>

          {/* Large viewport switch view mode */}
          {/* <div className="hidden md:ml-4 md:flex md:items-center">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {`${capitalise(dateRange.by)} view`}
                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href={switchViewMode("day")}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Day view
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href={switchViewMode("week")}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Week view
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href={switchViewMode("month")}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Month view
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div> */}

          {/* Small viewport switch view mode */}
          <Menu as="div" className="relative ml-6 md:hidden">
            <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Open menu</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white dark:bg-zinc-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href={navigateToToday()}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900 dark:text-white dark:hover:text-igloo' : 'text-gray-700 dark:text-white',
                          'block px-4 py-2 text-sm dark:bg-zinc-900'
                        )}
                      >
                        {generateTranslatedText("got_to_today", language)}
                      </a>
                    )}
                  </Menu.Item>
                </div>
                {/* <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href={switchViewMode("day")}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Day view
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href={switchViewMode("week")}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Week view
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href={switchViewMode("month")}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Month view
                      </a>
                    )}
                  </Menu.Item>
                </div> */}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </header>

      {/* If view mode is month */}
      {dateRange.by === "month" && (
        <div className="p-2 bg-white dark:bg-zinc-900 rounded-lg drop-shadow">
          <div className="lg:flex lg:flex-auto lg:flex-col">
            <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
              <div className="bg-white dark:bg-zinc-900 dark:text-white py-2">
                {generateTranslatedText("monday_1", language)}<span className="sr-only sm:not-sr-only">{generateTranslatedText("monday_2", language)}</span>
              </div>
              <div className="bg-white dark:bg-zinc-900 dark:text-white py-2">
                {generateTranslatedText("tuesday_1", language)}<span className="sr-only sm:not-sr-only">{generateTranslatedText("tuesday_2", language)}</span>
              </div>
              <div className="bg-white dark:bg-zinc-900 dark:text-white py-2">
                {generateTranslatedText("wednesday_1", language)}<span className="sr-only sm:not-sr-only">{generateTranslatedText("wednesday_2", language)}</span>
              </div>
              <div className="bg-white dark:bg-zinc-900 dark:text-white py-2">
                {generateTranslatedText("thursday_1", language)}<span className="sr-only sm:not-sr-only">{generateTranslatedText("thursday_2", language)}</span>
              </div>
              <div className="bg-white dark:bg-zinc-900 dark:text-white py-2">
                {generateTranslatedText("friday_1", language)}<span className="sr-only sm:not-sr-only">{generateTranslatedText("friday_2", language)}</span>
              </div>
              <div className="bg-white dark:bg-zinc-900 dark:text-white py-2">
                {generateTranslatedText("saturday_1", language)}<span className="sr-only sm:not-sr-only">{generateTranslatedText("saturday_2", language)}</span>
              </div>
              <div className="bg-white dark:bg-zinc-900 dark:text-white py-2">
                {generateTranslatedText("sunday_1", language)}<span className="sr-only sm:not-sr-only">{generateTranslatedText("sunday_2", language)}</span>
              </div>
            </div>
            <div className="flex bg-gray-200 dark:bg-zinc-900 text-xs leading-6 text-gray-700 lg:flex-auto">

              {/* Large viewport */}
              <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                {formattedEvents.map((day) => (
                  <div
                    key={day.date}
                    className={classNames(
                      day.isCurrentMonth ? 'bg-white dark:bg-zinc-600 dark:text-white' : 'bg-gray-50 text-gray-500 dark:bg-zinc-700 dark:text-white',
                      'relative px-3 py-2'
                    )}
                    onClick={(e) => setSelectedDate(day.date)}
                  >
                    <time
                      dateTime={day.date}
                      className={
                        day.isToday
                          ? 'flex h-6 w-6 items-center justify-center rounded-full bg-info font-semibold text-white'
                          : undefined
                      }
                    >
                      {/* {day.date.split('-').pop().replace(/^0/, '')} */}
                      {day.date.split("-")[2].replace(/^0/, '')}
                    </time>
                    {day.events.length > 0 && (
                      <ol className="mt-2">
                        {day.events.map((event) => (
                          <li key={event.id}>
                            <a href={event.href} className="group flex">
                              <p className="flex-auto truncate font-medium text-gray-900 dark:text-white group-hover:text-info">
                                {event.name}
                              </p>
                              {/* <time
                                dateTime={event.datetime}
                                className="ml-3 hidden flex-none text-gray-500 group-hover:text-info xl:block"
                              >
                                {event.time.readable}
                              </time> */}
                            </a>
                          </li>
                        ))}
                        {/* {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>} */}
                      </ol>
                    )}
                  </div>
                ))}
              </div>

              {/* Small viewport */}
              <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                {formattedEvents.map((day) => (
                  <button
                    key={day.date}
                    type="button"
                    className={classNames(
                      day.isCurrentMonth ? 'bg-white dark:bg-zinc-600 dark:text-white dark:hover:bg-zinc-500' : 'bg-gray-50 dark:bg-zinc-700 dark:text-white dark:hover:bg-zinc-500',
                      (day.isSelected || day.isToday) && 'font-semibold',
                      day.isSelected && 'text-white',
                      !day.isSelected && day.isToday && 'text-info',
                      !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                      !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                      'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10'
                    )}
                    onClick={(e) => setSelectedDate(day.date)}
                  >
                    <time
                      dateTime={day.date}
                      className={classNames(
                        day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                        day.isSelected && day.isToday && 'bg-info',
                        day.isSelected && !day.isToday && 'bg-gray-900',
                        'ml-auto'
                      )}
                    >

                      {day.date.split("-")[2].replace(/^0/, '')}
                    </time>
                    <span className="sr-only">{day.events.length} events</span>
                    {day.events.length > 0 && (
                      <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                        {day.events.map((event) => (
                          <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                        ))}
                      </span>
                    )}
                  </button>
                ))}
              </div>

            </div>
          </div>
          {selectedEvents.length > 0 && (
            <div className="px-4 py-10 sm:px-6 lg:hidden">
              <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white dark:bg-zinc-700 text-sm ring-1 ring-black ring-opacity-5">
                {selectedEvents.map((event) => (
                  <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50 dark:hover:bg-zinc-600">
                    <div className="flex-auto">
                      <p className="font-semibold text-gray-900 dark:text-white">{event.name}</p>
                      <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700 dark:text-gray-300">
                        <ClockIcon className="mr-2 h-5 w-5 text-gray-400 dark:text-info" aria-hidden="true" />
                        {`${event.range.start.readable} ${event.range.start.time.readable} - ${event.range.end.readable} ${event.range.end.time.readable}`}
                      </time>
                    </div>
                    <a
                      href={event.href}
                      className="ml-6 flex-none self-center rounded-md bg-white dark:bg-info dark:text-white px-3 py-2 font-semibold text-gray-900 opacity-0 ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
                    >
                      {generateTranslatedText("edit", language)}<span className="sr-only">, {event.name}</span>
                    </a>
                  </li>
                ))}
              </ol>
            </div>
          )}
        </div>
      )}

    </div>
  )
}
