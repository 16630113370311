import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { logout } from "../../slices/auth";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";

const navigation = [
  { name: "About", href: "/about", navbar: true },
  { name: "How it works", href: "/how-it-works", navbar: false },
  { name: "Stories", href: "/stories", navbar: false },
  { name: "The team", href: "/team", navbar: false },
  { name: "Pricing", href: "/pricing", navbar: true },
  { name: "FAQs", href: "/faqs", navbar: true },
  { name: "Contact", href: "/contact", navbar: true },
]

export default function LandingNav() {
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user, isAuthenticated, loading } = useAppSelector((state) => state.auth);

  // Functions
  async function logoutUser(e: any) {
    e.preventDefault();
    const res: any = await dispatch(logout());
    if (res.payload.status === 205) {
      dispatch(addAlert(generateTranslatedText("logout", language), "success"));
    } else {
      dispatch(addAlert(generateTranslatedText("logout_error", language), "danger"));
    }
  }

  function getGoToAppLink() {
    const groups = user === null ? [] : user.auth.groups.map((group: any) => group[1]);
    if (groups.includes("Individual User")) {
      return "/write";
    } else if (groups.includes("Entity Owner")) {
      return "/entity/overview";
    }
    return "/write";
  }

  // Constant variables
  const language = user?.language || "EN";
  const unAuthenticatedLinks = (
    <>
      <a
        href="/login"
        className="inline-flex items-center justify-center rounded-full border border-transparent bg-transparent hover:bg-white px-4 py-2 font-medium text-flint hover:drop-shadow"
      >
        {generateTranslatedText("sign_in", language)}
      </a>
      <a
        href="/register"
        className="ml-8 inline-flex items-center justify-center rounded-full border border-transparent bg-white px-4 py-2 text-base font-medium text-flint shadow-sm hover:bg-flint hover:text-white drop-shadow"
      >
        {generateTranslatedText("sign_up", language)}
      </a>
    </>
  );

  const authenticatedLinks = (
    <>
      <a
        href={getGoToAppLink()}
        className="inline-flex items-center justify-center rounded-full border border-transparent bg-white px-4 py-2 text-base font-medium text-flint shadow-sm hover:bg-flint hover:text-white drop-shadow"
      >
        {generateTranslatedText("go_to_app", language)}
      </a>
      <a
        href="/#!"
        className="ml-8 inline-flex items-center justify-center rounded-full border border-transparent bg-transparent px-4 py-2 font-medium text-flint hover:text-flint hover:bg-white hover:drop-shadow"
        onClick={(e) => logoutUser(e)}
      >
        {generateTranslatedText("logout_button", language)}
      </a>
    </>
  );

  const unAuthenticatedMobileLinks = (
    <>
      <a
        href="/register"
        className="flex w-full items-center justify-center rounded-full border border-transparent bg-igloo dark:bg-igloo dark:hover:bg-igloo-dark px-4 py-2 text-base font-medium text-flint shadow-sm hover:bg-igloo-dark drop-shadow"
      >
        {generateTranslatedText("sign_up", language)}
      </a>
      <p className="mt-6 text-center text-base font-medium text-flint dark:text-white">
        {generateTranslatedText("existing_user", language)}
        <a
          href="/login"
          className="text-igloo hover:text-igloo-dark dark:text-igloo dark:hover:text-igloo-dark"
        >
          {" "}
          {generateTranslatedText("sign_in", language)}
        </a>
      </p>
    </>
  );

  const authenticatedMobileLinks = (
    <>
      <a
        href={getGoToAppLink()}
        className="flex w-full items-center justify-center rounded-full border border-transparent bg-igloo px-4 py-2 text-base font-medium text-flint shadow-sm hover:bg-igloo-dark drop-shadow"
      >
        {generateTranslatedText("go_to_app", language)}
      </a>
      <p className="mt-6 text-center text-base font-medium text-gray-500 dark:text-white">
        {generateTranslatedText("had_enough", language)}
        <a
          href="/#!"
          className="text-igloo hover:text-igloo-dark dark:text-igloo dark:hover:text-igloo-dark"
          onClick={(e) => logoutUser(e)}
        >
          {" "}
          {generateTranslatedText("logout_button", language)}
        </a>
      </p>
    </>
  );

  return (
    <div className="bg-igloo relative drop-shadow">
      <Popover className="relative">
        <div className="mx-auto max-w-7xl px-4 sm:px-6">
          <div className="flex items-center justify-between py-2 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/" className="rounded-xl p-2">
                <span className="sr-only">Igloo</span>
                <img
                  className="h-10 w-auto sm:h-14"
                  src="/img/logo/logo_transparent_bg.svg"
                  alt=""
                />
              </a>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-transparent text-flint p-2 hover:bg-slate-100/10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden space-x-10 md:flex">
              {navigation.filter(x => x.navbar).map((item, idx) => (
                <a
                  key={idx}
                  href={item.href}
                  className="text-base font-medium text-flint hover:text-white"
                >
                  {item.name}
                </a>
              ))}
            </Popover.Group>
            <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
              {loading ? null : user && isAuthenticated
                ? authenticatedLinks
                : unAuthenticatedLinks}
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="divide-y-2 divide-gray-50 dark:divide-gray-600 rounded-lg bg-white dark:bg-zinc-800 shadow-lg ring-1 ring-flint ring-opacity-5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto rounded-full"
                      src="/img/logo/logo_coloured_bg.svg"
                      alt="Igloo"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white dark:bg-transparent dark:text-white p-2 text-flint hover:bg-gray-100 dark:hover:bg-zinc-600 hover:text-gray-500 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-300">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="space-y-6 py-6 px-5">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  {navigation.filter(x => x.navbar).map((item, idx) => (
                    <a
                      key={idx}
                      href={item.href}
                      className="text-base font-medium text-flint dark:text-white hover:text-igloo"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div>
                  {loading ? null : user && isAuthenticated
                    ? authenticatedMobileLinks
                    : unAuthenticatedMobileLinks}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}
