import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";
import { buildUrl } from "../utils/proxy";
import { NotificationState } from "./community";

// Define a type for the slice state
interface NotificationsState {
  data: NotificationState[];
  pagination: {
    has_previous: boolean;
    has_next: boolean;
    current_page: number;
    total_pages: number;
    total_objects: number;
  }
  loading: boolean;
}

// Define the initial state using that type
const initialState: NotificationsState = {
  data: [],
  pagination: {
    has_previous: false,
    has_next: false,
    current_page: 0,
    total_pages: 0,
    total_objects: 0,
  },
  loading: true,
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get profile notifications
export const getNotifications = createAsyncThunk(
  "notifications/get",
  async (obj: { pageNum: string | null }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      const params: { [key: string]: string } = {};
      if (obj.pageNum !== null) params.page = obj.pageNum;
      const searchParams = new URLSearchParams(params);

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/auth/notifications?${searchParams.toString()}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Update status of notification
export const updateStatusOfNotification = createAsyncThunk(
  "notifications/update/put",
  async (
    obj: {
      notification: string;
      status: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const { auth } = getState() as { auth: AuthState };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };
      const body = JSON.stringify({
        notification: obj.notification,
        status: obj.status,
      });
      const res = await axios.put(buildUrl(`/api/auth/notifications`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Create notification
export const createNotification = createAsyncThunk(
  "notifications/create/post",
  async (
    obj: {
      sentBy: string;
      recipient: string;
      associatedLog: string | null;
      type: string;
      action: string;
      message: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const { auth } = getState() as { auth: AuthState };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };
      const body = JSON.stringify({
        sent_by: obj.sentBy,
        recipient: obj.recipient,
        associated_log: obj.associatedLog,
        type: obj.type,
        action: obj.action,
        message: obj.message,
        status: "A"
      });
      const res = await axios.post(buildUrl(`/api/auth/notifications`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get profile notifications
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.pagination = action.payload.data.pagination;
        state.loading = false;
      })
      .addCase(getNotifications.rejected, (state) => {
        state.loading = false;
      })

      // Update status of notification
      .addCase(updateStatusOfNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStatusOfNotification.fulfilled, (state, action) => {
        const notificationData = action.payload.data;

        // Find associated log item in data
        const updatedData = state.data.map((x: NotificationState) => {
          if (x.id === notificationData.id) {
            return notificationData
          }
          return x;
        })
        state.data = updatedData
        state.loading = false;
      })
      .addCase(updateStatusOfNotification.rejected, (state) => {
        state.loading = false;
      })

      // Create notification
      .addCase(createNotification.pending, (state) => { })
      .addCase(createNotification.fulfilled, (state, action) => { })
      .addCase(createNotification.rejected, (state) => { })
  },
});

export default notificationSlice.reducer;
