import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

interface CheckboxesProps {
  id: string;
  label?: string;
  showLabel?: boolean;
  options: Array<[any, string, any, any, any]>;
  state?: any;
  stateName?: any;
  onChange?: any;
  disabled?: boolean;
  baseValue: any;
  spaceY?: number;
}

export default function Checkboxes({
  id,
  label,
  showLabel,
  options,
  state,
  stateName,
  onChange,
  disabled,
  baseValue,
  spaceY,
}: CheckboxesProps) {

  function checkIfChecked(option: any) {
    if (typeof state[stateName] === "boolean" || state[stateName] === null) {
      if (state[stateName] === null) return option === baseValue;
      return option === state[stateName];
    }
    if (typeof state[stateName] === "object") {
      return state[stateName].includes(option);
    }
    return state[stateName] === option;
  }

  function createCheckbox(idx: number, option: any) {
    if (disabled) {
      return (
        <input
          id={`checkbox-${idx}-${id}`}
          className="h-4 w-4 rounded border-gray-300 text-info focus:ring-info"
          name={stateName}
          value={option}
          type="checkbox"
          onChange={e => onChange({ ...state, [stateName]: state[stateName] === null ? true : !state[stateName] })}
          checked={checkIfChecked(option)}
          disabled
        />
      )
    }
    return (
      <input
        id={`checkbox-${idx}-${id}`}
        className="h-4 w-4 rounded border-gray-300 text-info focus:ring-info"
        name={stateName}
        value={option}
        type="checkbox"
        onChange={e => {
          if (typeof state[stateName] === "boolean" || state[stateName] === null) {
            onChange({ ...state, [stateName]: state[stateName] === null ? true : !state[stateName] })
          } else if (typeof state[stateName] === "object") {
            if (state[stateName].includes(option)) {
              onChange({ ...state, [stateName]: state[stateName].filter((item: any) => item !== option) })
            } else {
              onChange({ ...state, [stateName]: [...state[stateName], option] })
            }
          }
        }}
        checked={checkIfChecked(option)}
      />
    )
  }

  return (
    <div>
      {/* Label */}
      {showLabel ? (
        <label htmlFor={id} className="mb-3 block text-sm font-medium text-gray-700 dark:text-white text-left">
          {label}
        </label>
      ) : (
        <label htmlFor={id} className="mb-3 block text-sm font-medium text-gray-700 dark:text-white text-left" />
      )}

      {/* Checkboxes */}
      <div className={`mt-6 space-y-${spaceY}`}>
        {options.map((option, index) => (
          <div className="relative flex gap-x-3" key={index}>
            <div className="flex h-6 items-center">
              {createCheckbox(index, option[0])}
            </div>
            <div className="text-sm leading-6">
              <label htmlFor="comments" className="text-gray-900 dark:text-white">
                {option[1]}
              </label>
              {option[2] !== null && (
                <p className="text-xs text-gray-400">
                  {option[2] !== null && option[2]}
                  {option[3] !== null && (
                    <span>
                      <a className="underline" href={option[4]} target="_blank">{option[3]}</a>
                    </span>
                  )}
                </p>
              )}
            </div>
          </div>
        ))}

      </div>
    </div>
  )
}

Checkboxes.defaultProps = {
  showLabel: false,
  state: {},
  stateName: "",
  onChange: () => { },
  disabled: false,
  spaceY: "6",
}
