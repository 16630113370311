import { useEffect, useState } from "react";
import Header from "../layout/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Container from "../layout/Container";
import { useSearchParams } from "react-router-dom";
import Pagination from "../layout/Pagination";
import Filters from "../layout/Filters";
import SpaceFiller from "../layout/SpaceFiller";
import Spinner from "../layout/Spinner";
import NoContent from "../layout/NoContent";
import { CommunityState } from "../../slices/auth";
import { AnnouncementState, createEntityAnnouncement, deleteEntityAnnouncement, getEntityAnnouncements, updateEntityAnnouncement } from "../../slices/announcements";
import Card from "../layout/Card";
import Pill from "../layout/Pill";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import ProgressBar from "../layout/ProgressBar";
import Modal from "../layout/Modal";
import { Dialog } from "@headlessui/react";
import DangerZone from "../forms/DangerZone";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";
import Dropdown from "../layout/Dropdown";
import TextInput from "../layout/TextInput";
import TextArea from "../layout/TextArea";
import ActionButtons from "../layout/ActionButtons";
import Button from "../layout/Button";
import getButtonStyle from "../../utils/styles";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import RangeSlider from "../layout/RangeSlider";

export default function Announcements() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  // Get search params
  const pageNum = searchParams.get("page") === null ? 1 : parseInt(searchParams.get("page") as string);
  const communityId = searchParams.get("community");
  const dateRangeTerm = searchParams.get("dateRange");

  // Get data from the store
  const { data, pagination, loading, date_range: dateRangeSelected } = useAppSelector((state) => state.announcements);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getEntityAnnouncements({ pageNum: pageNum.toString(), community: communityId, dateRange: dateRangeTerm, }));
  }, [dispatch, pageNum, communityId, dateRangeTerm]);

  // Create component level state
  const [community, setCommunity] = useState({ value: "" });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [createForm, setCreateForm] = useState<{
    priority: string;
    community: string;
    title: string;
    message: string;
  }>({
    priority: "0",
    community: "",
    title: "",
    message: "",
  });
  const [selectedObject, setSelectedObject] = useState<{
    id: string;
    priority: number;
    community: [string, string];
    title: string;
    message: string;
    status: string;
  } | null>(null)

  // Functions
  function selectAnnouncement(e: any, announcement: AnnouncementState) {
    e.preventDefault();
    setSelectedObject({
      id: announcement.id,
      priority: announcement.priority,
      community: announcement.community,
      title: announcement.title,
      message: announcement.message,
      status: announcement.status[0],
    });
    setShowUpdateModal(true);
  }

  async function createAnnouncement(e: any) {
    e.preventDefault();

    // Validate state
    if (createForm.priority === null) {
      dispatch(addAlert(generateTranslatedText("missing_priority", language), "danger", 3000));
      return;
    }
    if (createForm.community === null) {
      dispatch(addAlert(generateTranslatedText("missing_community", language), "danger", 3000));
      return;
    }
    if (createForm.title === null) {
      dispatch(addAlert(generateTranslatedText("missing_title", language), "danger", 3000));
      return;
    }
    if (createForm.message === null) {
      dispatch(addAlert(generateTranslatedText("missing_message", language), "danger", 3000));
      return;
    }

    setShowCreateModal(false);
    const res: any = await dispatch(createEntityAnnouncement({
      priority: parseInt(createForm.priority, 10),
      community: createForm.community,
      title: createForm.title,
      message: createForm.message,
    }));
    if (res.payload.status === 201) {
      dispatch(
        addAlert(
          generateTranslatedText("update_success", language),
          "success",
          3000,
        ),
      );
      setCreateForm({
        priority: "0",
        community: "",
        title: "",
        message: "",
      });
    } else {
      dispatch(
        addAlert(generateTranslatedText("update_error", language), "danger", 3000),
      );
      setShowCreateModal(true);
    }
  }

  async function updateAnnouncement(e: any) {
    e.preventDefault();
    if (selectedObject === null) {
      dispatch(
        addAlert(generateTranslatedText("missing_object", language), "danger", 3000),
      );
      return;
    }

    // Validate state
    if (selectedObject.priority === null) {
      dispatch(addAlert(generateTranslatedText("missing_priority", language), "danger", 3000));
      return;
    }
    if (selectedObject.community === null) {
      dispatch(addAlert(generateTranslatedText("missing_community", language), "danger", 3000));
      return;
    }
    if (selectedObject.title === null) {
      dispatch(addAlert(generateTranslatedText("missing_title", language), "danger", 3000));
      return;
    }
    if (selectedObject.message === null) {
      dispatch(addAlert(generateTranslatedText("missing_message", language), "danger", 3000));
      return;
    }
    if (selectedObject.status === null) {
      dispatch(addAlert(generateTranslatedText("missing_status", language), "danger", 3000));
      return;
    }

    setShowUpdateModal(false);
    const res: any = await dispatch(updateEntityAnnouncement({
      announcementId: selectedObject.id,
      priority: selectedObject.priority,
      community: selectedObject.community[0],
      title: selectedObject.title,
      message: selectedObject.message,
      status: selectedObject.status,
    }));
    if (res.payload.status === 200) {
      dispatch(
        addAlert(
          generateTranslatedText("update_success", language),
          "success",
          3000,
        ),
      );
    } else {
      dispatch(
        addAlert(generateTranslatedText("update_error", language), "danger", 3000),
      );
      setShowUpdateModal(true);
    }
  }

  async function confirmDelete(e: any, args: any[]) {
    e.preventDefault();
    if (user === null) {
      dispatch(
        addAlert(generateTranslatedText("missing_user", language), "danger", 3000),
      );
      return;
    }
    if (selectedObject === null) {
      dispatch(
        addAlert(generateTranslatedText("missing_object", language), "danger", 3000),
      );
      return;
    }
    const [objectId] = args;
    if (objectId === null || objectId === undefined) {
      dispatch(
        addAlert(generateTranslatedText("missing_object", language), "danger", 3000),
      );
      return;
    }

    setShowUpdateModal(false);
    const res: any = await dispatch(deleteEntityAnnouncement({ announcementId: objectId }));
    if (res.payload.status === 200) {
      dispatch(
        addAlert(
          generateTranslatedText("delete_success", language),
          "success",
          3000,
        ),
      );
    } else {
      dispatch(
        addAlert(generateTranslatedText("delete_error", language), "danger", 3000),
      );
      setShowUpdateModal(true);
    }
  }

  // Constants
  const language = user === null ? "EN" : user.language;
  const filterChoicesCommunity = user === null ? [] : user.profile.communities.map((opt: CommunityState) => ({
    value: opt.id,
    label: opt.name,
  }));

  return (
    <Template>
      <Container className="pt-0 h-screen overflow-y-auto scrollbar-invisible">

        {/* Header */}
        <Header
          text="Announcements"
          actions={
            <ActionButtons
              primaryButton={
                <Button
                  type="button"
                  className={`${getButtonStyle("solid", "info")} rounded-full drop-shadow`}
                  onClick={(e) => setShowCreateModal(true)}
                >
                  <PlusCircleIcon className="h-5 w-5 text-white mr-2" aria-hidden="true" />
                  Create announcement
                </Button>
              }
            />
          }
        />

        {/* Create modal */}
        <Modal
          show={showCreateModal}
          setShow={setShowCreateModal}
          confirmText="Create announcement"
          cancelText="Cancel"
          confirmHandler={createAnnouncement}
        >
          <div className="overflow-auto px-1">
            <div className="text-center">
              <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                Create an announcement
              </Dialog.Title>
              <div className="my-10 grid grid-cols-1 gap-y-8">

                {/* Priority */}
                <RangeSlider
                  id="priority"
                  start={0}
                  end={10}
                  step={1}
                  state={createForm}
                  stateName="priority"
                  onChange={setCreateForm}
                  showLabel={true}
                  label="Priority"
                />

                {/* Community */}
                {loading ? (
                  <SpaceFiller>
                    <Spinner />
                  </SpaceFiller>
                ) : (
                  <>
                    <Dropdown
                      id="community"
                      className="col-span-full"
                      label="Community"
                      showLabel={true}
                      required={true}
                      state={createForm}
                      stateName="community"
                      onChange={setCreateForm}
                      defaultValue={createForm.community}
                    >
                      <option value="">Choose a community</option>
                      {filterChoicesCommunity.map((obj) => (
                        <option key={obj.value} value={obj.value}>{obj.label}</option>
                      ))}
                    </Dropdown>
                  </>
                )}

                {/* Title */}
                <TextInput
                  id="title"
                  type="text"
                  label="Title"
                  className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                  required={true}
                  showLabel={true}
                  state={createForm}
                  stateName="title"
                  onChange={setCreateForm}
                />

                {/* Message */}
                <TextArea
                  id="message"
                  rows={5}
                  label="Message"
                  className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                  required={true}
                  showLabel={true}
                  state={createForm}
                  stateName="message"
                  onChange={setCreateForm}
                />
              </div>
            </div>
          </div>
        </Modal>

        {/* Update / delete modal */}
        <Modal
          show={showUpdateModal}
          setShow={setShowUpdateModal}
          confirmText="Update announcement"
          cancelText="Cancel"
          confirmHandler={updateAnnouncement}
          showConfirmButton={true}
        >
          <div>
            <div className="text-center">
              <Dialog.Title
                as="h3"
                className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
              >
                Update announcement
              </Dialog.Title>
              <div className="my-10 grid grid-cols-1 gap-y-8">
                {selectedObject !== null && (
                  <>
                    {/* Priority */}
                    <RangeSlider
                      id="priority"
                      start={0}
                      end={10}
                      step={1}
                      state={selectedObject}
                      stateName="priority"
                      onChange={setSelectedObject}
                      showLabel={true}
                      label="Priority"
                    />

                    {/* Community */}
                    <Dropdown
                      id="community"
                      className="col-span-full"
                      label="Community"
                      showLabel={true}
                      required={true}
                      state={selectedObject}
                      stateName="community"
                      onChange={setSelectedObject}
                      defaultValue={selectedObject.community[0]}
                    >
                      {filterChoicesCommunity.map((opt) => (
                        <option key={opt.value} value={opt.label}>
                          {opt.label}
                        </option>
                      ))}
                    </Dropdown>

                    {/* Title */}
                    <TextInput
                      id="title"
                      type="text"
                      label="Title"
                      className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 dark:bg-zinc-700 placeholder-gray-400 px-3 py-2 dark:text-white dark:focus:bg-zinc-600 px-3 py-2 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm"
                      required={true}
                      showLabel={true}
                      state={selectedObject}
                      stateName="title"
                      onChange={setSelectedObject}
                    />

                    {/* Message */}
                    <TextArea
                      id="message"
                      label="Message"
                      className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 dark:bg-zinc-700 placeholder-gray-400 px-3 py-2 dark:text-white dark:focus:bg-zinc-600 px-3 py-2 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm"
                      required={true}
                      showLabel={true}
                      state={selectedObject}
                      stateName="message"
                      onChange={setSelectedObject}
                      rows={5}
                    />
                  </>
                )}
                {selectedObject !== null && (
                  <>
                    {/* Danger zone */}
                    <DangerZone
                      objectId={selectedObject.id}
                      handler={confirmDelete}
                      language="EN"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>

        {/* Feed of journal entries */}
        {loading ? (
          <SpaceFiller>
            <Spinner colour="info" />
          </SpaceFiller>
        ) : (
          <>
            <Filters
              language={language}
              className="pb-10 mx-1"
              filters={[
                {
                  id: "community",
                  name: "Community",
                  options: filterChoicesCommunity,
                },
              ]}
              queryParams={[
                ["community", communityId],
                ["dateRange", dateRangeTerm],
              ]}
              handlers={{
                community: {
                  state: community,
                  stateName: "value",
                  onChange: setCommunity
                },
                dateRange: {
                  state: dateRange,
                  stateName: "N/A",
                  onChange: setDateRange,
                }
              }}
              loading={loading}
              baseUrl="/entity/announcements"
              showDateRange={true}
              showFilters={true}
              showSearchBar={false}
              placeholderText={communityId === null && dateRangeTerm === null ? `Filters = Date range: ${dateRangeSelected.start_date} - ${dateRangeSelected.end_date}` : null}
            />
            {data.length > 0 ? (
              <>
                <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-6 mx-1 place-content-center">
                  {data.map((announcement: AnnouncementState) => (
                    <a key={`announcement-obj-${announcement.id}`} href="#!" onClick={(e) => selectAnnouncement(e, announcement)}>
                      <Card colour="bg-white dark:bg-zinc-800">
                        <h1 className="text-lg font-medium text-flint dark:text-white">
                          {announcement.title}
                        </h1>
                        <span className="text-slate-400 dark:text-slate-300 text-sm mb-4">{`Created @ ${announcement.datetime_created}`}</span>

                        <Pill
                          text={announcement.community[1]}
                          bgColour="primary"
                          textColour="primary"
                          icon={<UserGroupIcon className="h-4 w-4 inline-block mr-2" />}
                        />

                        <div className="py-4">
                          <span className="text-slate-400 italic dark:text-slate-300 text-sm mb-4">
                            {`"${announcement.message}"`}
                          </span>
                        </div>

                        <ProgressBar progress={announcement.priority} limit={10} />

                      </Card>
                    </a>
                  ))}
                </div>
                <Pagination
                  className="mt-10 mb-36 md:mb-0"
                  showPrevious={pagination.has_previous}
                  showNext={pagination.has_next}
                  currentPage={pagination.current_page}
                  totalPages={pagination.total_pages}
                  totalObjects={pagination.total_objects}
                  queryParams={["page", "community", "dateRange"]}
                />
              </>
            ) : (
              <SpaceFiller>
                <NoContent text={`No announcements`} subtext="You haven't created any community announcements, create one to get started!" />
              </SpaceFiller>
            )}
          </>
        )}

      </Container>
    </Template>
  );
}
