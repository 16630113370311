import { useEffect, useState } from "react";
import Header from "../layout/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Container from "../layout/Container";
import Pulse from "../layout/Pulse";
import ContentFeed from "../feed/ContentFeed";
import { getHistory } from "../../slices/diary";
import { useSearchParams } from "react-router-dom";
import Pagination from "../layout/Pagination";
import Filters from "../layout/Filters";
import generateTranslatedText from "../../utils/boilerplate";

export default function History() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const pageNum =
    searchParams.get("page") === null
      ? 1
      : parseInt(searchParams.get("page") as string);
  const dateRangeTerm = searchParams.get("dateRange");

  // Get app level state from redux store
  const { user } = useAppSelector((state) => state.auth);
  const { history: { data, pagination, loading, date_range: dateRangeSelected } } = useAppSelector((state) => state.diary);

  useEffect(() => {
    dispatch(getHistory({ pageNum, dateRange: dateRangeTerm, }));
  }, [dispatch, pageNum, dateRangeTerm]);


  // Create component level state
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  // Constant variables
  const language = user?.language || "EN";

  return (
    <Template>
      <Container className="pt-0 h-screen overflow-y-auto scrollbar-invisible">

        {/* Header */}
        <Header text={generateTranslatedText("history", language)} />

        {/* Feed of journal entries */}
        {loading ? (
          <ul className="mt-6 lg:mb-10 mb-32 mx-1">
            {Array.from({ length: 20 }, (_, i) => (
              <div className="bg-white dark:bg-zinc-900 rounded-lg mb-8 drop-shadow" key={i}>
                <Pulse type="full" />
              </div>
            ))}
          </ul>
        ) : (
          <>
            <Filters
              language={language}
              className="pb-10 mx-1"
              filters={[]}
              queryParams={[
                ["dateRange", dateRangeTerm],
              ]}
              handlers={{
                dateRange: {
                  state: dateRange,
                  stateName: "N/A",
                  onChange: setDateRange,
                },
              }}
              loading={loading}
              baseUrl="/history"
              showDateRange={true}
              showFilters={false}
              showSearchBar={false}
              placeholderText={dateRangeSelected.start_date !== null && dateRangeSelected.end_date !== null ? `${generateTranslatedText("filters_date_range_placeholder", language)}: ${dateRangeSelected.start_date} - ${dateRangeSelected.end_date}` : null}
            />
            <ContentFeed data={data} type="full" />
          </>
        )}

        <Pagination
          className="mt-10 mb-36 md:mb-0"
          showPrevious={pagination.has_previous}
          showNext={pagination.has_next}
          currentPage={pagination.current_page}
          totalPages={pagination.total_pages}
          totalObjects={pagination.total_objects}
          queryParams={["page", "dateRange"]}
        />

      </Container>

    </Template>
  );
}
