import React, { useEffect, useState } from "react";
import LargeTextInput from "../forms/LargeTextInput";
import SingleLineTextInput from "../forms/SingleLineTextInput";
import HorizontalRadio from "../forms/HorizontalRadio";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { createDiaryEntry, getDiaryEntrySubjects } from "../../slices/diary";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";
import WritingButtons from "./WritingButtons";
import Dictaphone from "../speech/VoiceNote";


export default function Writing() {
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user } = useAppSelector((state) => state.auth);
  const { options, loading } = useAppSelector((state) => state.diary);

  useEffect(() => {
    dispatch(getDiaryEntrySubjects());
  }, [dispatch]);

  // Get component level state
  const [promptText, setPromptText] = useState<number>(0);
  const [diaryEntry, setDiaryEntry] = useState<{ title: string, text: Array<{ index: number, value: string, highlighted: boolean, source: string }> }>({
    title: "",
    text: [],
  });
  const [diarySubject, setDiarySubject] = useState("NAN");
  const [burnEntry, setBurnEntry] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (writtenText.trim().length > 0) return;
      setPromptText(promptText < promptTextOptions.length - 1 ? promptText + 1 : 0);
    }, 5000);
    return () => clearInterval(timer);
  }, [diaryEntry.text, promptText]);

  // Functions
  const onDiaryEntryChange = (e: any) => {

    // If updating title
    if (e.target.name === "title") {
      setDiaryEntry({
        ...diaryEntry,
        [e.target.name]: e.target.value,
      });
      return;
    }

    // When updating text, create array
    const arrayOfWords = e.target.value.split(" ");
    const arrayOfValues = arrayOfWords.map((word: string, index: number) => ({ index, value: word, highlighted: false, source: "keyboard" }));
    setDiaryEntry({
      ...diaryEntry,
      [e.target.name]: arrayOfValues,
    });
  };

  async function submitDiaryEntry(e: any) {
    e.preventDefault();

    // Send action
    const res: any = await dispatch(
      createDiaryEntry({
        title: diaryEntry.title,
        subject: diarySubject,
        text: diaryEntry.text.map((word) => word.value).join(" "),
      })
    );
    if (res.payload.status === 201) {
      dispatch(addAlert(generateTranslatedText("diary_entry", language), "success", 2000));
      setDiarySubject("NAN");
      setDiaryEntry({ title: "", text: [] });
    } else {
      dispatch(
        addAlert(generateTranslatedText("diary_entry_error", language), "danger", 2000)
      );
    }
  }

  function burnDiaryEntry(e: any) {
    e.preventDefault();
    setBurnEntry(true);
    setDiaryEntry({ title: "", text: [] });
    setTimeout(() => {
      setBurnEntry(false);
    }, 1500);
  }

  // Constant variables
  const language = user?.language || "EN";
  const writtenText = diaryEntry.text.map((word) => word.value).join(" ");
  const promptTextOptions = [
    generateTranslatedText("writing_prompt_1", language),
    generateTranslatedText("writing_prompt_2", language),
    generateTranslatedText("writing_prompt_3", language),
    generateTranslatedText("writing_prompt_4", language),
    generateTranslatedText("writing_prompt_5", language),
    generateTranslatedText("writing_prompt_6", language),
    generateTranslatedText("writing_prompt_7", language),
    generateTranslatedText("writing_prompt_8", language),
    generateTranslatedText("writing_prompt_9", language),
    generateTranslatedText("writing_prompt_10", language),
  ];

  return (
    <div
      className="h-full min-w-0 flex-1 lg:order-last overflow-y-auto scrollbar-invisible"
    >
      <Dictaphone
        state={diaryEntry}
        setState={setDiaryEntry}
        stateName="text"
      />
      <div id="writing-pad" className={`rounded-xl my-10 mx-1 bg-white dark:bg-zinc-900 p-8 drop-shadow ${burnEntry && "burn"}`}>

        {/* Header */}
        <h1 id="primary-heading" className="sr-only">
          Daily entry
        </h1>
        <h1 className="lg:text-3xl md:text-2xl sm:text-lg text-lg font-bold text-flint dark:text-white">
          {promptTextOptions[promptText]}
        </h1>

        {/* Optional title */}
        <SingleLineTextInput
          name="title"
          placeholder={generateTranslatedText("title_placeholder", language)}
          value={diaryEntry.title}
          onChange={onDiaryEntryChange}
          required={false}
        />

        {/* Subject */}
        {!loading && (
          <HorizontalRadio
            options={options}
            state={diarySubject}
            changeHandler={setDiarySubject}
          />
        )}

        {/* Text */}
        <LargeTextInput
          name="text"
          rows={30}
          value={writtenText}
          onChange={onDiaryEntryChange}
        />

      </div>
      <WritingButtons show={writtenText.trim().length > 0} saveHandler={submitDiaryEntry} burnHandler={burnDiaryEntry} />
    </div>
  );
}
