import { ResponsiveLine } from "@nivo/line";
import { useEffect, useState } from "react";
import { getColour, getColours } from '../../../utils/colours';
import { randomIntFromInterval } from "../../../utils/random";

interface timeSeriesObservation {
  x: string;
  y: number;
}

interface lineData {
  id: string;
  data: timeSeriesObservation[];
}

const dummyData = [{ id: "activity_per_day", data: Array.from(Array(10).keys()).map(x => ({ x: x.toString(), y: randomIntFromInterval(1, 10) })) }];

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function LineChart({
  data,
  className,
  xScaleInputFormat,
  xScaleInputPrecision,
  xScaleOutputFormat,
  xScaleTickIncrement,
  padding,
  colourPalette,
  hSmall,
  hLarge,
  xAxisLinear,
  widthClass,
}: {
  data: lineData[],
  className?: string,
  xScaleInputFormat?: string,
  xScaleInputPrecision: "millisecond" | "second" | "minute" | "hour" | "day" | "month" | "year",
  xScaleOutputFormat?: string,
  xScaleTickIncrement?: string,
  padding: [number, number, number, number],
  colourPalette?: string,
  hSmall: string,
  hLarge: string;
  xAxisLinear?: boolean,
  widthClass?: string;
}) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showPlaceholder = data.length === 0
  let heightClass = hLarge;
  if (windowDimensions.width < 992) heightClass = hSmall;

  return (
    <div className={`${heightClass} chart-container overflow-auto scrollbar-invisible`}>
      <div className={`h-full ${widthClass}`}>
        {xAxisLinear ? (
          <ResponsiveLine
            data={showPlaceholder ? dummyData : data}
            margin={{ top: padding[0], right: showPlaceholder ? 40 : padding[1], bottom: padding[2], left: padding[3] }}
            curve="monotoneX"
            enableArea={true}
            areaOpacity={0.1}
            // xScale={showPlaceholder ? {type: "point"} : {
            //   reverse: true
            // }}
            xFormat={!showPlaceholder ? `time:${xScaleInputFormat}` : ""}
            axisLeft={showPlaceholder ? null : {
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              // tickValues: 5
            }}
            axisBottom={showPlaceholder ? null : {
              legendOffset: -12,
              tickRotation: -45,
              tickSize: 0,
              tickPadding: 10,
            }}
            enableGridX={false}
            enableGridY={!showPlaceholder}
            lineWidth={3}
            motionConfig="wobbly"
            enablePointLabel={false}
            pointSize={10}
            colors={showPlaceholder ? getColour("placeholder") : getColours(colourPalette)}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            useMesh={true}
            enableSlices="x"
            legends={showPlaceholder ? [] : [
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
              },
            ]}
            isInteractive={!showPlaceholder}
          />

        ) : (
          <ResponsiveLine
            data={showPlaceholder ? dummyData : data}
            margin={{ top: padding[0], right: showPlaceholder ? 40 : padding[1], bottom: padding[2], left: padding[3] }}
            curve="monotoneX"
            enableArea={true}
            areaOpacity={0.1}
            xScale={showPlaceholder ? { type: "point" } : {
              type: "time",
              format: xScaleInputFormat,
              useUTC: false,
              precision: xScaleInputPrecision,
            }}
            xFormat={!showPlaceholder ? `time:${xScaleInputFormat}` : ""}
            axisLeft={showPlaceholder ? null : {
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              // tickValues: 5
            }}
            axisBottom={showPlaceholder ? null : {
              format: xScaleOutputFormat,
              tickValues: xScaleTickIncrement,
              legendOffset: -12,
              tickRotation: -45,
              tickSize: 0,
              tickPadding: 10,
            }}
            enableGridX={false}
            enableGridY={!showPlaceholder}
            lineWidth={3}
            motionConfig="wobbly"
            enablePointLabel={false}
            pointSize={10}
            colors={showPlaceholder ? getColour("placeholder") : getColours(colourPalette)}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            useMesh={true}
            enableSlices="x"
            legends={showPlaceholder ? [] : [
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
              },
            ]}
            isInteractive={!showPlaceholder}
          />
        )}
      </div>
    </div>
  );
}

LineChart.defaultProps = {
  xScaleInputFormat: "%Y-%m-%d",
  xScaleOutputformat: "%b %d",
  xScaleTickIncrement: "every 1 days",
  colourPalette: "sentiment-chart",
  xAxisLinear: false,
  widthClass: "w-[32rem]"
}