export default function NumericChart({
  value,
  textSize,
  subText,
  colour,
  className,
  smallSuffixText,
}: {
  value: string;
  textSize?: string;
  subText?: string | null;
  colour?: string;
  className?: string;
  smallSuffixText?: string;
}) {
  return (
    <div className={`flex justify-center ${className} items-center`}>
      <h3 className={`text-${textSize} font-bold text-${colour}`}>
        {subText !== null && (
          <span className="text-5xl font-normal text-slate-400">{subText}</span>
        )}
        {value}{smallSuffixText !== undefined && smallSuffixText.length > 0 && (<span className="text-sm">{smallSuffixText}</span>)}
      </h3>
    </div>
  );
}

NumericChart.defaultProps = {
  textSize: "9xl",
  subText: null,
  colour: "black",
  className: "lg:h-64 md:h-48 h-32",
  smallSuffixText: "",
};
