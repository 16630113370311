import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";
import { buildUrl } from "../utils/proxy";

interface PlanState {
  id: string;
  datetime_created: string;
  datetime_updated: string;
  name: string;
  description: string;
  goal_type: [string, string, string];
  start_date: {
    readable: string,
    standard: string
  };
  end_date: {
    readable: string,
    standard: string
  };
  feedback: string;
  rating: number | null;
  status: string;
  complete: boolean;
}

export interface CheckInState {
  id: string;
  datetime: {
    readable: string,
    standard: string
  };
  plan: {
    id: string;
    name: string;
  }
  progress: number;
  confidence: number;
  enthusiasm: number;
  feedback: string;
}

// Define a type for the slice state
interface GoalsState {
  plans: {
    data: Array<PlanState>;
    loading: boolean;
  },
  check_ins: {
    data: Array<CheckInState>;
    loading: boolean;
  },
  options: Array<[string, string, string]>;
}

// Define the initial state using that type
const initialState: GoalsState = {
  plans: {
    data: [],
    loading: true
  },
  check_ins: {
    data: [],
    loading: true
  },
  options: []
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get goal plans
export const getGoalPlans = createAsyncThunk(
  "goal/plan/get",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/goal/plan`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get goal plan by Id
export const getGoalPlanById = createAsyncThunk(
  "goal/plan/id/get",
  async (obj: {
    planId: string | undefined;
  }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/goal/plan/${obj.planId}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Create goal plans
export const createGoalPlan = createAsyncThunk(
  "goal/plan/post",
  async (obj: {
    name: string;
    description: string;
    profile: string;
    goal_type: string;
    start_date: string;
    end_date: string;
    feedback: string | null;
    rating: number | null;
    status: string;
    complete: boolean;
  }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };

      // Build body
      const body = JSON.stringify(obj);

      const res = await axios.post(buildUrl(`/api/goal/plan`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Update goal plans
export const updateGoalPlan = createAsyncThunk(
  "goal/plan/patch",
  async (obj: {
    planId: string | undefined;
    name: string;
    description: string;
    profile: string;
    goal_type: string;
    start_date: string;
    end_date: string;
    feedback: string | null;
    rating: number | null;
    status: string;
    complete: boolean;
  }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };

      // Build body
      const body = JSON.stringify({ id: obj.planId, ...obj });

      const res = await axios.patch(buildUrl(`/api/goal/plan`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Delete goal plan
export const deleteGoalPlan = createAsyncThunk(
  "goal/plan/delete",
  async (
    obj: {
      planId: string | undefined;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const res = await axios.delete(buildUrl(`/api/goal/plan`), { data: { id: obj.planId }, headers: { Authorization: `Bearer ${auth.access}` } });
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get goal plan check ins
export const getCheckIns = createAsyncThunk(
  "goal/plan/check-in/get",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/goal/plan/check-in`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get goal plan check ins by plan ID
export const getCheckInsByPlanId = createAsyncThunk(
  "goal/plan/check-in/id/get",
  async (obj: {
    planId: string | undefined;
  }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/goal/plan/check-in/${obj.planId}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Create goal plan check in
export const createCheckIn = createAsyncThunk(
  "goal/plan/check-in/post",
  async (obj: {
    profile: string;
    plan: string | undefined;
    progress: number;
    confidence: number;
    enthusiasm: number;
    feedback: string | null;
    status: string;
  }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };

      // Build body
      const body = JSON.stringify(obj);

      const res = await axios.post(buildUrl(`/api/goal/plan/check-in`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Update goal plan check in
export const updateCheckIn = createAsyncThunk(
  "goal/plan/check-in/patch",
  async (obj: {
    planId: string | undefined;
    profile: string;
    plan: string;
    progress: number;
    confidence: number;
    enthusiasm: number;
    feedback: string | null;
    rating: number | null;
    status: string;
  }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };

      // Build body
      const body = JSON.stringify({ id: obj.planId, ...obj });

      const res = await axios.patch(buildUrl(`/api/goal/plan/check-in`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Delete goal plan check in
export const deleteCheckIn = createAsyncThunk(
  "goal/plan/check-in/delete",
  async (
    obj: {
      planId: string | undefined;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const res = await axios.delete(buildUrl(`/api/goal/plan/check-in`), { data: { id: obj.planId }, headers: { Authorization: `Bearer ${auth.access}` } });
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const goalsSlice = createSlice({
  name: "goals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get goal plans
      .addCase(getGoalPlans.pending, (state) => {
        state.plans.loading = true;
      })
      .addCase(getGoalPlans.fulfilled, (state, action) => {
        state.plans.data = action.payload.data.data;
        state.options = action.payload.data.options;
        state.plans.loading = false;
      })
      .addCase(getGoalPlans.rejected, (state) => {
        state.plans.loading = false;
      })

      // Get goal plan by ID
      .addCase(getGoalPlanById.pending, (state) => {
        state.plans.loading = true;
      })
      .addCase(getGoalPlanById.fulfilled, (state, action) => {
        state.plans.data = [action.payload.data.data];
        state.options = action.payload.data.options;
        state.plans.loading = false;
      })
      .addCase(getGoalPlanById.rejected, (state) => {
        state.plans.loading = false;
      })

      // Create goal plan
      .addCase(createGoalPlan.pending, (state) => {
        state.plans.loading = true;
      })
      .addCase(createGoalPlan.fulfilled, (state, action) => {
        state.plans.data = state.plans.data.length === 0 ? [action.payload.data] : [action.payload.data].concat(...state.plans.data);;
        state.plans.loading = false;
      })
      .addCase(createGoalPlan.rejected, (state) => {
        state.plans.loading = false;
      })

      // Update goal plan
      .addCase(updateGoalPlan.pending, (state) => {
        state.plans.loading = true;
      })
      .addCase(updateGoalPlan.fulfilled, (state, action) => {
        const resultId = action.payload.data.id;
        const resultIndex = state.plans.data?.findIndex((result) => result.id === resultId);
        if (resultIndex !== undefined && resultIndex !== -1) {
          state.plans.data![resultIndex] = action.payload.data;
        }
        state.plans.loading = false;
      })
      .addCase(updateGoalPlan.rejected, (state) => {
        state.plans.loading = false;
      })

      // Delete goal plans
      .addCase(deleteGoalPlan.pending, (state) => {
        state.plans.loading = true;
      })
      .addCase(deleteGoalPlan.fulfilled, (state, action) => {
        state.plans.data = action.payload.data;
        state.plans.loading = false;
      })
      .addCase(deleteGoalPlan.rejected, (state) => {
        state.plans.loading = false;
      })

      // Get check ins
      .addCase(getCheckIns.pending, (state) => {
        state.check_ins.loading = true;
      })
      .addCase(getCheckIns.fulfilled, (state, action) => {
        state.check_ins.data = action.payload.data;
        state.check_ins.loading = false;
      })
      .addCase(getCheckIns.rejected, (state) => {
        state.check_ins.loading = false;
      })

      // Get check ins by plan ID
      .addCase(getCheckInsByPlanId.pending, (state) => {
        state.check_ins.loading = true;
      })
      .addCase(getCheckInsByPlanId.fulfilled, (state, action) => {
        state.check_ins.data = action.payload.data;
        state.check_ins.loading = false;
      })
      .addCase(getCheckInsByPlanId.rejected, (state) => {
        state.check_ins.loading = false;
      })

      // Create check in
      .addCase(createCheckIn.pending, (state) => {
        state.check_ins.loading = true;
      })
      .addCase(createCheckIn.fulfilled, (state, action) => {
        state.check_ins.data = state.check_ins.data.length === 0 ? [action.payload.data] : [...state.check_ins.data, action.payload.data];
        state.check_ins.loading = false;
      })
      .addCase(createCheckIn.rejected, (state) => {
        state.check_ins.loading = false;
      })

      // Update check in
      .addCase(updateCheckIn.pending, (state) => {
        state.check_ins.loading = true;
      })
      .addCase(updateCheckIn.fulfilled, (state, action) => {
        const resultId = action.payload.data.id;
        const resultIndex = state.check_ins.data?.findIndex((result) => result.id === resultId);
        if (resultIndex !== undefined && resultIndex !== -1) {
          state.check_ins.data![resultIndex] = action.payload.data;
        }
        state.check_ins.loading = false;
      })
      .addCase(updateCheckIn.rejected, (state) => {
        state.check_ins.loading = false;
      })

      // Delete check in
      .addCase(deleteCheckIn.pending, (state) => {
        state.check_ins.loading = true;
      })
      .addCase(deleteCheckIn.fulfilled, (state, action) => {
        state.check_ins.data = action.payload.data;
        state.check_ins.loading = false;
      })
      .addCase(deleteCheckIn.rejected, (state) => {
        state.check_ins.loading = false;
      })

  },
});

export default goalsSlice.reducer;
