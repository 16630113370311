interface DropdownProps {
  id: string;
  className: string;
  label?: string;
  showLabel?: boolean;
  required?: boolean;
  children?: React.ReactNode;
  state?: any;
  stateName: string;
  onChange?: any;
  colour?: string;
  defaultValue: any
}

export default function Dropdown({ id, className, label, showLabel, required, children, state, stateName, onChange, colour, defaultValue }: DropdownProps) {
  return (
    <div>
      {showLabel ? (
        <label htmlFor={id} className="mb-3 block text-sm font-medium text-black dark:text-white text-left">
          {label}
        </label>
      ) : (
        <label htmlFor={id} className="mb-3 block text-sm font-medium text-black dark:text-white text-left" />
      )}
      <div className={className}>
        <select
          id={id}
          className={`block w-full appearance-none rounded-md border ${colour} dark:border-none dark:text-white dark:focus:bg-zinc-600 px-3 py-2 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm pr-8`}
          required={required}
          onChange={e => onChange({ ...state, [stateName]: e.target.value })}
          defaultValue={defaultValue}
        >
          {children}
        </select>
      </div>
    </div>
  )
}

Dropdown.defaultProps = {
  colour: "border-gray-200 bg-gray-50 dark:bg-zinc-700 placeholder-gray-400"
}
