import { FireIcon, SparklesIcon } from "@heroicons/react/24/solid";

export default function WritingButtons({show, saveHandler, burnHandler}: {show: boolean, saveHandler: (e: any) => void, burnHandler: (e: any) => void;}) {
  
  return show ? (
    <div className="z-10 fixed bottom-[90px] right-0 inline-flex items-end flex flex-col">
      {/* Burn */}
      <button
        type="button"
        className="mr-3 inline-flex items-center rounded-full border border-transparent p-2 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-danger focus:ring-offset-2 bg-danger hover:bg-danger-dark hover:underline font-medium drop-shadow"
        onClick={(e) => burnHandler(e)}
      >
        <FireIcon className="h-6 w-6 text-white mr-1" aria-hidden="true" />
        Burn
      </button>

      {/* Save */}
      <button
        type="button"
        className="mr-3 my-3 inline-flex items-center rounded-full border border-transparent py-2 px-3 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2 bg-info hover:bg-info-dark hover:underline font-medium drop-shadow"
        onClick={(e) => saveHandler(e)}
      >
        <SparklesIcon className="h-6 w-6 text-white mr-2" aria-hidden="true" />
        Save
      </button>
    </div>
  ) : null;
}
