
const lookup: any = {
  logout: {
    EN: "Goodbye, see you soon!",
  },
  logout_error: {
    EN: "Whoops, that didn't work! Try again...",
  },
  login: {
    EN: "Hello again, welcome!",
  },
  login_error: {
    EN: "Whoops, incorrect credentials!",
  },
  emotion_entry: {
    EN: "Emotion logged!",
  },
  emotion_entry_error: {
    EN: "Whoops, unable to make a log of emotion!",
  },
  diary_entry: {
    EN: "Diary entry created!",
  },
  diary_entry_error: {
    EN: "Whoops, unable to create the diary entry!",
  },
  updated_profile: {
    EN: "Your profile has been updated, well done!",
  },
  updated_profile_error: {
    EN: "Whoops, try again!",
  },
  register: {
    EN: "Welcome to Igloo! Complete verification to get started",
  },
  register_error: {
    EN: "Whoops, try again!",
  },
  missing_fields: {
    EN: "Whoops, please fill in all fields!",
  },
  auth_error: {
    EN: "Please reload the page to continue!",
  },
  create_goal_plan: {
    EN: "Goal plan created!",
  },
  create_goal_plan_error: {
    EN: "Whoops, try again!",
  },
  update_goal_plan: {
    EN: "Goal plan updated!",
  },
  delete_goal_plan: {
    EN: "Goal plan deleted!",
  },
  mark_goal_plan_complete: {
    EN: "Goal plan marked as complete!",
  },
  mark_goal_plan_incomplete: {
    EN: "Goal plan marked as not complete!",
  },
  create_check_in_button: {
    EN: "Create check in",
  },
  create_check_in: {
    EN: "Check in created!",
  },
  create_check_in_error: {
    EN: "Whoops, try again!",
  },
  create_event: {
    EN: "Calendar event created!",
  },
  update_event: {
    EN: "Calendar event updated!",
  },
  delete_calendar_event: {
    EN: "Calendar event deleted!",
  },
  upgrade_subscription: {
    EN: "To access this page, you must upgrade your subscription!",
  },
  premium_membership_cancelled: {
    EN: "Your premium membership has been cancelled!",
  },
  premium_membership_cancelled_error: {
    EN: "We had a problem when trying to cancel your Premium membership, try again!",
  },
  payment_session_portal_redirect: {
    EN: "Redirecting you to the payment portal...",
  },
  payment_session_portal_redirect_error: {
    EN: "Whoops, we had a problem when trying to redirect you to the payment portal!",
  },
  missing_email: {
    EN: "Whoops, please enter an email address!",
  },
  verification_email_sent: {
    EN: "Verification email sent, check your inbox!",
  },
  verification_email_sent_error: {
    EN: "Whoops, we had a problem when trying to send you a verification email. Try again!",
  },
  referral_email_sent: {
    EN: "Referral email sent!",
  },
  referral_email_sent_error: {
    EN: "Whoops, we had a problem when trying to send you a referral email. Try again!",
  },
  referral_email_sent_duplicate: {
    EN: "Whoops, you have already sent a referral email to this address!",
  },
  delete_account_permanently: {
    EN: "Your account has been deleted, we hope you come back soon!",
  },
  delete_account_permanently_error: {
    EN: "Whoops, we had a problem when trying to delete your account. Try again!",
  },
  missing_new_password: {
    EN: "Whoops, please enter a new password!",
  },
  missing_confirm_password: {
    EN: "Whoops, please confirm your new password!",
  },
  contact_us_email_sent: {
    EN: "Email sent, we will get back to you soon!",
  },
  contact_us_email_error: {
    EN: "Whoops, we had a problem when trying to send your email. Try again!",
  },
  email_required: {
    EN: "Whoops, please enter an email address!",
  },
  invalid_email: {
    EN: "Whoops, please enter a valid email address!",
  },
  password_recovery_email_sent: {
    EN: "Password recovery email sent, check your inbox!",
  },
  password_recovery_email_error: {
    EN: "Whoops, we had a problem when trying to send you a password recovery email. Try again!",
  },
  password_mismatch: {
    EN: "Whoops, your passwords do not match!",
  },
  password_recovery_success: {
    EN: "Password updated successfully!",
  },
  password_recovery_error: {
    EN: "Whoops, we had a problem when trying to update your password. Try again!",
  },
  password_recovery_not_allowed: {
    EN: "Whoops, this session is not allowed!",
  },
  password_recovery_invalid_session: {
    EN: "Whoops, this recovery is invalid! Generate a new recovery link.",
  },
  password_recovery_password_mismatch: {
    EN: "Whoops, your passwords do not match!",
  },
  password_recovery_invalid_password: {
    EN: "Whoops, your password is not strong enough!",
  },
  password_recovery_same_as_old: {
    EN: "Whoops, your new password is the same as your old password!",
  },
  missing_object: {
    EN: "Whoops, we had a problem when trying to find the object!",
  },
  delete_success: {
    EN: "Successfully deleted!",
  },
  delete_error: {
    EN: "Whoops, that didn't work... try again!",
  },
  missing_text: {
    EN: "Whoops, please enter some text!",
  },
  update_success: {
    EN: "Successfully updated!",
  },
  update_error: {
    EN: "Whoops, that didn't work... try again!",
  },
  register_over_18: {
    EN: "Whoops, you must be over 18 to register!",
  },
  register_agree_terms: {
    EN: "Whoops, you must agree to the terms to register!",
  },
  missing_icon: {
    EN: "Whoops, please select an icon!",
  },
  reaction_success: {
    EN: "Successfully reacted to community feed!",
  },
  reaction_error: {
    EN: "There was an issue when reacting, please try again",
  },
  thanks_success: {
    EN: "An appreciation message was sent! Keep up the great work!",
  },
  thanks_error: {
    EN: "There was an issue when sending your thanks, please try again",
  },
  notification_mark_as_read_success: {
    EN: "Notification marked as read!",
  },
  notification_mark_as_read_error: {
    EN: "There was an issue when marking the notification as read, please try again",
  },
  missing_priority: {
    EN: "Whoops, please select a priority!",
  },
  missing_community: {
    EN: "Whoops, please select a community!",
  },
  missing_title: {
    EN: "Whoops, please enter a title!",
  },
  missing_message: {
    EN: "Whoops, please enter a message!",
  },
  missing_status: {
    EN: "Whoops, please select a status!",
  },
  about_stats_header: {
    EN: "It's time we put our mental health & wellbeing at the top of our priority list."
  },
  about_stats_subheader: {
    EN: "In a modern world, it is clear the importance not only to look after our mental health but to implement pro-active practices to stay on top of it."
  },
  of_employees: {
    EN: "of Employees"
  },
  are_experiencing_anxiety: {
    EN: "are experiencing anxiety"
  },
  of_people_feel_so_stressed: {
    EN: "of people feel so stressed"
  },
  unable_to_cope: {
    EN: "they have been overwhelmed or unable to cope"
  },
  over: {
    EN: "over"
  },
  people_take_their_own_life: {
    EN: "people take their own life each year"
  },
  every_40_seconds: {
    EN: "that’s one person every 40 seconds"
  },
  about_cta_header: {
    EN: "We believe the solution is within you."
  },
  about_cta_text: {
    EN: "A proven, effective way to manage your mental health is simply to write, log your thoughts and keep track of your feelings & emotions. Sounds simple, but in the modern day we deserve a modern approach to help us get started."
  },
  this_is_where: {
    EN: "There is where "
  },
  comes_in: {
    EN: " comes in."
  },
  about_mission_header: {
    EN: "Harness the power of your words"
  },
  about_mission_subheader: {
    EN: "Our mission is to provide a safe space to express yourself at ease. We aim to build awareness, spot patterns and support you to live a happy, healthy life through writing and logging your thoughts & emotions."
  },
  learn_more: {
    EN: "Learn more"
  },
  safe_space: {
    EN: "Safe Space"
  },
  safe_space_description: {
    EN: "We provide a space for you to write anything you want without judgement. We are not here to sell your data, we are here solely to help you."
  },
  expression: {
    EN: "Expression"
  },
  expression_description: {
    EN: "It can be really hard to put your thoughts and feelings into words, we provide non-intrusive nudges and hints to help you express yourself."
  },
  awareness: {
    EN: "Awareness"
  },
  awareness_description: {
    EN: "Being aware of surroundings, events and feelings is the key to self-love and understanding. We will help you to build awareness 1 step at a time.",
  },
  safe_space_feature_subheader: {
    EN: "Write down your thoughts and feelings in a safe, private space."
  },
  safe_space_feature_2p: {
    EN: "We built Igloo to provide a simple extension to the non-judgemental, safe space environment that you get through counselling. Think of Igloo as your intelligent online safe space to support you in your mental health & wellness journey."
  },
  safe_space_feature_b1a: {
    EN: "Never any judgement."
  },
  safe_space_feature_b1b: {
    EN: " You can feel assured that Igloo is a 100% safe space for you to express yourself. Having a place to be able to write or say anything is so valuable."
  },
  safe_space_feature_b2a: {
    EN: "Your personal writing corner"
  },
  safe_space_feature_b2b: {
    EN: " You can log and see all of your personal entries at any time. The hints, recommendations & suggestions are specific and exclusively viewed by you."
  },
  safe_space_feature_b3a: {
    EN: "Security is paramount"
  },
  safe_space_feature_b3b: {
    EN: " We collect minimal personal information & use the latest security protocols and best practices to ensure that your data is encrypted and safe."
  },
  safe_space_feature_final_header: {
    EN: "Available anytime, anywhere"
  },
  safe_space_feature_final_p: {
    EN: "As well as gaining all the traditional benefits of writing, Igloo provides so much more. Igloo gives you instant feedback and keeps everything in a safe & secure place for you to pick up at anytime from any device."
  },
  expression_feature_subheader: {
    EN: "Good or bad news, life-changing events & impulsive thoughts... everything can be logged."
  },
  expression_feature_2p: {
    EN: "We provide simple tools to help you write, express emotions, categorise thoughts and factor in important context. Igloo then does all the heavy lifting to allow you to spend more time reflecting and less time worrying about where to start."
  },
  expression_feature_b1a: {
    EN: "Understand your emotions."
  },
  expression_feature_b1b: {
    EN: " Emotions can be fleeting and hard to understand. Igloo generates actionable insights to help you understand contributing factors, potential triggers and patterns."
  },
  expression_feature_b2a: {
    EN: "Build accountability."
  },
  expression_feature_b2b: {
    EN: " Without tracking, mental health progress is hard to quantify. We provide empirical evidence of your journey and help you to stay on track."
  },
  expression_feature_b3a: {
    EN: "Customise to your needs."
  },
  expression_feature_b3b: {
    EN: " We understand that everyone is different. You can customise Igloo's intelligence to your needs and preferences to provide even more personalised insights."
  },
  awareness_feature_subheader: {
    EN: "Build awareness of yourself, your environment and the subtle signals that make you tick."
  },
  awareness_feature_2p: {
    EN: "In so many scenarios whether it's relationships, work or health, simply being aware of yourself and your surroundings can be a significant factor in how things unfold."
  },
  awareness_feature_b1a: {
    EN: "Context is key."
  },
  awareness_feature_b1b: {
    EN: " Everything happens for a reason, we help you to understand from an emotional standpoint what those reasons are and how they impact you."
  },
  awareness_feature_b2a: {
    EN: "Take action."
  },
  awareness_feature_b2b: {
    EN: " We make it very easy for you to see where the root cause of things may be coming from and provide simple suggestions to help you take action."
  },
  awareness_feature_b3a: {
    EN: "Love yourself."
  },
  awareness_feature_b3b: {
    EN: " We built Igloo to help people on their mental health journeys and we strive to help people understand & love themselves, so that they can do the same for others."
  },
  awareness_feature_final_header: {
    EN: "Enabling your awakening"
  },
  awareness_feature_final_p: {
    EN: "It's easy to ignore or be oblivious to your mental wellbeing and it's causes. Igloo helps you to build awareness and understanding of yourself and to have the tools to take action when you do struggle."
  },
  free: {
    EN: "Free"
  },
  premium: {
    EN: "Premium"
  },
  free_description: {
    EN: 'Our free online safe space for everyone.',
  },
  premium_description: {
    EN: 'Advanced insight, recommended actions, intelligent suggestions and more.',
  },
  pricing_section_1: {
    "EN": "Journaling"
  },
  pricing_section_1_f1: {
    "EN": 'Free-text writing pad'
  },
  pricing_section_1_f2: {
    "EN": 'Tagging & categorisation'
  },
  pricing_section_1_f3: {
    "EN": 'Save to profile'
  },
  pricing_section_1_f4: {
    "EN": 'Instantly burn journal'
  },
  pricing_section_1_f5: {
    "EN": 'Instant emotion logging'
  },
  pricing_section_1_f6: {
    "EN": 'View history of all entries'
  },
  pricing_section_2: {
    "EN": "Goals"
  },
  pricing_section_2_f1: {
    "EN": 'Create goals for your mental health'
  },
  pricing_section_2_f2: {
    "EN": 'Instant check-ins'
  },
  pricing_section_2_f3: {
    "EN": 'Goal administration & management'
  },
  pricing_section_2_f4: {
    "EN": 'Track goal completion'
  },
  pricing_section_2_f5: {
    "EN": 'Track check-ins over time with your goals'
  },
  pricing_section_2_f6: {
    "EN": 'Progress reports and feedback'
  },
  pricing_section_3: {
    "EN": "Calendar"
  },
  pricing_section_3_f1: {
    "EN": 'View a calendar of significant events'
  },
  pricing_section_3_f2: {
    "EN": 'Manage calendar events'
  },
  pricing_section_3_f3: {
    "EN": 'Track calendar event logging'
  },
  pricing_section_3_f4: {
    "EN": 'Monitor calendar event types over time'
  },
  pricing_section_3_f5: {
    "EN": 'Visualise sentiment associated with calendar event types'
  },
  pricing_section_3_f6: {
    "EN": 'Google Calendar integration'
  },
  pricing_section_4: {
    "EN": "Profile"
  },
  pricing_section_4_f1: {
    "EN": 'View and update your personal profile'
  },
  pricing_section_4_f2: {
    "EN": 'Manage subscription tier'
  },
  pricing_section_4_f3: {
    "EN": 'Refer a friend'
  },
  pricing_section_4_f4: {
    "EN": 'Update email notifications'
  },
  pricing: {
    "EN": "Pricing"
  },
  pricing_subheader: {
    EN: "Free for everyone"
  },
  pricing_subtext: {
    EN: "Igloo is free for everyone, no payment card is needed. To access some extra special features we do rely on generous support, but feel free to try before you buy!"
  },
  per_month: {
    EN: "/month"
  },
  get_started: {
    EN: "Get started"
  },
  upgrade_for_free_trial: {
    EN: "Upgrade for a 30 day free trial"
  },
  sign_up_for_a: {
    EN: "Sign up for a "
  },
  free_account: {
    EN: "free account"
  },
  first_then_upgrade: {
    EN: " first and upgrade to Premium in "
  },
  your_profile: {
    EN: "your Profile"
  },
  pricing_plan_comparison: {
    EN: "Pricing plan comparison"
  },
  price: {
    EN: "Price"
  },
  faq_1_q: {
    EN: "Is my data safe?",
  },
  faq_1_a: {
    EN: "Yes, absolutely. We collect minimal personal information and everything is encrypted using the latest crytographic technology & standards. Furthermore, our servers are hosted on a robust platform with multiple security layers to ensure maximum security.",
  },
  faq_2_q: {
    EN: "How is my data used?",
  },
  faq_2_a: {
    EN: "We use your email to authenticate that you're a real person and to communicate with you when needed. You can specify how regularly you'd like us to reach out from your profile. The data that you create within Igloo, such as journal entries & logs of emotion are used to generate personalised insights that are only accessible to you.",
  },
  faq_3_q: {
    EN: "Does Igloo use artificial intelligence (AI)?",
  },
  faq_3_a: {
    EN: "Yes, we do. We have deep technical expertise in AI and we are committed to using AI responsibly and ethically. We do not use personal data to train AI models and our sole purpose for using AI is to create a better experience for you.",
  },
  faq_4_q: {
    EN: "How does Igloo use AI?",
  },
  faq_4_a: {
    EN: "We use AI to generate personalised insights based on your data. We also use AI to power our Recommendations feature, which provides you with personalised recommendations to help you improve your wellbeing.",
  },
  faq_5_q: {
    EN: "Will Igloo help me improve my mental health & wellbeing?",
  },
  faq_5_a: {
    EN: "There are many factors that contribute towards improving mental health & wellbeing, these could include diet, lifestyle, relationships, career etc... Igloo does not claim to fix any of these issues, but what we do provide is a simple way to track your mental health & wellbeing through an intelligent online safe space where you can write, log your feelings and document your thoughts and emotions. Through using Igloo and building healthy mental health practices, you may find that your wellbeing, awareness and self-understanding improves over time. Our goal is to support you on your mental health journey, not to solve it.",
  },
  faq_6_q: {
    EN: "What does a Premium membership offer?",
  },
  faq_6_a: {
    EN: "We designed our Free membership to include the core features that makes Igloo valuable, but our Premium membership offers an enhanced level of insights, as well as access to Premium features such as Goals, Calendar & Recommendations. You can view a comparison between the Free and Premium tier on the Pricing page.",
  },
  faq_7_q: {
    EN: "Can I cancel my Premium membership?",
  },
  faq_7_a: {
    EN: "Yes, you can cancel your Premium membership at anytime instantly through your Profile. If you cancel your Premium membership you will lose access to the Premium features and your account will automatically become a Free membership. If you cancel mid-way through a monthly billing cycle, you would have already been charged for that month but you will lose access to the Premium features immediately."
  },
  faq_8_q: {
    EN: "Can I delete my account completely?",
  },
  faq_8_a: {
    EN: "Yes, if you wish to delete your account you can do this from your Profile.",
  },
  faqs: {
    EN: "Frequently asked questions"
  },
  get_in_touch: {
    EN: "Get in touch"
  },
  send: {
    EN: "Send"
  },
  email: {
    EN: "Email"
  },
  email_placeholder: {
    EN: "What is your email address?"
  },
  subject: {
    EN: "Subject"
  },
  subject_placeholder: {
    EN: "Enter a subject for your message"
  },
  message: {
    EN: "Message"
  },
  message_placeholder: {
    EN: "What would you like to say?"
  },
  forgotten_your_password: {
    EN: "Forgotten your password?"
  },
  forgotten_your_password_subheader: {
    EN: "Submit your email below and we'll send you recovery instructions"
  },
  submit: {
    EN: "Submit"
  },
  link_expired: {
    EN: "Link expired!"
  },
  link_expired_subtext: {
    EN: "Generate a new recovery link by clicking on the button below"
  },
  submit_email_again: {
    EN: "Submit my email again"
  },
  reset_password: {
    EN: "Reset password"
  },
  reset_password_subtext: {
    EN: "Enter a new password below and submit"
  },
  new_password: {
    EN: "New password"
  },
  new_password_placeholder: {
    EN: "Enter a new password"
  },
  confirm_password: {
    EN: "Confirm password"
  },
  confirm_password_placeholder: {
    EN: "Confirm your new password"
  },
  confirm_new_password: {
    EN: "Confirm new password"
  },
  confirm_new_password_placeholder: {
    EN: "Confirm your new password"
  },
  sign_in: {
    EN: "Sign in"
  },
  sign_up: {
    EN: "Sign up"
  },
  go_to_app: {
    EN: "Go to app"
  },
  logout_button: {
    EN: "Logout"
  },
  existing_user: {
    EN: "Existing user?"
  },
  had_enough: {
    EN: "Had enough?"
  },
  writing_prompt_1: {
    EN: "How are you today?"
  },
  writing_prompt_2: {
    EN: "What's on your mind?"
  },
  writing_prompt_3: {
    EN: "What are you grateful for?"
  },
  writing_prompt_4: {
    EN: "What have you done today?"
  },
  writing_prompt_5: {
    EN: "What are you looking forward to?"
  },
  writing_prompt_6: {
    EN: "What are you struggling with?"
  },
  writing_prompt_7: {
    EN: "What are you proud of?"
  },
  writing_prompt_8: {
    EN: "What are you excited about?"
  },
  writing_prompt_9: {
    EN: "What are you anxious about?"
  },
  writing_prompt_10: {
    EN: "What are you happy about?"
  },
  title_placeholder: {
    EN: "Title"
  },
  history: {
    EN: "History"
  },
  filters_date_range_placeholder: {
    EN: "Filters = Date range"
  },
  missing_data_history: {
    EN: "I couldn't find any entries in this date range! Tell us how you're doing or log your emotions to get started 😊"
  },
  update_entry: {
    EN: "Update entry"
  },
  title: {
    EN: "Title"
  },
  text: {
    EN: "Text"
  },
  icon: {
    EN: "Icon"
  },
  danger_zone: {
    EN: "Danger zone"
  },
  danger_zone_subheader: {
    EN: "Be careful, clicking the button below will delete this object"
  },
  danger_zone_confirmation: {
    EN: "Are you sure you want to delete this object? This action cannot be undone."
  },
  danger_zone_cancel: {
    EN: "No, cancel"
  },
  danger_zone_delete: {
    EN: "Yes, delete"
  },
  date_range: {
    EN: "Date range"
  },
  date_picker_last_7_days: {
    EN: "Last 7 days"
  },
  date_picker_last_30_days: {
    EN: "Last 30 days"
  },
  date_picker_last_90_days: {
    EN: "Last 90 days"
  },
  date_picker_last_180_days: {
    EN: "Last 180 days"
  },
  date_picker_last_year: {
    EN: "Last year"
  },
  date_picker_last_3_years: {
    EN: "Last 3 years"
  },
  filter: {
    EN: "Filter"
  },
  filters: {
    EN: "Filters"
  },
  search_elipses: {
    EN: "Search..."
  },
  clear: {
    EN: "Clear"
  },
  activity: {
    EN: "Activity"
  },
  activity_by_type_subheader: {
    EN: "How many times have you made an entry?"
  },
  activity_by_subject_subheader: {
    EN: "What subjects have you been writing about?"
  },
  sentiment: {
    EN: "Sentiment"
  },
  sentiment_distribution_subheader: {
    EN: "What % of your writing was Positive, Negative & Neutral?"
  },
  sentiment_ts_subheader: {
    EN: "How did your sentiment change each day?"
  },
  keywords: {
    EN: "Keywords"
  },
  upgrade_subscription_to_view_chart: {
    EN: "You must upgrade your subscription to unlock this chart!"
  },
  keywords_clustering_subheader: {
    EN: "What are the key topics you're speaking about?"
  },
  keywords_sentiment_subheader: {
    EN: "Which keywords are you speaking about most positively and negatively?"
  },
  goals: {
    EN: "Goals"
  },
  goal_completion_pct: {
    EN: "What percentage of your goals have you completed?"
  },
  not_completed: {
    EN: "Not completed"
  },
  completed: {
    EN: "Completed"
  },
  goals_check_ins: {
    EN: "How many check-ins were made per day?"
  },
  calendar: {
    EN: "Calendar"
  },
  events_by_type: {
    EN: "What event types have been registered?"
  },
  events_sentiment: {
    EN: "What sentiment was associated with each event type?"
  },
  trends: {
    EN: "Trends"
  },
  missing_data_community_feed: {
    EN: "I couldn't find any updated in this date range! Get the community started by writing a journal! 😊"
  },
  community: {
    EN: "Community"
  },
  community_upgrade_header: {
    EN: "Want Community features?"
  },
  community_upgrade_subheader: {
    EN: "To unlock the Community page, upgrade your subscription!"
  },
  upgrade: {
    EN: "Upgrade"
  },
  announcements: {
    EN: "Announcements"
  },
  community_updates: {
    EN: "Community updates"
  },
  create_goal: {
    EN: "Create goal"
  },
  cancel: {
    EN: "Cancel"
  },
  create_new_goal_plan: {
    EN: "Create a new goal plan"
  },
  type: {
    EN: "Type"
  },
  name: {
    EN: "Name"
  },
  description: {
    EN: "Description"
  },
  start_end_date: {
    EN: "Start & end date"
  },
  goals_upgrade_header: {
    EN: "Want Goals?"
  },
  goals_upgrade_subheader: {
    EN: "To unlock the Goals page, upgrade your subscription!"
  },
  start: {
    EN: "Start"
  },
  end: {
    EN: "End"
  },
  no_goals: {
    EN: "No goals"
  },
  no_goals_subheader: {
    EN: "You haven't created any goals, create one to get started!"
  },
  goal_progress_label: {
    EN: "How much progress have you made towards your goal?"
  },
  goal_confidence_label: {
    EN: "How confident are you feeling about fulfilling this goal?"
  },
  goal_enthusiasm_label: {
    EN: "How enthusiastic are you feeling about achieving this goal?"
  },
  goal_feedback_label: {
    EN: "Try to describe your current feelings towards this goal"
  },
  create_check_in_modal: {
    EN: "Create check in"
  },
  create_check_in_for: {
    EN: "Create a check in for"
  },
  mark_as_complete: {
    EN: "Mark as complete"
  },
  mark_goal_plan: {
    EN: "Mark goal plan"
  },
  as_complete: {
    EN: "as complete"
  },
  mark_goal_plan_complete_confirmation: {
    EN: "Do you want to mark this goal plan as complete? You should do this to signify when you're satisfied that the outcomes of this goal plan have been achieved."
  },
  mark_as_incomplete: {
    EN: "Mark as incomplete"
  },
  as_incomplete: {
    EN: "as incomplete"
  },
  mark_goal_plan_incomplete_confirmation: {
    EN: "Are you sure you want to mark this goal plan as incomplete? If so, this will allow you to record check ins against this goal plan but will signify that you're still working on this plan."
  },
  check_ins: {
    EN: "Check ins"
  },
  no_check_ins: {
    EN: "No check ins"
  },
  no_check_ins_subheader: {
    EN: "You haven't created check ins for this goal plan, create one to get started!"
  },
  see_how_check_ins_for: {
    EN: "See how your check ins for"
  },
  have_changed_over_time: {
    EN: "have changed over time"
  },
  delete_goal_plan_modal: {
    EN: "Delete goal plan"
  },
  delete_goal_plan_confirmation: {
    EN: "Are you sure that you want to delete this goal plan? If you do, all progress and data related to this plan will be deleted and you won't be able to access it any more."
  },
  edit: {
    EN: "Edit"
  },
  settings: {
    EN: "Settings"
  },
  not_found: {
    EN: "Not found"
  },
  save: {
    EN: "Save"
  },
  create_event_modal: {
    EN: "Create event"
  },
  create_new_calendar_event: {
    EN: "Create a new calendar event"
  },
  name_placeholder_event: {
    EN: "Enter a name for this event"
  },
  description_placeholder_event: {
    EN: "Enter a description for this event"
  },
  event_type_default: {
    EN: "What type of event is this?"
  },
  start_hour_default: {
    EN: "Choose the starting hour"
  },
  end_hour_default: {
    EN: "Choose the ending hour"
  },
  start_minute_default: {
    EN: "Choose the starting minute"
  },
  end_minute_default: {
    EN: "Choose the ending minute"
  },
  calendar_upgrade_header: {
    EN: "Want Calendar?"
  },
  calendar_upgrade_subheader: {
    EN: "To unlock the Calendar page, upgrade your subscription!"
  },
  today: {
    EN: "Today"
  },
  go_to_today: {
    EN: "Go to today"
  },
  monday_1: {
    EN: "M"
  },
  tuesday_1: {
    EN: "T"
  },
  wednesday_1: {
    EN: "W"
  },
  thursday_1: {
    EN: "T"
  },
  friday_1: {
    EN: "F"
  },
  saturday_1: {
    EN: "S"
  },
  sunday_1: {
    EN: "S"
  },
  monday_2: {
    EN: "on"
  },
  tuesday_2: {
    EN: "ue"
  },
  wednesday_2: {
    EN: "ed"
  },
  thursday_2: {
    EN: "hu"
  },
  friday_2: {
    EN: "ri"
  },
  saturday_2: {
    EN: "at"
  },
  sunday_2: {
    EN: "un"
  },
  event_was_deleted: {
    EN: "This event was deleted!"
  },
  back_to_calendar: {
    EN: "Back to calendar"
  },
  delete_event: {
    EN: "Delete event"
  },
  delete_event_confirmation: {
    EN: "Are you sure that you want to delete this event? If you do, it will be removed from your calendar."
  },
  update_event_button: {
    EN: "Update event"
  },
  start_time: {
    EN: "Start time"
  },
  end_time: {
    EN: "End time"
  },
  notifications: {
    EN: "Notifications"
  },
  notifications_upgrade_header: {
    EN: "Want Notifications?"
  },
  notifications_upgrade_subheader: {
    EN: "To unlock the Notifications page, upgrade your subscription!"
  },
  missing_data_notifications: {
    EN: "You're all caught up! I'll let you know when you have some notifications 😊"
  },
  say_thanks: {
    EN: "Say thanks"
  },
  mark_as_seen: {
    EN: "Mark as seen"
  },
  go_to_activity: {
    EN: "Go to activity"
  },
  cancel_premium_confirm_text: {
    EN: "Yes, I want to cancel my Premium membership"
  },
  cancel_premium_cancel_text: {
    EN: "No, keep Premium membership"
  },
  cancel_premium: {
    EN: "Cancel premium membership"
  },
  cancel_premium_confirmation: {
    EN: "Are you sure that you want to cancel your Premium membership? You will lose access to Premium features at the end of the current billing cycle."
  },
  delete_account_confirm_text: {
    EN: "Yes, delete my account"
  },
  delete_account_cancel_text: {
    EN: "No, keep my account"
  },
  delete_account: {
    EN: "Delete account"
  },
  delete_account_premium_confirmation: {
    EN: "Are you sure you want to delete your account? Your Premium membership will be cancelled at the end of the current billing cycle but access to your account will ber terminated immediately. Your account data will be deleted forever."
  },
  delete_account_free_confirmation: {
    EN: "Are you sure you want to delete your account? You will no longer be able to access your account and your data will be deleted forever."
  },
  joined_igloo_on: {
    EN: " joined Igloo on"
  },
  refer_a_friend: {
    EN: "Refer a friend!"
  },
  refer_a_friend_subtext: {
    EN: "For every friend that joins, you'll receive a free month of Premium"
  },
  friend_email_placeholder: {
    EN: "Enter your friend's email address"
  },
  send_referral_link: {
    EN: "Send referral link"
  },
  or_copy_this_link: {
    EN: "Or copy this link"
  },
  subscription: {
    EN: "Subscription"
  },
  click_to_upgrade_premium: {
    EN: "Click here to upgrade to Premium"
  },
  cancel_premium_membership: {
    EN: "Cancel premium membership"
  },
  update_payment_method: {
    EN: "Update payment method"
  },
  timezone: {
    EN: "Timezone"
  },
  timezone_default: {
    EN: "What timezone are you in?"
  },
  current_password: {
    EN: "Current password"
  },
  like_to_receive_emails: {
    EN: "Would you like to receive emails?"
  },
  like_to_receive_emails_subtext: {
    EN: "This includes reminders to keep up your journalling and occasionally marketing materials."
  },
  email_frequency: {
    EN: "How often can we email you?"
  },
  email_frequency_default: {
    EN: "Choose a frequency"
  },
  calendar_auth_revoked: {
    EN: "Your Google Calendar has been disconnected!"
  },
  calendar_auth_revoked_error: {
    EN: "There was an issue when trying to disconnect your Google Calendar. Try again!"
  },
  calendar_auth_load: {
    EN: "Events from your Google Calendar have been loaded!"
  },
  calendar_auth_load_error: {
    EN: "There was an issue when trying to load your Google Calendar events. Try again!"
  },
  google_calendar_connect: {
    EN: "Connect"
  },
  google_calendar_revoke: {
    EN: "Disconnect"
  },
  google_calendar_load: {
    EN: "Load events"
  },
  calendar_settings_integration: {
    EN: "Calendar integration"
  },
  calendar_settings_integration_description: {
    EN: "Connect your Google Calendar to Igloo to view your events in one place."
  },
  landing_header: {
    EN: "Welcome to the Igloo"
  },
  landing_subheader: {
    EN: "Your intelligent online safe space for journaling, tracking your mental health and working towards a happier life."
  },
  landing_primary_features_header: {
    EN: "Find your inner peace."
  },
  landing_primary_features_subheader: {
    EN: "Through our own experience of mental health struggles, we designed Igloo to be your personalised online safe space."
  },
  landing_secondary_features_header: {
    EN: "Make positive changes"
  },
  landing_secondary_features_subheader: {
    EN: "Guidance on your journey"
  },
  landing_secondary_features_subtext: {
    EN: "In our busy lives it's not easy to be mindful and reflect on things. Igloo helps you to build awareness by considering context alongside your interactions."
  },
  landing_cta_header: {
    EN: "Write your first journal today!"
  },
  landing_cta_text: {
    EN: "Take care of your mental health. Sign up for free and start journalling straight away."
  },
  pricing_section_5: {
    "EN": "Notifications"
  },
  pricing_section_5_f1: {
    "EN": 'Receive notifications'
  },
  pricing_section_5_f2: {
    "EN": 'Send notifications'
  },
  pricing_section_5_f3: {
    "EN": 'Send gratitude messages'
  },
  pricing_section_5_f4: {
    "EN": ''
  },
  pricing_section_6: {
    "EN": "Community"
  },
  pricing_section_6_f1: {
    "EN": 'Enrol in community'
  },
  pricing_section_6_f2: {
    "EN": 'Receive community updates'
  },
  pricing_section_6_f3: {
    "EN": 'Receive community announcements'
  },
  pricing_section_6_f4: {
    "EN": 'React to community activity'
  },
  pricing_section_7: {
    "EN": 'Trends'
  },
  pricing_section_7_f1: {
    "EN": 'Track frequency of entries'
  },
  pricing_section_7_f2: {
    "EN": 'Track categorisations of journals over time'
  },
  pricing_section_7_f3: {
    "EN": 'Sentiment classification from journals'
  },
  pricing_section_7_f4: {
    "EN": 'Personal sentiment time series'
  },
  pricing_section_7_f5: {
    "EN": 'Personalised keyword hierarchy from your writing'
  },
  pricing_section_7_f6: {
    "EN": 'Positive and negative keyword clustering'
  },
  pricing_section_7_f7: {
    "EN": 'Goal completion trend analysis'
  },
  pricing_section_7_f8: {
    "EN": 'Goal check in time series'
  },
  pricing_section_7_f9: {
    "EN": 'Calendar event categorisation'
  },
  pricing_section_7_f10: {
    "EN": 'Calendar event type sentiment analysis'
  },
  no_community_header: {
    EN: "No active community"
  },
  no_community_subheader: {
    EN: "You're not a member of an active community! Contact your community leader or support to get started."
  },

};

const defaultError: any = {
  EN: "Error!"
};

function generateTranslatedText(action: string, language: string) {
  if (action in lookup) {
    if (language in lookup[action]) {
      const text = lookup[action][language];
      return text;
    }
    return defaultError[language];
  }
  return defaultError.EN;
}

export default generateTranslatedText;
