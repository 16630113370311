
interface TextInputProps {
  id: string;
  type: string;
  label?: string;
  className: string;
  ariaLabel?: string;
  placeholder?: string;
  autoComplete?: string;
  required?: boolean;
  children?: React.ReactNode;
  showLabel?: boolean;
  state?: any;
  stateName?: any;
  onChange?: any;
  disabled?: boolean;
}

export default function TextInput({ id, type, label, className, ariaLabel, placeholder, autoComplete, required, children, showLabel, state, stateName, onChange, disabled }: TextInputProps) {
  return (
    <div>
      {showLabel ? (
        <label htmlFor={id} className="mb-3 block text-sm font-medium text-black dark:text-white text-left">
          {label}
        </label>
      ) : (
        <label htmlFor={id} className="mb-3 block text-sm font-medium text-black dark:text-white text-left" />
      )}
      {disabled ? (
        <input
          id={id}
          type={type}
          className={className}
          placeholder={placeholder}
          aria-label={ariaLabel}
          autoComplete={autoComplete}
          required={required}
          onChange={e => onChange({ ...state, [stateName]: e.target.value })}
          value={state[stateName]}
          disabled
        >
          {children}
        </input>
      ) : (
        <input
          id={id}
          type={type}
          className={className}
          placeholder={placeholder}
          aria-label={ariaLabel}
          autoComplete={autoComplete}
          required={required}
          onChange={e => onChange({ ...state, [stateName]: e.target.value })}
          value={state[stateName]}
        >
          {children}
        </input>
      )}
    </div>
  )
}

TextInput.defaultProps = {
  ariaLabel: "",
  placeholder: "",
  autoComplete: "",
  required: true,
  showLabel: false,
  state: {},
  stateName: "",
  onChange: () => { },
  disabled: false
}
