import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";
import { buildUrl } from "../utils/proxy";

export interface TimeSeriesState {
  id: string;
  data: Array<{ x: string; y: number }>;
}

interface OverviewDataState {
  users: Array<{ id: number; username: string }>;
  activity: {
    by_user: { pct_active: number; num_inactive: number; };
    by_type: { id: string; label: string; value: number }[];
    by_day: TimeSeriesState[];
  };
  sentiment: {
    distribution: { id: string; label: string; value: number }[];
    time_series: TimeSeriesState[];
  };
  keywords: {
    hierarchy: {
      name: string;
      children: Array<{
        name: string;
        children: { name: string; score: number }[];
      }>;
    };
    sentiment: {
      positive: { keyword: string; score: number; compound: number }[];
      negative: { keyword: string; score: number; compound: number }[];
    }
  };
  goals: {
    completion: number;
    check_ins: {
      scores: {
        Progress: number;
        Confidence: number;
        Enthusiasm: number;
      };
      by_day: TimeSeriesState[];
    }
  };
  events: {
    type_frequency: { id: string; label: string; value: number }[];
    sentiment: Array<{ event_type: string; Positive: number; Negative: number; Neutral: number; Compound: number; }>;
  };
  date_range: {
    start_date: string | null;
    end_date: string | null;
  }
}

// Define a type for the slice state
interface OverviewState {
  data: OverviewDataState;
  loading: boolean;
}

// Define the initial state using that type
const initialState: OverviewState = {
  data: {
    users: [],
    activity: {
      by_user: { pct_active: 0, num_inactive: 0 },
      by_type: [],
      by_day: [],
    },
    sentiment: {
      distribution: [],
      time_series: [],
    },
    keywords: {
      hierarchy: {
        name: "",
        children: [],
      },
      sentiment: {
        positive: [],
        negative: [],
      }
    },
    goals: {
      completion: 0,
      check_ins: {
        scores: {
          Progress: 0,
          Confidence: 0,
          Enthusiasm: 0,
        },
        by_day: [],
      }
    },
    events: {
      type_frequency: [],
      sentiment: []
    },
    date_range: {
      start_date: null,
      end_date: null,
    }
  },
  loading: true,
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get entity community overview
export const getEntityCommunityOverview = createAsyncThunk(
  "overview/get",
  async (obj: { dateRange: string | null, user: string | null }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      const params: { [key: string]: string } = {};
      if (obj.dateRange !== null) params.dateRange = obj.dateRange;
      if (obj.user !== null) params.user = obj.user;
      const searchParams = new URLSearchParams(params);

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/entity/community/overview?${searchParams.toString()}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get entity community overview
      .addCase(getEntityCommunityOverview.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEntityCommunityOverview.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getEntityCommunityOverview.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default overviewSlice.reducer;
