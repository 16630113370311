import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";
import { buildUrl } from "../utils/proxy";
import { TimeSeriesState } from "./overview";

interface TrendDataState {
  activity: {
    by_type: { id: string; label: string; value: number }[];
    by_subject: { id: string; label: string; value: number }[];
  };
  sentiment: {
    distribution: { id: string; label: string; value: number }[];
    time_series: TimeSeriesState[];
  };
  keywords: {
    hierarchy: {
      name: string;
      children: Array<{
        name: string;
        children: { name: string; score: number }[];
      }>;
    };
    sentiment: {
      positive: { keyword: string; score: number; compound: number }[];
      negative: { keyword: string; score: number; compound: number }[];
    }
  };
  goals: {
    completion: number;
    check_ins: {
      scores: {
        Progress: any;
        Confidence: any;
        Enthusiasm: any;
      };
      by_day: TimeSeriesState[];
    }
  };
  events: {
    type_frequency: { id: string; label: string; value: number }[];
    sentiment: Array<{ event_type: string; Positive: number; Negative: number; Neutral: number; Compound: number; }>;
  };
  date_range: {
    start_date: string | null;
    end_date: string | null;
  }
}

// Define a type for the slice state
interface TrendsState {
  data: TrendDataState;
  loading: boolean;
}

// Define the initial state using that type
const initialState: TrendsState = {
  data: {
    activity: {
      by_type: [],
      by_subject: [],
    },
    sentiment: {
      distribution: [],
      time_series: [],
    },
    keywords: {
      hierarchy: {
        name: "",
        children: [],
      },
      sentiment: {
        positive: [],
        negative: [],
      }
    },
    goals: {
      completion: 0,
      check_ins: {
        scores: {
          Progress: 0,
          Confidence: 0,
          Enthusiasm: 0,
        },
        by_day: [],
      }
    },
    events: {
      type_frequency: [],
      sentiment: []
    },
    date_range: {
      start_date: null,
      end_date: null,
    }
  },
  loading: true,
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get trends
export const getTrends = createAsyncThunk(
  "trends/get",
  async (obj: { dateRange: string | null }, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      const params: { [key: string]: string } = {};
      if (obj.dateRange !== null) params.dateRange = obj.dateRange;
      const searchParams = new URLSearchParams(params);

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/trends?${searchParams.toString()}`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const trendsSlice = createSlice({
  name: "trends",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get trends
      .addCase(getTrends.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTrends.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getTrends.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default trendsSlice.reducer;
