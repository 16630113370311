import {
  ExclamationCircleIcon,
  HandThumbUpIcon as HandThumbUpIconSolid,
  CakeIcon as CakeIconSolid,
  FireIcon as FireIconSolid,
  HeartIcon as HeartIconSolid,
  RocketLaunchIcon as RocketLaunchIconSolid,
  TrophyIcon as TrophyIconSolid
} from "@heroicons/react/24/solid";
import {
  HandThumbUpIcon as HandThumbUpIconOutline,
  CakeIcon as CakeIconOutline,
  FireIcon as FireIconOutline,
  HeartIcon as HeartIconOutline,
  RocketLaunchIcon as RocketLaunchIconOutline,
  TrophyIcon as TrophyIconOutline
} from "@heroicons/react/24/outline";
import { getColour } from "../../utils/colours";
import { getSolidIcon } from "../../utils/icons";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";
import { CommunityFeedState, createReactionToCommunityItem, NotificationState } from "../../slices/community";
import Pill from "../layout/Pill";
import { capitalise } from "../../utils/text";
import Card from "../layout/Card";
import ProgressBar from "../layout/ProgressBar";

export default function CommunityFeed({
  data,
  type = "small",
}: {
  data: Array<CommunityFeedState>;
  type?: string;
}) {

  const dispatch = useAppDispatch();

  // Get redux store state
  const { user } = useAppSelector((state) => state.auth);

  // Functions
  function generateDescription(item: CommunityFeedState) {
    const username = item.user[1];
    const appLabel = item.content_type.app_label;
    const model = item.content_type.model;
    // const objectRepr = item.object_repr;
    const actionFlag = item.action_flag;
    // const changeMessage = item.change_message;
    if (appLabel === "diary") {
      if (model === "entry") {
        if (actionFlag === 1) return `${username} created a diary entry`;
        return `${username} updated a diary entry`;
      }
    }
    if (appLabel === "emotion") {
      if (model === "entry") {
        if (actionFlag === 1) return `${username} expressed emotion`
        return `${username} updated their emotion`
      }
    }
    if (appLabel === "events") {
      if (model === "event") {
        if (actionFlag === 1) return `${username} created an event`
        return `${username} updated an event`
      }
    }
    if (appLabel === "goals") {
      if (model === "plan") {
        if (actionFlag === 1) return `${username} created a goal plan`
        return `${username} updated a goal plan`
      }
      if (model === "checkin") {
        if (actionFlag === 1) return `${username} checked in with a goal`
        return `${username} updated a goal check in`
      }
    }
    return null;
  }

  function generateChangeContent(item: CommunityFeedState) {
    // const username = item.user[1];
    const appLabel = item.content_type.app_label;
    const model = item.content_type.model;
    const objectRepr = item.object_repr;
    // const actionFlag = item.action_flag;
    const changeMessage = item.change_message;
    if (appLabel === "diary") {
      if (model === "entry") {
        return null;
      }
    }
    if (appLabel === "emotion") {
      if (model === "entry") {
        return objectRepr;
      }
    }
    if (appLabel === "events") {
      if (model === "event") {
        return null;
      }
    }
    if (appLabel === "goals") {
      if (model === "plan") {
        return null;
      }
      if (model === "checkin") {
        return (
          <>
            {/* Progress */}
            <span className="mt-3 block text-sm font-medium text-gray-700 dark:text-gray-300 text-left">
              Progress
            </span>
            <ProgressBar
              progress={changeMessage.progress}
              limit={10}
              marginY="2"
            />

            {/* Confidence */}
            <span className="mt-3 block text-sm font-medium text-gray-700 dark:text-gray-300 text-left">
              Confidence
            </span>
            <ProgressBar
              progress={changeMessage.confidence}
              limit={10}
              marginY="2"
            />

            {/* Enthusiasm */}
            <span className="mt-3 block text-sm font-medium text-gray-700 dark:text-gray-300 text-left">
              Enthusiasm
            </span>
            <ProgressBar
              progress={changeMessage.enthusiasm}
              limit={10}
              marginY="2"
            />
          </>
        )
      }
    }
    return null;
  }

  async function handleReactionClick(e: any, log: CommunityFeedState, reactionType: string) {
    e.preventDefault();
    if (user === null) return;

    const res: any = await dispatch(createReactionToCommunityItem({
      sentBy: user?.profile.id,
      recipient: log.user[0],
      associatedLog: log.id,
      type: "R",
      action: reactionType,
      message: `Reacting to community item with ${reactionType}`,
    }));
    if (res.payload.status === 201) {
      dispatch(addAlert(generateTranslatedText("reaction_success", language), "success"));
    } else {
      dispatch(addAlert(generateTranslatedText("reaction_error", language), "danger"));
    }
  }

  // Constant variables
  const language = user?.language || "EN";
  const missingDataString = generateTranslatedText("missing_data_community_feed", language)

  return data.length === 0 ? (
    <div className="flow-root">
      <ul className="-mb-8">
        {type === "full" && (
          <li>
            <div className="relative bg-white drop-shadow dark:bg-zinc-900 p-8 mb-8 rounded-lg mx-1">
              <div className="relative flex items-start space-x-3">
                <div className="relative">
                  <div className="flex m-1 h-8 w-8 items-center justify-center rounded-full bg-white ring-8 ring-white text-black animate-bounce">
                    <ExclamationCircleIcon
                      className="h-8 w-8"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="min-w-0 flex-1">
                  <div className="mt-2 text-sm text-slate-600 dark:text-slate-300">
                    <p>{missingDataString}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
  ) : (
    <>
      {/* Feed */}
      <div className="flow-root">
        <ul className="-mb-8">
          {type === "full" &&
            data.map((item, itemIdx) => (
              <li key={item.id}>
                <Card colour="bg-white dark:bg-zinc-900 mx-1 group mb-8">

                  <div className="flex items-start">
                    {/* App label */}
                    <Pill
                      text={capitalise(item.content_type.app_label)}
                      bgColour={getColour(item.content_type.app_label)}
                      textColour={getColour(item.content_type.app_label)}
                      icon={getSolidIcon(item.content_type.app_label, getColour(item.content_type.app_label), 3, "mr-2")}
                    />
                  </div>

                  {/* Header */}
                  <h1 className="text-lg font-medium text-flint dark:text-white">
                    {generateDescription(item)}
                    {/* {plan.complete && <CheckCircleIcon className="h-5 w-5 inline-block ml-2 text-green-500" />} */}
                  </h1>
                  <span className="text-slate-400 dark:text-slate-300 text-sm mb-4">{item.action_time}</span>

                  {/* Change content */}
                  <div>
                    {generateChangeContent(item)}
                  </div>

                  {/* Reaction bar */}
                  {item.user[0] !== user?.profile.id && (
                    <div className="grid grid-cols-3 border-t grid-rows-2 gap-y-4 sm:gap-y-0 sm:grid-cols-6 sm:grid-rows-1 mt-4 pt-4">

                      {/* Like */}
                      <div className="flex justify-center">
                        <a className="transition ease-in-out delay-50 hover:scale-125 duration-300" href="#!" onClick={(e) => handleReactionClick(e, item, "LIKE")}>
                          {item.notifications.filter((x: NotificationState) => x.action[0] === "LIKE" && x.sent_by[0] === user?.profile.id).length > 0 ? (
                            <HandThumbUpIconSolid className="h-5 w-5 text-primary"></HandThumbUpIconSolid>
                          ) : (
                            <HandThumbUpIconOutline className="h-5 w-5 text-slate-400"></HandThumbUpIconOutline>
                          )}
                        </a>
                      </div>

                      {/* Heart */}
                      <div className="flex justify-center">
                        <a className="transition ease-in-out delay-50 hover:scale-125 duration-300" href="#!" onClick={(e) => handleReactionClick(e, item, "HRT")}>
                          {item.notifications.filter((x: NotificationState) => x.action[0] === "HRT" && x.sent_by[0] === user?.profile.id).length > 0 ? (
                            <HeartIconSolid className="h-5 w-5 text-love"></HeartIconSolid>
                          ) : (
                            <HeartIconOutline className="h-5 w-5 text-slate-400"></HeartIconOutline>
                          )}
                        </a>
                      </div>

                      {/* Fire */}
                      <div className="flex justify-center">
                        <a className="transition ease-in-out delay-50 hover:scale-125 duration-300" href="#!" onClick={(e) => handleReactionClick(e, item, "FIRE")}>
                          {item.notifications.filter((x: NotificationState) => x.action[0] === "FIRE" && x.sent_by[0] === user?.profile.id).length > 0 ? (
                            <FireIconSolid className="h-5 w-5 text-danger"></FireIconSolid>
                          ) : (
                            <FireIconOutline className="h-5 w-5 text-slate-400"></FireIconOutline>
                          )}
                        </a>
                      </div>

                      {/* Rocket launch */}
                      <div className="flex justify-center">
                        <a className="transition ease-in-out delay-50 hover:scale-125 duration-300" href="#!" onClick={(e) => handleReactionClick(e, item, "RKT")}>
                          {item.notifications.filter((x: NotificationState) => x.action[0] === "RKT" && x.sent_by[0] === user?.profile.id).length > 0 ? (
                            <RocketLaunchIconSolid className="h-5 w-5 text-info"></RocketLaunchIconSolid>
                          ) : (
                            <RocketLaunchIconOutline className="h-5 w-5 text-slate-400"></RocketLaunchIconOutline>
                          )}
                        </a>
                      </div>

                      {/* Trophy */}
                      <div className="flex justify-center">
                        <a className="transition ease-in-out delay-50 hover:scale-125 duration-300" href="#!" onClick={(e) => handleReactionClick(e, item, "TRY")}>
                          {item.notifications.filter((x: NotificationState) => x.action[0] === "TRY" && x.sent_by[0] === user?.profile.id).length > 0 ? (
                            <TrophyIconSolid className="h-5 w-5 text-warning"></TrophyIconSolid>
                          ) : (
                            <TrophyIconOutline className="h-5 w-5 text-slate-400"></TrophyIconOutline>
                          )}
                        </a>
                      </div>

                      {/* Cake */}
                      <div className="flex justify-center">
                        <a className="transition ease-in-out delay-50 hover:scale-125 duration-300" href="#!" onClick={(e) => handleReactionClick(e, item, "CEL")}>
                          {item.notifications.filter((x: NotificationState) => x.action[0] === "CEL" && x.sent_by[0] === user?.profile.id).length > 0 ? (
                            <CakeIconSolid className="h-5 w-5 text-success"></CakeIconSolid>
                          ) : (
                            <CakeIconOutline className="h-5 w-5 text-slate-400"></CakeIconOutline>
                          )}
                        </a>
                      </div>

                    </div>
                  )}
                </Card>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
