import { ResponsivePie } from "@nivo/pie";
import { getColour, getColours } from "../../../utils/colours";
import { randomIntFromInterval } from "../../../utils/random";

export interface DonutChartData {
  id: string | number;
  label: string;
  value: number;
}

interface DonutChartProps {
  data: DonutChartData[];
  className?: string;
  colours?: string[] | null;
}

const pct1 = randomIntFromInterval(0, 50);
const pct2 = randomIntFromInterval(pct1, 45);
const pct3 = 100 - pct1 - pct2;

const dummyData = [
  {
    id: "1",
    label: "No data yet!",
    value: pct1,
  },
  {
    id: "2",
    label: "No data yet!",
    value: pct2,
  },
  {
    id: "3",
    label: "No data yet!",
    value: pct3,
  },
];

export default function DonutChart({
  data,
  className,
  colours,
}: DonutChartProps) {
  const showPlaceholder = data.length === 0;
  return (
    <div className={className}>
      <ResponsivePie
        data={showPlaceholder ? dummyData : data}
        margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
        valueFormat=" >-"
        sortByValue={true}
        innerRadius={0.45}
        padAngle={2}
        cornerRadius={8}
        activeOuterRadiusOffset={8}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        colors={
          showPlaceholder
            ? getColour("placeholder")
            : colours !== null
            ? colours
            : getColours("donut-chart")
        }
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextOffset={5}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsOffset={-11}
        arcLinkLabelsDiagonalLength={23}
        arcLinkLabelsStraightLength={13}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        motionConfig="wobbly"
        transitionMode="centerRadius"
        legends={[]}
        enableArcLinkLabels={!showPlaceholder}
        enableArcLabels={!showPlaceholder}
        isInteractive={!showPlaceholder}
      />
    </div>
  );
}

DonutChart.defaultProps = {
  className: "",
  colours: null,
};
