import { configureStore } from "@reduxjs/toolkit";
import alertSlice from "./slices/alert";
import authSlice from "./slices/auth";
import emotionSlice from "./slices/emotion";
import diarySlice from "./slices/diary";
import trendsSlice from "./slices/trends";
import goalsSlice from "./slices/goals";
import calendarSlice from "./slices/calendar";
import paymentSlice from "./slices/payment";
import overviewSlice from "./slices/overview";
import communitySlice from "./slices/community"
import notificationSlice from "./slices/notifications"
import announcementsSlice from "./slices/announcements"

export const store = configureStore({
  reducer: {
    alert: alertSlice,
    auth: authSlice,
    emotion: emotionSlice,
    diary: diarySlice,
    trends: trendsSlice,
    goals: goalsSlice,
    calendar: calendarSlice,
    payment: paymentSlice,
    overview: overviewSlice,
    community: communitySlice,
    notifications: notificationSlice,
    announcements: announcementsSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
