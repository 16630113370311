import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import { updatePasswordByRecovery, verifyPasswordRecoverySession } from "../../slices/auth";
import generateTranslatedText from "../../utils/boilerplate";
import { LifebuoyIcon, PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../layout/Spinner";

export default function ResetPasswordForm() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  // Get app level state from redux store
  const { user, email: { recovery: { loading, verified } } } = useAppSelector((state) => state.auth);

  // Get query parameters
  const profileId = searchParams.get("profile");
  const sessionId = searchParams.get("session");

  useEffect(() => {
    dispatch(verifyPasswordRecoverySession({ profileId, sessionId }));
  }, [profileId, sessionId]);

  // Component level state
  const [state, setState] = useState({
    password: "",
    confirm_password: "",
  });

  // Functions
  const onChange = (e: any) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

  async function submitNewPassword(e: any) {
    e.preventDefault();

    const { password, confirm_password: confirmPassword } = state;

    // Validate length
    if (password === "") {
      dispatch(addAlert(generateTranslatedText("missing_new_password", language), "danger"));
      return;
    }
    if (confirmPassword === "") {
      dispatch(addAlert(generateTranslatedText("missing_confirm_password", language), "danger"));
      return;
    }

    // Validate if passwords match
    if (password !== confirmPassword) {
      dispatch(addAlert(generateTranslatedText("password_mismatch", language), "danger"));
      return;
    }

    // Submit passwords for update
    const res: any = await dispatch(updatePasswordByRecovery({ profileId, password, confirmPassword, sessionId }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("password_recovery_success", language), "success"));
      navigate("/login")
      return;
    }
    if (res.payload.message !== undefined) {
      dispatch(addAlert(generateTranslatedText(res.payload.message, language), "danger"));
      return;
    }
    dispatch(addAlert(generateTranslatedText("password_recovery_error", language), "danger"));
  }

  // Constant variables
  const language = user?.language || "EN";

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      {loading ? <Spinner /> : !verified ? (
        <div className="bg-white dark:bg-flint rounded-xl p-8 w-full max-w-md space-y-8 drop-shadow">
          <div>
            <a href="/">
              <img
                className="mx-auto h-12 w-auto rounded-lg"
                src="/img/logo/logo_coloured_bg.svg"
                alt="Igloo"
              />
            </a>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-flint dark:text-white">
              {generateTranslatedText("link_expired", language)}
            </h2>
            <p className="text-sm text-center mt-2 dark:text-light text-gray italic">{generateTranslatedText("link_expired_subtext", language)}</p>
          </div>
          <a
            type="button"
            href="/forgot-my-password"
            className="group relative flex w-full justify-center rounded-full border border-transparent bg-info py-2 px-4 text-sm font-medium text-white hover:bg-info-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <PaperAirplaneIcon
                className="h-5 w-5 fill-info-light group-hover:fill-white"
                aria-hidden="true"
              />
            </span>
            {generateTranslatedText("submit_email_again", language)}
          </a>
        </div>
      ) : (
        <div className="bg-white dark:bg-flint rounded-xl p-8 w-full max-w-md space-y-8 drop-shadow">
          <div>
            <a href="/">
              <img
                className="mx-auto h-12 w-auto rounded-lg"
                src="/img/logo/logo_coloured_bg.svg"
                alt="Igloo"
              />
            </a>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-flint dark:text-white">
              {generateTranslatedText("reset_password", language)}
            </h2>
            <p className="text-sm text-center mt-2 dark:text-light text-gray italic">{generateTranslatedText("reset_password_subtext", language)}</p>
          </div>
          <form
            className="mt-8 space-y-4"
            onSubmit={(e) => submitNewPassword(e)}
          >

            {/* New password */}
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="new-password" className="sr-only">
                  {generateTranslatedText("new_password", language)}
                </label>
                <input
                  id="new-password"
                  type="password"
                  name="password"
                  required
                  className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-flint placeholder-gray-500 focus:z-10 focus:border-info focus:outline-none focus:ring-info sm:text-sm dark:text-white"
                  placeholder={generateTranslatedText("new_password_placeholder", language)}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>

            {/* Confirm password */}
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="confirm-password" className="sr-only">
                  {generateTranslatedText("confirm_password", language)}
                </label>
                <input
                  id="confirm-password"
                  type="password"
                  name="confirm_password"
                  required
                  className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-flint placeholder-gray-500 focus:z-10 focus:border-info focus:outline-none focus:ring-info sm:text-sm dark:text-white"
                  placeholder={generateTranslatedText("confirm_password_placeholder", language)}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>

            {/* Submit button */}
            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-full border border-transparent bg-info py-2 px-4 text-sm font-medium text-white hover:bg-info-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LifebuoyIcon
                    className="h-5 w-5 fill-info-light group-hover:fill-white"
                    aria-hidden="true"
                  />
                </span>
                {generateTranslatedText("submit", language)}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
