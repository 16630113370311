import { RadioGroup } from "@headlessui/react";
import { getColour, getTextColour } from "../../utils/colours";
import Pill from "../layout/Pill";
import { getSolidIcon } from "../../utils/icons";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function HorizontalRadio({
  options,
  state,
  changeHandler,
}: {
  options: Array<[string, string]>;
  state: any;
  changeHandler: any;
}) {

  return (
    <div className="overflow-x-auto scrollbar-invisible pb-3 pl-2"> 
      <div className="mt-4 flex items-center space-x-3">
        {options.map((opt, idx) => (
          <Pill
            key={opt[0]}
            text={opt[1]} 
            bgColour={getColour(opt[0])} 
            textColour={getColour(opt[0])} 
            icon={getSolidIcon(opt[0], getColour(opt[0]), 3, "mr-2")} 
            onClick={changeHandler}
            onClickArg={opt[0]}
            active={opt[0] === state}
            className={idx === options.length - 1 ? "pr-2" : ""}
          />
        ))}
      </div>
    </div>
  );
}
