
interface TextAreaProps {
  id: string;
  rows?: number;
  label?: string;
  className: string;
  ariaLabel?: string;
  placeholder?: string;
  autoComplete?: string;
  required?: boolean;
  children?: React.ReactNode;
  showLabel?: boolean;
  state?: any;
  stateName?: any;
  onChange?: any;
}

export default function TextArea({ id, rows, label, className, ariaLabel, placeholder, autoComplete, required, children, showLabel, state, stateName, onChange }: TextAreaProps) {
  return (
    <div>
      {showLabel ? (
        <label htmlFor={id} className="mb-3 block text-sm font-medium text-gray-700 dark:text-white text-left">
          {label}
        </label>
      ) : (
        <label htmlFor={id} className="mb-3 block text-sm font-medium text-gray-700 dark:text-white text-left" />
      )}
      <textarea
        id={id}
        rows={rows}
        className={className}
        placeholder={placeholder}
        aria-label={ariaLabel}
        autoComplete={autoComplete}
        required={required}
        onChange={e => onChange({ ...state, [stateName]: e.target.value })}
        value={state[stateName]}
      >
        {children}
      </textarea>
    </div>
  )
}

TextArea.defaultProps = {
  rows: 3,
  ariaLabel: "",
  placeholder: "",
  autoComplete: "",
  required: true,
  showLabel: false,
  state: {},
  stateName: "",
  onChange: () => { },
}
