import { useAppDispatch, useAppSelector } from "../../hooks";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function PrivateRoute({
  allowedGroups = [],
}: {
  allowedGroups: string[];
}) {
  const dispatch = useAppDispatch();
  const { isAuthenticated, loading, user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {

    // If loading, do nothing
    if (loading) return;

    // If user is null after loading, redirect to login
    if (user === null) {
      navigate("/login");
      return;
    }

    // If not authenticated, redirect to login
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }

    // If user is not verified, redirect to verification
    if (!user.verified) {
      navigate("/verification");
    }

    // Check if user auth groups allows access to this page. If not, redirect to 404
    const groupCheck = user.auth.groups.filter((group) =>
      allowedGroups.includes(group[1]),
    );
    if (groupCheck.length === 0) {
      navigate("/404");
      return;
    }
  }, [navigate, isAuthenticated, loading, user, dispatch]);

  return <Outlet />;
}

PrivateRoute.defaultProps = {
  subscription: "free"
};