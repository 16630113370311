import { useEffect, useState } from "react";
import Header from "../layout/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Template from "../app/Template";
import Container from "../layout/Container";
import Card from "../layout/Card";
import { CalendarDaysIcon, Cog8ToothIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import Button from "../layout/Button";
import getButtonStyle from "../../utils/styles";
import ActionButtons from "../layout/ActionButtons";
import Modal from "../layout/Modal";
import { Dialog } from "@headlessui/react";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";
import { createCalendarEvent, getCalendarEvents } from "../../slices/calendar";
import EventCalendar from "./EventCalendar";
import { useSearchParams } from "react-router-dom";
import { formatDateStandard } from "../../utils/datetime";
import TextInput from "../layout/TextInput";
import TextArea from "../layout/TextArea";
import Dropdown from "../layout/Dropdown";
import Datepicker from "react-tailwindcss-datepicker";
import SpaceFiller from "../layout/SpaceFiller";
import Spinner from "../layout/Spinner";
import NoContent from "../layout/NoContent";
import Link from "../layout/Link";
import { KeyIcon } from "@heroicons/react/24/solid";

export default function Calendar() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  // Get app level state from redux store
  const { data: calendarData, loading: calendarLoading, options: calendarOptions, date_range: calendarDateRange } = useAppSelector(
    (state) => state.calendar
  );
  const { user: userData } = useAppSelector((state) => state.auth);

  // Get query parameters
  const now = new Date()
  const formattedNow = formatDateStandard(now)
  const by = searchParams.get("by") !== "month" ? "month" : searchParams.get("by");
  let date = searchParams.get("date") === null ? formattedNow.slice(0, -3) : searchParams.get("date");
  if (date !== null && date.length > 7) date = date.slice(0, -3);

  useEffect(() => {
    dispatch(getCalendarEvents({ filterBy: by, filterDate: date }));
  }, [by, date, dispatch]);

  // Get component level state
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [createEventData, setCreateEventData] = useState({
    name: "",
    description: "",
    event_type: "",
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });
  const [timeRange, setTimeRange] = useState({
    startHour: null,
    startMinute: null,
    endHour: null,
    endMinute: null,
  });

  // Functions
  const handleDateChange = (newValue: any) => {
    setDateRange(newValue);
  }

  function openCreateEventModal(e: any) {
    e.preventDefault();
    setShowCreateModal(true);
  }

  async function confirmEventCreate(e: any) {
    e.preventDefault();
    if (userData === null) {
      dispatch(addAlert(generateTranslatedText("auth_error", language), "danger"));
      return;
    };
    // Extract text
    const { name, description, event_type: eventType } = createEventData;

    // Extract dates
    const { startDate, endDate } = dateRange;
    if (startDate === null || endDate === null) {
      dispatch(addAlert(generateTranslatedText("missing_fields", language), "danger"));
      return;
    }

    // Extract times
    const { startHour, startMinute, endHour, endMinute } = timeRange;

    // Build datetimes
    const startDatetime = `${startDate} ${startHour}:${startMinute}`;
    const endDatetime = `${endDate} ${endHour}:${endMinute}`;

    const res: any = await dispatch(createCalendarEvent({
      name,
      description,
      profile: userData.profile.id,
      event_type: eventType,
      start_date: startDatetime,
      end_date: endDatetime,
      source: "IG",
      secondary_id: "",
      status: "A",
    }));
    if (res.payload.status === 201) {
      dispatch(addAlert(generateTranslatedText("create_event", language), "success"));
    } else {
      dispatch(addAlert(res.payload.data.response, "danger"));
    }
    setShowCreateModal(false);
    setCreateEventData({
      name: "",
      description: "",
      event_type: "",
    });
    setDateRange({
      startDate: null,
      endDate: null
    });
    setTimeRange({
      startHour: null,
      startMinute: null,
      endHour: null,
      endMinute: null,
    });
  }

  // Constant variables
  const language = userData?.language || "EN";
  const hours = Array.from(Array(24).keys());
  const minutes = Array.from(Array(60).keys());

  return (
    <Template>

      {/* Create calendar event */}
      <Modal
        show={showCreateModal}
        setShow={setShowCreateModal}
        confirmText={generateTranslatedText("create_event_modal", language)}
        cancelText={generateTranslatedText("cancel", language)}
        confirmHandler={confirmEventCreate}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
              {generateTranslatedText("create_new_calendar_event", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">

              {/* Name */}
              <TextInput
                id="name"
                type="text"
                label={generateTranslatedText("name", language)}
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                required={true}
                showLabel={true}
                state={createEventData}
                placeholder={generateTranslatedText("name_placeholder_event", language)}
                stateName="name"
                onChange={setCreateEventData}
              />

              {/* Description */}
              <TextArea
                id="description"
                label={generateTranslatedText("description", language)}
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                required={true}
                showLabel={true}
                state={createEventData}
                placeholder={generateTranslatedText("description_placeholder_event", language)}
                stateName="description"
                onChange={setCreateEventData}
              />

              {/* Event type */}
              <Dropdown
                id="event_type"
                className="col-span-full"
                label={generateTranslatedText("type", language)}
                showLabel={true}
                required={true}
                state={createEventData}
                stateName="event_type"
                onChange={setCreateEventData}
                defaultValue={createEventData.event_type}
              >
                <option value="">{generateTranslatedText("event_type_default", language)}</option>
                {calendarOptions?.map((opt) => (
                  <option key={opt[0]} value={opt[0]}>{opt[1]}</option>
                ))}
              </Dropdown>

              {/* Start and end date */}
              <div className="date-picker-wrapper w-full">
                <label className="mb-3 block text-sm font-medium text-gray-700 dark:text-white text-left">
                  {generateTranslatedText("start_end_date", language)}
                </label>
                <Datepicker
                  primaryColor={"indigo"}
                  value={dateRange}
                  onChange={handleDateChange}
                  showShortcuts={false}
                />
              </div>

              {/* Start time */}
              <div>
                <span className="block text-sm font-medium text-gray-700 dark:text-white text-left">Start time</span>
                <div className="grid grid-cols-2 gap-6">
                  <Dropdown
                    id="start_time_hour"
                    className="col-span-full"
                    label=""
                    showLabel={false}
                    required={true}
                    state={timeRange}
                    stateName="startHour"
                    onChange={setTimeRange}
                    defaultValue={timeRange.startHour}
                  >
                    <option value="">{generateTranslatedText("start_hour_default", language)}</option>
                    {hours.map((opt) => (
                      <option key={opt} value={`${opt < 10 ? `0${opt}` : opt}`}>{`${opt < 10 ? `0${opt}` : opt}`}</option>
                    ))}
                  </Dropdown>
                  <Dropdown
                    id="start_time_minute"
                    className="col-span-full"
                    label=""
                    showLabel={false}
                    required={true}
                    state={timeRange}
                    stateName="startMinute"
                    onChange={setTimeRange}
                    defaultValue={timeRange.startMinute}
                  >
                    <option value="">{generateTranslatedText("start_minute_default", language)}</option>
                    {minutes.map((opt) => (
                      <option key={opt} value={`${opt < 10 ? `0${opt}` : opt}`}>{`${opt < 10 ? `0${opt}` : opt}`}</option>
                    ))}
                  </Dropdown>
                </div>
              </div>

              {/* End time */}
              <div>
                <span className="block text-sm font-medium text-gray-700 dark:text-white text-left">End time</span>
                <div className="grid grid-cols-2 gap-6">
                  <Dropdown
                    id="end_time_hour"
                    className="col-span-full"
                    label=""
                    showLabel={false}
                    required={true}
                    state={timeRange}
                    stateName="endHour"
                    onChange={setTimeRange}
                    defaultValue={timeRange.endHour}
                  >
                    <option value="">{generateTranslatedText("end_hour_default", language)}</option>
                    {hours.map((opt) => (
                      <option key={opt} value={`${opt < 10 ? `0${opt}` : opt}`}>{`${opt < 10 ? `0${opt}` : opt}`}</option>
                    ))}
                  </Dropdown>
                  <Dropdown
                    id="end_time_minute"
                    className="col-span-full"
                    label=""
                    showLabel={false}
                    required={true}
                    state={timeRange}
                    stateName="endMinute"
                    onChange={setTimeRange}
                    defaultValue={timeRange.endMinute}
                  >
                    <option value="">{generateTranslatedText("end_minute_default", language)}</option>
                    {minutes.map((opt) => (
                      <option key={opt} value={`${opt < 10 ? `0${opt}` : opt}`}>{`${opt < 10 ? `0${opt}` : opt}`}</option>
                    ))}
                  </Dropdown>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Modal>

      <Container className="pt-0 overflow-y-auto h-screen scrollbar-invisible">

        {/* Header */}
        <Header
          text={generateTranslatedText("calendar", language)}
          actions={
            <ActionButtons
              primaryButton={userData?.subscription.type === "F" ? null :
                <Link
                  href={`/calendar/settings`}
                  className={`${getButtonStyle("outline", "gray")} rounded-full`}
                >
                  <Cog8ToothIcon className="h-5 w-5 text-gray-500 dark:text-gray-300" aria-hidden="true" />
                </Link>
              }
              secondaryButton={userData?.subscription.type === "F" ? null :
                <Button
                  type="button"
                  className={`${getButtonStyle("solid", "info")} rounded-full drop-shadow`}
                  onClick={openCreateEventModal}
                >
                  <PlusCircleIcon className="h-5 w-5 text-white mr-2" aria-hidden="true" />
                  {generateTranslatedText("create_event_modal", language)}
                </Button>
              }
            />
          }
        />

        {calendarLoading ? (
          <SpaceFiller>
            <Spinner colour="info" />
          </SpaceFiller>
        ) : userData?.subscription.type === "F" ? (
          <SpaceFiller>
            <NoContent
              icon={<CalendarDaysIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />}
              text={generateTranslatedText("calendar_upgrade_header", language)}
              subtext={generateTranslatedText("calendar_upgrade_subheader", language)}
              actionButton={
                <Link
                  href="/profile"
                  className={`${getButtonStyle("solid", "info")} rounded-full drop-shadow mt-4`}
                >
                  <KeyIcon className="mx-auto h-5 w-5 mr-2 text-white" aria-hidden="true" />
                  {`Upgrade`}
                </Link>
              }
            />
          </SpaceFiller>
        ) : (
          <div className="mx-1">
            <Card padding="p-0" colour="transparent" shadow="">
              <EventCalendar events={calendarData} dateRange={calendarDateRange} language={language} />
            </Card>
          </div>
        )}

      </Container>

    </Template>
  );
}
