import { getColour } from "../../utils/colours";
import { getSolidIcon } from "../../utils/icons";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import generateTranslatedText from "../../utils/boilerplate";
import { NotificationState } from "../../slices/community";
import Pill from "../layout/Pill";
import { capitalise } from "../../utils/text";
import Card from "../layout/Card";
import Button from "../layout/Button";
import { ArrowRightCircleIcon, EyeIcon, HeartIcon } from "@heroicons/react/24/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { createNotification, updateStatusOfNotification } from "../../slices/notifications";
import { useNavigate } from "react-router-dom";

export default function NotificationFeed({
  data,
  type = "small",
}: {
  data: Array<NotificationState>;
  type?: string;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Get redux store state
  const { user } = useAppSelector((state) => state.auth);

  // Functions
  function generateDescription(item: NotificationState) {
    const notificationType = item.type[0];
    const action = item.action[0];
    const sentBy = item.sent_by[1];
    if (notificationType === "R") {
      if (action === "MSG") return item.message;
      if (action === "LIKE") return `${sentBy} liked your activity! Keep up the good work!`;
      if (action === "HRT") return `${sentBy} loved your activity! Keep the momentum going!`;
      if (action === "FIRE") return `${sentBy} thinks you're on fire! Keep this hot streak up!`;
      if (action === "RKT") return `${sentBy} thinks you're out of this world! Keep reaching for the stars!`;
      if (action === "TRY") return `${sentBy} thinks you're a winner! Keep up the great work!`;
      if (action === "CEL") return `${sentBy} is celebrating your work! Keep striving for more!`;
    } else if (notificationType === "THX") {
      if (action === "MSG") return item.message;
    }
    return null;
  }

  async function handleActionButtonClick(e: any, args: any[]) {
    e.preventDefault();
    if (user === null) return;
    const [item, action] = args;

    // If action is thanks, create a notification saying thanks
    if (action === "THANKS") {
      const res: any = await dispatch(createNotification({
        sentBy: user?.profile.id,
        recipient: item.sent_by[0],
        associatedLog: null,
        type: "THX",
        action: "MSG",
        message: `${item.recipient[1]} says thanks for the support!`
      }));
      if (res.payload.status === 201) {
        dispatch(addAlert(generateTranslatedText("thanks_success", language), "success"));
      } else {
        dispatch(addAlert(generateTranslatedText("thanks_error", language), "danger"));
      }
      return;
    }

    // If action is acknowledge, update status of notification
    if (action === "ACKNOWLEDGE") {
      const res: any = await dispatch(updateStatusOfNotification({
        notification: item.id,
        status: "D"
      }));
      if (res.payload.status === 200) {
        dispatch(addAlert(generateTranslatedText("notification_mark_as_read_success", language), "success"));
      } else {
        dispatch(addAlert(generateTranslatedText("notification_mark_as_read_error", language), "danger"));
      }
      return;
    }

    // If action is view, redirect user to appropriate page
    if (action === "VIEW") {
      if (item.type[0] === "R") {
        navigate("/community")
        return;
      }
    }
    return null;
  }

  function generateActionButtons(item: NotificationState) {
    if (item.type[0] === "R") {
      return (
        <div className="grid grid-cols-3 border-t grid-rows-1 gap-y-4 sm:gap-y-0 mt-4 pt-4">
          {/* Say thanks */}
          <div className="flex justify-center">
            <Button
              type="button"
              className="inline-flex justify-center rounded-full border border-transparent hover:bg-danger/10 py-1 px-2 text-xs font-medium text-gray-400 dark:text-white hover:text-danger focus:outline-none focus:ring-2 focus:ring-danger focus:ring-offset-2"
              onClick={handleActionButtonClick}
              onClickArgs={[item, "THANKS"]}
            >
              <HeartIcon
                className="mr-2 h-4 w-4"
                aria-hidden="true"
              />
              {generateTranslatedText("say_thanks", language)}
            </Button>
          </div>

          {/* Acknowledge */}
          <div className="flex justify-center">
            <Button
              type="button"
              className="inline-flex justify-center rounded-full border border-transparent hover:bg-info/10 py-1 px-2 text-xs font-medium text-gray-400 dark:text-white hover:text-info focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
              onClick={handleActionButtonClick}
              onClickArgs={[item, "ACKNOWLEDGE"]}
            >
              <EyeIcon
                className="mr-2 h-4 w-4"
                aria-hidden="true"
              />
              {generateTranslatedText("mark_as_seen", language)}
            </Button>
          </div>

          {/* View activity */}
          <div className="flex justify-center">
            <Button
              type="button"
              className="inline-flex justify-center rounded-full border border-transparent hover:bg-success/10 py-1 px-2 text-xs font-medium text-gray-400 dark:text-white hover:text-success focus:outline-none focus:ring-2 focus:ring-success focus:ring-offset-2"
              onClick={handleActionButtonClick}
              onClickArgs={[item, "VIEW"]}
            >
              <ArrowRightCircleIcon
                className="mr-2 h-4 w-4"
                aria-hidden="true"
              />
              {generateTranslatedText("go_to_activity", language)}
            </Button>
          </div>
        </div>
      )
    }

    if (item.type[0] === "THX") {
      return (
        <div className="grid grid-cols-1 border-t grid-rows-1 gap-y-4 sm:gap-y-0 mt-4 pt-4">
          {/* Acknowledge */}
          <div className="flex justify-center">
            <Button
              type="button"
              className="inline-flex justify-center rounded-full border border-transparent hover:bg-info/10 py-1 px-2 text-xs font-medium text-gray-400 dark:text-white hover:text-info focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
              onClick={handleActionButtonClick}
              onClickArgs={[item, "ACKNOWLEDGE"]}
            >
              <EyeIcon
                className="mr-2 h-4 w-4"
                aria-hidden="true"
              />
              {generateTranslatedText("mark_as_seen", language)}
            </Button>
          </div>
        </div>
      )
    }
  }

  // Constant variables
  const language = user?.language || "EN";
  const missingDataString = generateTranslatedText("missing_data_notifications", language)

  return data.length === 0 ? (
    <div className="flow-root">
      <ul className="-mb-8">
        {type === "full" && (
          <li>
            <div className="relative bg-white drop-shadow dark:bg-zinc-900 p-8 mb-8 rounded-lg mx-1">
              <div className="relative flex items-start space-x-3">
                <div className="relative">
                  <div className="flex m-1 h-8 w-8 items-center justify-center rounded-full bg-white ring-8 ring-white text-black animate-bounce">
                    <ExclamationCircleIcon
                      className="h-8 w-8"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="min-w-0 flex-1">
                  <div className="mt-2 text-sm text-slate-600 dark:text-slate-300">
                    <p>{missingDataString}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
  ) : (
    <>
      {/* Feed */}
      <div className="flow-root">
        <ul className="-mb-8">
          {type === "full" &&
            data.filter((x: NotificationState) => ["A"].includes(x.status[0])).map((item: NotificationState, itemIdx: number) => (
              <li key={item.id}>
                <Card colour="bg-white dark:bg-zinc-900 mx-1 group mb-8">

                  <div className="flex items-start">
                    {/* Notification type */}
                    <Pill
                      text={capitalise(item.type[1])}
                      bgColour={getColour(item.type[1])}
                      textColour={getColour(item.type[1])}
                      icon={getSolidIcon(item.type[1], getColour(item.type[1]), 3, "mr-2")}
                    />
                  </div>

                  {/* Header */}
                  <h1 className="text-lg font-medium text-flint dark:text-white">
                    {generateDescription(item)}
                  </h1>
                  <span className="text-slate-400 dark:text-slate-300 text-sm mb-4">{item.datetime_created}</span>

                  {/* Action buttons */}
                  {generateActionButtons(item)}

                </Card>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
