
interface LinkProps {
  href: string;
  paddingX?: string;
  paddingY?: string;
  className?: string;
  children: React.ReactNode;
  onMouseEnter?: (e: any) => void;
  onMouseLeave?: (e: any) => void;
  onClick?: (e: any) => void;
}

export default function Link({href, paddingX, paddingY, className, children, onMouseEnter, onMouseLeave, onClick,}: LinkProps) {
  return (
    <a
      href={href}
      className={`px-${paddingX} py-${paddingY} text-sm ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {children}
    </a>
  ) 
}

Link.defaultProps = {
  className: "",
  paddingX: "3",
  paddingY: "2",
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onClick: () => {},
}
