import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";
import { buildUrl } from "../utils/proxy";

interface HistoryState {
  id: string;
  datetime_sort: number;
  datetime_created: string;
  datetime_updated: string | null;
  icon: string;
  text: string;
  type: string;
}

interface EmojiState {
  id: string;
  emoji: string;
  name: string;
}

// Define a type for the slice state
interface EmotionState {
  data: Array<HistoryState>;
  loading: boolean;
  emojis: Array<EmojiState>;
}

// Define the initial state using that type
const initialState: EmotionState = {
  data: [],
  loading: true,
  emojis: []
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get emotion entry history
export const getEmotions = createAsyncThunk(
  "emotion/get",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/emotion`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Make entry for emotion
export const emotionEntry = createAsyncThunk(
  "emotion/post",
  async (obj: { emojiId: string }, { getState, rejectWithValue }) => {
    try {
      const { auth } = getState() as { auth: AuthState };
      const { emojiId } = obj;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };
      const body = JSON.stringify({ emoji: emojiId, profile: auth.user?.profile.id });
      const res = await axios.post(buildUrl(`/api/emotion`), body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get emojis
export const getEmojis = createAsyncThunk(
  "emotion/emoji/get",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      let res = await axios.get(buildUrl(`/api/emotion/emoji`), config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const emotionSlice = createSlice({
  name: "emotion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Create entry for emotions
      .addCase(emotionEntry.pending, (state) => { })
      .addCase(emotionEntry.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(emotionEntry.rejected, (state) => {
        state.loading = false;
      })

      // Get emotions
      .addCase(getEmotions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmotions.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getEmotions.rejected, (state) => {
        state.loading = false;
      })

      // Get emojis
      .addCase(getEmojis.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmojis.fulfilled, (state, action) => {
        state.emojis = action.payload.data;
        state.loading = false;
      })
      .addCase(getEmojis.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default emotionSlice.reducer;
