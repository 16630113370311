interface StickyContentProps {
  header: string;
  subheader: string;
  image: string;
  firstParagraph: string;
  secondParagraph: string;
  bullets: React.ReactNode;
  thirdParagraph: string;
  finalHeader: string;
  finalParagraph: string;
}

export default function StickyContent({ header, subheader, image, firstParagraph, secondParagraph, bullets, thirdParagraph, finalHeader, finalParagraph }: StickyContentProps) {
  return (
    <div className="relative isolate overflow-hidden bg-white dark:bg-zinc-800 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-info">{header}</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-flint dark:text-white sm:text-4xl">{subheader}</h1>
              {firstParagraph.length > 0 && (
                <p className="mt-6 text-xl leading-8 text-slate-600 dark:text-slate-300">
                  {firstParagraph}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-2xl shadow-xl sm:w-[57rem]"
            src={image}
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-slate-600 dark:text-slate-300 lg:max-w-lg">
              {secondParagraph.length > 0 && <p>{secondParagraph}</p>}
              {bullets}
              {thirdParagraph.length > 0 && (
                <p className="mt-8">
                  {thirdParagraph}
                </p>
              )}
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-flint dark:text-white">{finalHeader}</h2>
              <p className="mt-6">
                {finalParagraph}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
