import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import { getAuth, login } from "../../slices/auth";
import generateTranslatedText from "../../utils/boilerplate";
import { LockOpenIcon } from "@heroicons/react/24/solid";
import TextInput from "../layout/TextInput";
import Spinner from "../layout/Spinner";

export default function LoginForm() {
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user, loading } = useAppSelector((state) => state.auth);

  // Get component level state
  const [loginState, setLoginState] = useState({
    email: "",
    password: "",
  });

  // Functions
  async function loginWithCredentials(e: any) {
    e.preventDefault();
    const { email, password } = loginState;
    const res: any = await dispatch(login({ email, password }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("login", language), "success"));
      dispatch(getAuth());
    } else {
      dispatch(addAlert(generateTranslatedText("login_error", language), "danger"));
    }
  }

  // Constant variables
  const language = user?.language || "EN";

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="bg-white dark:bg-zinc-800 rounded-xl p-8 w-full max-w-md space-y-8 drop-shadow">

        {/* Logo */}
        <div>
          <a href="/">
            <img
              className="mx-auto h-12 w-auto rounded-lg"
              src="/img/logo/logo_coloured_bg.svg"
              alt="Igloo"
            />
          </a>
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-flint dark:text-white">
            Sign in to your account
          </h2>
        </div>

        {/* Form */}
        <form
          className="mt-8 space-y-6"
          onSubmit={(e) => loginWithCredentials(e)}
        >
          <div className="grid grid-cols-1 gap-y-8">

            {/* Email */}
            <TextInput
              id="email"
              type="text"
              label="Email"
              className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
              required={false}
              showLabel={true}
              state={loginState}
              stateName="email"
              onChange={setLoginState}
              placeholder="What's your email address?"
            />

            {/* Password */}
            <TextInput
              id="password"
              type="password"
              label="Password"
              className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
              required={false}
              showLabel={true}
              state={loginState}
              stateName="password"
              onChange={setLoginState}
              placeholder="Enter your password"
            />
          </div>

          {/* Forgot your password */}
          <div className="flex items-center justify-between">
            <div className="text-sm">
              <a
                href="/forgot-my-password"
                className="font-medium text-gray-500 hover:text-flint focus:outline-igloo dark:text-white dark:hover:text-info hover:underline"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          {/* Submit */}
          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-full border border-transparent bg-info py-2 px-4 text-sm font-medium text-white hover:bg-info-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
              disabled={loading}
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                {loading ? (
                  <Spinner size={6} colour="white" />
                ) : (
                  <LockOpenIcon
                    className="h-5 w-5 fill-info-light group-hover:fill-white"
                    aria-hidden="true"
                  />
                )}
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
