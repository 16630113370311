import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  // Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import Landing from "./components/landing/Landing";
import Login from "./components/auth/Login";

// Redux
import { store } from "./store";
import { getAuth } from "./slices/auth";
import setAuthToken from "./utils/setAuthToken";
import Write from "./components/write/Write";
import History from "./components/history/History";
import PrivateRoute from "./components/routing/PrivateRoute";
import Trends from "./components/trends/Trends";
import Profile from "./components/profile/Profile";
import Register from "./components/auth/Register";
import About from "./components/about/About";
import Docs from "./components/documentation/Docs";
import NotFound from "./components/placeholders/NotFound";
import Goals from "./components/goals/Goals";
import Calendar from "./components/calendar/Calendar";
import GoalPlan from "./components/goals/GoalPlan";
import GoalPlanSettings from "./components/goals/GoalPlanSettings";
import EventDetail from "./components/calendar/EventDetail";
import Verification from "./components/placeholders/Verification";
import PaymentConfirmation from "./components/placeholders/PaymentConfirmation";
import VerificationConfirm from "./components/placeholders/VerificationConfirm";
import Pricing from "./components/pricing/Pricing";
import FAQs from "./components/faqs/FAQs";
import Contact from "./components/contact/Contact";
import ForgotMyPassword from "./components/auth/ForgotMyPassword";
import ResetPassword from "./components/auth/ResetPassword";
import PrivacyPolicy from "./components/privacy/PrivacyPolicy";
import TermsOfService from "./components/terms/TermsOfService";
import Overview from "./components/entity/Overview";
import Feed from "./components/community/Feed";
import Notifications from "./components/notifications/Notifications";
import Announcements from "./components/entity/Announcements";
import CalendarSettings from "./components/calendar/CalendarSettings";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  useEffect(() => {
    store.dispatch(getAuth());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Landing - Public */}
          <Route path="/" element={<Landing />} />

          {/* Login - Public */}
          <Route path="/login" element={<Login />} />

          {/* Register - Public */}
          <Route path="/register" element={<Register />} />

          {/* Forgot my Password - Public */}
          <Route path="/forgot-my-password" element={<ForgotMyPassword />} />

          {/* Forgot my Password (Recovery) - Public */}
          <Route path="/forgot-my-password/recovery" element={<ResetPassword />} />

          {/* About */}
          <Route path="/about" element={<About />} />

          {/* Pricing */}
          <Route path="/pricing" element={<Pricing />} />

          {/* FAQs */}
          <Route path="/faqs" element={<FAQs />} />

          {/* Contact */}
          <Route path="/contact" element={<Contact />} />

          {/* Privacy */}
          <Route path="/privacy" element={<PrivacyPolicy />} />

          {/* Terms of Service */}
          <Route path="/terms" element={<TermsOfService />} />

          {/* Register - Public */}
          <Route path="/register" element={<Register />} />

          {/* Write - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/write" element={<Write />} />
          </Route>

          {/* History - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/history" element={<History />} />
          </Route>

          {/* Trends - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/trends" element={<Trends />} />
          </Route>

          {/* Community - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/community" element={<Feed />} />
          </Route>

          {/* Goals - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/goals" element={<Goals />} />
          </Route>

          {/* Goal Plan - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/goals/:plan_id" element={<GoalPlan />} />
          </Route>

          {/* Goal Plan Settings - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/goals/:plan_id/settings" element={<GoalPlanSettings />} />
          </Route>

          {/* Calendar - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/calendar" element={<Calendar />} />
          </Route>

          {/* Calendar Settings - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/calendar/settings" element={<CalendarSettings />} />
          </Route>

          {/* Calendar Event Detail - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/calendar/event/:event_id" element={<EventDetail />} />
          </Route>

          {/* Profile - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/profile" element={<Profile />} />
          </Route>

          {/* Notifications - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/notifications" element={<Notifications />} />
          </Route>

          {/* Pending verification - Public */}
          <Route path="/verification" element={<Verification />} />

          {/* Verification confirmation - Public */}
          <Route path="/verification/confirm/:profileId" element={<VerificationConfirm />} />

          {/* Subscription payment confirmation - Private */}
          <Route element={<PrivateRoute allowedGroups={["Individual User"]} />}>
            <Route path="/payment/subscription/confirmation" element={<PaymentConfirmation />} />
          </Route>

          {/* Entity overview - Private */}
          <Route element={<PrivateRoute allowedGroups={["Entity Owner", "Entity Administrator"]} />}>
            <Route path="/entity/overview" element={<Overview />} />
          </Route>

          {/* Entity announcements - Private */}
          <Route element={<PrivateRoute allowedGroups={["Entity Owner", "Entity Administrator"]} />}>
            <Route path="/entity/announcements" element={<Announcements />} />
          </Route>

          {/* Not Found */}
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
