import { PlusCircleIcon } from "@heroicons/react/24/outline";

interface NoContentProps {
  text: string;
  subtext: string;
  icon?: React.ReactNode;
  actionButton: React.ReactNode | null;
}

export default function NoContent({text, subtext, icon, actionButton}: NoContentProps) {
  return (
    <div className="text-center">
      {icon}
      <h3 className="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-300">{text}</h3>
      <p className="mt-1 text-sm text-gray-500 dark:text-white">{subtext}</p>
      {actionButton}
    </div>
  )
}

NoContent.defaultProps = {
  icon: <PlusCircleIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />,
  actionButton: null
}
