 interface RangeSliderProps {
  id: string;
  start: number;
  end: number;
  step: number;
  state?: any;
  stateName?: any;
  onChange?: any;
  showLabel?: boolean;
  label?: string;
}


export default function RangeSlider({id, start, end, step, state, stateName, onChange, showLabel, label}: RangeSliderProps) {

  const pct_33 = (end - start) / 3;
  const pct_66 = pct_33 * 2;
  let rangeColour = "";
  const value = state[stateName];
  if (value > pct_33) rangeColour = "range-warning";
  if (value > pct_66) rangeColour = "range-success";
  const numSteps = ((end - start) / step) + 1;
  
  return (
    <div>
      {showLabel ? (
        <label htmlFor={id} className="mb-3 block text-sm font-medium text-gray-700 dark:text-gray-300 text-left">
          {label}
        </label>
      ) : (
        <label htmlFor={id} className="mb-3 block text-sm font-medium text-gray-700 dark:text-gray-300 text-left"/>
      )}
      <input 
        type="range"
        min={start} 
        max={end} 
        value={value}
        className={`range range-sm ${rangeColour} w-full`} 
        step={step} 
        onChange={e => onChange({...state, [stateName]: e.target.value})}

      />
      <div className="w-full flex justify-between text-xs px-2">
        {Array.from({ length: numSteps }, (_, i) => (
          <span key={i}>{start + (i * step)}</span>
        ))}
      </div>
    </div>
  )
}

RangeSlider.defaultProps = {
  state: {},
  stateName: "",
  onChange: () => {},
  label: "",
  showLabel: false,
}