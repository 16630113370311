export default function Pulse({ type = "small" }: { type?: string }) {
  return type === "small" ? (
    <div className="border border-none rounded-md p-4 w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-full bg-slate-200 dark:bg-zinc-600 h-10 w-10"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-slate-200 dark:bg-zinc-600 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-slate-200 dark:bg-zinc-600 rounded col-span-2"></div>
              <div className="h-2 bg-slate-200 dark:bg-zinc-600 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-slate-200 dark:bg-zinc-600 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  ) : type === "full" ? (
    <div className="border border-none rounded-md p-4 w-full">
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-full bg-slate-200 dark:bg-zinc-600 h-10 w-10"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 w-12/12 bg-slate-200 dark:bg-zinc-600 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-slate-200 dark:bg-zinc-600 rounded col-span-2"></div>
              <div className="h-2 w-3/6 bg-slate-200 dark:bg-zinc-600 rounded col-span-1"></div>
            </div>
            <div className="h-2 w-12/12 bg-slate-200 dark:bg-zinc-600 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  ) : type === "chart" ? (
    <div className="border border-none rounded-md p-4 h-auto w-full">
      <div className="h-full place-content-center">
        <div className="row flex-row mt-8">
          <div className="animate-pulse flex place-content-center">
            <div className="rounded-full bg-slate-200 dark:bg-zinc-600 h-28 w-28 my-auto"></div>
          </div>
        </div>

        <div className="row flex-row mt-10">
          <div className="animate-pulse flex place-content-center w-8/12 mx-auto">
            <div className="flex-1 space-y-3 py-1">
              <div className="h-2 bg-slate-200 dark:bg-zinc-600 rounded"></div>
              <div className="h-2 bg-slate-200 dark:bg-zinc-600 rounded"></div>
              <div className="h-2 bg-slate-200 dark:bg-zinc-600 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
