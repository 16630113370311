import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import { logout } from "../../slices/auth";
import generateTranslatedText from "../../utils/boilerplate";

interface Navigation {
  name: string;
  id: string;
  href: string;
  icon: any;
  alert: boolean;
  allowedGroups: string[];
}

interface DesktopSidebarProps {
  navigation: Array<Navigation>;
  // user: User;
}

export default function DesktopSidebar({ navigation }: DesktopSidebarProps) {
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user } = useAppSelector((state) => state.auth);

  // Functions
  async function logUserOut(e: any) {
    e.preventDefault();
    const res: any = await dispatch(logout());
    if (res.payload.status === 205) {
      dispatch(addAlert(generateTranslatedText("logout", language), "success"));
    } else {
      dispatch(addAlert(generateTranslatedText("logout_error", language), "danger"));
    }
  }

  // Constant variables
  const language = user?.language || "EN";

  return (
    <div className="hidden h-screen lg:flex lg:flex-shrink-0 py-3">
      <div className="flex w-20 flex-col">
        <div className="flex min-h-0 flex-1 flex-col overflow-y-auto bg-igloo rounded-r-3xl drop-shadow-md">
          <div className="flex-1">
            <div className="flex items-center justify-center py-4">
              <a href="/">
                <img
                  className="h-10 sm:h-12 w-auto"
                  src="/img/logo/logo_transparent_bg.svg"
                  alt="Igloo"
                />
              </a>
            </div>
            <nav
              aria-label="Sidebar"
              className="flex flex-col items-center space-y-3 py-6"
            >
              {navigation.map((item) => (
                <a
                  key={item.name}
                  id={item.id}
                  href={item.href}
                  className="group flex items-center rounded-full p-4 hover:bg-white/20"
                >
                  <item.icon
                    className={`h-6 w-6 text-white group-hover:text-zinc-800 dark:text-zinc-800 dark:group-hover:text-white ${item.alert === true && "animate-bounce text-danger dark:text-danger"}`}
                    aria-hidden="true"
                  />
                  <span className="sr-only">{item.name}</span>
                </a>
              ))}
              <a
                href="#!"
                className="group flex items-center rounded-full p-4 hover:bg-white/20 fixed bottom-6"
                onClick={(e) => logUserOut(e)}
              >
                <ArrowLeftCircleIcon
                  className="h-6 w-6 text-white group-hover:text-zinc-800 dark:text-zinc-800 dark:group-hover:text-white"
                  aria-hidden="true"
                />
                <span className="sr-only">Logout</span>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
