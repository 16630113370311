import Template from "../app/Template";
import Writing from "./Writing";
import Container from "../layout/Container";

export default function Write() {
  return (
    <Template>
      <Container className="w-10/12 h-full scrollbar-invisible pb-0" paddingY={0}>

        {/* Write a diary entry */}
        <Writing />
      </Container>
    </Template>
  );
}
