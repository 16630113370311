import Container from "./Container";

interface HeaderProps {
  text: string;
  metadata?: React.ReactNode;
  actions?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
}

export default function Header({ text, metadata, actions, breadcrumbs }: HeaderProps) {
  return (
    <Container className="sm:pt-6 pt-2" width="12/12" paddingY={6}>
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">

          {/* Breadcrumb */}
          {breadcrumbs !== null && <>{breadcrumbs}</>}

          {/* Title */}
          <h2 className="mt-2 text-2xl font-bold leading-7 text-flint dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">
            {text}
          </h2>

          {/* Metadata */}
          {metadata !== null && <>{metadata}</>}

        </div>

        {/* Actions */}
        {actions !== null && <>{actions}</>}
      </div>
    </Container>
  );
}

Header.defaultProps = {
  metadata: null,
  actions: null,
  breadcrumbs: null,
};