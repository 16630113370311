import { ResponsiveRadar } from "@nivo/radar";
import { getColour, getColours } from "../../../utils/colours";
import { useEffect, useState } from "react";
import getWindowDimensions from "../../../utils/dimensions";
import { randomIntFromInterval } from "../../../utils/random";

interface radarData {
  [key: string]: string | number;
}
const dummyData = Array.from(Array(10).keys()).map(x => ({ keyword: " ".repeat(x), score: randomIntFromInterval(1, 10) }));

export default function Radar({
  data,
  indexBy,
  scoreBy,
  hSmall,
  hLarge
}: {
  data: radarData[],
  indexBy: string,
  scoreBy: string[],
  hSmall: string,
  hLarge: string
}) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let heightClass = hLarge;
  if (windowDimensions.width < 992) heightClass = hSmall;

  const showPlaceholder = data.length === 0
  return (
    <div className={`${heightClass} chart-container`}>
      <ResponsiveRadar
        data={showPlaceholder ? dummyData : data}
        keys={showPlaceholder ? ["score"] : scoreBy}
        indexBy={showPlaceholder ? "keyword" : indexBy}
        valueFormat=">-.0f"
        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
        gridLevels={5}
        gridShape="circular"
        borderWidth={2}
        borderColor={{ from: "color" }}
        gridLabelOffset={20}
        dotSize={10}
        dotBorderWidth={2}
        motionConfig="wobbly"
        curve="catmullRomClosed"
        fillOpacity={0.8}
        blendMode="difference"
        colors={showPlaceholder ? getColour("placeholder") : getColours("radar-sentiment-chart")}
        isInteractive={!showPlaceholder}
      />
    </div>
  )
}
