import {
  CheckBadgeIcon,
  FaceSmileIcon,
  LockClosedIcon,
  PencilIcon,
  SparklesIcon,
  TrophyIcon
} from "@heroicons/react/20/solid";
import { useAppSelector } from "../../hooks";
import generateTranslatedText from "../../utils/boilerplate";

const primaryFeatures = [
  {
    name: 'Journal. ',
    description: "Writing is therapeutic and is proven to reduce stress and anxiety. Whether it's a quick rant or a monologue, you can securely write it or burn it.",
    icon: PencilIcon,
  },
  {
    name: 'Emotions. ',
    description: 'Emotions are so complex. Our AI takes makes them easy to visualise, track and understand. We also use emojis to help you express your feelings.',
    icon: FaceSmileIcon,
  },
  {
    name: 'Action. ',
    description: 'Mental health has always been reactive & anecdotal. We make it really clear for you to spot patterns & trends so that you can take action.',
    icon: CheckBadgeIcon,
  },
]

const secondaryFeatures = [
  {
    name: 'Set goals and check-in',
    description: "Take accountability for your mental health by setting goals and checking in on your progress. We'll help you to stay on track.",
    icon: TrophyIcon,
  },
  {
    name: 'Significant events',
    description: "Quickly log things that are happening and the Igloo will help you to spot any triggers or correlations with your mental health.",
    icon: LockClosedIcon,
  },
  {
    name: 'Recommendations',
    description: "If feeling good or bad, believe in holistic remedies or traditional practices, our system will make personalised suggestions for you.",
    icon: SparklesIcon,
  },
]

export default function LandingContent() {
  const { user, isAuthenticated, loading } = useAppSelector(
    (state) => state.auth
  );

  const language = user?.language || 'EN';
  return (
    <div className="bg-white dark:bg-zinc-800">
      <div className="container mx-auto pt-10 pb-44 bg-white dark:bg-zinc-800">
        <div className="bg-transparent pb-8 sm:pb-12 lg:pb-12">

          {/* Hero */}
          <div className="overflow-hidden pt-8 sm:pt-12 lg:relative lg:py-48">
            {/* Left side hero text and calls to action */}
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-24 lg:px-8">
              <div>
                <div className="sm:max-w-xl">
                  <h1 className="text-4xl font-bold tracking-tight text-flint dark:text-white sm:text-5xl">
                    {generateTranslatedText("landing_header", language)}
                  </h1>
                  <p className="mt-4 text-xl text-flint dark:text-white">
                    {generateTranslatedText("landing_subheader", language)}
                  </p>
                </div>

                {loading ? null : (isAuthenticated && user !== null) ? (
                  <div className="mt-6 space-x-4">
                    <a
                      href="/write"
                      className="inline-flex items-center justify-center rounded-full border border-transparent bg-igloo hover:bg-flint hover:text-white px-4 py-2 font-medium text-flint dark:text-flint dark:hover:bg-igloo-dark drop-shadow"
                    >
                      {generateTranslatedText("go_to_app", language)}
                    </a>
                  </div>
                ) : (
                  <div className="mt-6 space-x-4">
                    <a
                      href="/login"
                      className="inline-flex items-center justify-center rounded-full border border-transparent bg-transparent hover:bg-flint px-4 py-2 font-medium text-flint dark:text-white hover:text-white hover:drop-shadow"
                    >
                      {generateTranslatedText("sign_in", language)}
                    </a>
                    <a
                      href="/register"
                      className="ml-8 inline-flex items-center justify-center rounded-full border border-transparent bg-igloo px-4 py-2 text-base font-medium text-flint dark:text-flint shadow-sm hover:bg-igloo-dark drop-shadow"
                    >
                      {generateTranslatedText("sign_up", language)}
                    </a>
                  </div>
                )}
              </div>
            </div>

            {/* Right side screenshot and backdrops */}
            <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
              <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <div className="hidden sm:block">
                  {/* White backdrop */}
                  <div className="absolute inset-y-0 left-1/2 w-screen rounded-l-3xl bg-slate-100 dark:bg-igloo-dark lg:left-80 lg:right-0 lg:w-full" />

                  {/* Dotted pattern */}
                  <svg
                    className="absolute top-8 right-1/2 -mr-3 lg:left-0 lg:m-0"
                    width={404}
                    height={392}
                    fill="none"
                    viewBox="0 0 404 392"
                  >
                    <defs>
                      <pattern
                        id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className="text-igloo"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={404}
                      height={392}
                      fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                    />
                  </svg>
                </div>

                {/* Screenshot */}
                <div className="relative -mr-40 pl-4 sm:mx-auto sm:max-w-3xl sm:px-0 lg:h-full lg:max-w-none lg:pl-12">
                  <img
                    className="w-full rounded-2xl shadow-xl ring-1 ring-flint ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                    src="/img/graphics/trends.svg"
                    alt="trends"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Primary features */}
          <div className="mx-auto mt-32 max-w-7xl sm:mt-56 sm:px-6 lg:px-8">
            <div className="relative isolate overflow-hidden bg-igloo px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24 drop-shadow">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
                <div className="lg:row-start-2 lg:max-w-md">
                  <h2 className="text-3xl font-bold tracking-tight text-flint sm:text-4xl">
                    {generateTranslatedText("landing_primary_features_header", language)}
                    <br />
                  </h2>
                  <p className="mt-6 text-lg leading-8 text-slate-600">
                    {generateTranslatedText("landing_primary_features_subheader", language)}
                  </p>
                </div>
                <img
                  src="/img/graphics/write_focus.svg"
                  alt="Writing page"
                  className="relative -z-20 min-w-full max-w-xl rounded-2xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:w-[64rem] lg:max-w-none"
                  width={2432}
                  height={1442}
                />
                <div className="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-slate-600/50 lg:pt-10">
                  <dl className="max-w-xl space-y-8 text-base leading-7 lg:max-w-none">
                    {primaryFeatures.map((feature) => (
                      <div key={feature.name} className="relative">
                        <dt className="ml-9 inline-block font-semibold text-flint">
                          <feature.icon className="absolute left-1 top-1 h-5 w-5 text-info" aria-hidden="true" />
                          {feature.name}
                        </dt>{' '}
                        <dd className="inline text-slate-600">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>

            </div>
          </div>

          {/* Secondary features */}
          <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-info">{generateTranslatedText("landing_secondary_features_header", language)}</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-flint dark:text-white sm:text-4xl">
                {generateTranslatedText("landing_secondary_features_subheader", language)}
              </p>
              <p className="mt-6 text-lg leading-8 text-slate-600 dark:text-slate-300">
                {generateTranslatedText("landing_secondary_features_subtext", language)}
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                {secondaryFeatures.map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-flint dark:text-white">
                      <feature.icon className="h-5 w-5 flex-none text-info" aria-hidden="true" />
                      {feature.name}
                    </dt>
                    <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-slate-600 dark:text-slate-300">
                      <p className="flex-auto">{feature.description}</p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          {/* Sign up CTA */}
          <div className="mx-auto mt-32 max-w-7xl sm:mt-56 sm:px-6 lg:px-8">
            <div className="relative isolate overflow-hidden bg-flint dark:bg-info px-6 py-24 sm:rounded-3xl sm:px-24 xl:py-32 drop-shadow">
              <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
                {generateTranslatedText("landing_cta_header", language)}
              </h2>
              <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-slate-100">
                {generateTranslatedText("landing_cta_text", language)}
              </p>

              {/* CTA button */}
              <div className="mx-auto mt-10 max-w-md">
                <a
                  href="/write"
                  className="inline-flex items-center justify-center rounded-full border border-transparent bg-white dark:bg-white hover:bg-igloo dark:hover:bg-igloo px-4 py-2 font-medium text-flint dark:hover:text-flint drop-shadow w-full"
                >
                  {generateTranslatedText("go_to_app", language)}
                </a>
              </div>
            </div>
          </div>

          {/* Testimonials */}
          {/* <Testimonials/> */}

        </div>
      </div>
    </div>
  );
}
