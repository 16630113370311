const datepickerLocalisationLookup: any = {
  EN: "en",
  FR: "fr",
  IT: "it",
};

function getDatepickerLocalisation(language: string) {
  if (language in datepickerLocalisationLookup) {
    const text = datepickerLocalisationLookup[language];
    return text;
  }
  return "en";
}

export default getDatepickerLocalisation;
