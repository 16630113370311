import { useEffect, useState } from "react";
import Header from "../layout/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getTrends } from "../../slices/trends";
import Template from "../app/Template";
import Container from "../layout/Container";
import SpaceFiller from "../layout/SpaceFiller";
import Spinner from "../layout/Spinner";
import { ArrowTrendingUpIcon, CalendarDaysIcon, CursorArrowRaysIcon, StarIcon, TrophyIcon } from "@heroicons/react/24/solid";
import Filters from "../layout/Filters";
import { useSearchParams } from "react-router-dom";
import DonutChart from "../charts/radial/Donut";
import { getColour, getColours } from "../../utils/colours";
import LineChart from "../charts/line/Line";
import { getWidthOfLineChart } from "../../utils/charts";
import CirclePacking from "../charts/hierarchy/CirclePacking";
import Swarm from "../charts/comparison/Swarm";
import WaffleChart from "../charts/block/Waffle";
import Radar from "../charts/radial/Radar";
import generateTranslatedText from "../../utils/boilerplate";

export default function Trends() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const dateRangeTerm = searchParams.get("dateRange");

  useEffect(() => {
    dispatch(getTrends({ dateRange: dateRangeTerm }));
  }, [dispatch, dateRangeTerm]);

  const { data: trendsData, loading: trendsLoading } = useAppSelector(
    (state) => state.trends
  );
  const { user } = useAppSelector((state) => state.auth);
  const subscription = user?.subscription.type;

  // Create component level state
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  // Functions
  function createContent() {
    if (trendsLoading) {
      return (
        <SpaceFiller>
          <Spinner colour="info" />
        </SpaceFiller>
      );
    }

    let keywordSentiment: any[] = [];
    if (Object.keys(trendsData.keywords.sentiment).length > 0) {
      keywordSentiment = trendsData.keywords.sentiment.positive.map((x: any) => ({ id: x.keyword, group: "Positive", sentiment: x.score, compound: x.compound }));
      keywordSentiment.push(...trendsData.keywords.sentiment.negative.map((x: any) => ({ id: x.keyword, group: "Negative", sentiment: x.score, compound: x.compound })));
    }

    let keywordClustering: any = { name: "Clustering", children: [] };
    if (Object.keys(trendsData.keywords.hierarchy).length > 0) keywordClustering = trendsData.keywords.hierarchy;

    return (
      <>
        {/* Activity */}
        <div className="grid gap-6 grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 mb-6 mx-1 place-content-center">

          {/* Activity by type */}
          <div className="rounded-lg bg-white dark:bg-zinc-900 p-4 drop-shadow">
            <div className="flex inline-flex">
              <CursorArrowRaysIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">{generateTranslatedText("activity", language)}</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              {generateTranslatedText("activity_by_type_subheader", language)}
            </p>
            <DonutChart
              data={trendsData.activity.by_type.filter((x) => x.value === 0).length === trendsData.activity.by_type.length ? [] : trendsData.activity.by_type}
              className="lg:h-64 md:h-48 h-32"
            />
          </div>

          {/* Activity by subject */}
          <div className="rounded-lg bg-white dark:bg-zinc-900 p-4 drop-shadow">
            <div className="flex inline-flex">
              <CursorArrowRaysIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">{generateTranslatedText("activity", language)}</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              {generateTranslatedText("activity_by_subject_subheader", language)}
            </p>
            <DonutChart
              data={trendsData.activity.by_subject.filter((x) => x.value === 0).length === trendsData.activity.by_subject.length ? [] : trendsData.activity.by_subject}
              className="lg:h-64 md:h-48 h-32"
            />
          </div>
        </div>

        {/* Sentiment */}
        <div className="grid lg:gap-6 gap-y-6 grid-cols-1 grid-rows-2 lg:grid-cols-3 lg:grid-rows-1 mb-6 mx-1 place-content-center">

          {/* Sentiment distribution */}
          <div className="rounded-lg bg-white dark:bg-zinc-900 p-4 drop-shadow">
            <div className="flex inline-flex">
              <ArrowTrendingUpIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">{generateTranslatedText("sentiment", language)}</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              {generateTranslatedText("sentiment_distribution_subheader", language)}
            </p>
            <DonutChart
              data={trendsData.sentiment.distribution.filter((x) => x.value === null).length === trendsData.sentiment.distribution.length ? [] : trendsData.sentiment.distribution}
              className="lg:h-64 md:h-48 h-32"
              colours={getColours("radar-sentiment-chart")}
            />
          </div>

          {/* Sentiment time series */}
          <div className="rounded-lg bg-white dark:bg-zinc-900 p-4 drop-shadow col-span-2">
            <div className="flex inline-flex">
              <ArrowTrendingUpIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">{generateTranslatedText("sentiment", language)}</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              {generateTranslatedText("sentiment_ts_subheader", language)}
            </p>
            <LineChart
              data={trendsData.sentiment.time_series}
              xScaleInputFormat="%Y-%m-%d"
              xScaleInputPrecision="day"
              xScaleOutputFormat="%d %b %y"
              xScaleTickIncrement="every 1 days"
              padding={[40, 100, 50, 40]}
              hSmall="h-32"
              hLarge="h-64"
              widthClass={getWidthOfLineChart(trendsData.sentiment.time_series.length === 0 ? 0 : trendsData.sentiment.time_series[0].data.length)}
              colourPalette="sentiment-chart"
            />
          </div>
        </div>

        {/* Keyword clustering */}
        <div className="grid gap-6 grid-cols-1 grid-rows-1 mt-6 mx-1 place-content-center">
          <div className="rounded-lg bg-white dark:bg-zinc-900 p-4 drop-shadow">
            <div className="flex inline-flex">
              <StarIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">{generateTranslatedText("keywords", language)}</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              {subscription === "F" ? generateTranslatedText("upgrade_subscription_to_view_chart", language) : generateTranslatedText("keywords_clustering_subheader", language)}
            </p>
            <CirclePacking data={keywordClustering} />
          </div>
        </div>

        {/* Keyword sentiment */}
        <div className="grid gap-6 grid-cols-1 grid-rows-1 mt-6 mx-1 place-content-center mb-6">
          <div className="rounded-lg bg-white dark:bg-zinc-900 p-4 drop-shadow">
            <div className="flex inline-flex">
              <StarIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">{generateTranslatedText("keywords", language)}</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              {subscription === "F" ? generateTranslatedText("upgrade_subscription_to_view_chart", language) : generateTranslatedText("keywords_sentiment_subheader", language)}
            </p>
            <Swarm
              data={keywordSentiment}
              hSmall="h-32"
              hLarge="h-64"
            />
          </div>
        </div>

        {/* Goals */}
        <div className="grid lg:gap-6 gap-y-6 grid-cols-1 grid-rows-2 lg:grid-cols-3 lg:grid-rows-1 mb-6 mx-1 place-content-center">

          {/* Goal completion */}
          <div className="rounded-lg bg-white dark:bg-zinc-900 p-4 drop-shadow">
            <div className="flex inline-flex">
              <TrophyIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">{generateTranslatedText("goals", language)}</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              {subscription === "F" ? generateTranslatedText("upgrade_subscription_to_view_chart", language) : generateTranslatedText("goal_completion_pct", language)}
            </p>
            <WaffleChart
              className="mx-auto text-sm"
              data={trendsData.goals.completion === null ? [] : [
                {
                  id: generateTranslatedText("not_completed", language),
                  label: generateTranslatedText("not_completed", language),
                  value: 100 - trendsData.goals.completion
                },
                {
                  id: generateTranslatedText("completed", language),
                  label: generateTranslatedText("completed", language),
                  value: trendsData.goals.completion
                },
              ]}
              hSmall="h-32"
              hLarge="h-64"
              colour={trendsData.goals.completion === null ? [getColour("placeholder")] : [trendsData.goals.completion < 33 ? "#FF7878" : trendsData.goals.completion < 66 ? "#FFB76C" : "#DADADA", "#61DF94"]}
            />
          </div>

          {/* Goal check ins */}
          <div className="rounded-lg bg-white dark:bg-zinc-900 p-4 drop-shadow col-span-2">
            <div className="flex inline-flex">
              <TrophyIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">{generateTranslatedText("goals", language)}</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              {subscription === "F" ? generateTranslatedText("upgrade_subscription_to_view_chart", language) : generateTranslatedText("goals_check_ins", language)}
            </p>
            <LineChart
              data={trendsData.goals.check_ins.by_day}
              xScaleInputFormat="%Y-%m-%d"
              xScaleInputPrecision="day"
              xScaleOutputFormat="%d %b %y"
              xScaleTickIncrement="every 1 days"
              padding={[40, 100, 50, 40]}
              hSmall="h-32"
              hLarge="h-64"
              widthClass={trendsData.goals.check_ins.by_day.length === 0 ? "w-full" : getWidthOfLineChart(trendsData.goals.check_ins.by_day[0].data.length)}
              colourPalette="bar-chart"
            />
          </div>
        </div>

        {/* Events */}
        <div className="grid gap-6 grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 my-6 lg:mb-10 mb-32 mx-1 place-content-center">

          {/* Event type frequency */}
          <div className="rounded-lg bg-white dark:bg-zinc-900 p-4 drop-shadow">
            <div className="flex inline-flex">
              <CalendarDaysIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">{generateTranslatedText("calendar", language)}</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              {subscription === "F" ? generateTranslatedText("upgrade_subscription_to_view_chart", language) : generateTranslatedText("events_by_type", language)}
            </p>
            <DonutChart
              data={trendsData.events.type_frequency}
              className="lg:h-64 md:h-48 h-32"
            />
          </div>

          {/* Event type sentiment */}
          <div className="rounded-lg bg-white dark:bg-zinc-900 p-4 drop-shadow">
            <div className="flex inline-flex">
              <CalendarDaysIcon className="h-4 w-4 my-auto text-flint dark:text-white mr-2" />
              <h1 className="text-lg font-medium text-flint dark:text-white">{generateTranslatedText("calendar", language)}</h1>
            </div>
            <p className="text-gray-400 dark:text-gray-200 text-sm mb-4">
              {subscription === "F" ? generateTranslatedText("upgrade_subscription_to_view_chart", language) : generateTranslatedText("events_sentiment", language)}
            </p>
            <Radar
              data={trendsData.events.sentiment}
              indexBy="event_type"
              scoreBy={["Positive", "Negative", "Neutral"]}
              hSmall="h-64"
              hLarge="h-80"
            />
          </div>

        </div>
      </>
    );
  }

  // Constant variables
  const language = user?.language || "EN";

  return (
    <Template>
      {/* Charts */}
      <Container className="pt-0 overflow-y-auto h-screen scrollbar-invisible">

        {/* Header */}
        <Header text={generateTranslatedText("trends", language)} />

        <Filters
          language={language}
          className="pb-10 mx-1"
          filters={[]}
          queryParams={[
            ["dateRange", dateRangeTerm],
          ]}
          handlers={{
            dateRange: {
              state: dateRange,
              stateName: "N/A",
              onChange: setDateRange,
            },
          }}
          loading={trendsLoading}
          baseUrl="/trends"
          showDateRange={true}
          showFilters={false}
          showSearchBar={false}
          placeholderText={trendsData.date_range.start_date !== null && trendsData.date_range.end_date !== null ? `Filters = Date range: ${trendsData.date_range.start_date} - ${trendsData.date_range.end_date}` : null}
        />

        {createContent()}
      </Container>
    </Template>
  );
}
