import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
// import LandingNav from "../landing/LandingNav";
import Banner from "../layout/Banner";
import Spinner from "../layout/Spinner";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Container from "../layout/Container";
import { confirmAccountVerification } from "../../slices/auth";
import { CheckBadgeIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import Link from "../layout/Link";
import getButtonStyle from "../../utils/styles";
import SpaceFiller from "../layout/SpaceFiller";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

export default function VerificationConfirm() {
  const { loading, user } = useAppSelector((state) => state.auth);
  const { profileId } = useParams();

  // Get query parameters
  const [searchParams] = useSearchParams();
  const referralId = searchParams.get("referral_id");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(confirmAccountVerification({ profileId, referralId }));
  }, [dispatch, navigate, profileId, referralId, user?.verified]);

  return (
    <>
      <div className="w-full h-screen overflow-scroll bg-white dark:bg-flint">
        {loading ? <SpaceFiller><Spinner colour="info" /></SpaceFiller> : !user?.verified ? (
          <>
            {/* Navbar */}
            {/* <LandingNav /> */}

            {/* Content */}
            <SpaceFiller>
              <Container className="justify-center bg-slate-100 dark:bg-zinc-800 p-4 rounded-lg grid grid-cols-1 gap-y-8 drop-shadow">
                <h1 className="text-4xl font-bold tracking-tight text-flint dark:text-white sm:text-5xl mx-auto text-center">
                  We we're unable to verify your account
                </h1>
                <p className="mt-5 text-flint dark:text-white text-center">
                  Please click on your verification link again, or try re-sending another link to your inbox.
                </p>
                <ExclamationCircleIcon className="mx-auto mt-10 h-20 w-20 animate-bounce text-warning" aria-hidden="true" />
              </Container>

            </SpaceFiller>
          </>
        ) : (
          <>
            {/* Navbar */}
            {/* <LandingNav /> */}

            {/* Content */}
            <SpaceFiller>
              <Container className="justify-center bg-slate-100 dark:bg-zinc-800 p-4 rounded-lg grid grid-cols-1 gap-y-8 drop-shadow">
                <h1 className="text-4xl font-bold tracking-tight text-flint dark:text-white sm:text-5xl mx-auto text-center">
                  Your account has been verified!
                </h1>
                <p className="mt-5 text-flint dark:text-white text-center">
                  Thank you for verifying your account, you're free to enter the Igloo!
                </p>
                <CheckBadgeIcon className="mx-auto mt-10 h-20 w-20 animate-bounce text-success" aria-hidden="true" />

                <Link
                  href="/profile"
                  className={`${getButtonStyle("solid", "info")} rounded-full drop-shadow mt-4 text-center justify-center`}
                >
                  <span className="mx-auto flex inline-flex">
                    <ArrowRightCircleIcon className="h-5 w-5 mr-2 text-white" aria-hidden="true" />
                    {`Visit your profile`}
                  </span>
                </Link>
              </Container>
            </SpaceFiller>
          </>
        )}

        {/* Alert banner */}
        <Banner />
      </div>
    </>
  );
}
