import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import { register } from "../../slices/auth";
import generateTranslatedText from "../../utils/boilerplate";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LockOpenIcon } from "@heroicons/react/24/solid";
import Dropdown from "../layout/Dropdown";
import timezones from "../../utils/timezones";
import Checkboxes from "../layout/Checkboxes";
import TextInput from "../layout/TextInput";
import Spinner from "../layout/Spinner";


export default function RegisterForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user, loading } = useAppSelector((state) => state.auth);

  // Get query parameters
  const [searchParams] = useSearchParams();
  const referralId = searchParams.get("referral_id");

  const [registerState, setRegisterState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    timezone: "",
    over_18: false,
    agree_terms: false,
  });

  // Functions
  async function completeRegistration(e: any) {
    e.preventDefault();
    const {
      password,
      confirm_password: confirmPassword,
      first_name: firstName,
      last_name: lastName,
      email,
      timezone,
      over_18: over18,
      agree_terms: agreeTerms,
    } = registerState;

    // Validate booleans
    if (!over18) {
      dispatch(addAlert(generateTranslatedText("register_over_18", language), "danger"));
      return;
    }
    if (!agreeTerms) {
      dispatch(addAlert(generateTranslatedText("register_agree_terms", language), "danger"));
      return;
    }

    const res: any = await dispatch(
      register({
        password,
        confirmPassword,
        firstName,
        lastName,
        email,
        timezone,
        referralId,
        over18,
        agreeTerms
      })
    );
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("register", language), "success"));
      navigate("/verification");
    } else {
      dispatch(addAlert(generateTranslatedText("register_error", language), "danger"));
    }
  }

  // Constant variables
  const language = user?.language || "EN";

  return (
    <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="bg-white dark:bg-zinc-800 rounded-xl p-8 space-y-8 drop-shadow lg:w-[40rem] md:w-[32rem] w-96 flex-none">

        {/* Logo */}
        <div>
          <a href="/">
            <img
              className="mx-auto h-12 w-auto rounded-lg"
              src="/img/logo/logo_coloured_bg.svg"
              alt="Igloo"
            />
          </a>
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-flint dark:text-white">
            Sign up for free!
          </h2>
        </div>

        {/* Form */}
        <form
          className="mt-8 space-y-6"
          onSubmit={(e) => completeRegistration(e)}
        >
          <div className="grid grid-cols-1 gap-y-8">

            <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-4 gap-y-4">
              {/* First name */}
              <TextInput
                id="first_name"
                type="text"
                label="First name"
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                required={false}
                showLabel={true}
                state={registerState}
                stateName="first_name"
                onChange={setRegisterState}
                placeholder="What's your first name?"
              />

              {/* Last name */}
              <TextInput
                id="last_name"
                type="text"
                label="Last name"
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                required={false}
                showLabel={true}
                state={registerState}
                stateName="last_name"
                onChange={setRegisterState}
                placeholder="What's your last name?"
              />
            </div>

            {/* Email */}
            <TextInput
              id="email"
              type="text"
              label="Email"
              className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
              required={false}
              showLabel={true}
              state={registerState}
              stateName="email"
              onChange={setRegisterState}
              placeholder="What's your email address?"
            />

            {/* Timezone */}
            <Dropdown
              id="timezone"
              className="col-span-full dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600 rounded-md"
              label="Plan type"
              showLabel={true}
              required={true}
              state={registerState}
              stateName="timezone"
              onChange={setRegisterState}
              defaultValue={registerState.timezone}
            >
              <option key="" value="">What timezone are you in?</option>
              {timezones.map((opt) => (
                <option key={opt[0]} value={opt[0]}>{opt[1]}</option>
              ))}
            </Dropdown>

            <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-4 gap-y-4">
              {/* Password */}
              <TextInput
                id="password"
                type="password"
                label="Password"
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                required={false}
                showLabel={true}
                state={registerState}
                stateName="password"
                onChange={setRegisterState}
                placeholder="Choose a password"
              />

              {/* Confirm password */}
              <TextInput
                id="confirm_password"
                type="password"
                label="Confirm password"
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-info focus:bg-white focus:outline-none focus:ring-info sm:text-sm dark:bg-zinc-700 dark:border-none dark:text-white dark:focus:bg-zinc-600"
                required={false}
                showLabel={true}
                state={registerState}
                stateName="confirm_password"
                onChange={setRegisterState}
                placeholder="Confirm your password"
              />
            </div>

            {/* Confirm over 18 years of age */}
            <Checkboxes
              id="over_18"
              label="Age confirmation"
              showLabel={false}
              options={[
                [true, "Please confirm that you are over the age of 18", "By ticking this box, you are confiming that you are over the age of 18 and are legally allowed to use this service.", null, null]
              ]}
              state={registerState}
              stateName="over_18"
              onChange={setRegisterState}
              baseValue={registerState.over_18}
            />

            {/* Agreed to ToS */}
            <Checkboxes
              id="agree_terms"
              label="Agreed to Terms of Service"
              showLabel={false}
              options={[
                [true, "Do you agree to our Terms of Service?", "By ticking this box, you are confiming that you have read fully, understood and agree to our ", "Terms of Service.", "/terms"],
              ]}
              state={registerState}
              stateName="agree_terms"
              onChange={setRegisterState}
              baseValue={registerState.agree_terms}
            />
          </div>

          {/* Submit */}
          {registerState.over_18 && registerState.agree_terms && (
            <div>
              <button
                type="submit"
                className={`group relative flex w-full justify-center rounded-full border border-transparent bg-info py-2 px-4 text-sm font-medium text-white hover:bg-info-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2`}
                disabled={loading}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  {loading ? (
                    <Spinner size={6} colour="white" />
                  ) : (
                    <LockOpenIcon
                      className="h-5 w-5 fill-info-light group-hover:fill-white"
                      aria-hidden="true"
                    />
                  )}
                </span>
                Sign up
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
