import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import SearchBar from "./SearchBar";
import getButtonStyle from "../../utils/styles";
import Link from "./Link";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { capitalise } from "../../utils/text";
import Datepicker from "react-tailwindcss-datepicker";
import getDatepickerLocalisation from "../../utils/localisation";
import { formatDateStandard } from "../../utils/datetime";
import generateTranslatedText from "../../utils/boilerplate";

interface FilterValuesProps {
  id: string;
  name: string;
  options: Array<{ value: string; label: string }>;
}

interface FiltersProps {
  language: string;
  className?: string;
  filters?: FilterValuesProps[] | [];
  queryParams: Array<[string, string | null]>;
  handlers: {
    [key: string]: {
      state: any;
      stateName: string;
      onChange: (e: any) => void;
    };
  };
  loading: boolean;
  baseUrl: string;
  showSearchBar?: boolean;
  showFilters?: boolean;
  showDateRange?: boolean;
  options?: Array<[string, string]> | null;
  placeholderText?: string | null;
}

export default function Filters({
  language,
  className,
  filters,
  queryParams,
  handlers,
  loading,
  baseUrl,
  showSearchBar,
  showFilters,
  showDateRange,
  options,
  placeholderText
}: FiltersProps) {
  const [open, setOpen] = useState(false);

  function buildExistingFilters() {
    let existingFilters = "";
    queryParams.forEach((param) => {
      if (param[1] !== null) {
        if (param[0] === "dateRange") {
          const [startDate, endDate] = param[1].split("___");
          if (startDate === null || endDate === undefined) return;
          existingFilters +=
            existingFilters === ""
              ? `${generateTranslatedText("date_range", language)}: ${startDate} - ${endDate}`
              : `, ${generateTranslatedText("date_range", language)}: ${startDate} - ${endDate}`;
          return;
        }

        const paramOptions = filters?.find((filter) => filter.id === param[0])
          ?.options;
        const check = paramOptions?.find((option) => option.value === param[1]);
        let paramName = param[0];
        if (paramName.includes("_")) {
          paramName = paramName.replace("_", " ");
        }
        if (check !== undefined) {
          existingFilters +=
            existingFilters === ""
              ? `${capitalise(paramName)}: ${check.label}`
              : `, ${capitalise(paramName)}: ${check.label}`;
        } else {
          existingFilters +=
            existingFilters === ""
              ? `${capitalise(paramName)}: ${param[1]}`
              : `, ${capitalise(paramName)}: ${param[1]}`;
        }
      }
    });
    return existingFilters;
  }

  function generateLink() {
    let url = "";
    queryParams.forEach((param) => {
      const [queryParamName, queryParamValue] = param;
      if (queryParamName !== "dateRange") {
        const stateValue =
          handlers[queryParamName].stateName === "N/A"
            ? handlers[queryParamName].state
            : handlers[queryParamName].state[
            handlers[queryParamName].stateName
            ];
        if (stateValue !== "" && queryParamValue !== null)
          url +=
            url === ""
              ? `?${queryParamName}=${stateValue}`
              : `&${queryParamName}=${stateValue}`;
        if (stateValue === "" && queryParamValue !== null)
          url +=
            url === ""
              ? `?${queryParamName}=${queryParamValue}`
              : `&${queryParamName}=${queryParamValue}`;
        if (stateValue !== "" && queryParamValue === null)
          url +=
            url === ""
              ? `?${queryParamName}=${stateValue}`
              : `&${queryParamName}=${stateValue}`;
      } else {
        const stateValue = handlers[queryParamName].state;
        const splits = queryParamValue?.split("___");
        const startDate = splits?.[0];
        const endDate = splits?.[1];
        const checkState =
          stateValue.startDate !== null && stateValue.endDate !== null;
        const checkParam =
          (startDate !== null || undefined) && (endDate !== null || undefined);

        if (!checkState && !checkParam)
          url +=
            url === ""
              ? `?${queryParamName}=${stateValue.startDate}___${stateValue.endDate}`
              : `&${queryParamName}=${stateValue.startDate}___${stateValue.endDate}`;
        if (!checkState && checkParam)
          url +=
            url === ""
              ? `?${queryParamName}=${queryParamValue}`
              : `&${queryParamName}=${queryParamValue}`;
        if (checkState && checkParam)
          url +=
            url === ""
              ? `?${queryParamName}=${stateValue.startDate}___${stateValue.endDate}`
              : `&${queryParamName}=${stateValue.startDate}___${stateValue.endDate}`;
      }
    });
    if (url === "") return "#!";
    return `${baseUrl}${url}`;
  }

  function buildShortcuts() {

    // Get current date
    const todayDate = new Date();

    // Get seven days before
    const sevenDaysBefore = new Date(todayDate);
    sevenDaysBefore.setDate(todayDate.getDate() - 7);

    // Get 30 days before
    const thirtyDaysBefore = new Date(todayDate);
    thirtyDaysBefore.setDate(todayDate.getDate() - 30);

    // Get 90 days before
    const ninetyDaysBefore = new Date(todayDate);
    ninetyDaysBefore.setDate(todayDate.getDate() - 90);

    // Get 180 days before
    const oneEightyDaysBefore = new Date(todayDate);
    oneEightyDaysBefore.setDate(todayDate.getDate() - 180);

    // Get 1 year before
    const oneYearBefore = new Date(todayDate);
    oneYearBefore.setDate(todayDate.getDate() - 365);

    // Get 3 years before
    const threeYearsBefore = new Date(todayDate);
    threeYearsBefore.setDate(todayDate.getDate() - 1095);

    return {
      shortcuts: {
        last7Days: {
          text: generateTranslatedText("date_picker_last_7_days", language),
          period: {
            start: formatDateStandard(sevenDaysBefore),
            end: formatDateStandard(todayDate),
          },
        },
        last30Days: {
          text: generateTranslatedText("date_picker_last_30_days", language),
          period: {
            start: formatDateStandard(thirtyDaysBefore),
            end: formatDateStandard(todayDate),
          },
        },
        last90Days: {
          text: generateTranslatedText("date_picker_last_90_days", language),
          period: {
            start: formatDateStandard(ninetyDaysBefore),
            end: formatDateStandard(todayDate),
          },
        },
        last180Days: {
          text: generateTranslatedText("date_picker_last_180_days", language),
          period: {
            start: formatDateStandard(oneEightyDaysBefore),
            end: formatDateStandard(todayDate),
          },
        },
        lastYear: {
          text: generateTranslatedText("date_picker_last_year", language),
          period: {
            start: formatDateStandard(oneYearBefore),
            end: formatDateStandard(todayDate),
          },
        },
        last3Years: {
          text: generateTranslatedText("date_picker_last_3_years", language),
          period: {
            start: formatDateStandard(threeYearsBefore),
            end: formatDateStandard(todayDate),
          },
        },
      }
    }
  }

  return loading ? null : (
    <div className={className}>
      {/* Mobile filter dialog */}
      {showFilters && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[1000] sm:hidden"
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      {generateTranslatedText("filters", language)}
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4">
                    {filters !== undefined &&
                      filters.map((section, idx) => (
                        <Disclosure
                          as="div"
                          key={`${section.name}-${idx}`}
                          className="border-t border-gray-200 px-4 py-6"
                        >
                          {({ open }) => (
                            <>
                              <h3 className="-mx-2 -my-3 flow-root">
                                <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                                  <span className="font-medium text-gray-900">
                                    {section.name}
                                  </span>
                                  <span className="ml-6 flex items-center">
                                    <ChevronDownIcon
                                      className={`${open ? "-rotate-180" : "rotate-0"
                                        } h-5 w-5 transform`}
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel className="pt-6">
                                <div className="space-y-6">
                                  {section.options.map((option, optionIdx) => (
                                    <div
                                      key={`${option.value}-${optionIdx}`}
                                      className="flex items-center"
                                    >
                                      <input
                                        id={`filter-mobile-${section.id}-${optionIdx}`}
                                        name={`${section.id}[]`}
                                        defaultValue={option.value}
                                        type="radio"
                                        onChange={(e) =>
                                          handlers[section.id].onChange({
                                            ...handlers[section.id].state,
                                            [handlers[section.id].stateName]:
                                              e.target.value,
                                          })
                                        }
                                        className="h-4 w-4 rounded border-gray-300 text-primary"
                                      />
                                      <label
                                        htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                        className="ml-3 text-sm text-gray-500"
                                      >
                                        {option.label}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                  </form>

                  <Link
                    href={generateLink()}
                    className={`${getButtonStyle(
                      "solid",
                      "primary",
                    )} mx-2 mt-10`}
                  >
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-white mr-1"
                      aria-hidden="true"
                    />
                    {generateTranslatedText("filter", language)}
                  </Link>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}

      {/* Filters */}
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>

        <div>
          <div className="mx-auto flex items-center justify-start gap-x-4">
            {showSearchBar && (
              <SearchBar
                className="w-full"
                placeholder={generateTranslatedText("sesrch_elipses", language)}
                state={handlers.search.state}
                stateName={handlers.search.stateName}
                setState={handlers.search.onChange}
              />
            )}
            {showFilters && (
              <>
                <button
                  type="button"
                  className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
                  onClick={() => setOpen(true)}
                >
                  {generateTranslatedText("filters", language)}
                </button>
                <div className="hidden sm:block">
                  <div className="flow-root">
                    <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
                      {filters !== undefined &&
                        filters.map((section, sectionIdx) => (
                          <Popover
                            key={`${section.name}-${sectionIdx}`}
                            className="relative inline-block px-4 text-left"
                          >
                            <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-400 hover:text-zinc-800 dark:hover:text-primary">
                              <span className="whitespace-nowrap">
                                {section.name}
                              </span>
                              <ChevronDownIcon
                                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-500 group-hover:text-zinc-800 dark:group-hover:text-primary"
                                aria-hidden="true"
                              />
                            </Popover.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="absolute left-8 top-6 z-10 mt-6 origin-top-right rounded-md bg-white dark:bg-zinc-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none max-h-96 overflow-y-auto">
                                <form
                                  className="space-y-4"
                                  style={{ zIndex: 10000 }}
                                >
                                  {section.options.map((option, optionIdx) => (
                                    <div
                                      key={`${option.value}-${optionIdx}`}
                                      className="flex items-center"
                                    >
                                      <input
                                        id={`filter-${section.id}-${optionIdx}`}
                                        name={`${section.id}[]`}
                                        defaultValue={option.value}
                                        type="radio"
                                        onChange={(e) =>
                                          handlers[section.id].onChange({
                                            ...handlers[section.id].state,
                                            [handlers[section.id].stateName]:
                                              e.target.value,
                                          })
                                        }
                                        className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                        checked={
                                          handlers[section.id].state[
                                          handlers[section.id].stateName
                                          ] === option.value
                                        }
                                      />
                                      <label
                                        htmlFor={`filter-${section.id}-${optionIdx}`}
                                        className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-zinc-800 dark:text-white"
                                      >
                                        {option.label}
                                      </label>
                                    </div>
                                  ))}
                                </form>
                              </Popover.Panel>
                            </Transition>
                          </Popover>
                        ))}
                    </Popover.Group>
                  </div>
                </div>
              </>
            )}
            {showDateRange && (
              <div className="date-picker-wrapper w-full">
                <Datepicker
                  i18n={getDatepickerLocalisation(language)}
                  primaryColor={"sky"}
                  value={handlers.dateRange.state}
                  onChange={handlers.dateRange.onChange}
                  showShortcuts={true}
                  configs={buildShortcuts()}
                  inputClassName="relative transition-all duration-300 py-2.5 pl-4 pr-14 w-full border-gray-300 dark:border-zinc-500 drop-shadow tracking-wide font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 focus:ring-sky-500/20"
                />
              </div>
            )}
            {(showSearchBar || showFilters || showDateRange) && (
              <Link
                href={generateLink()}
                className={`${getButtonStyle("solid", "info")} inline-flex justify-center rounded-full border border-transparent drop-shadow text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2`}
              >
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </Link>
            )}
          </div>
        </div>

        {/* Active filters */}
        {buildExistingFilters().length === 0 ? (
          <div className="bg-transparent">
            <div className="mx-auto justify-start py-3 sm:flex sm:items-center">
              <h3 className="text-sm text-gray-400">
                {placeholderText === null ? `${generateTranslatedText("filters", language)} = ${buildExistingFilters()}` : placeholderText}
                <span className="sr-only">, active</span>
              </h3>
            </div>
          </div>
        ) : (
          <div className="bg-transparent">
            <div className="mx-auto justify-start py-3 sm:flex sm:items-center">
              <h3 className="text-sm text-gray-400">
                {`Filters = ${buildExistingFilters()}`}
                <span className="sr-only">, active</span>
              </h3>

              <div
                aria-hidden="true"
                className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block"
              />

              <div className="mt-2 sm:ml-4 sm:mt-0">
                <div className="-m-1 flex flex-wrap items-center">
                  <a
                    href={baseUrl}
                    className="group m-1 inline-flex items-center rounded-full bg-white dark:bg-zinc-900 drop-shadow border-gray-300 py-1.5 pl-3 pr-2 text-sm font-medium hover:bg-danger-light"
                  >
                    <span className="group-hover:text-danger text-gray-600 dark:text-gray-400">
                      {generateTranslatedText("clear", language)}
                    </span>
                    <span className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400">
                      <span className="sr-only">Remove all filters</span>
                      <svg
                        className="h-2 w-2 text-gray-600 group-hover:text-danger dark:text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 8 8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeWidth="1.5"
                          d="M1 1l6 6m0-6L1 7"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

Filters.defaultProps = {
  className: "",
  filters: [],
  showSearchBar: true,
  showFilters: true,
  showDateRange: false,
  options: null,
  placeholderText: null
};
