interface SingleLineTextInputProps {
  name: string;
  placeholder?: string;
  value?: string;
  onChange: any;
  required?: boolean;
}

export default function SingleLineTextInput({
  name,
  placeholder,
  value,
  onChange,
  required = true,
}: SingleLineTextInputProps) {
  return (
    <div className="-space-y-px rounded-md mt-3">
      <div>
        <label htmlFor={`${name}-input`} className="sr-only">
          {name}
        </label>
        <input
          id={`${name}-input`}
          type="text"
          name={name}
          className="relative block w-full appearance-none border-none text-gray-700 placeholder-gray-400 border-gray-500 focus:z-10 focus:border-none focus:outline-none focus:ring-transparent text-lg p-0 bg-transparent dark:text-white"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          required={required}
        />
      </div>
    </div>
  );
}
